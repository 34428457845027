import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import Skeleton from "../../components/Skeleton/ReportSkeleton"
import Paper from "@material-ui/core/Paper"
import SearchIcon from "@material-ui/icons/Search"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import { StyledTableCell } from "../../components/StyledTableCell"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import Tag from "../../components/Tag"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import { addIcon } from "../../assets/icons/icons"
import EmptyTable from "../../components/EmptyTable"
import Modal from "../../components/Modal"
import { showAlert } from "../../redux/reducers/alertReducer"
import parseQuery from "../../functions/parseQuery"

const bgStyle = {
  in_stock: "bg-blue-100",
  in_use: "bg-green-100",
  repairing: "bg-amber-100",
  in_fine: "bg-amber-100",
}

const textStyle = {
  in_stock: "text-blue-600",
  in_use: "text-green-600",
  repairing: "text-amber-600",
  in_fine: "text-amber-600",
}

function App() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)
  const userId = useSelector((state) => state.auth.userId)
  const roleId = useSelector((state) => state.auth.roleId)

  const [btnDisabled, setBtnDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [reloadData, setReloadData] = useState(false)
  const [items, setItems] = useState({})
  const [repairBtnClick, setRepairBtnClick] = useState(false)

  const pageCount = 10

  const [showModal, setShowModal] = useState(new Array(pageCount).fill(false))
  let debounce = setTimeout(() => {}, 0)
  let { status, limit, offset } = parseQuery()

  useEffect(() => {
    if (roleId === "a1ca1301-4da9-424d-a9e2-578ae6dcde02") {
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        investor_id: userId,
        status,
      })
    } else {
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, status })
    }
  }, [reloadData])

  // **** FUNCTIONS ****
  const getItems = async (params = {}) => {
    let response = await axios.get(`/car`, {
      params,
    })
    setItems(response.data)
    setIsLoading(false)
    setReloadData(false)
  }

  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      if (roleId === "a1ca1301-4da9-424d-a9e2-578ae6dcde02") {
        getItems({
          limit,
          offset: offset ?? 0,
          investor_id: userId,
          search: val,
          status,
        })
      } else {
        getItems({
          limit: limit ?? pageCount,
          offset: offset ?? 0,
          search: val,
          status,
        })
      }
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/cars`,
      search: `?limit=${pageCount}&offset=${offset}${
        status ? "&status=" + status : ""
      }`,
    })
  }

  const handleModal = (input) => {
    const updatedModalState = showModal.map((item, index) =>
      index === input ? !item : item
    )

    setShowModal(updatedModalState)
  }

  const onModalSubmit = (carId, status) => {
    axios
      .patch(`/car-status`, {
        id: carId,
        status:
          status === "in_stock_from_fine" || status === "in_stock_from_repair"
            ? status.slice(0, 8)
            : status,
      })
      .then((res) => {
        setShowModal(new Array(pageCount).fill(false))
        setReloadData(true)

        let msg
        switch (status) {
          case "repairing":
            msg = t("send.to.repair.success")
            break
          case "in_fine":
            msg = t("send.to.fine.success")
            break
          case "in_stock_from_fine":
            msg = t("bring.from.fine.success")
            break
          default:
            msg = t("bring.from.repair.success")
            break
        }
        dispatch(showAlert(msg, "success"))
        repairBtnClick && setRepairBtnClick(false)
      })
      .finally(() => setBtnDisabled(false))
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "45%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("cars")}
        endAdornment={
          permissions.includes("cars_create")
            ? [
                <Button
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() => history.push("/home/cars/create")}
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 pb-4">
        <TableContainer className="mt-4" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("number")}</StyledTableCell>

                <StyledTableCell>{t("model")}</StyledTableCell>

                <StyledTableCell>{t("brand")}</StyledTableCell>
                <StyledTableCell>{t("investor")}</StyledTableCell>

                <StyledTableCell>Срок тех. осмотра</StyledTableCell>
                <StyledTableCell>Срок страховки</StyledTableCell>
                {permissions.includes("car_status") && (
                  <StyledTableCell>{t("status")}</StyledTableCell>
                )}
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                {permissions.includes("cars_delete") && (
                  <StyledTableCell></StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.cars && items.cars?.length ? (
                items.cars.map(
                  (
                    {
                      id,
                      brand,
                      model,
                      investor,
                      state_number,
                      tech_passport_expire_date,
                      insurance_expire_date,
                      status,
                    },
                    index
                  ) => (
                    <TableRow
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer"
                      style={{ background: index % 2 === 0 && "#F6F8F9" }}
                      onClick={() => {
                        return history.push(`/home/cars/${id}`)
                      }}
                    >
                      <StyledTableCell style={{ width: "10%" }}>
                        {state_number}
                      </StyledTableCell>
                      <StyledTableCell>{model.name}</StyledTableCell>
                      <StyledTableCell>{brand.name}</StyledTableCell>
                      <StyledTableCell>{investor.name}</StyledTableCell>
                      <StyledTableCell>
                        {dateTimeConvert(insurance_expire_date).slice(0, 10)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {dateTimeConvert(tech_passport_expire_date).slice(
                          0,
                          10
                        )}
                      </StyledTableCell>
                      {permissions.includes("car_status") && (
                        <StyledTableCell>
                          <Tag
                            shape="subtle"
                            textColor={textStyle[status]}
                            bgColor={bgStyle[status]}
                            size="large"
                            style={{ width: "150px" }}
                          >
                            {t(status)}
                          </Tag>
                        </StyledTableCell>
                      )}
                      <StyledTableCell style={{ borderLeft: "1px solid #ddd" }}>
                        <Button
                          shape="outlined"
                          className="border w-full border-gray-600"
                          style={{ borderRadius: "0.2rem" }}
                          disabled={status === "in_fine"}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleModal(index)
                            setRepairBtnClick(true)
                          }}
                        >
                          {t(
                            `${
                              status !== "repairing"
                                ? "send.to.repair"
                                : "bring.from.repair"
                            }`
                          )}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell style={{ borderLeft: "1px solid #ddd" }}>
                        <Button
                          shape="outlined"
                          className="border w-full border-gray-600"
                          style={{ borderRadius: "0.2rem" }}
                          disabled={status === "repairing"}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleModal(index)
                          }}
                        >
                          {t(
                            `${
                              status !== "in_fine"
                                ? "send.to.fine"
                                : "bring.from.fine"
                            }`
                          )}
                        </Button>
                      </StyledTableCell>

                      <Modal
                        title=""
                        onClick={(e) => e.stopPropagation()}
                        onClose={(e) => {
                          e.stopPropagation()
                          setShowModal(new Array(pageCount).fill(false))
                          repairBtnClick && setRepairBtnClick(false)
                        }}
                        isOpen={showModal[index]}
                        className="cursor-default"
                        width="600px"
                      >
                        <div className="flex flex-col space-y-8">
                          <h6 className="text-gray-600 font-medium">
                            {repairBtnClick && status !== "repairing"
                              ? t("are.you.sure.you.want.to.send.car.to.repair")
                              : repairBtnClick && status === "repairing"
                              ? t(
                                  "are.you.sure.you.want.to.bring.car.from.repair"
                                )
                              : !repairBtnClick && status !== "in_fine"
                              ? t("are.you.sure.you.want.to.send.car.to.fine")
                              : t(
                                  "are.you.sure.you.want.to.bring.car.from.fine"
                                )}
                          </h6>
                          <div className="flex space-x-4 py-2 justify-end">
                            <button
                              className="
                                border-2 border-gray-600 
                                font-normal py-1.5 px-6 
                                cursor-pointer rounded
                                hover:bg-gray-100
                              "
                              onClick={() => {
                                setShowModal(new Array(pageCount).fill(false))
                                repairBtnClick && setRepairBtnClick(false)
                              }}
                            >
                              {t("no")}
                            </button>
                            <button
                              className="
                          bg-green-600 text-gray-50 
                          font-normal py-1.5 px-6 
                          cursor-pointer rounded
                          hover:opacity-90
                          "
                              disabled={btnDisabled}
                              onClick={() => {
                                setBtnDisabled(true)
                                onModalSubmit(
                                  id,
                                  repairBtnClick && status !== "repairing"
                                    ? "repairing"
                                    : repairBtnClick && status === "repairing"
                                    ? "in_stock_from_repair"
                                    : !repairBtnClick && status !== "in_fine"
                                    ? "in_fine"
                                    : "in_stock_from_fine"
                                )
                              }}
                            >
                              {repairBtnClick && status !== "repairing"
                                ? "Да, отправить в ремонт"
                                : repairBtnClick && status === "repairing"
                                ? "Привезти из ремонта"
                                : !repairBtnClick && status !== "in_fine"
                                ? "Да, отправить в штраф"
                                : "Привезти из штрафа"}
                            </button>
                          </div>
                        </div>
                      </Modal>

                      {permissions.includes("cars_delete") && (
                        <MoreClickHandler
                          id={id}
                          api="/car"
                          pushLocation="/home/cars"
                          editPermission="cars_edit"
                          deletePermission="cars_delete"
                          getItems={() => {
                            if (
                              roleId === "a1ca1301-4da9-424d-a9e2-578ae6dcde02"
                            ) {
                              getItems({
                                limit: limit ?? pageCount,
                                offset: offset ?? 0,
                                investor_id: userId,
                                status,
                              })
                            } else {
                              getItems({
                                limit: limit ?? pageCount,
                                offset: offset ?? 0,
                                status,
                              })
                            }
                          }}
                        />
                      )}
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет машин"
          isActive={!isLoading && !items.cars?.length > 0}
        />
        {isLoading && <Skeleton />}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}

export default App
