import { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { makeStyles } from "@material-ui/core/styles"
import "react-image-lightbox/style.css"

import axios from "../../../utils/axios"
import Card from "../../../components/Card"
import formatPhoneNumber from "../../../functions/formatPhoneNumber"
import EmptyImage from "../../../components/EmptyImage"
import Textarea from "../../../components/Textarea"
import fodlerIcon from "../../../assets/icons/folder.png"
import Input from "../../../components/Input"
import { CircularProgress } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import CustomButton from "../../../components/Buttons/index"
import { saveIcon, cancelIcon } from "../../../assets/icons/icons"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "7 !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function ReceiveCar({ client }) {
  const { t } = useTranslation()
  const params = useParams()
  const classes = useStyles()

  const [items, setItems] = useState({})
  const [loading, setLoading] = useState(false)
  const [costs, setCosts] = useState([])
  const [mileage, setMileage] = useState()
  const [activeImage, setActiveImage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isOnFocus, setisOnFocus] = useState(false)
  const userType = localStorage.getItem("userType")

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
      getCostPayments(params.id)
    }
  }, [])

  useEffect(() => {
    if (!isOnFocus) {
      setMileage(items?.mileage)
    }
  }, [isOnFocus])

  //   ********FUNCTIONS********

  const getProperty = async (orderId) => {
    await axios
      .get(`/recieve-car?limit=10&offset=0&order_id=${orderId}`)
      .then((res) => {
        setItems(res.data?.orders[0] ?? {})
        setMileage(res.data?.orders[0]?.mileage ?? null)
        // setInitialValues(data);
      })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let req = {
      ...items,
      mileage: +mileage,
      mechanic_id: items?.mechanic?.id,
    }
    setLoading(true)
    delete req.id
    axios
      .put(`/recieve-car/${items.id}`, req)
      .then((res) => {
        console.log("updated", res)
        setItems(res.data?.orders[0] && res.data?.orders[0])
        getProperty(params.id)
      })
      .finally(() => {
        setLoading(false)
        setisOnFocus(false)
      })
  }

  const handleMileageChange = (e) => setMileage(e.target.value)

  const getCostPayments = async (orderId) => {
    let res = await axios.get(`/cost-by-order?order_id=${orderId}`)

    setCosts(res.data?.costs)
  }

  const handleModal = () => setIsModalOpen((prev) => !prev)

  const closeModal = () => setIsModalOpen(false)

  const handleImagePreview = (imageUrl) => setActiveImage(imageUrl)

  return (
    <div className="w-full flex flex-col gap-4 box-border font-body text-sm">
      <Card title={t("about.client")} className="w-1/2">
        <div className="flex flex-col space-y-6 items-center">
          <div className="w-full py-2 flex items-center">
            <div className="w-1/3">{t("photo")}</div>
            <div className="w-2/3">
              {client?.photo ? (
                <img
                  src={`https://cdn.car24.uz/car24/${client?.photo}`}
                  alt="client"
                  style={{
                    width: "120px",
                    height: "120px",
                    objectFit: "cover",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleImagePreview(
                      `https://cdn.car24.uz/car24/${client?.photo}`
                    )
                  }
                />
              ) : (
                <EmptyImage title={t("no.image")} />
              )}
            </div>
          </div>
          {/* Name */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("first.name")}</div>
            <div className="w-2/3">
              {client.first_name + " " + client.last_name}
            </div>
          </div>
          {/* Phone */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("phone.number")}</div>
            <div className="w-2/3">
              {formatPhoneNumber(client.phone_number)}
            </div>
          </div>
        </div>
      </Card>
      {/* Document images */}
      <Card title={t("documents")} className="w-1/2">
        {items?.document_images ? (
          <div className="w-full py-2 flex items-center">
            <div className="w-1/3">
              <p className="text-gray-600">
                {items?.document_images ? t("documents") : "Нет документов"}
              </p>
            </div>
            <div className="flex w-2/3 space-x-2">
              <img
                src={fodlerIcon}
                alt={`#{image}`}
                style={{
                  minWidth: "100px",
                  maxHeight: "100px",
                  cursor: "pointer",
                }}
                onClick={() => handleModal()}
              />
            </div>
          </div>
        ) : (
          <div className="w-full py-2 flex items-center">
            <div className="w-1/3">
              <p className="text-gray-600">Нет документов</p>
            </div>
            <div className="flex w-2/3 space-x-2">
              <EmptyImage title={t("no.image")} />
            </div>
          </div>
        )}
      </Card>
      <Modal
        className={classes.modal}
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        width="400px"
      >
        <Fade in={isModalOpen}>
          <div
            className={classes.paper}
            style={{
              width: "650px",
              height: "500px",
              overflow: "scroll",
              outlineStyle: "none",
            }}
          >
            <p className="text-gray-600 pb-4">{t("documents")}</p>

            <div className="flex flex-col">
              {items?.document_images?.map(({ document_name, images }) => (
                <div className="flex space-x-2 space-y-2">
                  <p className="py-2 w-1/4">{document_name}</p>
                  <div className="flex flex-wrap gap-2">
                    {images?.map((image) => (
                      <img
                        src={`https://cdn.car24.uz/car24/${image}`}
                        alt={`#{image}`}
                        style={{
                          objectFit: "cover",
                          borderRadius: "4px",
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleImagePreview(
                            `https://cdn.car24.uz/car24/${image}`
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </Modal>
      <Card title={t("application.data")} className="w-1/2">
        {/* Mileage */}
        <div className="w-full py-2 flex items-center">
          <div className="w-1/3">
            <p className="text-gray-600">{t("mileage")}</p>
            {userType === "SUPER-ADMIN" ? (
              <div className="w-2/3">
                {isOnFocus ? (
                  <Input
                    autoFocus
                    value={isOnFocus ? mileage : items.mileage}
                    onChange={(e) => handleMileageChange(e)}
                  />
                ) : (
                  <p>
                    {items?.mileage} {t("km ")}
                  </p>
                )}
              </div>
            ) : (
              <p>
                {items?.mileage} {t("km ")}
              </p>
            )}
          </div>
          <div className="flex w-2/3 space-x-2">
            {items?.mileage_photo ? (
              <img
                src={`https://cdn.car24.uz/car24/${items?.mileage_photo}`}
                alt="mileage"
                style={{
                  objectFit: "cover",
                  borderRadius: "6px",
                  width: "100px",
                  height: "100px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleImagePreview(
                    `https://cdn.car24.uz/car24/${items?.mileage_photo}`
                  )
                }
              />
            ) : (
              <EmptyImage title={t("no.image")} />
            )}
            {userType === "SUPER-ADMIN" && (
              <div className="grid jc-end">
                {" "}
                <CustomButton
                  disabled={loading}
                  loading={loading}
                  size="small"
                  shape="text"
                  onClick={() => setisOnFocus((prev) => !prev)}
                  color={isOnFocus ? "text-error-600" : "text-primary-600"}
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={
                    isOnFocus ? (
                      cancelIcon
                    ) : (
                      <EditIcon className="bg-blue-100 rounded text-blue-600 p-0" />
                    )
                  }
                >
                  {isOnFocus ? t("cancel") : t("edit")}
                </CustomButton>
                <CustomButton
                  disabled={loading || mileage == items?.mileage}
                  loading={loading}
                  type="submit"
                  size="small"
                  shape="text"
                  color="text-primary-600"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  className={{ padding: "0", border: "none" }}
                  icon={saveIcon}
                  onClick={onSubmit}
                >
                  {t("save")}
                </CustomButton>
              </div>
            )}
          </div>
        </div>
        {/* Fuel */}
        <div className="w-full py-2 flex items-center">
          <div className="w-1/3">
            <p className="text-gray-600">{t("fuel")}</p>
            <p>
              {items?.fuel} {t("somm")}
            </p>
          </div>
          <div className="flex w-2/3 space-x-2">
            {items?.fuel_photo ? (
              <img
                src={`https://cdn.car24.uz/car24/${items?.fuel_photo}`}
                alt="fuel"
                style={{
                  objectFit: "cover",
                  borderRadius: "6px",
                  width: "100px",
                  height: "100px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleImagePreview(
                    `https://cdn.car24.uz/car24/${items?.fuel_photo}`
                  )
                }
              />
            ) : (
              <EmptyImage title={t("no.image")} />
            )}
          </div>
        </div>
      </Card>

      {/* Images */}
      <div className="space-y-4">
        <Card title={t("fines")} className="w-1/2">
          {costs?.map((cost, costIndex) =>
            cost?.expenses
              ? cost?.expenses?.map(({ comment, images }, index) => (
                  <div className="flex flex-col">
                    <div className="w-full py-2 flex items-center">
                      <div className="w-1/4">
                        <p className="text-gray-600">
                          {index === 0 && t(cost.cost_name)}
                        </p>
                      </div>
                      <div className="flex flex-col space-y-2 w-full">
                        {images?.map(({ image, comment }) => (
                          <div className="flex items-center space-x-4">
                            {(
                              <img
                                src={`https://cdn.car24.uz/car24/${image}`}
                                alt=""
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "6px",
                                  width: "150px",
                                  height: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleImagePreview(
                                    `https://cdn.car24.uz/car24/${image}`
                                  )
                                }
                              />
                            ) || <EmptyImage title={t("no.image")} />}
                            <Textarea
                              disabled
                              value={comment || "Нет комментариев"}
                              style={{ minHeight: "90px" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>

                    {costIndex !== costs?.length - 1 && <hr className="my-2" />}
                  </div>
                ))
              : ""
          )}
        </Card>
        {activeImage && (
          <Lightbox
            mainSrc={activeImage}
            onCloseRequest={() => handleImagePreview(null)}
          />
        )}
      </div>
    </div>
  )
}

export default ReceiveCar
