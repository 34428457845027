import { useState } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import EditIcon from "@material-ui/icons/Edit"
import ClearIcon from "@material-ui/icons/Clear"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"

import axios from "../../utils/axios"
import { StyledPropMenu } from "../StyledPropMenu"
import { showAlert } from "../../redux/reducers/alertReducer"
import { StyledTableCell } from "../../components/DataTable/index"

const listItems = [
  {
    title: "edit",
    icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
    type: "edit",
  },
  {
    title: "delete",
    icon: <ClearIcon className="bg-error-100 rounded text-error-600 p-0.5" />,
    type: "delete",
  },
]

function MoreClickHandler({
  id,
  api = "",
  params = {},
  pushLocation = "",
  editPermission = "",
  deletePermission = "",
  listRender = listItems,
  getItems = () => {},
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)
  const dispatch = useDispatch()

  const [dropList, setDropList] = useState({ target: null, id: "" })

  // **** EVENTS *****
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      // if edit, then go to edit page
      if (permissions.includes(editPermission)) {
        setDropList((old) => {
          return { ...old, target: null }
        })
        return history.push(`${pushLocation}/${id}`)
      } else {
        dispatch(showAlert(t("no.permission"), "warning"))
      }
    } else if (type === "delete") {
      // if delete, then delete that specific item
      if (permissions.includes(deletePermission))
        axios.delete(`${api}/${id}`).then((res) => {
          setDropList((old) => {
            return { ...old, target: null }
          })
          dispatch(showAlert(t("deleted.successfully"), "success"))
          getItems(params)
        })
    }
  }

  return (
    <StyledTableCell
      align="center"
      style={{
        width: "5%",
        borderLeft: "1px solid #ddd",
      }}
      onClick={(e) => {
        e.stopPropagation()
        setDropList({
          target: e.currentTarget,
          id: id,
        })
      }}
      className="cursor-pointer"
    >
      <MoreHorizIcon />
      <StyledPropMenu
        anchorEl={dropList.target}
        open={Boolean(dropList.target)}
        onClose={(e) => {
          e.stopPropagation()

          setDropList((old) => {
            return { ...old, target: null }
          })
        }}
      >
        <div className="flex flex-col divide-y font-body text-sm">
          {(!permissions.includes(deletePermission)
            ? listRender?.filter((el) => el.title !== "delete")
            : listRender
          ).map((el, index) => (
            <div
              className="flex w-full cursor-pointer py-2 px-2 hover:bg-gray-100"
              onClick={(e) => {
                e.stopPropagation()
                handleMoreClick(dropList.id, el.type)
              }}
              key={index}
            >
              <div className="rounded">{el.icon}</div>
              <div className="my-auto mx-2">{t(el.title)}</div>
            </div>
          ))}
        </div>
      </StyledPropMenu>
    </StyledTableCell>
  )
}

export default MoreClickHandler
