import { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import AddIcon from "@material-ui/icons/Add"

import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import Textarea from "../../components/Textarea/index"
import Select from "../../components/Select/index"
import Switch from "../../components/Switch"
import ImageUpload from "../../components/Upload/ImgUpload"
import validateForm from "../../functions/validateForm"
import PhoneInput from "../../components/Form/input/PhoneInput"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import AlertComponent from "../../components/Alert"
import { showAlert } from "../../redux/reducers/alertReducer"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"

function MechanicsCreate() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [branches, setBranches] = useState([])
  const [image, setImage] = useState("")
  const [initialValues, setInitialValues] = useState({
    status: true,
    full_name: "",
    phone_number: "",
    company_id: "",
    branch: "",
    photo: "",
    address: "",
    login: "",
    password: "",
    receive_car: null,
  })

  useEffect(() => {
    getBranches()
  }, [])

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    let [response] = await Promise.all([
      axios.get("/branch?limit=100&offset=0").then((res) => res.data),
    ])

    let listOfBranches = response?.branches?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))

    setBranches(listOfBranches)

    await axios
      .get(`/system-user/${id}`)
      .then((res) => {
        let data = {
          ...res,
          phone_number: formatPhoneNumber(res.phone_number),
          branch: { label: res.branch.name, value: res.branch.id },
        }
        setInitialValues(data)
        setImage(res.photo)
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/system-user/${params.id}`, {
          ...values,
          photo: image,
          phone_number: values.phone_number.replace(/ +/g, ""),
          status: values.status ? 1 : 0,
          branch_id: values.branch.value,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/roles/mechanics")
        })
        .catch((err) => {
          if (
            err.data?.error.includes("duplicate key") &&
            err.data?.error.includes("user_phone_pkey")
          ) {
            dispatch(showAlert(t("duplicate.key.number"), "error"))
          } else {
            dispatch(showAlert(t(err.data?.error), "error"))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/system-user", {
          ...values,
          photo: image,
          type: "mechanic",
          status: values.status ? 1 : 0,
          phone_number: values.phone_number.replace(/ +/g, ""),
          branch_id: values.branch.value,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/roles/mechanics")
        })
        .catch((err) => {
          if (
            err.data?.error.includes("duplicate key") &&
            err.data?.error.includes("user_phone_pkey")
          ) {
            dispatch(showAlert(t("duplicate.key.number"), "error"))
          } else {
            dispatch(showAlert(t(err.data?.error), "error"))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get Attribute Types and Categories
  async function getBranches() {
    let response = await axios.get("/branch?limit=100&offset=0")

    setBranches(
      response.data.branches?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    full_name: validateForm("first_name", t),
    phone_number: validateForm("phone_number", t),
    branch: validateForm("mixed", t),
  })

  const routes = [
    {
      title: t("roles"),
      link: true,
      route: "/home/settings/roles",
    },
    {
      title: t("mechanics"),
      link: true,
      route: "/home/settings/roles/mechanics",
    },
    {
      title: params.id ? initialValues.full_name : "Create",
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("mechanic")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/roles/mechanics")
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <AlertComponent
                style={{
                  top: "2px",
                  left: "45%",
                  marginLeft: "-100px",
                }}
              />
              <div className="p-4 w-full grid grid-cols-3 gap-4 box-border font-body text-sm">
                <Card title="Общие сведения">
                  <div className="flex flex-col space-y-6 items-center">
                    {/* Photo */}
                    <ImageUpload
                      onChange={(val) => setImage(val.filename)}
                      defaultImage={image}
                      style={{ width: "165px" }}
                      shape="rounded"
                    >
                      <div className="flex flex-col items-center justify-center space-y-4">
                        <AddIcon style={{ fontSize: "200%" }} />
                        <h5>{t("select.image")}</h5>
                      </div>
                    </ImageUpload>

                    {/* Name */}
                    <div className="flex flex-col w-full space-y-1">
                      <div className="text-gray-600">{t("first.name")}</div>
                      <div>
                        <Form.Item name="full_name" formik={formik}>
                          <Input
                            id="full_name"
                            type="text"
                            maxLength="30"
                            {...formik.getFieldProps("full_name")}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Phone */}
                    <div className="flex flex-col w-full space-y-1">
                      <div className="text-gray-600">{t("phone")}</div>
                      <div>
                        <Form.Item name="phone_number" formik={formik}>
                          <Input
                            id="phone_number"
                            type="text"
                            {...formik.getFieldProps("phone_number")}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "phone_number",
                                e.target.value
                              )
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Branch */}
                    <div className="flex flex-col w-full space-y-1">
                      <div className="text-gray-600">{t("branch")}</div>
                      <div>
                        <Form.Item name="branch" formik={formik}>
                          <Select
                            id="branch"
                            options={branches}
                            {...formik.getFieldProps("branch")}
                            onChange={(val) => {
                              formik.setFieldValue("branch", val)
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Status */}
                    <div className="flex flex-col w-full space-y-1">
                      <div className="text-gray-600">Статус</div>
                      <div className="flex w-2/3 space-x-4">
                        <div>
                          <Form.Item name="status" formik={formik}>
                            <Switch
                              id="status"
                              color="primary"
                              checked={formik.values.status}
                              onChange={(val) =>
                                formik.setFieldValue("status", val)
                              }
                            />
                          </Form.Item>
                        </div>
                        <h6>
                          {formik.values.status ? "Активный" : "Неактивный"}
                        </h6>
                      </div>
                    </div>
                    {/* Permission*/}
                    <div className="flex flex-col w-full space-y-1">
                      <div className="text-gray-600">Разрешить принимать заказы с задолженностью</div>
                      <div className="flex w-2/3 space-x-4">
                        <div>
                          <Form.Item name="receive_car" formik={formik}>
                            <Switch
                              id="receive_car"
                              color="primary"
                              checked={formik.values.receive_car}
                              onChange={(val) =>
                                formik.setFieldValue("receive_car", val)
                              }
                            />
                          </Form.Item>
                        </div>
                        <h6>
                          {formik.values.receive_car
                            ? "Разрешить"
                            : "Не разрешить"}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default MechanicsCreate
