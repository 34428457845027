import React from "react";
import PropTypes from "prop-types";
import { StyledInputPrice } from ".";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: " ",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 16, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const PriceInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <StyledInputPrice
      mask={currencyMask}
      {...inputProps}
      includeThousandsSeparator
      allowDecimal
      requireDecimal
      allowNegative={false}
      allowLeadingZeroes
    />
  );
};

PriceInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
};

PriceInput.propTypes = {
  inputmode: PropTypes.number,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    thousandsSeparatorSymbol: PropTypes.string,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    integerLimit: PropTypes.number,
  }),
};

export default PriceInput;
