import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import SearchIcon from "@material-ui/icons/Search"
import Paper from "@material-ui/core/Paper"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import { StyledTableCell } from "../../components/StyledTableCell"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import { addIcon } from "../../assets/icons/icons"
import EmptyTable from "../../components/EmptyTable"
import parseQuery from "../../functions/parseQuery"

export default function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10

  const [deposits, setDeposits] = useState({})
  const [balances, setBalances] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})
  let debounce = setTimeout(() => {}, 0)
  let { limit, offset } = parseQuery()

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [])

  useEffect(() => {
    items?.data?.cashboxes?.forEach((item, index) =>
      getBalances(items?.data?.cashboxes[index].id)
    )
  }, [items])

  // **** FUNCTIONS ****
  const getItems = async (params = {}) => {
    let response = await axios.get(`/cashbox`, { params })
    setItems({
      data: response.data,
    })
    setIsLoading(false)
  }

  const getBalances = async (cashbox_id) => {
    let response = await axios.get(
      `/billing-report/by?type=cashbox&id=${cashbox_id}`
    )
    setBalances((prev) =>
      prev ? [...prev, { id: cashbox_id, data: response }] : prev
    )
    setIsLoading(false)
  }

  const getDeposits = async () => {
    for (let i = 0; i < items?.data?.cashboxes?.length; i++) {
      let res = await axios.get(
        `report/deposit?cashbox_id=${items?.data?.cashboxes[i].id}`
      )
      setDeposits((prev) => ({
        ...prev,
        [items?.data?.cashboxes[i].id]: res.total_amount,
      }))
    }
    setIsLoading(false)
  }

  // ***** EVENTS *****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, search: val })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/billing/cashbox`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  useEffect(() => {
    getDeposits()
  }, [items])

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("cashbox")}
        endAdornment={
          permissions.includes("billing_cashbox_create")
            ? [
                <Button
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() => history.push("/home/billing/cashbox/create")}
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 py-4">
        <TableContainer
          className="mt-2 mt-2 bg-white p-4"
          elevation={0}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("created.date")}</StyledTableCell>
                <StyledTableCell>{t("total.income")}</StyledTableCell>
                <StyledTableCell>{t("total.expense")}</StyledTableCell>
                <StyledTableCell>{t("balance")}</StyledTableCell>
                <StyledTableCell>{t("deposit")}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.data && items.data?.cashboxes?.length ? (
                items?.data?.cashboxes.map(
                  ({ id, name, created_at }, index) => (
                    <TableRow
                      key={id}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        history.push(`/home/billing/cashbox/${id}`)
                      }}
                    >
                      <StyledTableCell>{name}</StyledTableCell>

                      <StyledTableCell>
                        <div className="text-blue-600">
                          {dateTimeConvert(created_at)}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {convertToInternationalCurrencySystem(
                          balances.filter((el) => el.id === id)[0]?.data
                            .total_debit
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {convertToInternationalCurrencySystem(
                          balances.filter((el) => el.id === id)[0]?.data
                            .total_credit
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {convertToInternationalCurrencySystem(
                          balances.filter((el) => el.id === id)[0]?.data.balance
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {convertToInternationalCurrencySystem(deposits[id])}
                      </StyledTableCell>
                      <MoreClickHandler
                        id={id}
                        api="/cashbox"
                        pushLocation="/home/billing/cashbox"
                        editPermission="billing_cashbox_edit"
                        deletePermission="billing_cashbox_delete"
                        getItems={() =>
                          getItems({
                            limit: limit ?? pageCount,
                            offset: offset ?? 0,
                          })
                        }
                      />
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable
          isActive={!isLoading && !items.data?.cashboxes?.length > 0}
        />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.data?.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}
