import ReactDOM from "react-dom"

const Backdrop = (props) => {
  return (
    <div
      className="w-screen h-screen bg-black-100 absolute top-8 left-0 cursor-default z-40"
      onClick={props.onConfirm}
    />
  )
}

export default function BackdropPortal(props) {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onConfirm={props.onConfirm} />,
        document.querySelector("#backdrop-root")
      )}
    </>
  )
}
