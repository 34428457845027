import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import SearchIcon from "@material-ui/icons/Search"
import Paper from "@material-ui/core/Paper"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import Tag from "../../components/Tag"
import Pagination from "../../components/Pagination/Pagination"
import { StyledTableCell } from "../../components/StyledTableCell"
import pdfIcon from "../../assets/icons/pdf.png"
import wordIcon from "../../assets/icons/word.png"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import EmptyTable from "../../components/EmptyTable"
import { addIcon } from "../../assets/icons/icons"
import parseQuery from "../../functions/parseQuery"
import { StyledTab, StyledTabs } from "../../components/StyledTabs"

export default function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})

  let debounce = setTimeout(() => {}, 0)
  let { limit, offset, tab } = parseQuery()

  useEffect(() => {
    getItems({
      limit: limit ?? pageCount,
      offset: offset ?? 0,
      type: +tab ? "mechanic" : "admin",
    })
  }, [limit, offset, tab])

  // **** FUNCTIONS ****
  const getItems = async (params = {}) => {
    let response = await axios.get(`/document`, { params })
    setItems({
      data: response.data,
    })
    setIsLoading(false)
  }

  // **** EVENTS *****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        type: +tab ? "mechanic" : "admin",
        search: val,
      })
    }, 300)
  }

  const changeTabLocationQuery = (tabId) => {
    history.push({
      pathname: `/home/settings/documents`,
      search: `?tab=${tabId}`,
    })
  }

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    changeTabLocationQuery(newValue)
  }

  const tabLabel = (text, number = "", isActive = false) => {
    return (
      <span
        className={`px-1 ${
          !isActive ? "text-warmGray-500" : "text-primary-600"
        }`}
      >
        {text}
      </span>
    )
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/settings/documents`,
      search: `?tab=${tab}&limit=${pageCount}&offset=${offset}`,
    })
  }

  return (
    <>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("documents")}
        endAdornment={
          permissions.includes("settings_documents") && tab == 1
            ? [
                <Button
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() =>
                    history.push("/home/settings/documents/create?tab=1")
                  }
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 py-4">
        <StyledTabs
          value={+tab}
          onChange={handleChange}
          centered={false}
          aria-label="full width tabs example"
          TabIndicatorProps={{ children: <span className="w-2" /> }}
          className="bg-white rounded"
        >
          <StyledTab
            label={tabLabel(t("for.admin"), "", +tab === 0)}
            {...a11yProps(0)}
          />
          <StyledTab
            label={tabLabel(t("for.mechanic"), "", +tab === 1)}
            {...a11yProps(1)}
          />
        </StyledTabs>
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("photo")}</StyledTableCell>
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("status")}</StyledTableCell>

                {tab == 1 && <StyledTableCell></StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.data && items.data?.documents?.length ? (
                items.data.documents.map(
                  ({ id, title, photo, status, created_at }, index) => (
                    <TableRow
                      key={id}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        history.push(
                          `/home/settings/documents/${id}?tab=${tab}`
                        )
                      }}
                    >
                      <StyledTableCell>
                        <img
                          src={photo.slice(-3) === "pdf" ? pdfIcon : wordIcon}
                          alt="icon"
                          style={{ width: "35px", height: "40px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{title}</StyledTableCell>

                      <StyledTableCell>
                        <Tag
                          shape="subtle"
                          color={status ? "blue" : "red"}
                          size="large"
                          style={{ width: "150px" }}
                        >
                          {status ? t("active") : t("inactive")}
                        </Tag>
                      </StyledTableCell>

                      {tab == 1 && (
                        <MoreClickHandler
                          id={id + `?tab=${tab}`}
                          api="/document"
                          pushLocation="/home/settings/documents"
                          editPermission="settings_documents_edit"
                          deletePermission="settings_documents_edit"
                          getItems={() =>
                            getItems({
                              limit: limit ?? pageCount,
                              offset: offset ?? 0,
                              type: +tab ? "mechanic" : "admin",
                            })
                          }
                        />
                      )}
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет документов"
          isActive={!isLoading && !items.data?.documents?.length > 0}
        />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.data?.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </>
  )
}
