import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import Select from "../../components/Select/index"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"

var expense_types = [
  { value: "company", label: "Компания" },
  { value: "physical", label: "Физ. лицо" },
  { value: "juristic", label: "Юр. лицо" },
  { value: "commission", label: "Комиссия" },
]
var amount_types = [
  { value: "percentage", label: "Процент" },
  { value: "fixed_amount", label: "Фиксированная сумма" },
]
var payment_types = [
  { value: "cash", label: "Cash" },
  { value: "uzcard", label: "Uzcard" },
  { value: "humo", label: "Humo" },
  { value: "click", label: "Click" },
  { value: "payme", label: "Payme" },
  { value: "visa", label: "Visa" },
]

const percentageRegExp = new RegExp("^[0-9][0-9]?$|^100$")

function CarExpensesCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [percentageSelected, setPercentageSelected] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: undefined,
    type: undefined,
    amount_type: undefined,
    amount: undefined,
    amount_pc: undefined,
    payment_type: undefined,
  })

  // **** FUNCTIONS ****
  const getProperty = useCallback((id) => {
    setIsGettingData(true)
    axios
      .get(`/auto-expense/${id}`)
      .then((res) => {
        const amount = {}
        if (res.amount_type === "percentage") {
          setPercentageSelected(true)
          amount.amount_pc = res?.amount
        } else {
          amount.amount = res?.amount
        }
        setInitialValues({
          ...res,
          type: expense_types.filter(
            (el, i) => expense_types[i].value === res?.type
          )[0],
          amount_type: amount_types.filter(
            (el, i) => amount_types[i].value === res?.amount_type
          )[0],
          amount: res?.amount,
          payment_type: payment_types.filter(
            (el, i) => payment_types[i].value === res?.payment_type
          )[0],
          ...amount,
        })
      })
      .finally(() => setIsGettingData(false))
  }, [])

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    setBtnDisabled(true)
    if (params.id) {
      axios
        .put(`/auto-expense/${params.id}`, {
          name: values?.name,
          type: values?.type?.value,
          amount_type: values?.amount_type.value,
          amount: percentageSelected
            ? Number(values?.amount_pc)
            : values?.amount || 0,
          payment_type: values?.payment_type?.value,
        })
        .then((res) => {
          history.push("/home/billing/car-expenses")
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    } else {
      axios
        .post("/auto-expense", {
          name: values?.name,
          type: values?.type?.value,
          amount_type: values?.amount_type.value,
          amount: values?.amount || Number(values?.amount_pc) || 0,
          payment_type: values?.payment_type?.value,
        })
        .then((res) => {
          history.push("/home/billing/car-expenses")
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    type: validateForm("mixed", t),
    amount_type: validateForm("mixed", t),
    amount: !percentageSelected && validateForm("number", t),
    amount_pc: percentageSelected && validateForm("number", t),
  })

  const routes = [
    {
      title: t("expenses.auto"),
      link: true,
      route: "/home/auto-expense",
    },

    {
      title: params.id ? initialValues.name : t("create"),
    },
  ]

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [params.id, getProperty])

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("expenses.auto")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    shape="text"
                    color="text-error-600"
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    icon={cancelIcon}
                    onClick={() => history.push("/home/billing/car-expenses")}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                    disabled={btnDisabled}
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="row-span-2">
                  {/* Name */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("name")}</div>
                    <div className="w-2/3">
                      <Form.Item name="name" formik={formik}>
                        <Input
                          id="name"
                          type="text"
                          {...formik.getFieldProps("name")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Type */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("type")}</div>
                    <div className="w-2/3">
                      <Form.Item name="type" formik={formik}>
                        <Select
                          options={expense_types}
                          {...formik.getFieldProps("type")}
                          onChange={(val) => {
                            formik.setFieldValue("type", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Amount Type */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("amount.type")}</div>
                    <div className="w-2/3">
                      <Form.Item name="amount_type" formik={formik}>
                        <Select
                          options={amount_types}
                          {...formik.getFieldProps("amount_type")}
                          onChange={(val) => {
                            formik.setFieldValue("amount_type", val)
                            val.value === "percentage"
                              ? setPercentageSelected(true)
                              : setPercentageSelected(false)
                            formik.setFieldValue("amount", undefined)
                            formik.setFieldValue("amount_pc", undefined)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Amount */}
                  {!percentageSelected && formik.values?.amount_type && (
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("sum")}</div>
                      <div className="w-2/3">
                        <Form.Item name="amount" formik={formik}>
                          <Input
                            type="number"
                            onKeyPress={(evt) => {
                              const charCodes = [65, 66, 67]
                              if (
                                evt.key.charCodeAt() < 48 ||
                                (evt.key.charCodeAt() > 57 &&
                                  !charCodes.includes(evt.key.charCodeAt()))
                              )
                                evt.preventDefault()
                            }}
                            {...formik.getFieldProps("amount")}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  {console.log(
                    formik?.values?.amount,
                    formik?.values?.amount_pc
                  )}
                  {/* Amount in Percentage */}
                  {percentageSelected && formik.values?.amount_type && (
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("percentage")}</div>
                      <div className="w-2/3">
                        <Form.Item name="amount_pc" formik={formik}>
                          <div className="flex items-center">
                            <Input
                              id="amount_pc"
                              type="number"
                              max={"100"}
                              style={{ width: "95%", marginRight: "4px" }}
                              {...formik.getFieldProps("amount_pc")}
                              onChange={(e) => {
                                if (e.target.value === "") {
                                  formik.setFieldValue(
                                    "amount_pc",
                                    e.target.value
                                  )
                                } else if (
                                  !percentageRegExp.test(e.target.value)
                                ) {
                                  e.preventDefault()
                                } else {
                                  formik.setFieldValue(
                                    "amount_pc",
                                    +e.target.value
                                  )
                                }
                              }}
                              onKeyPress={(evt) => {
                                const charCodes = [65, 66, 67]
                                if (
                                  evt.key.charCodeAt() < 48 ||
                                  (evt.key.charCodeAt() > 57 &&
                                    !charCodes.includes(evt.key.charCodeAt()))
                                )
                                  evt.preventDefault()
                              }}
                            />
                            <span className="text-xl text-gray-500">%</span>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  {/* Payment Type */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("payment.type")}</div>
                    <div className="w-2/3">
                      <Form.Item name="payment_type" formik={formik}>
                        <Select
                          options={payment_types}
                          {...formik.getFieldProps("payment_type")}
                          onChange={(val) => {
                            formik.setFieldValue("payment_type", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default CarExpensesCreate
