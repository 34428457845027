import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Breadcrumb from "../../../components/Breadcrumb/index"
import CustomButton from "../../../components/Buttons/index"
import Card from "../../../components/Card/index"
import Textarea from "../../../components/Textarea"
import cashIcon from "../../../assets/icons/cash.png"
import humoIcon from "../../../assets/icons/humo.png"
import uzcardIcon from "../../../assets/icons/uzcard.png"
import visaIcon from "../../../assets/icons/visa.png"
import clickIcon from "../../../assets/icons/click.png"
import paymeIcon from "../../../assets/icons/payme.png"
import AlertComponent from "../../../components/Alert"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import moment from "moment"

const paymentOptions = [
  {
    type: "cash",
    icon: cashIcon,
    amount: undefined,
  },
  {
    type: "humo",
    icon: humoIcon,
    amount: undefined,
  },
  {
    type: "uzcard",
    icon: uzcardIcon,
    amount: undefined,
  },
  {
    type: "visa",
    icon: visaIcon,
    amount: undefined,
  },
  {
    type: "click",
    icon: clickIcon,
    amount: undefined,
  },
  {
    type: "payme",
    icon: paymeIcon,
    amount: undefined,
  },
]

function OrderPayment() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const [isGettingData, setIsGettingData] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    if (params.history_id) {
      getProperty(params.history_id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)
    let result = await axios.get(`/payment-history/${id}`)
    setData(result.data)
    setIsGettingData(false)
  }

  // **** CONSTANTS ****
  const routes = [
    {
      title: t("orders"),
      link: true,
      route: "/home/orders?tab=0",
    },
    {
      title: params.id?.slice(0, 8),
      link: true,
      route: `/home/orders/${params.id}`,
    },
    {
      title: t("order.history"),
      link: true,
      route: `/home/orders/${params.id}`,
    },
    {
      title: params.history_id,
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <>
          <Header
            title={t("payments")}
            startAdornment={[<Breadcrumb routes={routes} />]}
            endAdornment={[
              <CustomButton
                size="large"
                shape="text"
                color="text-secondary"
                onClick={() => history.goBack()}
              >
                {t("back")}
              </CustomButton>,
            ]}
          />
          <AlertComponent
            style={{
              top: "2px",
              left: "40%",
              marginLeft: "-100px",
            }}
          />
          <div className="p-4 w-full grid grid-cols-2 grid-flow-row auto-rows-auto gap-4 box-border font-body text-sm">
            <Card title={t("order.history")}>
              <div className="space-y-4 ">
                <div className="space-y-2">
                  <div className="flex items-center w-full text-md text-gray-600">
                    <p className="w-1/2">{t("sum")}:</p>
                    <p>{convertToInternationalCurrencySystem(data?.amount)}</p>
                  </div>
                  <div className="flex items-center w-full text-md text-gray-600">
                    <p className="w-1/2">{t("payment.type")}:</p>
                    <p>{t(data?.payment_type)}</p>
                  </div>
                  <div className="flex items-center w-full text-md text-gray-600">
                    <p className="w-1/2">{t("payment.for")}:</p>
                    <p>{t(data?.subcategory)}</p>
                  </div>
                  <div className="flex items-center w-full text-md text-gray-600">
                    <p className="w-1/2">{t("date")}:</p>
                    <p>
                      {moment(data?.created_at).format("YYYY-MM-DD | HH:mm")}
                    </p>
                  </div>
                </div>
                {/* Comment */}
                <div>
                  <div className="flex w-full text-md text-gray-600">
                    <p className="w-1/2 mr-8">{t("comment")}:</p>
                    {data?.comment}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  )
}

export default OrderPayment
