import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import menu from "../../components/Sidebar/menu";

function Index() {
  const history = useHistory();

  useEffect(() => {
    console.log(
      menu.find((el, index) =>
        el.path === "/home/reports" ? history.push(el.children[0].path) : ""
      ),
      "11"
    );
  }, []);
  return <div></div>;
}

export default Index;
