import TableCell from "@material-ui/core/TableCell"
import { useTranslation } from "react-i18next"
import moment from "moment"
import RangePicker from "../DatePicker/ReportRangePicker"

const TableHeadCalendarCell = (props) => {
  const { row = 1, col = 1, title, startKey, endKey, datePicker } = props
  const { t } = useTranslation()

  const updateDate = (val) => {
    datePicker({
      [startKey]: String(moment(val[0]).toISOString().substring(0, 10)) ?? "",
      [endKey]: String(moment(val[1]).toISOString().substring(0, 10)) ?? "",
    })
  }

  return (
    <TableCell
      colSpan={col}
      rowSpan={row}
      style={{
        fontWeight: "600",
        borderRight: "1px solid #ddd",
        whiteSpace: "pre",
      }}
    >
      <div className="flex justify-between items-center whitespace-pre">
        <div className="mr-2">{t(title)}</div>
        <div>
          <RangePicker
            showToday={false}
            showWeekNumber
            dateInputPlaceholder={[t("from"), t("till")]}
            showOk={false}
            showClear
            placeholder={t("select.a.date")}
            hideTimePicker
            onChange={(val) => updateDate(val)}
          />
          {/* <img
            src={calendarIcon}
            alt="calendar icon"
            className="cursor-pointer"
          />
          {statusDates && (
            <>
              <BackdropPortal
                onConfirm={(e) => {
                  e.stopPropagation()
                  setStatusDates(undefined)
                }}
              />
              <div className="border-solid border-1 rounded-lg w-100 absolute top-6 left-0 z-50 overflow-auto bg-transparent py-6 px-4">
                <RangePicker
                  showToday={false}
                  showWeekNumber
                  dateInputPlaceholder={[t("from"), t("till")]}
                  showOk={false}
                  showClear
                  placeholder={t("select.a.date")}
                  hideTimePicker
                  // onChange={(val) => updateDate(val)}
                />
                <div className="py-36"></div>
              </div>
            </>
          )} */}
        </div>
      </div>
    </TableCell>
  )
}

export default TableHeadCalendarCell
