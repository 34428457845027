import React, { useEffect } from "react";
import { useHistory } from "react-router";

function App() {
  const history = useHistory();
  useEffect(() => history.push('organizations/companies'), [])
  return <></>;
}

export default App;
