import { useState, useEffect, useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import moment from "moment"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import EmptyTable from "../../../components/EmptyTable"
import RangePicker from "../../../components/DatePicker/RangePicker"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import dateTimeConvert from "../../../functions/dateTimeConvert"
import Pagination from "../../../components/Pagination/Pagination"
import parseQuery from "../../../functions/parseQuery"
import { useHistory } from "react-router-dom"
import SearchIcon from "@material-ui/icons/Search"
import Input from "../../../components/Input"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"
import TableHeadCell from "../../../components/TableCells/TableHeadCell"
import TableBodyCell from "../../../components/TableCells/TableBodyCell"

const useStyles = makeStyles({
  root: {
    transition: "all .1s cubic-bezier(0.94, 0.05, 0.99, 0.02);",
    "&:hover": {
      transform: "scale(1.25)",
      color: "rgb(37 99 235)",
    },
  },
})

function provideCycler() {
  function* generator(val = "") {
    var mapper = new Map([
      [0, null],
      [1, "asc"],
      [2, "desc"],
    ])
    var count = 1
    while (true) {
      yield mapper.get(count)
      if (count < 2) count++
      else count = 0
    }
  }
  var it = generator()
  return function (val) {
    return it.next(val).value
  }
}

var cycle

const initialState = {
  first_name: {
    order: null,
    arrangement: null,
  },
  average_cheque: {
    order: null,
    arrangement: null,
  },
  max_cheque: {
    order: null,
    arrangement: null,
  },
  min_cheque: {
    order: null,
    arrangement: null,
  },
  count_order: {
    order: null,
    arrangement: null,
  },
  total_price: {
    order: null,
    arrangement: null,
  },
  last_order: {
    order: null,
    arrangement: null,
  },
  created_at: {
    order: null,
    arrangement: null,
  },
}

const reducerFn = (state, { column, arrangement }) => {
  switch (arrangement) {
    case "asc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case "desc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case null:
      return initialState
    default:
      throw new Error("Error in reducer")
  }
}

const RFMAnalysisReport = () => {
  const { offset, limit } = parseQuery()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const pageCount = 10

  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [lastClickedArrow, setLastClickedArrow] = useState()
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [state, dispatch] = useReducer(reducerFn, initialState)

  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })

  const getItems = useCallback(
    async (queryParams) => {
      let response = await axios.get(`/report/rfm`, {
        params: {
          from_time: fromToDate.start,
          to_time: fromToDate.end,
          ...queryParams,
        },
      })

      setItems(response)
      setIsLoading(false)
    },
    [fromToDate]
  )

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/report/rfm-excel`, {
        params: {
          from_time: fromToDate.start,
          to_time: fromToDate.end,
        },
      })
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const updateDate = (val) =>
    setFromToDate({
      start: moment(val[0]).toISOString() ?? "",
      end: moment(val[1]).toISOString() ?? "",
    })

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/reports/rfm-analysis`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  let debounce = setTimeout(() => {}, 0)

  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        search: val,
      })
    }, 300)
  }

  const SorterArrow = useCallback(
    ({ column }) => {
      return state[column].arrangement === null ? (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer"
          classes={{ root: classes.root }}
          color="disabled"
          fontSize="medium"
          onClick={(e) => {
            if (lastClickedArrow !== e.target.closest(".arrow").id) {
              setLastClickedArrow(e.target.closest(".arrow").id)
              cycle = provideCycler()
            }
            dispatch({ column, arrangement: cycle() })
          }}
        />
      ) : state[column].arrangement === "asc" ? (
        <ArrowDownwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      ) : (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      )
    },
    [classes.root, state, lastClickedArrow]
  )

  useEffect(() => {
    var i, obj
    for (i in state) {
      if (state.hasOwnProperty(i) && state[i].order && state[i].arrangement) {
        obj = state[i]
      }
    }
    getItems({
      limit: pageCount,
      offset: offset ?? 0,
      order: obj?.order,
      arrangement: obj?.arrangement,
    })
  }, [fromToDate, getItems, offset, state])

  return (
    <div>
      <Header title={t("RFMAnalysisTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        <div className="flex">
          <RangePicker
            showToday={false}
            showWeekNumber
            dateInputPlaceholder={[t("from"), t("till")]}
            showOk={false}
            showClear
            placeholder={t("select.a.date")}
            hideTimePicker
            onChange={(val) => updateDate(val)}
          />
          <div className="flex space-x-2 ml-4">
            <Input
              icon={<SearchIcon className="text-gray-400" />}
              placeholder={t("search...")}
              style={{ width: "350px" }}
              onChange={(val) => {
                onSearch(val.target.value)
                setIsLoading(true)
              }}
            />
          </div>
        </div>
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell row={2} title="&#8470;" />
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow"
                  id="arrow0"
                >
                  {t("client")} {<SorterArrow column="first_name" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow"
                  id="arrow1"
                >
                  {t("average.check")} {<SorterArrow column="average_cheque" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow"
                  id="arrow2"
                >
                  {t("biggest.check")} {<SorterArrow column="max_cheque" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow"
                  id="arrow3"
                >
                  {t("smallest.check")} {<SorterArrow column="min_cheque" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "600",
                    borderRight: "1px solid #ddd",
                  }}
                  className="arrow"
                  id="arrow4"
                >
                  {t("order.count")} {<SorterArrow column="count_order" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "600",
                    borderRight: "1px solid #ddd",
                  }}
                  className="arrow"
                  id="arrow5"
                >
                  {t("total.price")} {<SorterArrow column="total_price" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "600",
                    borderRight: "1px solid #ddd",
                  }}
                  className="arrow"
                  id="arrow6"
                >
                  {t("latest.order")} {<SorterArrow column="last_order" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow"
                  id="arrow7"
                >
                  {t("registration.datetime")}{" "}
                  {<SorterArrow column="created_at" />}
                </TableCell>
              </TableRow>
            </TableHead>

            {!isLoading &&
              items?.reports?.map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    background: index % 2 === 0 && "#F6F8F9",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/home/clients/${item.id}`)}
                >
                  <TableBodyCell title={index + 1} fontWeight="600" />
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {item?.first_name + "\n"}
                    {item?.last_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {item?.average_cheque}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {item?.max_cheque}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {item?.min_cheque}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {item?.count_order}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.total_price)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {dateTimeConvert(item?.last_order)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {dateTimeConvert(item?.created_at)}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет отчетов"
          isActive={!isLoading && !items?.count > 0}
        />
        {isLoading && <ReportSkeleton />}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items?.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}

export default RFMAnalysisReport
