import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import SearchIcon from "@material-ui/icons/Search"
import { CLEAR_ON_SIGNOUT } from "../../redux/constants"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import TableContext from "./TableContext"
import AlertComponent from "../../components/Alert"
import { addIcon } from "../../assets/icons/icons"
import parseQuery from "../../functions/parseQuery"
import { CircularProgress } from "@material-ui/core"

const cStatuses = [
  "new",
  "client_took",
  "client_returned",
  "completed",
  "cancelled",
]

export default function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10
  const [pageLimit, setPageLimit] = useState(pageCount)

  const [isLoading, setIsLoading] = useState(true)

  const [itemCounts, setItemCounts] = useState([])
  const [items, setItems] = useState({})
  const [showModal, setShowModal] = useState(new Array(pageCount).fill(false))

  let debounce = setTimeout(() => {}, 0)
  let {
    tab,
    limit,
    offset,
    order,
    from_dashboard,
    from_time,
    to_time,
    arrangement,
  } = parseQuery()

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch({ type: CLEAR_ON_SIGNOUT })
    localStorage.removeItem("token")
    localStorage.removeItem("refresh-token")
  }

  useEffect(() => {
    getItems({
      limit,
      offset: offset ?? 0,
      tab: tab ?? 0,
      status: cStatuses[tab ?? 0],
      order: order,
      from_time,
      to_time,
      arrangement,
    })
  }, [])

  useEffect(() => {
    getItemCounts()
  }, [])

  const changeTabLocationQuery = (tabId) => {
    history.push({
      pathname: `/home/orders`,
      search: `?tab=${tabId}`,
    })
  }

  const changePaginationLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/orders`,
      search: `?limit=${pageCount}&offset=${offset}&tab=${tab ?? 0}${
        order ? "&order=" + order : ""
      }${arrangement ? "&arrangement=" + arrangement : ""}`,
    })
  }

  // **** FUNCTIONS ****
  const getItems = async (params = {}) => {
    let response = await axios.get(`/order`, {
      params,
    })

    if (response.status == "401") {
      logoutHandler()
    } else {
      setItems({
        data: response.data,
      })
      setIsLoading(false)
    }
  }

  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        tab: tab ?? 0,
        search: val,
        status: cStatuses[tab ?? 0],
        from_time,
        to_time,
        arrangement,
      })
    }, 300)
  }

  const getItemCounts = async () => {
    for (const el of cStatuses) {
      let result = await axios.get(`/order`, {
        params: {
          limit: pageCount,
          offsett: pageLimit - pageCount,
          status: el,
        },
      })
      setItemCounts((old) => [...old, result.data.count])
    }
  }

  const handleModal = (input) => {
    const updatedModalState = showModal.map((item, index) =>
      index === input ? !item : item
    )
    setShowModal(updatedModalState)
  }

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => changeTabLocationQuery(newValue)

  const tabLabel = (text, number, isActive = false) => {
    return (
      <div className="flex items-center px-2">
        <span
          className={`${!isActive ? "text-warmGray-500" : "text-primary-600"}`}
        >
          {text}
        </span>
        <span
          style={{
            minWidth: "30px",
            height: "32px",
            background: !isActive
              ? "rgba(110, 139, 183, 0.75)"
              : "rgba(64, 148, 247, 1)",
          }}
          className="
            flex items-center justify-center 
            rounded text-white 
            py-2 px-1 ml-1.5 text-xs
          "
        >
          {number ?? <CircularProgress size={12} color="#fff" />}
        </span>
      </div>
    )
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "40%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("orders")}
        endAdornment={
          permissions.includes("orders_create")
            ? [
                <Button
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  shape="text"
                  color="text-secondary"
                  icon={addIcon}
                  onClick={() => history.push("/home/orders/create")}
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>
      <TableContext
        statuses={cStatuses}
        value={+tab ?? 0}
        offset={offset}
        itemCounts={itemCounts}
        permissions={permissions}
        items={items}
        pageCount={pageCount}
        getItems={getItems}
        getItemCounts={getItemCounts}
        handleChange={handleChange}
        a11yProps={a11yProps}
        tabLabel={tabLabel}
        handleModal={handleModal}
        setShowModal={setShowModal}
        setPageLimit={setPageLimit}
        changePaginationLocationQuery={changePaginationLocationQuery}
        showModal={showModal}
        isLoading={isLoading}
        from_dashboard={from_dashboard}
        order={order}
        from_time={from_time}
        to_time={to_time}
        arrangement={arrangement}
        cStatuses={cStatuses}
      />
    </div>
  )
}
