import { useState, useEffect } from "react"
import axios from "../../../utils/axios"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Header from "../../../components/Header/Header"
import Breadcrumb from "../../../components/Breadcrumb/index"
import CustomButton from "../../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "../../../components/Card/index"
// import Skeleton from "@material-ui/lab/Skeleton";

import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../../components/Form/Index"
import Input from "../../../components/Input/index"
import Select from "../../../components/Select/index"
import AsyncSelect from "../../../components/Select/AsyncSelect"
import DatePicker from "../../../components/DatePicker/index"
import moment from "moment"

const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 10.8332H10.8334V14.9998C10.8334 15.4582 10.4584 15.8332 10.0001 15.8332C9.54175 15.8332 9.16675 15.4582 9.16675 14.9998V10.8332H5.00008C4.54175 10.8332 4.16675 10.4582 4.16675 9.99984C4.16675 9.5415 4.54175 9.1665 5.00008 9.1665H9.16675V4.99984C9.16675 4.5415 9.54175 4.1665 10.0001 4.1665C10.4584 4.1665 10.8334 4.5415 10.8334 4.99984V9.1665H15.0001C15.4584 9.1665 15.8334 9.5415 15.8334 9.99984C15.8334 10.4582 15.4584 10.8332 15.0001 10.8332Z"
      fill="#703581"
    />
  </svg>
)

function OrderHistoryCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [tariffs, setTariffs] = useState([])
  const [additionalTariffs, setAdditionalTariffs] = useState([])
  const [cars, setCars] = useState([])
  const [clients, setClients] = useState([])
  const [statuses, setStatuses] = useState([])
  const [listData, setListData] = useState({
    additional_tariffs: [],

    car: {},
    client: {},
    status: {},
    tariff: {},

    from_time: "",
    to_time: "",
  })
  const [initialValues, setInitialValues] = useState({
    additional_tariffs: [],

    car_id: "",
    client_id: "",
    status_id: "",
    tariff_id: "",

    paid_price: 0,
    total_price: 0,

    source: "operator",

    from_time: "",
    to_time: "",
  })

  useEffect(() => {
    if (params.order_id) {
      getProperty(params.order_id)
    }

    // get all the data
    getTariffs()
    getAdditionalTariffs()
    getCars()
    getClients()
    getStatuses()
  }, [])

  useEffect(() => {
    console.log(initialValues)
  }, [initialValues])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/order/${id}`)
      .then((res) => {
        let data = {
          ...res,
        }
        setInitialValues(data)
        setListData((old) => {
          return {
            ...old,
            additional_tariffs: res.additional_tariffs?.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
            tariff: { label: res.tariff.name, value: res.tariff.id },
            car: { label: res.car.state_number, value: res.car.id },
            status: { label: res.status.name, value: res.status.id },
            client: {
              label: res.client.phone_number,
              value: res.client.id,
            },

            from_time: res.from_time,
            to_time: res.to_time,
          }
        })
      })
      .finally(() => setIsGettingData(false))
  }

  const getTariffs = async () => {
    let res = await axios.get(`/tariff?limit=10&offset=0`)

    setTariffs(
      res.data.tariffs.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const getAdditionalTariffs = async () => {
    let res = await axios.get(`/additional-tariff?limit=10&offset=0`)

    setAdditionalTariffs(
      res.data.additional_tariffs.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const getCars = async () => {
    let res = await axios.get(`/car?limit=5&offset=0`)

    setCars(
      res.data.cars.map(({ id, state_number }) => ({
        label: state_number,
        value: id,
      }))
    )
  }

  const getClients = async () => {
    let res = await axios.get(`/client?limit=5&offset=0`)

    setClients(
      res.data.clients.map(({ id, phone_number }) => ({
        label: phone_number,
        value: id,
      }))
    )
  }

  const getStatuses = async () => {
    let res = await axios.get(`/status?limit=10&offset=0`)

    setStatuses(
      res.data.statuses.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const loadClientOptions = async (inputValue, callback) => {
    let res = await axios.get(`/client?limit=5&offset=0&search=${inputValue}`)
    console.log(res.data.clients, "res")
    callback(
      res.data.clients.map(({ id, phone_number }) => {
        return {
          value: id,
          label: phone_number,
        }
      })
    )
  }
  const loadCarOptions = async (inputValue, callback) => {
    let res = await axios.get(`/car?limit=5&offset=0&search=${inputValue}`)
    console.log(res.data.cars, "res")
    callback(
      res.data.cars.map(({ id, state_number }) => {
        return {
          value: id,
          label: state_number,
        }
      })
    )
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.order_id) {
      axios
        .put(`/order/${params.order_id}`, {
          ...values,
          additional_tariffs: listData.additional_tariffs.map(
            ({ value }) => value
          ),
          tariff_id: listData.tariff.value,
          car_id: listData.car.value,
          status_id: listData.status.value,
          client_id: listData.client.value,
          from_time: listData.from_time,
          to_time: listData.to_time,
          discount: values.discount === "" ? 0 : values.discount,
          estimated_prolong_day:
            values.estimated_prolong_day === ""
              ? 0
              : values.estimated_prolong_day,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/orders")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/order", {
          ...values,
          additional_tariffs: listData.additional_tariffs.map(
            ({ value }) => value
          ),
          tariff_id: listData.tariff.value,
          car_id: listData.car.value,
          status_id: listData.status.value,
          client_id: listData.client.value,
          from_time: listData.from_time,
          to_time: listData.to_time,
          discount: values.discount === "" ? 0 : values.discount,
          estimated_prolong_day:
            values.estimated_prolong_day === ""
              ? 0
              : values.estimated_prolong_day,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/orders")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const updateTariff = (val) => {
    setListData((old) => {
      return { ...old, tariff: val }
    })
  }

  const updateAdditionalTariffs = (val) => {
    setListData((old) => {
      return {
        ...old,
        additional_tariffs: val,
      }
    })
  }

  const updateCar = (val) => {
    setListData((old) => {
      return { ...old, car: val }
    })
  }

  const updateClient = (val) => {
    setListData((old) => {
      return { ...old, client: val }
    })
  }

  const updateStatus = (val) => {
    setListData((old) => {
      return { ...old, status: val }
    })
  }

  const updateFromTime = (val) => {
    setListData((old) => {
      return { ...old, from_time: moment(val).toISOString() }
    })
  }

  const updateToTime = (val) => {
    setListData((old) => {
      return { ...old, to_time: moment(val).toISOString() }
    })
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    // status: Yup.bool().required(t("required.field.error")),
  })

  const routes = [
    {
      title: t("orders"),
      link: true,
      route: "/home/orders",
    },

    {
      title: params.order_id ? listData.car.label : t("create"),
    },
  ]

  return (
    <div>
      {!params.order_id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("order")}
                startAdornment={[<Breadcrumb routes={routes} />]}
              />

              <div className="p-4 w-full flex flex-col gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="w-1/2">
                  {/* Tariff */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("tariff")}</div>
                    <div className="w-2/3">
                      <Form.Item name="tariff_id" formik={formik}>
                        <Select
                          isDisabled
                          options={tariffs}
                          onChange={(val) => updateTariff(val)}
                          value={listData.tariff}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Additional tariffs */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("additional.tariffs")}</div>
                    <div className="w-2/3">
                      <Form.Item name="additional_tariffs" formik={formik}>
                        <Select
                          isDisabled
                          options={additionalTariffs}
                          onChange={(val) => updateAdditionalTariffs(val)}
                          value={listData.additional_tariffs}
                          isMulti
                          hideSelectedOptions
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Car */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("car")}</div>
                    <div className="w-2/3">
                      <Form.Item name="car_id" formik={formik}>
                        <AsyncSelect
                          isDisabled
                          loadOptions={loadCarOptions}
                          defaultOptions={cars}
                          placeholder=""
                          onChange={(val) => updateCar(val)}
                          value={listData.car}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Client */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("client")}</div>
                    <div className="w-2/3">
                      <Form.Item name="client_id" formik={formik}>
                        <AsyncSelect
                          isDisabled
                          loadOptions={loadClientOptions}
                          defaultOptions={clients}
                          placeholder=""
                          onChange={(val) => updateClient(val)}
                          value={listData.client}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Status */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("status")}</div>
                    <div className="w-2/3">
                      <Form.Item name="status_id" formik={formik}>
                        <Select
                          isDisabled
                          options={statuses}
                          onChange={(val) => updateStatus(val)}
                          value={listData.status}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* Paid price */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("paid.price")}</div>
                    <div className="w-2/3">
                      <Form.Item name="paid_price" formik={formik}>
                        <Input
                          disabled
                          id="paid_price"
                          type="number"
                          {...formik.getFieldProps("paid_price")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Total price */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("total.price")}</div>
                    <div className="w-2/3">
                      <Form.Item name="total_price" formik={formik}>
                        <Input
                          disabled
                          id="total_price"
                          type="number"
                          {...formik.getFieldProps("total_price")}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* From time */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("from")}</div>
                    <div className="w-2/3">
                      <Form.Item name="from_time" formik={formik}>
                        <DatePicker
                          disabled
                          style={{ height: "35px" }}
                          value={
                            listData.from_time
                              ? moment(listData.from_time)
                              : null
                          }
                          onChange={(val) => updateFromTime(val)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* To time */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("till")}</div>
                    <div className="w-2/3">
                      <Form.Item name="to_time" formik={formik}>
                        <DatePicker
                          disabled
                          style={{ height: "35px" }}
                          value={
                            listData.to_time ? moment(listData.to_time) : null
                          }
                          onChange={(val) => updateToTime(val)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default OrderHistoryCreate
