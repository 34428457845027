import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";

import axios from "../../utils/axios";
import Card from "../../components/Card";
import Select from "../../components/Select/index";
import CustomButton from "../../components/Buttons/index";
import Form from "../../components/Form/Index";

const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 10.8332H10.8334V14.9998C10.8334 15.4582 10.4584 15.8332 10.0001 15.8332C9.54175 15.8332 9.16675 15.4582 9.16675 14.9998V10.8332H5.00008C4.54175 10.8332 4.16675 10.4582 4.16675 9.99984C4.16675 9.5415 4.54175 9.1665 5.00008 9.1665H9.16675V4.99984C9.16675 4.5415 9.54175 4.1665 10.0001 4.1665C10.4584 4.1665 10.8334 4.5415 10.8334 4.99984V9.1665H15.0001C15.4584 9.1665 15.8334 9.5415 15.8334 9.99984C15.8334 10.4582 15.4584 10.8332 15.0001 10.8332Z"
      fill="#703581"
    />
  </svg>
);

function CategoryCard({
  categories,
  modelAttributes,
  categoryIndex,
  formik,
  el,
  removeCategory = function () {},
  updateCategory = function () {},

  setModelAttributes,
}) {
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    let filteredAttributes = modelAttributes[categoryIndex].options;
    modelAttributes[categoryIndex].attributes.forEach((el) => {
      filteredAttributes = filteredAttributes.filter(
        (item) => item.value !== el.value
      );
    });

    setAttributes(filteredAttributes);
  }, [modelAttributes[categoryIndex]]);

  // Functions for Changing Attributes
  const addAttribute = (index) => {
    setModelAttributes((old) =>
      old.map((el, i) =>
        i === index
          ? {
              ...el,
              attributes: [
                ...el.attributes,
                { value: "", label: "", values: [] },
              ],
            }
          : el
      )
    );
  };

  const removeAttribute = (attrIndex, index) => {
    setModelAttributes((old) =>
      old.map((el, i) =>
        i === index
          ? {
              ...el,
              attributes: el.attributes.filter(
                (elm, attrI) => attrIndex !== attrI
              ),
            }
          : el
      )
    );
  };

  // val, categoryIndex, index
  const updateAttributes = async (val, categoryIndex, index) => {
    let response = await axios.get(`/attribute/${val.value}`);
    console.log("response = > ", response);

    setModelAttributes((old) =>
      old.map((el, i) =>
        i === categoryIndex
          ? {
              ...el,
              attributes: el.attributes.map((attr, attrIndex) =>
                // if attribute exists
                attrIndex === index
                  ? {
                      ...attr,
                      value: val.value,
                      label: val.label,
                      values: response.values?.map(({ id, value }) => ({
                        value: id,
                        label: value,
                      })),
                    }
                  : attr
              ),
            }
          : el
      )
    );
    let filteredAttributes = modelAttributes[categoryIndex].options;
    modelAttributes[categoryIndex].attributes.forEach((el, index) => {
      filteredAttributes = filteredAttributes.filter(
        (item) => item.value !== el.value
      );
    });
    setAttributes(filteredAttributes);
  };

  const updateValues = (val, categoryIndex, index) => {
    setModelAttributes((old) =>
      old.map((el, i) =>
        i === categoryIndex
          ? {
              ...el,
              attributes: el.attributes.map((attr, attrIndex) =>
                // if attribute exists
                attrIndex === index
                  ? {
                      ...attr,
                      selected_value: val,
                    }
                  : attr
              ),
            }
          : el
      )
    );
  };

  return (
    <Card className="w-1/2">
      {/* Delete Button */}
      <div className="relative">
        <ClearIcon
          onClick={() => removeCategory(categoryIndex)}
          className="
            absolute -top-4 -right-4
            cursor-pointer border-2
            rounded bg-gray-100
            text-gray-600
            shadow-lg
            "
        />
      </div>

      <div className="w-full py-2 items-baseline" key={categoryIndex}>
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3">
            {t("attribute.category")} {categoryIndex + 1}
          </div>
          <div className="w-2/3">
            <Form.Item name="attribute_category_id" formik={formik}>
              <Select
                hideSelectedOptions
                options={categories}
                value={modelAttributes[categoryIndex].attribute_category}
                onChange={(val) => {
                  updateCategory(val, categoryIndex);
                }}
              />
            </Form.Item>
          </div>
        </div>
        {el.attributes && el.attributes.length
          ? el.attributes.map((item, i) => (
              <div className="border-b-2 border-gray-200 py-2 mb-4">
                {/* Attributes */}
                <div className="w-full py-2 flex items-baseline">
                  <div className="w-1/3">
                    {t("attribute")} {i + 1}
                  </div>
                  <div className="w-2/3 flex items-center space-x-2">
                    <div className="w-full">
                      <Form.Item name="attribute_id" formik={formik}>
                        <Select
                          id="attribute_id"
                          options={attributes}
                          onChange={(val) => {
                            // console.log(val)
                            updateValues([], categoryIndex, i);
                            updateAttributes(val, categoryIndex, i);
                          }}
                          value={modelAttributes[categoryIndex].attributes[i]}
                        />
                      </Form.Item>
                    </div>
                    {/* Delete Button */}
                    <div className="rounded border border-red-300 text-red-300 p-1">
                      <DeleteIcon
                        onClick={() => removeAttribute(i, categoryIndex)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </div>

                {/* Values */}
                <div className="w-full py-2 flex items-baseline">
                  <div className="w-1/3">{t("choose.option")}</div>
                  <div className="w-2/3">
                    <Form.Item name="values" formik={formik}>
                      <Select
                        hideSelectedOptions
                        options={el.attributes[i].values}
                        onChange={(val) => updateValues(val, categoryIndex, i)}
                        value={
                          modelAttributes[categoryIndex].attributes[i]
                            .selected_value
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            ))
          : ""}
        <div
          className="
            w-full border-2 border-dashed
            rounded flex justify-center
            space-x-2 cursor-pointer
            "
          onClick={() => addAttribute(categoryIndex)}
        >
          <CustomButton icon={addIcon} shape="text" color="text-primary-600">
            {t("add.attribute")}
          </CustomButton>
        </div>
      </div>
    </Card>
  );
}

export default CategoryCard;
