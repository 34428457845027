import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import { StyledTableCell } from "../../components/StyledTableCell"
import Paper from "@material-ui/core/Paper"
import TableContainer from "@material-ui/core/TableContainer"

import { useSelector } from "react-redux"

function Permissions() {
  const { t } = useTranslation()
  const history = useHistory()
  const [items, setItems] = useState({})

  const pageCount = 10
  const [pageLimit, setPageLimit] = useState(pageCount)

  const permissions = useSelector((state) => state.auth.permissions)

  useEffect(() => {
    const getItems = () => {
      axios
        .get(`/attribute?limit=${pageCount}&offset=${pageLimit - pageCount}`)
        .then((res) =>
          setItems({
            data: res.data,
          })
        )
    }
    getItems()
  }, [pageLimit])

  // **** FUNCTIONS ****

  // **** CONSTANTS ****

  const roleList = [
    {
      name: t("admin"),
      id: "a1ca1301-4da9-424d-a9e2-578ae6dcde01",
    },
    {
      name: t("investor"),
      id: "a1ca1301-4da9-424d-a9e2-578ae6dcde02",
    },
    {
      name: t("operator"),
      id: "a1ca1301-4da9-424d-a9e2-578ae6dcde09",
    },
    {
      name: t("cashier"),
      id: "a1ca1301-4da9-424d-a9e2-578ae6dcde05",
    },
    {
      name: t("salesman"),
      id: "a1ca1301-4da9-424d-a9e2-578ae6dcde12",
    },
  ]

  return (
    <div>
      <Header title={t("permissions")} />
      <Filters>
        <div className="flex space-x-2">
          <Input placeholder={t("search...")} style={{ width: "350px" }} />
        </div>
      </Filters>

      <div className="px-5 pb-4">
        <TableContainer className="mt-4" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell align="center" style={{ width: "5%" }}>
                  {t("No")}
                </StyledTableCell>
                <StyledTableCell>{t("role")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleList && roleList.length ? (
                roleList.map(({ name, id }, index) => (
                  <TableRow
                    key={index}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      if (permissions.includes("settings_permissions_edit"))
                        return history.push({
                          pathname: `/home/settings/permissions/${id}`,
                          state: name,
                        })
                    }}
                  >
                    <StyledTableCell align="center" style={{ width: "5%" }}>
                      <div className="text-blue-600">{index + 1}</div>
                    </StyledTableCell>

                    <StyledTableCell>{name}</StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default Permissions
