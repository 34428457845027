import { useState, useEffect, useRef, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Formik } from "formik"
import Form from "../../components/Form/Index"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import ClearIcon from "@material-ui/icons/Clear"
import axios from "../../utils/axios"
import axiosNoCheck from "../../utils/axiosNoCheck"
import { showAlert } from "../../redux/reducers/alertReducer"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import PriceInput from "../../components/Form/input/PriceInput"
import Textarea from "../../components/Textarea"
import cashIcon from "../../assets/icons/cash.png"
import humoIcon from "../../assets/icons/humo.png"
import uzcardIcon from "../../assets/icons/uzcard.png"
import visaIcon from "../../assets/icons/visa.png"
import clickIcon from "../../assets/icons/click.png"
import paymeIcon from "../../assets/icons/payme.png"
import AlertComponent from "../../components/Alert"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import defaultSetting from "../../config/defaultSettings"
import capitalizeFirstString from "../../functions/capitalizeFirstLetter"
import { useReactToPrint } from "react-to-print"
import Cheque from "../../components/Cheque/Cheque"

const paymentOptions = [
  {
    type: "cash",
    icon: cashIcon,
    amount: undefined,
  },
  {
    type: "humo",
    icon: humoIcon,
    amount: undefined,
  },
  {
    type: "uzcard",
    icon: uzcardIcon,
    amount: undefined,
  },
  {
    type: "visa",
    icon: visaIcon,
    amount: undefined,
  },
  {
    type: "click",
    icon: clickIcon,
    amount: undefined,
  },
  {
    type: "payme",
    icon: paymeIcon,
    amount: undefined,
  },
]

function OrderPayment() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const clientId = useSelector((state) => state.auth.clientId)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [tariffPayment, setTariffPayment] = useState()
  const [depositPayment, setDepositPayment] = useState()
  const [comment, setComment] = useState("")
  const [depositComment, setDepositComment] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [isValidDeposit, setIsValidDeposit] = useState(false)
  const [orderStatus, setOrderStatus] = useState()
  const [chequeData, setChequeData] = useState()
  const [changedPayment, setChangedPayment] = useState(false)
  const [changedDeposit, setChangedDeposit] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const chequeRef = useRef()

  // **** FUNCTIONS ****
  const getProperty = useCallback(async (orderId) => {
    setIsGettingData(true)
    let result = await axios.get(`/order/${orderId}`)

    setOrderStatus(result.status.id)

    console.log('AAA', result)

    setTariffPayment({
      actual_prolong_day: result.actual_prolong_day,
      is_paid: result.is_paid,
      tariff_price: +result.tariff.price,
      additional_tariffs_price:
        result.additional_tariffs?.reduce(
          (prev, cur) => prev + +cur.price,
          0
        ) || 0,
      total_price: result.total_price,
      paid_price: result.paid_price,
      selectedPayments: [
        {
          type: "cash",
          icon: cashIcon,
          amount: result.total_price - result.paid_price,
        },
      ],
      paymentMethods: paymentOptions.slice(1),
      from_time: result.from_time,
      to_time: result.to_time,
      insurance_price: result.tariff.insurance_price,
      day_count: result.day_count,
      insurance: result.insurance,
      returned_money: result.returned_money,
    })
    setDepositPayment({
      selectedPayments: [
        {
          type: "cash",
          icon: cashIcon,
          amount: undefined,
        },
      ],
      paymentMethods: paymentOptions.slice(1),
    })
    setIsGettingData(false)
  }, [])

  const calculateTotalPrice = (forwhat) => {
    if (forwhat === "paid_price") {
      return tariffPayment?.selectedPayments.reduce((acc, curr) => {
        if (curr.amount) {
          return acc + curr.amount
        } else {
          return acc
        }
      }, 0)
    } else if (forwhat === "total_price") {
      return (
        tariffPayment?.selectedPayments.reduce((acc, curr) => {
          if (curr.amount) {
            return acc + curr.amount
          } else {
            return acc
          }
        }, 0) +
        depositPayment?.selectedPayments.reduce((acc, curr) => {
          if (curr.amount) {
            return acc + curr.amount
          } else {
            return acc
          }
        }, 0)
      )
    }
  }

  // **** EVENTS ****
  const onSubmit = () => {
    setBtnDisabled(true)
    if (tariffPayment?.is_paid !== 1) {
      onTariffSubmit()
    }
    if (orderStatus && orderStatus !== "client_took") {
      onDepositSubmit()
    }
  }

  const onTariffSubmit = () => {
    setLoading(true)
    if (!(orderStatus && orderStatus !== "client_took")) {
      setIsValidDeposit(true)
    }

    if (isValid && isValidDeposit) {
      // Order payment
      axios
        .post(`/order-payment`, {
          comment,
          cashier_id: clientId,
          order_id: params.id,
          paid_amount:
            tariffPayment?.selectedPayments?.reduce(
              (prev, cur) => prev + (+cur?.amount || 0),
              0
            ) || 0,
          payments: tariffPayment?.selectedPayments
            ?.filter(({ amount, type }) => {
              return amount !== undefined
            })
            .map(({ amount, type }) => ({
              paid_amount: amount,
              payment_type: type,
            })),
          subaccount_category: defaultSetting.TariffSubCategoryID,
        })
        .then((res) => {
          // Alert to notify the user
          dispatch(
            showAlert(
              t(
                `Вы успешно оплатили сумму тарифа${
                  orderStatus && orderStatus !== "client_took"
                    ? " и депозита"
                    : ""
                }`
              ),
              "success"
            )
          )
          getChequeData(params.id)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled((prev) => {
            if (prev) return false
          })
        })
    } else {
      // Alert to notify the user
      if (!isValid) {
        dispatch(
          showAlert(t("Пожалуйста, оплатите полную сумму тарифа"), "warning")
        )
      }
      setLoading(false)
      setBtnDisabled((prev) => {
        if (prev) return false
      })
    }
  }

  const onDepositSubmit = () => {
    setLoading(true)
    if (isValid && isValidDeposit) {
      axiosNoCheck
        .post(`/billing/deposit`, {
          comment: depositComment,
          cashier_id: clientId,
          subaccount_category_id: "993224db-d25f-43cb-bfea-a8ee842f34b8",
          order_id: params.id,
          payments: depositPayment?.selectedPayments?.map((payment) => ({
            amount: payment.amount,
            payment_type: payment.type,
          })),
          status: "pay",
        })
        .then((res) => {
          if (tariffPayment?.is_paid === 1) {
            dispatch(
              showAlert(t("Вы успешно оплатили сумму депозита"), "success")
            )
            getChequeData(params.id)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false)
          setBtnDisabled((prev) => {
            if (prev) return false
          })
        })
    } else {
      if (isValid) {
        dispatch(showAlert(t("Пожалуйста, введите сумму депозита"), "error"))
      }
      setLoading(false)
      setBtnDisabled((prev) => {
        if (prev) return false
      })
    }
  }

  const getChequeData = async (orderId) => {
    setIsGettingData(true)
    axios
      .get(`/cheque?&order_id=${orderId}`)
      .catch((error) => {
        console.log(error)
      })
      .then((res) => {
        setChequeData(res)
        setIsGettingData(false)
      })
      .finally(history.push("/home/orders?tab=0"))
  }

  const generateCheque = useReactToPrint({
    content: () => chequeRef.current,
    onAfterPrint: () => history.push("/home/orders?tab=0"),
    removeAfterPrint: true,
  })

  useEffect(() => {
    if (chequeData) {
      generateCheque()
    }
    return () => {
      setChequeData(undefined)
    }
  }, [chequeData, generateCheque])

  const addTariffsSelectedPayment = (val, paymentIndex) => {
    setTariffPayment((prev) => ({
      ...prev,
      selectedPayments: [
        ...prev.selectedPayments,
        { type: val.type, amount: undefined, icon: val.icon },
      ],
      paymentMethods: prev.paymentMethods?.filter(
        (item, i) => i !== paymentIndex
      ),
    }))
  }

  const removeTariffsSelectedPayment = (val, paymentIndex) => {
    setTariffPayment((prev) => ({
      ...prev,
      selectedPayments: prev.selectedPayments?.filter(
        (payment, i) => i !== paymentIndex
      ),
      paymentMethods: [
        ...prev.paymentMethods,
        { type: val.type, amount: undefined, icon: val.icon },
      ],
    }))
  }

  const updateTariffsPayments = (input, paymentIndex) => {
    const num = input ? +input.replace(/\s+/g, "") : ""

    setTariffPayment((prev) => ({
      ...prev,
      selectedPayments: prev.selectedPayments?.map((item, itemIndex) =>
        itemIndex === paymentIndex ? { ...item, amount: num } : item
      ),
    }))
  }

  const addDepositSelectedPayment = (val, paymentIndex) => {
    setDepositPayment((prev) => ({
      ...prev,
      selectedPayments: [
        ...prev.selectedPayments,
        { type: val.type, amount: undefined, icon: val.icon },
      ],
      paymentMethods: prev.paymentMethods?.filter(
        (item, i) => i !== paymentIndex
      ),
    }))
  }

  const removeDepositSelectedPayment = (val, paymentIndex) => {
    setDepositPayment((prev) => ({
      ...prev,
      selectedPayments: prev.selectedPayments?.filter(
        (payment, i) => i !== paymentIndex
      ),
      paymentMethods: [
        ...prev.paymentMethods,
        { type: val.type, amount: undefined, icon: val.icon },
      ],
    }))
  }

  const updateDepositPayments = (input, paymentIndex) => {
    const num = input ? +input.replace(/\s+/g, "") : ""

    setDepositPayment((prev) => ({
      ...prev,
      selectedPayments: prev.selectedPayments?.map((item, itemIndex) =>
        itemIndex === paymentIndex ? { ...item, amount: num } : item
      ),
    }))
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({})

  const routes = [
    {
      title: t("orders"),
      link: true,
      route: "/home/orders?tab=0",
    },
    {
      title: t("payments"),
    },
  ]

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [getProperty, params.id])

  useEffect(() => {
    if (
      orderStatus &&
      orderStatus !== "client_took" &&
      (tariffPayment?.selectedPayments?.reduce(
        (prev, cur) => prev + (+cur?.amount || 0),
        0
      ) || 0) >=
        tariffPayment?.total_price - tariffPayment?.paid_price
    ) {
      setIsValid(true)
    } else if (
      (tariffPayment?.selectedPayments?.reduce(
        (prev, cur) => prev + (+cur?.amount || 0),
        0
      ) || 0) ==
      tariffPayment?.total_price - tariffPayment?.paid_price
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [tariffPayment])

  useEffect(() => {
    if (
      (depositPayment?.selectedPayments?.reduce(
        (prev, cur) => prev + (+cur?.amount || 0),
        0
      ) || 0) >= 1
    ) {
      setIsValidDeposit(true)
    } else {
      setIsValidDeposit(false)
    }
  }, [depositPayment])

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("payments")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    size="large"
                    shape="text"
                    color="text-secondary"
                    onClick={() => history.goBack()}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    size="large"
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        <></>
                      )
                    }
                    disabled={btnDisabled}
                  >
                    {t("make.payment")}
                  </CustomButton>,
                ]}
              />
              <AlertComponent
                style={{
                  top: "2px",
                  left: "40%",
                  marginLeft: "-100px",
                }}
              />
              <div className="p-4 w-full grid grid-cols-2 grid-flow-row auto-rows-auto gap-4 box-border font-body text-sm">
                {tariffPayment?.is_paid !== 1 && (
                  <div>
                    <Card title={t("tariff.payment")}>
                      <div className="space-y-4 ">
                        <div className="space-y-2">
                          <div className="flex items-center w-full text-md text-gray-600">
                            <p className="w-1/2">{t("tariff.price")}:</p>
                            <p>
                              {convertToInternationalCurrencySystem(
                                tariffPayment?.tariff_price
                              )}
                            </p>
                          </div>
                          {tariffPayment?.insurance ? (
                            <div className="flex items-center w-full text-md text-gray-600">
                              <p className="w-1/2">{t("insurance_price")}:</p>
                              <p>
                                {convertToInternationalCurrencySystem(
                                  tariffPayment?.insurance_price
                                )}
                              </p>
                            </div>
                          ) : null}
                          <div className="flex items-center w-full text-md text-gray-600">
                            <p className="w-1/2">
                              {t("additional.tariffs.price")}:
                            </p>
                            <p>
                              {convertToInternationalCurrencySystem(
                                tariffPayment?.additional_tariffs_price
                              )}
                            </p>
                          </div>
                          <div className="flex items-center w-full text-md text-gray-600">
                            <p className="w-1/2">{t("day.count")}:</p>
                            <p>{tariffPayment?.day_count}</p>
                          </div>
                          <div className="flex items-center w-full text-md text-gray-600">
                            <p className="w-1/2">{t("tariff.total.price")}:</p>
                            <p>
                              {convertToInternationalCurrencySystem(
                                tariffPayment?.tariff_price *
                                  tariffPayment?.day_count
                              )}
                            </p>
                          </div>
                          {tariffPayment?.insurance ? (
                            <div className="flex items-center w-full text-md text-gray-600">
                              <p className="w-1/2">
                                {t("insurance.total.price")}:
                              </p>
                              <p>
                                {convertToInternationalCurrencySystem(
                                  tariffPayment?.insurance_price *
                                    tariffPayment?.day_count
                                )}
                              </p>
                            </div>
                          ) : null}
                          <div className="flex items-center w-full text-md text-gray-600">
                            <p className="w-1/2">
                              {t("tariff.add.tariffs.total.price")}:
                            </p>
                            <p>
                              {convertToInternationalCurrencySystem(
                                tariffPayment?.additional_tariffs_price *
                                  tariffPayment?.day_count
                              )}
                            </p>
                          </div>
                          <div
                            className="flex w-full font-semibold text-gray-600 pt-4"
                            style={{ fontSize: "16px" }}
                          >
                            <p className="w-1/2">{t("total")}</p>
                            <p>
                              {convertToInternationalCurrencySystem(
                                tariffPayment?.total_price
                              )}
                            </p>
                          </div>
                          <div className="flex items-center w-full text-md text-gray-600">
                            <p className="w-1/2">{t("paid")}:</p>
                            <p>
                              {convertToInternationalCurrencySystem(
                                tariffPayment?.paid_price
                              )}
                            </p>
                          </div>
                          <div className="flex items-center w-full text-md text-gray-600">
                            <p className="w-1/2">
                              {capitalizeFirstString(t("shortage"))}:
                            </p>
                            <p>
                              {convertToInternationalCurrencySystem(
                                tariffPayment?.returned_money
                              )}
                            </p>
                          </div>
                        </div>

                        {/* Sub payments */}
                        <div className="py-2 space-y-2">
                          {tariffPayment?.selectedPayments &&
                            tariffPayment?.selectedPayments?.map(
                              (el, index) => (
                                <div className="w-full flex text-gray-700">
                                  <div className="flex w-1/3 items-center">
                                    <span
                                      className="flex justify-center 
                                      items-center border 
                                      rounded-md hover:bg-gray-100
                                      transition ease-linear
                                      cursor-pointer py-2 px-6 mr-4"
                                      style={{ width: "85px", height: "40px" }}
                                    >
                                      <img src={el.icon} alt="" />
                                    </span>
                                    {t(el.type)}
                                  </div>
                                  <div className="flex w-2/3 items-center space-x-1">
                                    <div className="w-full">
                                      <Form.Item
                                        name={`subpayment[${index}]`}
                                        formik={formik}
                                      >
                                        <PriceInput
                                          placeholder={t("enter.the.amount")}
                                          value={
                                            changedPayment ? "" : el.amount
                                          }
                                          onChange={(e) => {
                                            setChangedPayment(false)
                                            updateTariffsPayments(
                                              e.target.value,
                                              index
                                            )
                                          }}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div
                                      className="border rounded cursor-pointer
                                        transition  ease-linear hover:border-gray-400
                                      "
                                      style={{ padding: "0.185rem" }}
                                      onClick={() => {
                                        setChangedPayment(true)
                                        removeTariffsSelectedPayment(el, index)
                                      }}
                                    >
                                      <ClearIcon
                                        className="text-gray-400 "
                                        style={{ fontSize: "25px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          {tariffPayment?.paymentMethods?.length !== 0 && (
                            <>
                              <h6 className="font-semibold text-gray-700 py-2">
                                {t("add.payment.option")}
                              </h6>

                              {/* Payment options */}
                              <div className="w-full py-2 flex items-center space-x-2">
                                {tariffPayment?.paymentMethods?.length &&
                                  tariffPayment?.paymentMethods?.map(
                                    (el, index) => (
                                      <span
                                        className="flex justify-center 
                                      rounded-md hover:bg-gray-100
                                      transition ease-linear
                                      items-center border cursor-pointer
                                      py-2 px-6"
                                        style={{
                                          width: "85px",
                                          height: "40px",
                                        }}
                                        onClick={() =>
                                          addTariffsSelectedPayment(el, index)
                                        }
                                      >
                                        <img src={el.icon} alt="" />
                                      </span>
                                    )
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        {/* Comment */}
                        <div>
                          <div className="flex w-full text-md text-gray-600">
                            <p className="w-1/2 mr-8">{t("comment")}:</p>
                            <Textarea
                              type="text"
                              placeholder={t("leave.comment")}
                              style={{
                                minHeight: "100px",
                                width: "100%",
                              }}
                              className={"w-full"}
                              onChange={(val) => setComment(val.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
                {orderStatus && orderStatus !== "client_took" ? (
                  <div>
                    <Card title={t("deposit.payment")}>
                      <div className="space-y-4 ">
                        {/* Sub payments */}
                        <div className="py-2 space-y-2">
                          {depositPayment?.selectedPayments &&
                            depositPayment?.selectedPayments?.map(
                              (el, index) => (
                                <div className="w-full flex text-gray-700">
                                  <div className="flex w-1/3 items-center">
                                    <span
                                      className="flex justify-center 
                                      items-center border 
                                      rounded-md hover:bg-gray-100
                                      transition ease-linear
                                      cursor-pointer py-2 px-6 mr-4"
                                      style={{ width: "85px", height: "40px" }}
                                    >
                                      <img src={el.icon} alt="" />
                                    </span>
                                    {t(el.type)}
                                  </div>
                                  <div className="flex w-2/3 items-center space-x-1">
                                    <div className="w-full">
                                      <Form.Item
                                        name={`subpayment[${index}]`}
                                        formik={formik}
                                      >
                                        <PriceInput
                                          placeholder={t("enter.the.amount")}
                                          onChange={(e) => {
                                            setChangedDeposit(false)
                                            updateDepositPayments(
                                              e.target.value,
                                              index
                                            )
                                          }}
                                          value={
                                            changedDeposit ? "" : el.amount
                                          }
                                        />
                                      </Form.Item>
                                    </div>
                                    <div
                                      className="border rounded cursor-pointer
                                        transition  ease-linear hover:border-gray-400
                                      "
                                      style={{ padding: "0.185rem" }}
                                      onClick={() => {
                                        setChangedDeposit(true)
                                        removeDepositSelectedPayment(el, index)
                                      }}
                                    >
                                      <ClearIcon
                                        className="text-gray-400 "
                                        style={{ fontSize: "25px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          {depositPayment?.paymentMethods &&
                            depositPayment?.paymentMethods?.length !== 0 && (
                              <>
                                <h6 className="font-semibold text-gray-700 py-2">
                                  {t("add.payment.option")}
                                </h6>
                                {/* Payment options */}
                                <div className="w-full py-2 flex items-center space-x-2">
                                  {depositPayment?.paymentMethods?.length &&
                                    depositPayment?.paymentMethods?.map(
                                      (el, index) => (
                                        <span
                                          className="flex justify-center 
                                      rounded-md hover:bg-gray-100
                                      transition ease-linear
                                      items-center border cursor-pointer
                                      py-2 px-6"
                                          style={{
                                            width: "85px",
                                            height: "40px",
                                          }}
                                          onClick={() =>
                                            addDepositSelectedPayment(el, index)
                                          }
                                        >
                                          <img src={el.icon} alt="" />
                                        </span>
                                      )
                                    )}
                                </div>
                              </>
                            )}
                        </div>
                        {/* Comment */}
                        <div>
                          <div className="flex w-full text-md text-gray-600">
                            <p className="w-1/2 mr-8">{t("comment")}:</p>
                            <Textarea
                              type="text"
                              placeholder={t("leave.comment")}
                              style={{
                                minHeight: "100px",
                                width: "100%",
                              }}
                              className={"w-full"}
                              onChange={(val) =>
                                setDepositComment(val.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : null}
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
      <div className="">
        {chequeData && (
          <Cheque
            ref={chequeRef}
            cheque_no={chequeData?.cheque_no}
            firm={chequeData?.firm}
            client={chequeData?.client}
            tariff_price={tariffPayment?.tariff_price}
            cash={
              tariffPayment?.selectedPayments?.find(
                (payment) => payment.type === "cash"
              )
                ? tariffPayment?.selectedPayments?.find(
                    (payment) => payment.type === "cash"
                  ).amount
                : 0
            }
            uzcard={
              tariffPayment?.selectedPayments?.find(
                (payment) => payment.type === "uzcard"
              )
                ? tariffPayment?.selectedPayments?.find(
                    (payment) => payment.type === "uzcard"
                  ).amount
                : 0
            }
            humo={
              tariffPayment?.selectedPayments?.find(
                (payment) => payment.type === "humo"
              )
                ? tariffPayment?.selectedPayments?.find(
                    (payment) => payment.type === "humo"
                  ).amount
                : 0
            }
            visa={
              tariffPayment?.selectedPayments?.find(
                (payment) => payment.type === "visa"
              )
                ? tariffPayment?.selectedPayments?.find(
                    (payment) => payment.type === "visa"
                  ).amount
                : 0
            }
            click={
              tariffPayment?.selectedPayments?.find(
                (payment) => payment.type === "click"
              )
                ? tariffPayment?.selectedPayments?.find(
                    (payment) => payment.type === "click"
                  ).amount
                : 0
            }
            payme={
              tariffPayment?.selectedPayments?.find(
                (payment) => payment.type === "payme"
              )
                ? tariffPayment?.selectedPayments?.find(
                    (payment) => payment.type === "payme"
                  ).amount
                : 0
            }
            discount={chequeData?.discount}
            deposit={depositPayment?.selectedPayments.reduce((acc, curr) => {
              if (curr.amount) {
                return acc + +curr.amount
              } else {
                return acc
              }
            }, 0)}
            service_payment={
              calculateTotalPrice("paid_price") -
              Math.round((calculateTotalPrice("paid_price") * 0.15) / 1.15)
            }
            qqs={Math.round((calculateTotalPrice("paid_price") * 0.15) / 1.15)}
            total_price={calculateTotalPrice("total_price")}
          />
        )}
      </div>
    </div>
  )
}

export default OrderPayment
