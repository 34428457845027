export default function Header({
  startAdornment = [],
  endAdornment = [],
  title,
}) {
  return (
    <div className="w-full bg-white flex justify-between">
      <div className="p-4 flex items-center divide-x">
        {title && title.length ? (
          <div
            className="text-lg leading-6 font-semibold pr-4"
            style={{ color: "rgba(91, 104, 113, 1)" }}
          >
            {title}
          </div>
        ) : (
          <></>
        )}
        {startAdornment}
      </div>
      <div className="flex items-center divide-x">
        <div></div>
        {endAdornment && endAdornment.length ? (
          endAdornment.map((Button) => <div className="px-1">{Button}</div>)
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
