import { useState } from "react"
import TableCell from "@material-ui/core/TableCell"
import { useTranslation } from "react-i18next"
import filterIcon from "../../assets/icons/filter.svg"
import BackdropPortal from "../../components/BackdropPortal/BackdropPortal"

const TableHeadFilterCellCustomList = (props) => {
  const { row = 1, col = 1, align, title, paramKey, data, filter } = props
  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState(false)

  return (
    <TableCell
      colSpan={col}
      rowSpan={row}
      align="center"
      style={{
        fontWeight: "600",
        borderRight: "1px solid #ddd",
        whiteSpace: "pre",
      }}
    >
      <div className="flex justify-between items-center whitespace-pre">
        <div className="mr-2">{t(title)}</div>
        <div
          className="border-solid border-2 border-gray-100 rounded-lg inline-block p-2 cursor-pointer w-8 relative"
          onClick={() => setIsVisible(true)}
        >
          <img src={filterIcon} alt="icon" />
          {isVisible && (
            <>
              <BackdropPortal
                onConfirm={(e) => {
                  e.stopPropagation()
                  setIsVisible(false)
                }}
              />
              <div className={`border-solid border-1 bg-white w-80 shadow-xl absolute top-8 z-50 min-h-32 max-h-40 overflow-auto p-2 ${align ?? 'left-0'}`}>
                <p
                  className="hover:bg-gray-100 p-2 cursor-pointer"
                  onClick={(e) => {
                    filter(e, "", paramKey)
                    setIsVisible(false)
                  }}
                >
                  {t("filter.clear")}
                </p>
                {data.map((item, index) => (
                  <p
                    key={index}
                    className="hover:bg-gray-100 p-2 cursor-pointer text-left"
                    onClick={(e) => {
                      filter(e, item.id, paramKey)
                      setIsVisible(false)
                    }}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </TableCell>
  )
}

export default TableHeadFilterCellCustomList