import { useState } from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "@material-ui/icons/Add"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import ImageUpload from "../../components/Upload/ImgUpload"
import Button from "../../components/Buttons/index"
import { FieldArray } from "formik"
import DeleteIcon from "@material-ui/icons/Delete"
import DatePicker from "../../components/DatePicker/index"
import moment from "moment"

const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 10.8332H10.8334V14.9998C10.8334 15.4582 10.4584 15.8332 10.0001 15.8332C9.54175 15.8332 9.16675 15.4582 9.16675 14.9998V10.8332H5.00008C4.54175 10.8332 4.16675 10.4582 4.16675 9.99984C4.16675 9.5415 4.54175 9.1665 5.00008 9.1665H9.16675V4.99984C9.16675 4.5415 9.54175 4.1665 10.0001 4.1665C10.4584 4.1665 10.8334 4.5415 10.8334 4.99984V9.1665H15.0001C15.4584 9.1665 15.8334 9.5415 15.8334 9.99984C15.8334 10.4582 15.4584 10.8332 15.0001 10.8332Z"
      fill="#703581"
    />
  </svg>
)

export default function Context({ formik }) {
  const { t } = useTranslation()

  const [image, setImage] = useState("")

  return (
    <div className="w-full grid grid-cols-3 gap-4 box-border font-body text-sm mt-4">
      <Card title={t("general.information")}>
        <div className="flex flex-col space-y-6 items-center">
          {/* Photo */}
          <ImageUpload
            onChange={(val) => setImage(val.filename)}
            defaultImage={image}
            style={{ width: "165px" }}
            shape="rounded"
          >
            <div className="flex flex-col mx-8 items-center justify-center space-y-4">
              <AddIcon style={{ fontSize: "200%" }} />
              <h5>{t("select.image")}</h5>
            </div>
          </ImageUpload>

          {/* Name */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">Имя</div>
            <div>
              <Form.Item name="name" formik={formik}>
                <Input
                  id="name"
                  type="text"
                  maxLength="100"
                  {...formik.getFieldProps("name")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Dob */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">{t("date_of_birth")}</div>
            <div>
              <Form.Item name="date_of_birth" formik={formik}>
                <DatePicker
                  placeholder={t("select.a.date")}
                  style={{ height: "35px" }}
                  value={
                    formik.values.date_of_birth
                      ? moment(formik.values.date_of_birth)
                      : null
                  }
                  onChange={(val) => {
                    formik.setFieldValue("date_of_birth", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Phone */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">Телефон</div>
            <div>
              <Form.Item name="phone_number" formik={formik}>
                <Input
                  id="phone_number"
                  type="text"
                  {...formik.getFieldProps("phone_number")}
                  onChange={(e) => {
                    formik.setFieldValue("phone_number", e.target.value)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <FieldArray name="additional_phone_numbers">
            {({ insert, remove, push }) => (
              <div className="w-full">
                {formik?.values?.additional_phone_numbers?.length > 0 &&
                  formik?.values?.additional_phone_numbers?.map(
                    (number, index) => (
                      <div
                        className="flex flex-col w-full space-y-1"
                        key={index}
                      >
                        {/* Phone Number */}
                        <div className="text-gray-600">
                          {t("number.additional")}
                        </div>
                        <div className="flex items-baseline">
                          <div className="w-11/12 mr-2">
                            <Form.FieldArrayItem
                              name={`additional_phone_numbers`}
                              formik={formik}
                              index={index}
                            >
                              <Input
                                id={`additional_phone_numbers.${index}`}
                                type="text"
                                {...formik.getFieldProps(
                                  `additional_phone_numbers.${index}`
                                )}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `additional_phone_numbers.${index}`,
                                    e.target.value
                                  )
                                }}
                              />
                            </Form.FieldArrayItem>
                          </div>
                          {/* Delete Button */}
                          <div
                            className="w-0.5/12 p-0.5 cursor-pointer rounded border border-red-300 text-red-300"
                            onClick={() => remove(index)}
                            style={{ scale: "0.78" }}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                {/* Add Btn */}
                <div
                  className="ml-auto border-2 border-dashed rounded flex justify-center mt-4 space-x-2 cursor-pointer"
                  onClick={() => {
                    push("")
                  }}
                >
                  <Button icon={addIcon} shape="text" color="text-primary-600">
                    {t("add")}
                  </Button>
                </div>
              </div>
            )}
          </FieldArray>
        </div>
      </Card>
    </div>
  )
}
