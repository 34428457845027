import { useTranslation } from "react-i18next"
import { useState, useRef } from "react"
import AddIcon from "@material-ui/icons/Add"
import EmptyTable from "../../../components/EmptyTable"
import Input from "../../../components/Input/index"
import DatePicker from "../../../components/DatePicker/index"
import moment from "moment"
import Card from "../../../components/Card/index"
import Form from "../../../components/Form/Index"
import Switch from "../../../components/Switch"
import ImageUpload from "../../../components/Upload/ImgUpload2"
import FileUpload from "../../../components/Upload/FileUpload/FileUpload2"
import { useHistory } from "react-router-dom"
import Button from "../../../components/Buttons/index"
import { FieldArray } from "formik"
import DeleteIcon from "@material-ui/icons/Delete"

const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 10.8332H10.8334V14.9998C10.8334 15.4582 10.4584 15.8332 10.0001 15.8332C9.54175 15.8332 9.16675 15.4582 9.16675 14.9998V10.8332H5.00008C4.54175 10.8332 4.16675 10.4582 4.16675 9.99984C4.16675 9.5415 4.54175 9.1665 5.00008 9.1665H9.16675V4.99984C9.16675 4.5415 9.54175 4.1665 10.0001 4.1665C10.4584 4.1665 10.8334 4.5415 10.8334 4.99984V9.1665H15.0001C15.4584 9.1665 15.8334 9.5415 15.8334 9.99984C15.8334 10.4582 15.4584 10.8332 15.0001 10.8332Z"
      fill="#703581"
    />
  </svg>
)

function GeneralInfo({
  formik,
  comments,
  params,
  onImage,
  onDrivingLicense,
  onPassport,
  onPropiska,
}) {
  const { t } = useTranslation()
  const [image, setImage] = useState("")
  const [file, setFile] = useState("")

  const commentListRef = useRef()

  const history = useHistory()

  const commentClickHandler = (e, orderId) => {
    history.push(`/home/orders/${orderId}`)
  }

  return (
    <div className="w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
      <div className="space-y-4">
        <Card title={t("general.information")} className="row-span-2">
          {/* Photo */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("photo")}</div>
            <div className="w-2/3">
              <Form.Item name="photo" formik={formik}>
                <ImageUpload
                  onStart={(val) => console.log(val)}
                  onFinish={(val) => console.log(val)}
                  onSuccess={(val) => console.log(val)}
                  onError={(val) => console.log(val)}
                  onChange={(val) => {
                    setImage(val.filename)
                    onImage(val.file_url)
                  }}
                  defaultImage={formik.getFieldProps("photo").value}
                >
                  <div className="flex flex-col mx-8 items-center justify-center space-y-4">
                    <AddIcon style={{ fontSize: "200%" }} />
                    <h5>{t("select.image")}</h5>
                  </div>
                </ImageUpload>
              </Form.Item>
            </div>
          </div>
          {/* Last Name */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("last.name")}</div>
            <div className="w-2/3">
              <Form.Item name="last_name" formik={formik}>
                <Input
                  id="last_name"
                  type="text"
                  {...formik.getFieldProps("last_name")}
                />
              </Form.Item>
            </div>
          </div>
          {/* First Name */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("first.name")}</div>
            <div className="w-2/3">
              <Form.Item name="first_name" formik={formik}>
                <Input
                  id="first_name"
                  type="text"
                  {...formik.getFieldProps("first_name")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Middle Name */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("middle.name")}</div>
            <div className="w-2/3">
              <Form.Item name="middle_name" formik={formik}>
                <Input
                  id="middle_name"
                  type="text"
                  {...formik.getFieldProps("middle_name")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Phone Number */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("number")}</div>
            <div className="w-2/3">
              <Form.Item name="phone_number" formik={formik}>
                <Input
                  id="phone_number"
                  type="text"
                  {...formik.getFieldProps("phone_number")}
                  onChange={(e) => {
                    formik.setFieldValue("phone_number", e.target.value)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <FieldArray name="additional_phone_numbers">
            {({ insert, remove, push }) => (
              <div>
                {formik?.values?.additional_phone_numbers?.length > 0 &&
                  formik?.values?.additional_phone_numbers?.map(
                    (number, index) => (
                      <div className="row" key={index}>
                        {/* Phone Number */}
                        <div className="w-full py-2 flex items-baseline">
                          <div className="w-1/3">
                            &#42; {t("number.additional")}
                          </div>
                          <div className="w-7/12 mr-2">
                            <Form.FieldArrayItem
                              name={`additional_phone_numbers`}
                              formik={formik}
                              index={index}
                            >
                              <Input
                                id={`additional_phone_numbers.${index}`}
                                type="text"
                                {...formik.getFieldProps(
                                  `additional_phone_numbers.${index}`
                                )}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `additional_phone_numbers.${index}`,
                                    e.target.value
                                  )
                                }}
                              />
                            </Form.FieldArrayItem>
                          </div>
                          {/* Delete Button */}
                          <div
                            className="w-0.5/12 p-0.5 cursor-pointer rounded border border-red-300 text-red-300"
                            onClick={() => remove(index)}
                            style={{ scale: "0.78" }}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                {/* Add Btn */}
                <div
                  className="w-2/3 ml-auto border-2 border-dashed rounded flex justify-center space-x-2 cursor-pointer"
                  onClick={() => {
                    push("")
                  }}
                >
                  <Button icon={addIcon} shape="text" color="text-primary-600">
                    {t("add")}
                  </Button>
                </div>
              </div>
            )}
          </FieldArray>
          {/* Address */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("address")}</div>
            <div className="w-2/3">
              <Form.Item name="address" formik={formik}>
                <Input
                  id="address"
                  type="text"
                  {...formik.getFieldProps("address")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Is_Blocked */}
          {params.id && (
            <div className="flex justify-between w-full space-y-1">
              <div className="w-1/3">{t("status")}</div>
              <div className="flex w-2/3 space-x-4">
                <div>
                  <Form.Item name="is_blocked" formik={formik}>
                    <Switch
                      id="status"
                      color="primary"
                      checked={formik.values.is_blocked}
                      onChange={(val) =>
                        formik.setFieldValue("is_blocked", Number(val))
                      }
                    />
                  </Form.Item>
                </div>
                <h6>
                  {formik.values.is_blocked ? "Блокирован" : "Разблокирован"}
                </h6>
              </div>
            </div>
          )}
        </Card>
        <Card title={t("technical.information")}>
          {/* Driving License Number */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("driving.license.number")}</div>
            <div className="w-2/3">
              <Form.Item name="driving_license_number" formik={formik}>
                <Input
                  id="driving_license_number"
                  type="text"
                  {...formik.getFieldProps("driving_license_number")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Driving License Expire Date */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("driving.license.expire.date")}</div>
            <div className="w-2/3">
              <Form.Item name="driving_license_expire_date" formik={formik}>
                <DatePicker
                  placeholder={t("select.a.date")}
                  style={{ height: "35px" }}
                  value={
                    formik.values.driving_license_expire_date
                      ? moment(formik.values.driving_license_expire_date)
                      : null
                  }
                  onChange={(val) => {
                    formik.setFieldValue("driving_license_expire_date", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Driving license given date */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("driving.license.given.date")}</div>
            <div className="w-2/3">
              <Form.Item name="driving_license_given_date" formik={formik}>
                <DatePicker
                  placeholder={t("select.a.date")}
                  style={{ height: "35px" }}
                  value={
                    formik.values.driving_license_given_date
                      ? moment(formik.values.driving_license_given_date)
                      : null
                  }
                  onChange={(val) => {
                    formik.setFieldValue("driving_license_given_date", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Driving license given place */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("driving.license.given.place")}</div>
            <div className="w-2/3">
              <Form.Item name="driving_license_given_place" formik={formik}>
                <Input
                  id="driving_license_given_place"
                  type="text"
                  {...formik.getFieldProps("driving_license_given_place")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Passport Pinfl */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("passport.pinfl")}</div>
            <div className="w-2/3">
              <Form.Item name="passport_pinfl" formik={formik}>
                <Input
                  id="passport_pinfl"
                  type="text"
                  {...formik.getFieldProps("passport_pinfl")}
                  value={formik.values.passport_pinfl.slice(0, 14)}
                  onChange={(val) =>
                    formik.setFieldValue(
                      "passport_pinfl",
                      val.target.value.slice(0, 14).trim()
                    )
                  }
                />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title={t("files")}>
          {/* File */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("driving.license")}</div>
            <div className="w-1/3">
              <Form.Item name="driving_license" formik={formik}>
                <FileUpload
                  onChange={(val) => {
                    setFile(val.filename)
                    onDrivingLicense(val.file_url)
                  }}
                  defaultFile={formik.getFieldProps("driving_license").value}
                >
                  <div className="flex flex-col mx-8 items-center justify-center space-y-4">
                    <AddIcon style={{ fontSize: "200%" }} />
                    <h5>{t("select.file")}</h5>
                  </div>
                </FileUpload>
              </Form.Item>
            </div>
          </div>
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("passport")}</div>
            <div className="w-1/3">
              <Form.Item name="passport" formik={formik}>
                <FileUpload
                  onChange={(val) => {
                    setFile(val.filename)
                    onPassport(val.file_url)
                  }}
                  defaultFile={formik.getFieldProps("passport").value}
                >
                  <div className="flex flex-col mx-8 items-center justify-center space-y-4">
                    <AddIcon style={{ fontSize: "200%" }} />
                    <h5>{t("select.file")}</h5>
                  </div>
                </FileUpload>
              </Form.Item>
            </div>
          </div>
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("propiska")}</div>
            <div className="w-1/3">
              <Form.Item name="propiska" formik={formik}>
                <FileUpload
                  onChange={(val) => {
                    setFile(val.filename)
                    onPropiska(val.file_url)
                  }}
                  defaultFile={formik.getFieldProps("propiska").value}
                >
                  <div className="flex flex-col mx-8 items-center justify-center space-y-4">
                    <AddIcon style={{ fontSize: "200%" }} />
                    <h5>{t("select.file")}</h5>
                  </div>
                </FileUpload>
              </Form.Item>
            </div>
          </div>
        </Card>
      </div>
      <Card
        title={t("comments")}
        style={{ position: "relative", height: "35.6%" }}
      >
        <div
          className="overflow-y-auto"
          style={{ maxHeight: "28rem" }}
          ref={commentListRef}
        >
          {comments?.map((comment) => (
            <div
              key={comment.commentator_id}
              className="flex justify-between text-gray-600 my-2 cursor-pointer p-2"
              onClick={(e) => commentClickHandler(e, comment.order_id)}
            >
              <div style={{ maxWidth: "74%" }}>
                <div className="font-semibold my-1">
                  {comment.commentator_name}
                </div>
                <div className="w-11/12 break-words">{comment.message}</div>
              </div>
              <div className="px-2 self-end">
                {moment(comment.created_at).format("YYYY-MM-DD | HH:MM")}
              </div>
            </div>
          ))}
          <EmptyTable
            message="Без комментариев"
            isActive={comments == undefined || comments.length === 0}
            className="border-none flex flex-col justify-center items-center text-gray-400 w-50 h-24 my-40"
          />
        </div>
      </Card>
    </div>
  )
}

export default GeneralInfo
