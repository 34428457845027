import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import SearchIcon from "@material-ui/icons/Search"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons/index"
import Filters from "../../components/Filters"
import Input from "../../components/Input"
import { StyledTableCell } from "../../components/DataTable/index"
import { StyledTab, StyledTabs } from "../../components/StyledTabs"
import Pagination from "../../components/Pagination/Pagination"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import { addIcon } from "../../assets/icons/icons"
import parseQuery from "../../functions/parseQuery"
import EmptyTable from "../../components/EmptyTable"
import Skeleton from "../../components/Skeleton/ReportSkeleton"

export default function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)

  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})

  const pageCount = 10
  let debounce = setTimeout(() => {}, 0)
  let { tab, limit, offset } = parseQuery()

  useEffect(() => {
    getItems({
      limit: limit ?? pageCount,
      offset: offset ?? 0,
      tab: tab ?? 0,
      is_blocked: +tab ? 1 : 0,
    })
  }, [])

  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, search: val })
    }, 500)
  }

  // **** FUNCTIONS ****
  const getItems = (params = {}) => {
    axios
      .get(`/client`, {
        params,
      })
      .then((res) => {
        setItems(res.data)
        setIsLoading(false)
      })
  }

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => changeTabLocationQuery(newValue)

  const tabLabel = (text, number = "", isActive = false) => {
    return (
      <span
        className={`px-1 ${
          !isActive ? "text-warmGray-500" : "text-primary-600"
        }`}
      >
        {text}
      </span>
    )
  }

  // **** EVENTS ****
  const changeTabLocationQuery = (tabId) => {
    history.push({
      pathname: `/home/clients`,
      search: `?tab=${tabId}`,
    })
  }

  const changePaginationLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/clients`,
      search: `?limit=${pageCount}&offset=${offset}&tab=${tab ?? 0}`,
    })
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("clients")}
        endAdornment={
          permissions.includes("clients_create")
            ? [
                <Button
                  shape="text"
                  color="text-secondary"
                  style={{ fontWeight: "500", padding: "1rem 1rem" }}
                  icon={addIcon}
                  onClick={() => history.push("/home/clients/create")}
                >
                  {t("add")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 py-4">
        <StyledTabs
          value={+tab}
          onChange={handleChange}
          centered={false}
          aria-label="full width tabs example"
          TabIndicatorProps={{ children: <span className="w-2" /> }}
          className="bg-white rounded"
        >
          <StyledTab
            label={tabLabel(t("clients.list"), "", +tab === 0)}
            {...a11yProps(0)}
          />
          <StyledTab
            label={tabLabel(t("clients.blocked"), "", +tab === 1)}
            {...a11yProps(1)}
          />
        </StyledTabs>

        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-xl mt-4"
        >
          <Table elevation={0} aria-label="customized table">
            <TableHead>
              <TableRow className="text-black font-body">
                <StyledTableCell>{t("client")}</StyledTableCell>
                <StyledTableCell align="center">
                  {t("phone.number")}
                </StyledTableCell>
                <StyledTableCell align="left">{t("address")}</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.clients && items.clients?.length
                ? items.clients.map(
                    (
                      { first_name, last_name, phone_number, address, id },
                      i
                    ) => (
                      <TableRow
                        className={`hover:bg-gray-100 cursor-pointer`}
                        key={i}
                        style={{ background: i % 2 === 0 && "#F6F8F9" }}
                        onClick={() => {
                          if (permissions.includes("clients_edit"))
                            return history.push(`/home/clients/${id}`)
                        }}
                      >
                        <StyledTableCell>
                          {first_name} {last_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {formatPhoneNumber(phone_number)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {address}
                        </StyledTableCell>

                        <MoreClickHandler
                          id={id}
                          api="/client"
                          pushLocation="/home/clients"
                          editPermission="clients_edit"
                          deletePermission="clients_delete"
                          getItems={() =>
                            getItems({
                              limit: limit ?? pageCount,
                              offset: offset ?? 0,
                              tab: tab ?? 0,
                              is_blocked: +tab ? 1 : 0,
                            })
                          }
                        />
                      </TableRow>
                    )
                  )
                : ""}
            </TableBody>
          </Table>
        </TableContainer>

        <EmptyTable
          message="Нет клиентов"
          isActive={!isLoading && !items.clients?.length > 0}
        />
        {isLoading && <Skeleton />}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changePaginationLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}
