import logo from "../assets/icons/brand_logo.png"

const defaultSetting = {
  baseURL: process.env.REACT_APP_BASE_URL, // base url for whole project
  mode: "default", // mode => default | light | semi-dark
  avatar: "",
  fixedHeader: true, // sticky header
  fixSiderbar: true, // sticky sidebar
  project: {
    title: "CAR24",
    logo: logo,
    createdBy: "Udevs company",
  },
  TariffSubCategoryID: "4b7322e4-980f-4db9-82b1-8f395efd87d5",
  ColorAttributeId: "7ef8ee89-7bbf-45e5-a2e5-1a5697ede9db",
}

document.title = defaultSetting.project.title

export default defaultSetting
