import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export default function TableSkeleton() {
  return (
    <div>
      <SkeletonTheme color="#ddd" highlightColor="#fff">
        <div className="flex flex-col w-full box-border spaxe-y-4">
          <div className="flex flex-col justify-center rounded-md m-2 bg-white py-2 px-4 ">
            <Skeleton
              color="#202020"
              highlightColor="#444"
              width="100%"
              height={15}
            />
            <Skeleton
              color="#202020"
              highlightColor="#444"
              width="85%"
              height={15}
            />
          </div>
          {[1].map((el) => (
            <>
              <div className="flex flex-col justify-center rounded-md m-2 bg-white py-8 px-4 space-y-8">
                <div className="flex flex-row items-center">
                  <Skeleton circle={true} height={80} width={80} />
                  <Skeleton
                    color="#202020"
                    highlightColor="#444"
                    width="65%"
                    height={20}
                  />
                  <Skeleton
                    color="#202020"
                    highlightColor="#444"
                    width="65%"
                    height={20}
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <Skeleton
                    color="#202020"
                    highlightColor="#444"
                    width="65%"
                    height={20}
                  />
                  <Skeleton
                    color="#202020"
                    highlightColor="#444"
                    width="50%"
                    height={20}
                  />
                  <Skeleton
                    color="#202020"
                    highlightColor="#444"
                    width="40%"
                    height={20}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
      </SkeletonTheme>
    </div>
  )
}
