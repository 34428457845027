import styles from "./Upload.module.scss"
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"

export default function Upload({
  style,
  className,
  children,
  accept,
  disabled,
  getFilesFromEvent,
  maxFiles,
  maxSize,
  minSize,
  multiple,
  noClick,
  noDrag,
  noDragEventsBubbling,
  noKeyboard,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDropAccepted,
  onDropRejected,
  onFileDialogCancel,
  preventDropOnDocument,
  validator,
  shape,
  onChange = function () {},
  ...props
}) {
  // **** USE-HOOKS ****
  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className={`${styles.upload} ${className} `} style={style}>
      {/* DROPZONE */}
      <div
        {...getRootProps()}
        className={`${styles.dropzone} ${
          shape === "rounded" ? "rounded-full" : "rounded-md"
        } transition ease-linear bg-gray-100`}
        // style={{ height: 164 }}
      >
        <input
          {...getInputProps()}
          {...props}
          accept={accept}
          disabled={disabled}
          getFilesFromEvent={getFilesFromEvent}
          maxFiles={maxFiles}
          maxSize={maxSize}
          minSize={minSize}
          multiple={multiple}
          noClick={noClick}
          noDrag={noDrag}
          noDragEventsBubbling={noDragEventsBubbling}
          noKeyboard={noKeyboard}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          onFileDialogCancel={onFileDialogCancel}
          preventDropOnDocument={preventDropOnDocument}
          validator={validator}
        />
        {children}
      </div>
    </div>
  )
}
