import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import EditIcon from "@material-ui/icons/Edit"
import ClearIcon from "@material-ui/icons/Clear"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import Paper from "@material-ui/core/Paper"
import axios from "../../utils/axios"
import Pagination from "../../components/Pagination/Pagination"
import moment from "moment"
import { StyledTableCell } from "../../components/StyledTableCell"
import { StyledPropMenu } from "../../components/StyledPropMenu"
import EmptyTable from "../../components/EmptyTable"

function TechInspection({ limit, offset, count, pageCount, setCount }) {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const [items, setItems] = useState({})
  const [dropList, setDropList] = useState({ target: null, id: "" })

  const permissions = useSelector((state) => state.auth.permissions)

  // **** FUNCTIONS ****
  const getItems = useCallback(
    async (queryParams = {}) => {
      let response = await axios.get(`/tech-inspection?car_id=${params.id}`, {
        params: queryParams,
      })
      setItems(response.data)
      setCount(response.data.count)
    },
    [params.id, setCount]
  )

  const changeOffset = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
    },
    [getItems, limit, pageCount]
  )

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [getItems, limit, pageCount, offset])

  // **** EVENTS *****
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      // if edit, then go to edit page
      if (permissions.includes("cars_tech_inspection_edit"))
        history.push(`/home/cars/${params.id}/tech-inspection/${id}`)
    } else if (type === "delete") {
      // if delete, then delete that specific item
      if (permissions.includes("cars_tech_inspection_delete"))
        axios.delete(`/tech-inspection/${id}`).then((res) => {
          console.log(res)
          // get the new list of items
          getItems()
          // close the droplist
          setDropList((old) => {
            return { ...old, target: null }
          })
        })
    }
  }

  // CONSTANTS
  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-error-100 rounded text-error-600 p-0.5" />,
      type: "delete",
    },
  ]

  return (
    <>
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>№</StyledTableCell>
              <StyledTableCell>{t("date")}</StyledTableCell>
              <StyledTableCell>{t("deadline")}</StyledTableCell>
              <StyledTableCell>{t("given_place")}</StyledTableCell>
              {permissions.includes("cars_tech_inspection_delete") && (
                <StyledTableCell></StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.tech_inspections && items.tech_inspections?.length ? (
              items.tech_inspections.map(
                ({ id, to_time, given_place, from_time }, index) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      if (permissions.includes("cars_tech_inspection_edit"))
                        return history.push(
                          `/home/cars/${params.id}/tech-inspection/${id}`
                        )
                    }}
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {moment(from_time).format("YYYY-MM-DD")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(to_time).format("YYYY-MM-DD")}
                    </StyledTableCell>
                    <StyledTableCell>{given_place}</StyledTableCell>
                    {permissions.includes("cars_tech_inspection_delete") && (
                      <StyledTableCell
                        style={{ width: "5%", borderLeft: "1px solid #ddd" }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setDropList({ target: e.currentTarget, id: id })
                        }}
                        className="cursor-pointer"
                      >
                        <MoreHorizIcon />
                        <StyledPropMenu
                          anchorEl={dropList.target}
                          open={Boolean(dropList.target)}
                          onClose={(e) => {
                            e.stopPropagation()
                            setDropList((old) => {
                              return { ...old, target: null }
                            })
                          }}
                        >
                          <div className="flex flex-col divide-y font-body text-sm">
                            {listRender.map((el) => (
                              <div
                                className="flex w-full cursor-pointer py-2 mx-2"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleMoreClick(dropList.id, el.type)
                                }}
                              >
                                <div className="rounded">{el.icon}</div>
                                <div className="my-auto mx-2">{el.title}</div>
                              </div>
                            ))}
                          </div>
                        </StyledPropMenu>
                      </StyledTableCell>
                    )}
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <EmptyTable
        message="Нет историй"
        isActive={!items.tech_inspections?.length > 0}
      />

      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(count / pageCount)}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changeOffset}
        />
      </div>
    </>
  )
}

export default TechInspection
