export default function convertToInternationalCurrencySystem(labelValue) {
  // // Nine Zeroes for Billions
  // return Math.abs(Number(labelValue)) >= 1.0e9
  //   ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
  //   : // Six Zeroes for Millions
  //   Math.abs(Number(labelValue)) >= 1.0e6
  //   ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
  //   : // Three Zeroes for Thousands
  //   Math.abs(Number(labelValue)) >= 1.0e3
  //   ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
  //   : Math.abs(Number(labelValue));
  var formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "UZS",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(labelValue || 0)
}
