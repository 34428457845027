import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import axios from "../../../utils/axios"
import Input from "../../../components/Input/index"
import CarNumberInput from "../../../components/Form/input/CarNumberInput"
import DatePicker from "../../../components/DatePicker/index"
import moment from "moment"
import Select from "../../../components/Select/index"
import Tag from "../../../components/Tag/index"
import Card from "../../../components/Card/index"
import Form from "../../../components/Form/Index"
import Attributes from "./Attributes/Index"
import Radio from "../../../components/Radio"
import { useSelector } from "react-redux"

function GeneralInfo({
  formik,
  params,
  models = [],
  brands = [],
  colorAttributes = [],
  categories = [],
  firms = [],
  investors = [],
  owners = [],
  modelAttributes,
  setModelAttributes,
  setInitialValues,
  setInvestorSelected,
}) {
  const { t } = useTranslation()
  const numberType = formik.values.number_type?.value
  const [disabled, setDisabled] = useState(false)
  const [numRadioValue, setNumRadioValue] = useState(numberType)
  const permissions = useSelector((state) => state.auth.permissions)
  const [modelChanged, setModelChanged] = useState(false)

  const bgColor = {
    in_stock: "bg-blue-100",
    in_use: "bg-green-100",
    repairing: "bg-amber-100",
  }

  const textColor = {
    in_stock: "text-blue-600",
    in_use: "text-green-600",
    repairing: "text-amber-600",
  }

  useEffect(() => {
    if (!permissions.includes("cars_edit")) {
      setDisabled(true)
    }
  }, [permissions])

  const updateModel = async (val) => {
    let response = await axios.get(`/model/${val.value}`)

    setModelAttributes(response.model_attributes)
  }

  const onChangeRadio = (e) => {
    setNumRadioValue(e.target.value)
    formik.setFieldValue("state_number", "")
  }

  useEffect(() => {
    if (modelChanged) {
      // dynamically set model attributes
      modelAttributes?.forEach(({ attributes }) => {
        attributes?.forEach((attribute) => {
          formik.setFieldValue(attribute.name, "")
        })
      })
      setModelChanged(false)
    }
  }, [modelAttributes])

  const percentageRegExp = new RegExp("^[0-9][0-9]?$|^100$")

  return (
    <div className="w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
      <div className="space-y-4">
        <Card title="Общие сведения">
          {/* Status */}
          {permissions.includes("car_status") && params.id && (
            <div className="w-full py-2 flex items-center">
              <div className="w-1/3">{t("status")}</div>
              <div className="flex">
                <div className="w-2/3">
                  <Form.Item name="photo" formik={formik}>
                    <Tag
                      shape="subtle"
                      textColor={textColor[formik.values.status]}
                      bgColor={bgColor[formik.values.status]}
                      size="large"
                      style={{ width: "150px" }}
                    >
                      {t(formik.values.status)}
                    </Tag>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          {/* Brand */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("brand")}</div>
            <div className="w-2/3">
              <Form.Item name="brand" formik={formik}>
                <Select
                  isDisabled={disabled}
                  options={brands}
                  {...formik.getFieldProps("brand")}
                  onChange={(val) => {
                    formik.setFieldValue("brand", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Body Type */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("body-type")}</div>
            <div className="w-2/3">
              <Form.Item name="category" formik={formik}>
                <Select
                  isDisabled={disabled}
                  options={categories}
                  {...formik.getFieldProps("category")}
                  onChange={(val) => {
                    formik.setFieldValue("category", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Firm */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("firm")}</div>
            <div className="w-2/3">
              <Form.Item name="firm" formik={formik}>
                <Select
                  isDisabled={disabled}
                  options={firms}
                  {...formik.getFieldProps("firm")}
                  onChange={(val) => {
                    formik.setFieldValue("firm", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>

          {/* Investor */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("investor")}</div>
            <div className="w-2/3">
              <Form.Item name="investor" formik={formik}>
                <Select
                  isDisabled={disabled}
                  options={investors}
                  {...formik.getFieldProps("investor")}
                  onChange={(val) => {
                    formik.setFieldValue("investor", val)
                    if (val.value === "") {
                      setInvestorSelected(false)
                    } else {
                      setInvestorSelected(true)
                    }
                  }}
                />
              </Form.Item>
            </div>
          </div>

          {/* Investor share */}
          {formik.values?.investor && formik.values?.investor.value !== "" && (
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">&#42; {t("investor.share")}</div>
              <div className="w-2/3">
                <Form.Item name="investor_share" formik={formik}>
                  <div className="flex items-center">
                    <Input
                      disabled={disabled}
                      id="investor_share"
                      type="number"
                      max={"100"}
                      style={{ width: "95%", marginRight: "4px" }}
                      {...formik.getFieldProps("investor_share")}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          formik.setFieldValue("investor_share", e.target.value)
                        } else if (!percentageRegExp.test(e.target.value)) {
                          e.preventDefault()
                        } else {
                          formik.setFieldValue("investor_share", e.target.value)
                        }
                      }}
                      onKeyPress={(evt) => {
                        const charCodes = [65, 66, 67]
                        if (
                          evt.key.charCodeAt() < 48 ||
                          (evt.key.charCodeAt() > 57 &&
                            !charCodes.includes(evt.key.charCodeAt()))
                        )
                          evt.preventDefault()
                      }}
                    />
                    <span className="text-xl text-gray-500">%</span>
                  </div>
                </Form.Item>
              </div>
            </div>
          )}

          {/* Car Owner */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("car-owner")}</div>
            <div className="w-2/3">
              <Form.Item name="owner" formik={formik}>
                <Select
                  isDisabled={disabled}
                  options={owners}
                  {...formik.getFieldProps("owner")}
                  onChange={(val) => {
                    formik.setFieldValue("owner", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>

          {/* Model */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("category")}</div>
            <div className="w-2/3">
              <Form.Item name="model" formik={formik}>
                <Select
                  isDisabled={disabled}
                  options={models}
                  {...formik.getFieldProps("model")}
                  onChange={(val) => {
                    formik.setFieldValue("model", val)
                    updateModel(val)
                    setModelChanged(true)
                  }}
                />
              </Form.Item>
            </div>
          </div>

          {modelAttributes
            ? modelAttributes.map((modelAttribute, index) => (
                <div>
                  <hr className="mt-2" />
                  <div className="w-full py-2 flex items-baseline" key={index}>
                    <div className="w-1/3 font-semibold">
                      {modelAttribute.attribute_category.name}
                    </div>
                    <div className="w-2/3 font-semibold justify-center"></div>
                  </div>
                  {modelAttribute.attributes &&
                    modelAttribute.attributes.map(
                      (attribute, attributeIndex) => (
                        <>
                          <Attributes
                            disabled={disabled}
                            modelAttributes={modelAttributes}
                            attribute={attribute}
                            modelIndex={index}
                            attributeIndex={attributeIndex}
                            setModelAttributes={setModelAttributes}
                            formik={formik}
                          />
                        </>
                      )
                    )}
                </div>
              ))
            : ""}
        </Card>
      </div>
      <div className="space-y-4">
        <Card title="Технические сведения">
          {/* State number */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("type.of.number")}</div>
            <div className="w-2/3 flex">
              <div className="pr-10">
                <Radio
                  text={t("juristic")}
                  value="gov_number"
                  name="typeOfNumber"
                  onChange={onChangeRadio}
                  checked={numRadioValue === "gov_number"}
                />
              </div>
              <div>
                <Radio
                  text={t("physical")}
                  value="own_number"
                  name="typeOfNumber"
                  onChange={onChangeRadio}
                  checked={numRadioValue === "own_number"}
                />
              </div>
            </div>
          </div>
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("state.number")}</div>
            <div className="w-2/3">
              <div className="w-full">
                <Form.Item name="state_number" formik={formik}>
                  <CarNumberInput
                    disabled={disabled}
                    value={formik.values.state_number}
                    placeholder={
                      numRadioValue === "gov_number"
                        ? "01 111 AAA"
                        : "01 A 111 AA"
                    }
                    mask={
                      numRadioValue === "gov_number"
                        ? "99 999 aaa"
                        : "99 a 999 aa"
                    }
                    onChange={(val) =>
                      formik.setFieldValue(
                        "state_number",
                        val.target.value.toUpperCase()
                      )
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {/* Notary Office */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">{t("notary.office")}</div>
            <div className="w-2/3">
              <Form.Item name="notary_office" formik={formik}>
                <Input
                  disabled={disabled}
                  id="notary_office"
                  type="text"
                  {...formik.getFieldProps("notary_office")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Rent Start Date */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">Дата начала аренды</div>
            <div className="w-2/3">
              <Form.Item name="from_time" formik={formik}>
                <DatePicker
                  disabled={disabled}
                  placeholder={t("select.a.date")}
                  style={{ height: "35px" }}
                  value={
                    formik.values.from_time
                      ? moment(formik.values.from_time)
                      : null
                  }
                  hideTimeBlock
                  onChange={(val) => {
                    formik.setFieldValue("from_time", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Rent End Date */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">Дата окончания аренды</div>
            <div className="w-2/3">
              <Form.Item name="to_time" formik={formik}>
                <DatePicker
                  disabled={disabled}
                  placeholder={t("select.a.date")}
                  style={{ height: "35px" }}
                  value={
                    formik.values.to_time ? moment(formik.values.to_time) : null
                  }
                  hideTimeBlock
                  onChange={(val) => {
                    formik.setFieldValue("to_time", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Carcase no */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">Номер кузова</div>
            <div className="w-2/3">
              <Form.Item name="carcase_no" formik={formik}>
                <Input
                  disabled={disabled}
                  id="carcase_no"
                  type="text"
                  {...formik.getFieldProps("carcase_no")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Chassis no */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">Номер шасси</div>
            <div className="w-2/3">
              <Form.Item name="chassis_no" formik={formik}>
                <Input
                  disabled={disabled}
                  id="chassis_no"
                  type="text"
                  {...formik.getFieldProps("chassis_no")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Engine no */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">Номер двигателя</div>
            <div className="w-2/3">
              <Form.Item name="engine_no" formik={formik}>
                <Input
                  disabled={disabled}
                  id="engine_no"
                  type="text"
                  {...formik.getFieldProps("engine_no")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Car Color */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">Цвет машины</div>
            <div className="w-2/3">
              <Form.Item name="color" formik={formik}>
                <Select
                  isDisabled={disabled}
                  options={colorAttributes}
                  {...formik.getFieldProps("color")}
                  onChange={(val) => {
                    formik.setFieldValue("color", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Tone Expire Date */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">Срок тонировки</div>
            <div className="w-2/3">
              <Form.Item name="tone_expire_date" formik={formik}>
                <DatePicker
                  disabled={disabled}
                  placeholder={t("select.a.date")}
                  style={{ height: "35px" }}
                  value={
                    formik.values.tone_expire_date
                      ? moment(formik.values.tone_expire_date)
                      : null
                  }
                  onChange={(val) => {
                    formik.setFieldValue("tone_expire_date", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Made Date */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("made.year")}</div>
            <div className="w-2/3">
              <Form.Item name="made_year" formik={formik}>
                <Input
                  disabled={disabled}
                  id="made_year"
                  type="number"
                  {...formik.getFieldProps("made_year")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Mileage */}
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">&#42; {t("mileage")}</div>
            <div className="w-2/3">
              <Form.Item name="mileage" formik={formik}>
                <Input
                  disabled={disabled}
                  id="mileage"
                  type="number"
                  {...formik.getFieldProps("mileage")}
                />
              </Form.Item>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default GeneralInfo
