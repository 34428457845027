import React from "react";
import { StyledInputPhone } from ".";

function CarNumberInput({ placeholder, id, boxStyles, mask, ...rest }) {
  return (
    <StyledInputPhone
      id={id}
      mask={mask}
      placeholder={placeholder}
      maskChar={null}
      {...rest}
    />
  );
}

export default CarNumberInput;
