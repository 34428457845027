import { useSelector } from "react-redux";

import AlertElement from "./AlertElement";
import "./index.scss";

const Alert = ({ className, ...rest }) => {
  const alerts = useSelector((state) => state.alert.alerts);

  return (
    <div className={`${className} alerts fixed left-5 top-5 z-50`} {...rest}>
      {alerts?.map((alert) => (
        <AlertElement
          key={alert.id}
          id={alert.id}
          title={alert.title}
          type={alert.type}
        />
      ))}
    </div>
  );
};

export default Alert;
