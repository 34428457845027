import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export default function ReportSkeleton() {
  return (
    <SkeletonTheme color="#ddd" highlightColor="#fff">
      <div className="p-4 w-3/3 box-border bg-white m-4">
        <Skeleton
          color="#202020"
          highlightColor="#444"
          width="100%"
          height={50}
          className="mb-4"
          count={7}
        />
      </div>
    </SkeletonTheme>
  )
}
