import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import { useSelector } from "react-redux"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import validateForm from "../../functions/validateForm"
import PriceInput from "../../components/Form/input/PriceInput"
import Select from "../../components/Select"
import AsyncSelect from "../../components/Select/AsyncSelect"
import DatePicker from "../../components/DatePicker"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import cashIcon from "../../assets/icons/cash.png"
import clickIcon from "../../assets/icons/click.png"
import humoIcon from "../../assets/icons/humo.png"
import uzcardIcon from "../../assets/icons/uzcard.png"
import visaIcon from "../../assets/icons/visa.png"
import paymeIcon from "../../assets/icons/payme.png"
import Textarea from "../../components/Textarea"
import Input from "../../components/Input/index"

const paymentOptions = [
  {
    type: "cash",
    icon: cashIcon,
    amount: 0,
  },
  {
    type: "click",
    icon: clickIcon,
    amount: 0,
  },
  {
    type: "humo",
    icon: humoIcon,
    amount: 0,
  },
  {
    type: "uzcard",
    icon: uzcardIcon,
    amount: 0,
  },
  {
    type: "visa",
    icon: visaIcon,
    amount: 0,
  },
  {
    type: "payme",
    icon: paymeIcon,
    amount: 0,
  },
]

const icons = {
  cash: cashIcon,
  click: clickIcon,
  humo: humoIcon,
  uzcard: uzcardIcon,
  visa: visaIcon,
  payme: paymeIcon,
}

var unique_investor_id = "f78d1058-0776-475a-9a5e-ea2594d33db9"

function ExpensesCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const clientId = useSelector((state) => state.auth.clientId)

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [expenseTypes, setExpenseTypes] = useState([])
  const [cars, setCars] = useState([])
  const [carNumber, setCarNumber] = useState()
  const [payments, setPayments] = useState(paymentOptions)
  const [selectedPayment, setSelectedPayment] = useState()
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [investors, setInvestors] = useState([])
  const [investorSelected, setInvestorSelected] = useState(false)
  const [initialValues, setInitialValues] = useState({
    date: "",
    car: undefined,
    category: undefined,
    sub_account_category: undefined,
    investor: undefined,
    overall_money: undefined,
    subpayment: [],
    comment: "",
  })

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    } else {
      setSelectedPayment({
        type: "cash",
        amount: undefined,
        icon: icons["cash"],
      })
      setPayments((old) => old.slice(1))
    }
    getCars()
  }, [])

  useEffect(() => {
    if (investorSelected) {
      getInvestors()
    }
  }, [investorSelected])

  // **** FUNCTIONS ****
  const getCarNumber = async (id) => {
    await axios.get(`car/${id}`).then((res) => {
      setCarNumber(res?.state_number)
    })
  }
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/billing/transaction/${id}`)
      .then((res) => {
        let data = {
          ...res,
          category: { label: t(res.category.name), value: res.category.id },
          car: { label: res?.car?.state_number, value: res?.car?.id },
          sub_account_category: {
            label: res.subaccount_category.name,
            value: res.subaccount_category.id,
          },
        }

        setInitialValues(data)
        setSelectedPayment({
          type: res.payments[0].payment_type,
          amount: res.payments[0].amount,
          icon: icons[res.payments[0].payment_type],
        })
        setPayments((prev) =>
          prev?.filter((item) => item.type !== res.payments[0].payment_type)
        )
        getExpenseTypes(res.category.id)
        getCarNumber(res.car_id)
      })
      .finally(() => setIsGettingData(false))
  }

  const getCars = async () => {
    let res = await axios.get(`/car?limit=5&offset=0`)

    setCars(
      res.data.cars?.map(({ id, state_number }) => ({
        label: state_number,
        value: id,
      }))
    )
  }

  const getExpenseTypes = async (category_id) => {
    let response = await axios.get(
      `/billing/subaccount-category?limit=5&offset=0&category_id=${category_id}`
    )

    setExpenseTypes(
      response?.sub_account_categories?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const getInvestors = async () => {
    let response = await axios.get(`/investor?limit=5&offset=0`)

    setInvestors(
      response?.data?.investors?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const loadExpenseTypes = async (inputValue, callback) => {
    let res = await axios.get(
      `/billing/subaccount-category?limit=5&offset=0&category_id=3&search=${inputValue}`
    )

    callback(
      res.sub_account_categories?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        }
      })
    )
  }

  const loadInvestors = async (inputValue, callback) => {
    let res = await axios.get(`/investor?limit=5&offset=0&search=${inputValue}`)
    callback(
      res.data.investors?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        }
      })
    )
  }

  const loadCarOptions = async (inputValue, callback) => {
    let res = await axios.get(`/car?limit=5&offset=0&search=${inputValue}`)
    callback(
      res.data.cars?.map(({ id, state_number }) => {
        return {
          value: id,
          label: state_number,
        }
      })
    )
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    setBtnDisabled(true)
    const investor_id = {}
    const payments = []
    const paymentsObj = {}
    if (values?.investor?.value) {
      investor_id.investor_id = values?.investor?.value
    }
    if (selectedPayment.amount) {
      payments.push({
        amount: selectedPayment.amount,
        payment_type: selectedPayment.type,
      })
    }
    if (payments.length) {
      paymentsObj.payments = payments
    }
    axios
      .post("/billing-expenditure", {
        ...values,
        cashier_id: clientId,
        category_id: 3,
        car_id: values?.car?.value ?? "",
        ...paymentsObj,
        subaccount_category_id: values?.sub_account_category?.value,
        category_id: +values?.category?.value,
        ...investor_id,
      })
      .then((res) => {
        setLoading(false)
        setBtnDisabled(false)
      })
      .finally(() => {
        history.goBack()
      })
  }

  const updatePaymentType = (val) => {
    setSelectedPayment(val)
    setPayments((prev) => [...prev, selectedPayment])
  }

  const removePayment = (id) => {
    setPayments((old) => old?.filter((el, index) => index !== id))
  }

  const updatePaymentAmount = (input) => {
    setSelectedPayment((prev) => ({
      ...prev,
      amount: +input.replace(/\s+/g, ""),
    }))
  }
  // **** CONSTANTS ****
  const routes = [
    {
      title: t("expenses"),
      link: true,
      route: "/home/billing/expenses",
    },

    {
      title: params.id ? initialValues.name : t("edit"),
    },
  ]

  const categories = [
    {
      value: "3",
      label: t("employee_expenditure"),
    },
    {
      value: "2",
      label: t("car_expenditure"),
    },
  ]

  const ValidationSchema = Yup.object().shape({
    date: validateForm("mixed", t),
    category: validateForm("mixed", t),
    sub_account_category: validateForm("mixed", t),
    investor: investorSelected && validateForm("mixed", t),
  })

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("expense")}
                endAdornment={
                  !params.id
                    ? [
                        <CustomButton
                          shape="text"
                          color="text-error-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={cancelIcon}
                          onClick={() => history.goBack()}
                        >
                          {t("cancel")}
                        </CustomButton>,
                        <CustomButton
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                          disabled={btnDisabled}
                        >
                          {params.id ? t("save") : t("create")}
                        </CustomButton>,
                      ]
                    : [
                        <CustomButton
                          size="large"
                          shape="text"
                          color="text-secondary"
                          onClick={() => history.goBack()}
                        >
                          {t("back")}
                        </CustomButton>,
                      ]
                }
              />

              <div className="p-4 w-full grid grid-cols-5 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="col-span-3">
                  {/* Date */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("date")}</div>
                    <div className="w-2/3">
                      <Form.Item name="date" formik={formik}>
                        <DatePicker
                          disabled={params.id}
                          placeholder="Выберите дату"
                          style={{ height: "35px" }}
                          value={
                            formik.values.date
                              ? moment(formik.values.date)
                              : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue("date", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Category type */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("category.type")}</div>
                    <div className="w-2/3">
                      <Form.Item name="category" formik={formik}>
                        <Select
                          isDisabled={params.id}
                          placeholder="Выберите категорию"
                          options={categories}
                          {...formik.getFieldProps("category")}
                          onChange={(val) => {
                            getExpenseTypes(val.value)
                            formik.setFieldValue("category", val)
                            formik.setFieldValue("sub_account_category", "")
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Expense type */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("expense.type")}</div>
                    <div className="w-2/3">
                      <Form.Item name="sub_account_category" formik={formik}>
                        <AsyncSelect
                          isDisabled={params.id}
                          placeholder="Выберите тип расхода"
                          loadOptions={loadExpenseTypes}
                          defaultOptions={expenseTypes}
                          {...formik.getFieldProps("sub_account_category")}
                          onChange={(val) => {
                            formik.setFieldValue("sub_account_category", val)
                            val.value === unique_investor_id
                              ? setInvestorSelected(true)
                              : setInvestorSelected(false)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Investor */}
                  {investorSelected && (
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("investor")}</div>
                      <div className="w-2/3">
                        <Form.Item name="investor" formik={formik}>
                          <AsyncSelect
                            isDisabled={params.id}
                            placeholder="Выберите инвестора"
                            loadOptions={loadInvestors}
                            defaultOptions={investors}
                            {...formik.getFieldProps("investor")}
                            onChange={(val) => {
                              formik.setFieldValue("investor", val)
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  {/* CarNumber */}
                  {formik.values.category?.value === "2" && !params.id ? (
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("car.number")}</div>
                      <div className="w-2/3">
                        <Form.Item name="car" formik={formik}>
                          <AsyncSelect
                            isDisabled={params.id}
                            loadOptions={loadCarOptions}
                            defaultOptions={cars}
                            placeholder="Выберите номер машины"
                            {...formik.getFieldProps("car")}
                            onChange={(val) => {
                              formik.setFieldValue("car", val)
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ) : (
                    params.id &&
                    carNumber && (
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("car.number")}</div>
                        <div className="w-2/3">
                          <Input disabled value={carNumber} />
                        </div>
                      </div>
                    )
                  )}
                  {/* Comment */}
                  <div className="w-full py-2 flex items-baseline">
                    <p className="w-1/3">{t("comment")}:</p>
                    <div className="w-2/3">
                      <Form.Item name="comment" formik={formik}>
                        <Textarea
                          disabled={params.id}
                          type="text"
                          placeholder={t("leave.comment")}
                          style={{
                            minHeight: "100px",
                          }}
                          {...formik.getFieldProps("comment")}
                          onChange={(val) => {
                            formik.setFieldValue("comment", val.target.value)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
                <Card title={t("sum")} className="col-span-3">
                  {/* Sub payments */}
                  {selectedPayment && (
                    <div className="w-full py-2 flex items-center text-gray-700">
                      <div className="flex w-1/3 items-center">
                        <span
                          className="flex justify-center 
                                    items-center border 
                                    rounded-md hover:bg-gray-100
                                    transition ease-linear
                                    cursor-pointer py-2 px-6 mr-4"
                          style={{ width: "85px", height: "40px" }}
                        >
                          <img src={selectedPayment?.icon} alt="" />
                        </span>
                        {t(selectedPayment.type)}
                      </div>
                      <div className="w-2/3">
                        <Form.Item formik={formik}>
                          <PriceInput
                            disabled={params.id}
                            placeholder={t("enter.the.amount")}
                            value={selectedPayment?.amount || ""}
                            onChange={(e) =>
                              updatePaymentAmount(e.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  {!params.id && payments.length !== 0 && (
                    <>
                      <hr className="my-4" />
                      <h6 className="font-semibold text-gray-700">
                        {t("choose.payment.type")}
                      </h6>

                      {/* Payment options */}
                      <div className="w-full py-2 flex items-center space-x-2">
                        {payments?.length &&
                          payments?.map((el, index) => (
                            <span
                              key={index}
                              className="flex justify-center 
                              items-center border cursor-pointer
                              rounded-md hover:bg-gray-100
                              transition ease-linear
                              py-2 px-6 mr-2 "
                              style={{ width: "85px", height: "40px" }}
                              onClick={() => {
                                updatePaymentType(el)
                                removePayment(index)
                              }}
                            >
                              <img src={el.icon} alt="" />
                            </span>
                          ))}
                      </div>
                    </>
                  )}
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
}

export default ExpensesCreate
