import { useState, useEffect } from "react"
import RangePicker from "../../components/DatePicker/RangePicker"
import { useTranslation } from "react-i18next"
export default function TableComp(props) {
  const {
    className,
    title,
    hasRangePicker,
    updateDate,
    headings,
    sourceData,
    checkboxSelection = false,
    children,
    ...rest
  } = props

  const [isAllSelected, setIsAllSelected] = useState(false)
  const [isSelected, setIsSelected] = useState(
    new Array(sourceData.length).fill(false)
  )

  const { t } = useTranslation()

  const checkAllBoxes = () => {
    // if none checkboxes are selected
    if (
      isSelected.every((item) => {
        return item === false
      })
    ) {
      setIsSelected(new Array(sourceData.length).fill(true))
      document.getElementById("parentCheckbox").indeterminate = false

      setIsAllSelected(true)
    }
    // if all checkboxes are selected
    else if (
      isSelected.every((item) => {
        return item === true
      })
    ) {
      setIsSelected(new Array(sourceData.length).fill(false))
      setIsAllSelected(false)
    }
    // if at least one checkbox is selected
    else if (
      isSelected.some((item) => {
        return item === true
      })
    ) {
      setIsSelected(new Array(sourceData.length).fill(false))
      document.getElementById("parentCheckbox").indeterminate = false

      setIsAllSelected(true)
    }
  }

  const checkABox = (input) => {
    const updatedCheckState = isSelected.map((item, index) =>
      index === input ? !item : item
    )

    setIsSelected(updatedCheckState)
    document.getElementById("parentCheckbox").indeterminate = true
  }

  useEffect(() => {
    if (
      isSelected.some((item) => {
        return item === true
      })
    ) {
    } else {
      setIsAllSelected(false)
    }
  }, [isSelected])

  const data = sourceData.map((row, index) => {
    let rowData = []
    let i = 0

    for (const key in row) {
      rowData.push({
        key: headings[i],
        val: row[key],
      })
      i++
    }

    return (
      <tr
        key={index}
        className="border-b border-gray-200"
        style={{ background: index % 2 === 0 ? "#F6F8F9" : "#FFFFFF" }}
      >
        {checkboxSelection && (
          <td className="py-4 px-6">
            <input
              type="checkbox"
              checked={isSelected[index]}
              onChange={() => checkABox(index)}
            />
          </td>
        )}
        {rowData.map((data, index) => (
          <td
            key={index}
            headers={data.key}
            className="py-4 px-6 text-left whitespace-nowrap"
            style={{ whiteSpace: "pre" }}
          >
            {data.val}
          </td>
        ))}
      </tr>
    )
  })

  return (
    <div
      {...rest}
      className={`${className} bg-white border rounded-lg shadow-md rounded my-6`}
    >
      <div className="text-gray-500 font-bold py-4 flex justify-between">
        <h2 className={`px-6`}>{title}</h2>
        {hasRangePicker && (
          <RangePicker
            showToday={false}
            showWeekNumber
            dateInputPlaceholder={[t("from"), t("till")]}
            showOk={false}
            showClear
            placeholder={t("select.a.date")}
            hideTimePicker
            onChange={(val) => updateDate(val)}
            style={{ width: "20rem", marginRight: "1rem" }}
            
          />
        )}
      </div>
      <table className="min-w-max w-full table-auto">
        <thead>
          <tr className="bg-gray-200 text-gray-600 text-sm text-left">
            {checkboxSelection && (
              <th className="py-3 px-6">
                <input
                  type="checkbox"
                  id="parentCheckbox"
                  checked={isAllSelected}
                  onChange={() => checkAllBoxes()}
                />
              </th>
            )}
            {headings.map((col, index) => (
              <th key={index} className="py-3 px-6">
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">{data}</tbody>
      </table>
    </div>
  )
}
