import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import axios from "../../../utils/axios"
import Pagination from "../../../components/Pagination/Pagination"
import dateTimeConvert from "../../../functions/dateTimeConvert"
import Tag from "../../../components/Tag"
import { StyledTableCell } from "../../../components/StyledTableCell"
import uzcardIcon from "../../../assets/icons/uzcard.png"
import cashIcon from "../../../assets/icons/cash.png"
import humoIcon from "../../../assets/icons/humo.png"
import paymeIcon from "../../../assets/icons/payme.png"
import clickIcon from "../../../assets/icons/click.png"
import visaIcon from "../../../assets/icons/visa.png"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import { useParams } from "react-router"
import parseQuery from "../../../functions/parseQuery"

const paymentTypes = {
  uzcard: uzcardIcon,
  cash: cashIcon,
  humo: humoIcon,
  payme: paymeIcon,
  click: clickIcon,
  visa: visaIcon,
}

export default function App({ client }) {
  const { t } = useTranslation()
  const params = useParams()
  const pageCount = 10
  let { limit, offset } = parseQuery()

  const [pageLimit, setPageLimit] = useState(pageCount)
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})
  const [count, setCount] = useState()

  // **** FUNCTIONS ****
  const getItems = useCallback(
    async (queryParams = {}) => {
      let response = await axios.get(
        `/billing/transaction?car_id=${params.id}&category_id=2`,
        {
          params: queryParams,
        }
      )
      setItems(response)
      setIsLoading(false)
      setCount(response.data.count)
    },
    [params.id]
  )

  const changeOffset = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      console.log(value, pageCount)
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
    },
    [getItems, limit]
  )

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [getItems, limit, offset])

  return (
    <div>
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("sum")}</StyledTableCell>
              <StyledTableCell>{t("payment.for")}</StyledTableCell>
              <StyledTableCell>{t("date")}</StyledTableCell>
              <StyledTableCell>{t("cashier")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.data && items.data?.billing_transactions?.length ? (
              items.data.billing_transactions?.map(
                (
                  { id, cashier, payments, subaccount_category, date },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 transition ease-linear"
                  >
                    <StyledTableCell
                      style={{
                        width: "30%",
                      }}
                    >
                      {payments?.map((payment) => (
                        <span className="flex space-x-4 items-center">
                          <span
                            className={`flex justify-center 
                              border-2 $ rounded-md 
                              cursor-pointer px-2 py-1`}
                            style={{ width: "75px", height: "35px" }}
                          >
                            <img
                              src={paymentTypes[payment.payment_type]}
                              alt=""
                            />
                          </span>
                          <p>
                            {convertToInternationalCurrencySystem(
                              payment.amount
                            )}
                          </p>
                        </span>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      <Tag
                        shape="subtle"
                        color={
                          subaccount_category.name === "fine" ? "error" : "blue"
                        }
                        size="large"
                        style={{ width: "160px" }}
                      >
                        {t(subaccount_category.name)}
                      </Tag>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      <div className="text-blue-600">
                        {dateTimeConvert(date)}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      <div className="text-blue-600">{cashier.name}</div>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <EmptyTable
        message="Нет транзакций"
        isActive={!isLoading && !items.data?.billing_transactions?.length > 0}
        style={{ marginTop: "0" }}
      />

      {isLoading && (
        <div className="flex justify-center py-2">
          <CircularProgress />
        </div>
      )}
      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(count / pageCount)}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changeOffset}
        />
      </div>
    </div>
  )
}
