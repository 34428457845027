import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import { useHistory, useParams, useLocation } from "react-router-dom"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import Card from "../../components/Card/index"

import { Formik } from "formik"
import Form from "../../components/Form/Index"
import CircularProgress from "@material-ui/core/CircularProgress"

import Switch from "../../components/Switch"

function PermissionsCreate() {
  const { t } = useTranslation()
  const location = useLocation()
  const params = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [initialValues, setInitialValues] = useState([])

  useEffect(() => {
    getPermissions()
  }, [])

  const getPermissions = async () => {
    let response = await axios
      .get("auth/nested-permission")
      .then((res) => res.data)
    let roleResponse = await axios
      .get(`auth/role/${params.id}`)
      .then((res) => res.data)

    let updatedValues = roleResponse.permissions?.map(({ id, name }) => id)

    setInitialValues(
      response.childs?.map(({ id, name }) => ({
        id: id,
        name: name,
        isChecked: updatedValues?.includes(id),
      }))
    )
    setIsLoading(false)
  }

  // **** EVENTS ****
  const onSubmit = () => {
    axios.delete("auth/role-permission", {
      data: {
        permission_id: "",
        role_id: params.id,
      },
    })
  }

  const updateStatus = (index) => {
    let updatedStatuses = initialValues.map((item, i) => {
      if (index === i) {
        // if checked, then delete the permission
        if (item.isChecked) {
          axios.delete("auth/role-permission", {
            data: {
              permission_id: item.id,
              role_id: params.id,
            },
          })
        } // if not checked, then add new permission
        else {
          axios.post("auth/role-permission", {
            permission_id: item.id,
            role_id: params.id,
          })
        }
        return { ...item, isChecked: !item.isChecked }
      }
      return item
    })
    setInitialValues(updatedStatuses)
  }

  const routes = [
    {
      title: t("permissions"),
      link: true,
      route: "/home/settings/permissions",
    },
    {
      title: t(location.state),
    },
  ]

  return (
    <div>
      {!params.id || !isLoading ? (
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("permissions")}
                startAdornment={[<Breadcrumb routes={routes} />]}
              />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <div className="space-y-4">
                  <Card title={t("general.information")}>
                    {initialValues && initialValues.length
                      ? initialValues.map(({ id, name }, index) => (
                          <div className="w-full flex justify-between items-baseline space-y-4">
                            <div className="w-1/2">{name}</div>
                            <div className="w-1/6">
                              <Form.Item formik={formik}>
                                <Switch
                                  color="primary"
                                  checked={
                                    initialValues[index].isChecked
                                      ? true
                                      : false
                                  }
                                  onChange={() => updateStatus(index)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        ))
                      : ""}
                    {isLoading && (
                      <div className="flex justify-center py-2">
                        <CircularProgress size={24} />
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div>Loading</div>
      )}
    </div>
  )
}

export default PermissionsCreate
