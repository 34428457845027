import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import menu from "../../components/Sidebar/menu";

function Index() {
  const history = useHistory();

  useEffect(() => {
    history.push(menu[menu.length - 1].children[0].path);
  }, [history]);
  return <div></div>;
}

export default Index;
