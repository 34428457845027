import { useState, useEffect } from "react"
import { useTheme } from "@material-ui/core/styles"
import { TabPanel } from "../../../components/Tab/TabBody"
import OilChangeHistory from "../../oil-change/Index"
import InsuranceHistory from "../../insurance/Index"
import TechInspection from "../../tech-inspection/Index"
import Maintenance from "../../maintenance/Index"
import { StyledTabs, StyledTab } from "../../../components/StyledTabs"
import parseQuery from "../../../functions/parseQuery"

function CarHistory({ a11yProps, onChange = () => {} }) {
  const theme = useTheme()
  let { limit, offset } = parseQuery()

  const [count, setCount] = useState()

  const pageCount = 10

  const [insideValue, setInsideValue] = useState(0)

  useEffect(() => {
    onChange(insideValue)
  }, [insideValue])

  // Tab handle events
  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>
  }

  const handleInsideChange = (event, newInsideValue) => {
    setInsideValue(newInsideValue)
  }

  return (
    <div>
      <StyledTabs
        value={insideValue}
        onChange={handleInsideChange}
        centered={false}
        aria-label="full width tabs example"
        className="font-body bg-white mx-6"
      >
        <StyledTab
          label={tabLabel("Замено масла")}
          {...a11yProps(0)}
          style={{ width: "100px" }}
        />
        <StyledTab label={tabLabel("Страховка")} {...a11yProps(1)} />
        <StyledTab label={tabLabel("Тех паспорт")} {...a11yProps(2)} />
        <StyledTab label={tabLabel("Ремонт")} {...a11yProps(3)} />
      </StyledTabs>
      <TabPanel value={insideValue} index={0} dir={theme.direction}>
        <OilChangeHistory
          limit={limit}
          offset={offset}
          count={count}
          pageCount={pageCount}
          setCount={setCount}
        />
      </TabPanel>
      <TabPanel value={insideValue} index={1} dir={theme.direction}>
        <InsuranceHistory
          limit={limit}
          offset={offset}
          count={count}
          pageCount={pageCount}
          setCount={setCount}
        />
      </TabPanel>
      <TabPanel value={insideValue} index={2} dir={theme.direction}>
        <TechInspection
          limit={limit}
          offset={offset}
          count={count}
          pageCount={pageCount}
          setCount={setCount}
        />
      </TabPanel>
      <TabPanel value={insideValue} index={3} dir={theme.direction}>
        <Maintenance
          limit={limit}
          offset={offset}
          count={count}
          pageCount={pageCount}
          setCount={setCount}
        />
      </TabPanel>
    </div>
  )
}

export default CarHistory
