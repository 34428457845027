import CloudDownloadRounded from "@material-ui/icons/CloudDownloadRounded";
import { CircularProgress } from "@material-ui/core";

function index({ isActive = false, ...rest }) {
  return (
    <div
      className={` 
        flex bg-gray-600 
        text-white items-center 
        rounded cursor-pointer
        hover:opacity-90
        transition duration-100 ease-linear
        space-x-1 ${isActive ? "py-1.5" : "py-1"} px-2
      `}
      style={{ width: "155px" }}
      {...rest}
    >
      <div className="flex w-7 items-center">
        {isActive ? (
          <CircularProgress color="#fff" size={20} />
        ) : (
          <CloudDownloadRounded />
        )}
      </div>
      <p className="text-sm">Экспорт в Excel</p>
    </div>
  );
}

export default index;
