import { Component, useState, useEffect } from "react"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import Input from "../Input/index"

export default class CustomForm extends Component {
  static Input(props) {
    return <FormInput {...props} />
  }

  static Item(props) {
    return <FormItem {...props} />
  }

  static FieldArrayItem(props) {
    return <FieldArrayItem {...props} />
  }

  render() {
    return <FromWrapper {...this.props} />
  }
}

const FromWrapper = ({
  initialValues,
  onSubmit,
  layout = "vertical",
  children,
  rules,
}) => {
  const [formRules, setFormRules] = useState(null)

  useEffect(() => {
    let obj = {}
    for (let key in rules) {
      obj[key] = rules[key](Yup)
    }
    console.log(obj)
    setFormRules(obj)
  }, [rules])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(formRules ?? {})}
      onSubmit={onSubmit}
    >
      {(formik) => children(formik)}
    </Formik>
  )
}

const FormInput = ({ type = "text", name = "", label = "", ...args }) => {
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Input name={name} type={type} {...args} />
      <ErrorMessage name={name} />
    </div>
  )
}

const FormItem = ({ name = "", label = "", children, formik, ...args }) => {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      {children}
      <div
        className="min-h-6 w-full"
        style={{ fontSize: "14px", lineHeight: 1.5715, color: "#ff4d4f" }}
      >
        {formik.errors[name] && formik.touched[name] ? formik.errors[name] : ""}
      </div>
    </>
  )
}

const FieldArrayItem = ({
  name = "",
  label = "",
  children,
  formik,
  index,
  ...args
}) => {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      {children}
      <div
        className="min-h-6 w-full"
        style={{ fontSize: "14px", lineHeight: 1.5715, color: "#ff4d4f" }}
      >
        {formik.errors[name] &&
        formik.errors[name][index] &&
        formik.touched[name] &&
        formik.touched[name][index]
          ? formik.errors[name][index]
          : ""}
      </div>
    </>
  )
}
