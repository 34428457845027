import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import { useHistory, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "../../components/Card/index"
import DatePicker from "../../components/DatePicker/index"
import moment from "moment"
import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import validateForm from "../../functions/validateForm"

function TechInspectionCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [initialValues, setInitialValues] = useState({
    number: "",
    from_time: "",
    to_time: "",
    given_place: "",
  })

  useEffect(() => {
    if (params.tech_id) {
      getProperty(params.tech_id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/tech-inspection/${id}`)
      .then((res) => {
        let data = {
          ...res,
        }
        setInitialValues(data)
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.tech_id) {
      axios
        .put(`/tech-inspection/${params.tech_id}`, {
          car_id: `${params.id}`,
          number: values?.number,
          from_time: moment(values?.from_time).toISOString(),
          to_time: moment(values?.to_time).toISOString(),
          given_place: values?.given_place,
        })
        .then((res) => {
          history.push(`/home/cars/${params.id}`)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/tech-inspection", {
          car_id: `${params.id}`,
          number: values?.number,
          from_time: moment(values?.from_time).toISOString(),
          to_time: moment(values?.to_time).toISOString(),
          given_place: values?.given_place,
        })
        .then((res) => {
          history.push(`/home/cars/${params.id}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    number: validateForm("mixed", t),
    from_time: validateForm("mixed", t),
    to_time: validateForm("mixed", t),
    given_place: validateForm("mixed", t),
  })

  const routes = [
    {
      title: t("cars"),
      link: true,
      route: "/home/cars",
    },
    {
      title: t("car.history"),
      link: true,
      route: `/home/cars/${params.id}`,
    },
    {
      title: params.tech_id ? t("edit") : t("create"),
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("technical.information")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    size="large"
                    shape="text"
                    color="text-secondary"
                    onClick={() => history.push(`/home/cars/${params.id}`)}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    size="large"
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        <></>
                      )
                    }
                  >
                    {params.tech_id ? t("edit") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")}>
                  {/* Tech Passport Number */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">Номер тех паспорта</div>
                    <div className="w-2/3">
                      <Form.Item name="number" formik={formik}>
                        <Input
                          id="number"
                          type="text"
                          {...formik.getFieldProps("number")}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* From time */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("from")}</div>
                    <div className="w-2/3">
                      <Form.Item name="from_time" formik={formik}>
                        <DatePicker
                          placeholder={t("select.a.date")}
                          style={{ height: "35px" }}
                          value={
                            formik?.values?.from_time
                              ? moment(formik.values.from_time)
                              : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue("from_time", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* To time */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("till")}</div>
                    <div className="w-2/3">
                      <Form.Item name="to_time" formik={formik}>
                        <DatePicker
                          placeholder={t("select.a.date")}
                          style={{ height: "35px" }}
                          onChange={(val) => {
                            formik.setFieldValue("to_time", val)
                          }}
                          value={
                            formik.values.to_time
                              ? moment(formik.values.to_time)
                              : null
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* {/* Given place */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("given_place")}</div>
                    <div className="w-2/3">
                      <Form.Item name="given_place" formik={formik}>
                        <Input
                          id="given_place"
                          type="text"
                          {...formik.getFieldProps("given_place")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default TechInspectionCreate
