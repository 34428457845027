import React from "react"
import { StyledInputPhone } from "."

function PhoneInput({ placeholder, id, boxStyles, mask, ...rest }) {
  return (
    <StyledInputPhone
      {...rest}
      id={id}
      mask={mask || `999999999999999999`}
      placeholder={placeholder}
      maskChar={null}
      type="tel"
    />
  )
}

export default PhoneInput
