import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";

import axios from "../../utils/axios";
import Header from "../../components/Header/Header";
import Button from "../../components/Buttons";
import Input from "../../components/Input";
import Filters from "../../components/Filters";
import Pagination from "../../components/Pagination/Pagination";
import dateTimeConvert from "../../functions/dateTimeConvert";
import { StyledTableCell } from "../../components/StyledTableCell";
import MoreClickHandler from "../../components/MoreClick";
import AlertComponent from "../../components/Alert";

export default function App() {
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = useSelector((state) => state.auth.permissions);

  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(pageCount);

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [items, setItems] = useState({});

  useEffect(() => {
    getItems();
  }, [pageLimit, searchQuery]);

  // **** FUNCTIONS ****
  const getItems = async () => {
    let response = await axios.get(
      `/billing/transaction?limit=${pageCount}&offset=${
        pageLimit - pageCount
      }&search=${searchQuery}`
    );

    setItems({
      data: response.data,
    });
    console.log(response);
    setIsLoading(false);
  };

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("income")}
        endAdornment={
          permissions.includes("billing_expenses_create")
            ? [
                <Button
                  size="large"
                  shape="text"
                  color="text-secondary"
                  onClick={() => history.push("/home/billing/expenses/create")}
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              setSearchQuery(val.target.value);
              setIsLoading(true);
            }}
          />
        </div>
      </Filters>

      <div className="px-5 py-4">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("date")}</StyledTableCell>
                <StyledTableCell>{t("sum")}</StyledTableCell>
                <StyledTableCell>{t("expense.type")}</StyledTableCell>

                {permissions.includes("billing_expenses_delete") && (
                  <StyledTableCell></StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.data && items?.data?.cashboxes?.length ? (
                items.data?.cashboxes?.map(
                  (
                    { id, name, account_number, cashiers, created_at },
                    index
                  ) => (
                    <TableRow
                      key={id}
                      className="hover:bg-gray-100 cursor-pointer transition ease-linear"
                      onClick={() => {
                        history.push(`/home/billing/expenses/${id}`);
                      }}
                    >
                      <StyledTableCell>
                        {dateTimeConvert(created_at)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {cashiers?.map(({ full_name, phone_number }, i) => (
                          <div>
                            <p>{full_name}</p>
                            <p className="text-blue-600">{phone_number}</p>
                          </div>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell>{account_number}</StyledTableCell>

                      {permissions.includes("billing_expenses_delete") && (
                        <StyledTableCell
                          style={{
                            width: "5%",
                            borderLeft: "1px solid #ddd",
                          }}
                        >
                          <MoreClickHandler
                            id={id}
                            api="/expenses"
                            pushLocation="/home/expenses"
                            editPermission="billing_expenses_edit"
                            deletePermission="billing_expenses_delete"
                            getItems={getItems}
                          />
                        </StyledTableCell>
                      )}
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <Pagination
          className="mt-5"
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            console.log(val);
            setPageLimit(val);
          }}
        />
      </div>
    </div>
  );
}
