export default function App(props) {
  return (
    <div
      className="flex bg-background w-full"
      style={{ minHeight: "100vh", height: "inherit" }}
    >
      <div className="flex-none overflow-hidden">{props.sidebar}</div>
      <div
        className="flex flex-grow flex-col"
        style={{
          maxWidth: "100%",
          overflowX: "hidden",
          height: "100vh",
          overflowY: "scroll",
          margin: 0,
        }}
      >
        <div className="mb-auto">{props.children}</div>
        <div className="">{/* <Footer /> */}</div>
      </div>
    </div>
  );
}
