import { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"

const InvestorShareReport = () => {
  const { t } = useTranslation()

  const [items, setItems] = useState([])
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)

  useEffect(() => {
    const getItems = async () => {
      let response = await axios.get(`/report/investor-share`)
      setItems(response.reports || [])
      setIsLoading(false)
    }
    getItems()
  }, [])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/report/investor-share-excel`)
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  var modData = useMemo(() => {
    var january = new Set()
    var february = new Set()
    var march = new Set()
    var april = new Set()
    var may = new Set()
    var june = new Set()
    var july = new Set()
    var august = new Set()
    var september = new Set()
    var october = new Set()
    var november = new Set()
    var december = new Set()

    items.forEach((item) => {
      january.add(item.share.january)
      february.add(item.share.february)
      march.add(item.share.march)
      april.add(item.share.april)
      may.add(item.share.may)
      june.add(item.share.june)
      july.add(item.share.july)
      august.add(item.share.august)
      september.add(item.share.september)
      october.add(item.share.october)
      november.add(item.share.november)
      december.add(item.share.december)
    })
    return {
      januray: january.has(0) && january.size === 1 ? false : true,
      february: february.has(0) && february.size === 1 ? false : true,
      march: march.has(0) && march.size === 1 ? false : true,
      april: april.has(0) && april.size === 1 ? false : true,
      may: may.has(0) && may.size === 1 ? false : true,
      june: june.has(0) && june.size === 1 ? false : true,
      july: july.has(0) && july.size === 1 ? false : true,
      august: august.has(0) && august.size === 1 ? false : true,
      september: september.has(0) && september.size === 1 ? false : true,
      october: october.has(0) && october.size === 1 ? false : true,
      november: november.has(0) && november.size === 1 ? false : true,
      december: december.has(0) && december.size === 1 ? false : true,
    }
  }, [items])

  return (
    <div>
      <Header title={t("investorShareReport")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      ></Filters>
      <div className="px-4 py-6 bg-white m-2">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  rowSpan={3}
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                >
                  {t("investor")}
                </TableCell>
                {modData.january && (
                  <TableCell
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("january")}
                  </TableCell>
                )}
                {modData.february && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("february")}
                  </TableCell>
                )}
                {modData.march && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("march")}
                  </TableCell>
                )}
                {modData.april && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("april")}
                  </TableCell>
                )}
                {modData.may && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("may")}
                  </TableCell>
                )}
                {modData.june && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("june")}
                  </TableCell>
                )}
                {modData.july && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("july")}
                  </TableCell>
                )}
                {modData.august && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("august")}
                  </TableCell>
                )}
                {modData.september && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("september")}
                  </TableCell>
                )}
                {modData.october && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("october")}
                  </TableCell>
                )}
                {modData.november && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("november")}
                  </TableCell>
                )}
                {modData.december && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("december")}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            {items?.map((item, index) => (
              <TableRow
                onClick={() =>
                  history.push(
                    `/home/reports/investor_share/${item.investor_id}`
                  )
                }
                style={{
                  cursor: "pointer",
                  background: index % 2 === 0 && "#F6F8F9",
                }}
              >
                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {item?.name}
                </TableCell>
                {modData.january && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.january)}
                  </TableCell>
                )}
                {modData.february && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.february)}
                  </TableCell>
                )}
                {modData.march && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.march)}
                  </TableCell>
                )}
                {modData.april && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.april)}
                  </TableCell>
                )}
                {modData.may && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.may)}
                  </TableCell>
                )}
                {modData.june && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.june)}
                  </TableCell>
                )}
                {modData.july && (
                  <TableCell style={{ borderRight: "1px solid #ddd" }}>
                    {convertToInternationalCurrencySystem(item?.share.july)}
                  </TableCell>
                )}
                {modData.august && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.august)}
                  </TableCell>
                )}
                {modData.september && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(
                      item?.share.september
                    )}
                  </TableCell>
                )}
                {modData.october && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.october)}
                  </TableCell>
                )}
                {modData.november && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.november)}
                  </TableCell>
                )}
                {modData.december && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(item?.share.december)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет отчетов"
          isActive={!isLoading && !items?.length > 0}
        />
        {isLoading && <ReportSkeleton />}
      </div>
    </div>
  )
}

export default InvestorShareReport
