import { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import moment from "moment"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Filters from "../../components/Filters"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import EmptyTable from "../../components/EmptyTable"
import RangePicker from "../../components/DatePicker/RangePicker"
import ConvertToExcel from "../../components/ConvertToExcel"
import downloadFile from "../../functions/downloadFile"
import ReportSkeleton from "../../components/Skeleton/ReportSkeleton"
import CustomButton from "../../components/Buttons/index"
import parseQuery from "../../functions/parseQuery"

const Cashflow = () => {
  const { t } = useTranslation()
  const { from_time, to_time } = parseQuery()

  const [data, setData] = useState({})
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })

  const firstRowCells = useMemo(() => {
    let costs = {},
      company = {},
      car = {}

    if (data) {
      const { company_expenditure, car_expenditure, cost } = data
      cost?.expenditures?.forEach((fine) => {
        costs[fine.name] = fine.name
      })
      company_expenditure?.expenditures?.forEach((expenditure) => {
        company[expenditure.name] = expenditure.name
      })
      car_expenditure?.expenditures?.forEach((expenditure) => {
        car[expenditure.name] = expenditure.name
      })
    }

    const costItems = Object.keys(costs).map((propName) => ({
      [propName]: costs[propName],
    }))
    const companyItems = Object.keys(company).map((propName) => ({
      [propName]: company[propName],
    }))
    const carItems = Object.keys(car).map((propName) => ({
      [propName]: car[propName],
    }))

    return [
      { "Доход от аренды машин": "order_income" },
      { "Расход от аренды машин": "order_outcome" },
      { Пополнение: "top_up" },
      ...costItems,
      { "Общий доход": "total_income" },
      { "Расходы компании": "company_expenditure" },
      ...companyItems,
      { "Расходы на машину": "car_expenditure" },
      ...carItems,
      { "Общый расход": "total_expenditure" },
      { "Чистый денежный поток": "profit" },
      { "Начальное сальдо": "initial_balance" },
      { "Конечное сальдо": "closing_balance" },
    ]
  }, [data])

  const valueCells = useMemo(() => {
    let costs = [],
      company = [],
      car = []

    if (data) {
      const { company_expenditure, car_expenditure, cost } = data
      cost?.expenditures?.forEach((fine) => {
        costs.push(fine.amount)
      })
      company_expenditure?.expenditures?.forEach((expenditure) => {
        company.push(expenditure.amount)
      })
      car_expenditure?.expenditures?.forEach((expenditure) => {
        car.push(expenditure.amount)
      })
    }

    return [
      data.order_income?.total_price,
      data.order_outcome?.total_price,
      data.top_up?.total_price,
      ...costs,
      data.total_income?.total_price,
      data.company_expenditure?.total_price,
      ...company,
      data.car_expenditure?.total_price,
      ...car,
      data.total_expenditure?.total_price,
      data.profit?.total_price,
      data.initial_balance?.total_price,
      data.closing_balance?.total_price,
    ]
  }, [data])

  useEffect(() => {
    const getData = async () => {
      let response = await axios.get(`/daily-cashflow`, {
        params: {
          from_time: fromToDate.start || from_time,
          to_time: fromToDate.end || to_time,
        },
      })

      setData(response)
      setIsLoading(false)
    }
    getData()
  }, [fromToDate, from_time, to_time])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/report/investor-car-excel`, {
        params: {
          start_date: fromToDate.start,
          end_date: fromToDate.end,
        },
      })
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const updateDate = (val) =>
    setFromToDate({
      start: moment(val[0]).toISOString() ?? "",
      end: moment(val[1]).toISOString() ?? "",
    })

  return (
    <div>
      <Header
        title={t("incomeExpenseReportTitle")}
        endAdornment={[
          <CustomButton
            size="large"
            shape="text"
            color="text-secondary"
            onClick={() => history.goBack()}
          >
            {t("back")}
          </CustomButton>,
        ]}
      />
      <Filters
        extra={
          <div className="flex space-x-2 data-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        <RangePicker
          showToday={false}
          showWeekNumber
          dateInputPlaceholder={[t("from"), t("till")]}
          showOk={false}
          showClear
          placeholder={t("select.a.date")}
          hideTimePicker
          onChange={(val) => updateDate(val)}
        />
      </Filters>
      {isLoading ? (
        <ReportSkeleton />
      ) : (
        <div className="px-4 py-6 bg-white m-2">
          <TableContainer className="mt-2" elevation={0} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {firstRowCells.map((cell, index) => (
                    <TableCell
                      key={Math.random().toString()}
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {Object.keys(cell)[0]}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableRow>
                {valueCells?.map((cell, index) => (
                  <TableCell
                    key={Math.random().toString()}
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(cell)}
                  </TableCell>
                ))}
              </TableRow>
            </Table>
          </TableContainer>
          <EmptyTable
            message="Нет отчетов"
            isActive={!isLoading && !firstRowCells}
          />
        </div>
      )}
    </div>
  )
}

export default Cashflow
