import { useState, useEffect, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import CountUp from "react-countup"
import axios from "../../utils/axios"
import TableComp from "../../components/TableComp/index"
import "./index.scss"
import moment from "moment"
import parseQuery from "../../functions/parseQuery"
import Pagination from "../../components/Pagination/Pagination"
import EmptyTable from "../../components/EmptyTable"
import Skeleton from "../../components/Skeleton/ReportSkeleton"
import PeopleIcon from "../../assets/icons/clients.svg"
import LoadingChart from "../../assets/icons/loading_chart.svg"
import CarIcon from "../../assets/icons/car.svg"
import Car2Icon from "../../assets/icons/car2.svg"
import Car3Icon from "../../assets/icons/car3.svg"
import WarningIcon from "../../assets/icons/warning.svg"
import DollarIcon from "../../assets/icons/dollar.svg"
import OrdersIcon from "../../assets/icons/cart.svg"
import Card from "../../components/Card/index"
import { useHistory } from "react-router-dom"
import { useSpring, animated } from "react-spring"
import RangePicker from "../../components/DatePicker/RangePicker"
import upIcon from "../../assets/icons/up.svg"
import downIcon from "../../assets/icons/down.svg"

export default function App() {
  const userType = localStorage.getItem("userType")
  const { t } = useTranslation()
  let { limit, offset } = parseQuery()
  const pageCount = 10
  const history = useHistory()

  const [tableData, setTableData] = useState([])
  const [tableData2, setTableData2] = useState([])
  const [carCount, setCarCount] = useState()
  const [clientCount, setClientCount] = useState()
  const [orderReport, setOrderReport] = useState()
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })
  const [count, setCount] = useState()
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  const [isLoadingTable2, setIsLoadingTable2] = useState(true)
  const [tabNum, setTabNum] = useState("1")
  const [customDate, setCustomDate] = useState()
  const [cashflow, setCashflow] = useState()
  const [status, setStatus] = useState({})

  const updateDate = useCallback((val) => {
    setFromToDate({
      start: moment(val[0]).format('yyyy-MM-DD') ?? "",
      end: moment(val[1]).format('yyyy-MM-DD') ?? "",
    })
  }, [])

  const query = useMemo(() => {
    if (tabNum) {
      let days = tabNum == 1 ? 0 : tabNum == 7 ? 6 : tabNum == 30 ? 29 : tabNum
      return `?from_time=${moment().subtract(days, "days").toISOString() ?? ""}`
    }
    return `?from_time=${
      moment(customDate.from_time).toISOString() ?? ""
    }&to_time=${moment(customDate.to_time).toISOString() ?? ""}`
  }, [tabNum, customDate])

  const updateCustomDate = useCallback((val) => {
    if (val[0] !== null) {
      setCustomDate({
        from_time: moment(val[0]).toISOString() ?? "",
        to_time: moment(val[1]).toISOString() ?? "",
      })
      setTabNum(undefined)
    }
  }, [])

  const getTableData = useCallback(
    (params = {}) => {
      axios
        .get("/car-expire-doc?&day=10", { params })
        .then((res) => {
          let arr = []
          res.data.cars.forEach((item, index) => {
            let docs = ""
            let expire = ""
            item.expire_docs.forEach((el, id) => {
              docs += `${id + 1}. ${
                el.name === "mileage"
                  ? "До замены масла"
                  : t(el.name.replace(" ", "."))
              }\n`
              expire += `${id + 1}. ${
                el.name === "mileage"
                  ? el.expire_date + " км"
                  : el.expire_date.substring(0, el.expire_date.length - 8)
              }\n`
            })
            let count = `${params.offset + index + 1}`
            arr.push({
              count,
              carNum: item.state_number,
              model: item.model.name,
              investor: item.investor.name,
              documents: docs,
              expire_date: expire,
            })
          })
          setTableData(arr)
          setCount(res.data.count)
          setIsLoadingTable(false)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    [t]
  )

  const getItems = async () => {
    let response = await axios.get(`/car-activity`, {
      params: {
        from_time: fromToDate.start !== "" ? fromToDate.start : "2022-04-01",
        to_time: fromToDate.end !== "" ? fromToDate.end : "2022-04-30",
      },
    })

    setStatus(response.data)
  }
  const getTableData2 = useCallback(() => {
    // res is an array
    axios
      .get(
        `report/car?car_status=in_stock&arrangement=desc&limit=10000&order=total_day_count&detalization=month`,
        {
          params: {
            start_date: fromToDate.start,
            end_date: fromToDate.end,
          },
        }
      )
      .then((res) => {
        let arr = []
        if (res) {
          res.forEach((item, index) =>
            arr.push({
              count: index + 1,
              model: item.model,
              carNum: item.state_number,
              dayCount: item.total_day_count,
              investor: item.investor_name,
            })
          )
          setTableData2(arr)
          setIsLoadingTable2(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [fromToDate.end, fromToDate.start])

  const getCarCount = useCallback((date) => {
    if (typeof date === "string") {
      let days
      date == 1
        ? (days = 0)
        : date == 7
        ? (days = 6)
        : date == 30
        ? (days = 29)
        : (days = date)
      axios
        .get(
          `/car-count?from_time=${
            moment().subtract(days, "days").toISOString() ?? ""
          }`
        )
        .then((res) => {
          setCarCount(res)
        })
    } else if (typeof date === "object") {
      axios
        .get(
          `/car-count?from_time=${
            moment(date.from_time).toISOString() ?? ""
          }&to_time=${moment(date.to_time).toISOString() ?? ""}`
        )
        .then((res) => {
          setCarCount(res)
        })
    }
  }, [])

  const getOrderReport = useCallback((date) => {
    if (typeof date === "string") {
      let days
      date == 1
        ? (days = 0)
        : date == 7
        ? (days = 6)
        : date == 30
        ? (days = 29)
        : (days = date)
      axios
        .get(
          `/order-report?from_time=${
            moment().subtract(days, "days").toISOString() ?? ""
          }`
        )
        .then((res) => setOrderReport(res))
    } else if (typeof date === "object") {
      axios
        .get(
          `/order-report?from_time=${
            moment(date.from_time).toISOString() ?? ""
          }&to_time=${moment(date.to_time).toISOString() ?? ""}`
        )
        .then((res) => setOrderReport(res))
    }
  }, [])

  const getCashflow = useCallback((date) => {
    if (typeof date === "string") {
      let days
      date == 1
        ? (days = 0)
        : date == 7
        ? (days = 6)
        : date == 30
        ? (days = 29)
        : (days = date)
      axios
        .get(
          `/report/dashboard?from_time=${
            moment().subtract(days, "days").toISOString() ?? ""
          }`
        )
        .then((res) => setCashflow(res))
    } else if (typeof date === "object") {
      axios
        .get(
          `/report/dashboard?from_time=${
            moment(date.from_time).toISOString() ?? ""
          }&to_time=${moment(date.to_time).toISOString() ?? ""}`
        )
        .then((res) => setCashflow(res))
    }
  }, [])

  const getAllData = useCallback(() => {
    axios.get("/client-count").then((res) => {
      setClientCount(res.count)
      getTableData({ limit: limit ?? pageCount, offset: offset ?? 0 })
      getTableData2()
      getOrderReport(tabNum)
      getCarCount(tabNum)
      getCashflow(tabNum)
    })
  }, [
    tabNum,
    getCarCount,
    getOrderReport,
    getTableData,
    getTableData2,
    getCashflow,
    limit,
    offset,
  ])

  useEffect(() => {
    getAllData()
    getItems()
  }, [getAllData])

  useEffect(() => {
    getTableData2()
  }, [fromToDate, getTableData2])

  useEffect(() => {
    getCarCount(customDate)
    getOrderReport(customDate)
    getCashflow(customDate)
  }, [customDate, getCarCount, getOrderReport, getCashflow])

  useEffect(() => {
    getCarCount(tabNum)
    getOrderReport(tabNum)
    getCashflow(tabNum)
  }, [tabNum, getCarCount, getOrderReport, getCashflow])

  const changeOffset = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getTableData({ limit: limit ?? pageCount, offset: offset ?? 0 })
    },
    [getTableData, limit]
  )

  const tabClickHandler = useCallback((e) => {
    if (e.target.id) {
      setTabNum(e.target.id)
      setCustomDate(undefined)
    }
  }, [])

  const cardClickHandler = useCallback(
    (e) => {
      let path = ""
      let node = e.target

      for (let i = 0; i < 10; i++) {
        if (node.id) {
          path = node.id
          break
        } else {
          node = node.parentElement
        }
      }

      if (node.id === "orders") {
        let days
        tabNum == 1
          ? (days = 0)
          : tabNum == 7
          ? (days = 6)
          : tabNum == 30
          ? (days = 29)
          : (days = tabNum)
        if (tabNum) {
          path += `?tab=1&limit=10&offset=0&from_dashboard=true&from_time=${
            moment().subtract(days, "days").toISOString() ?? ""
          }`
        } else if (customDate) {
          path += `?tab=1&limit=10&offset=0&from_dashboard=true&from_time=${customDate.from_time}&to_time=${customDate.to_time}`
        }
      } else if (node.id === "orders2") {
        path = path.slice(0, path.length - 1)
        path += `?tab=0&limit=10&offset=0` // query: tab or status, from_dashboard
      } else if (node.id === "orders1") {
        path = path.slice(0, path.length - 1)
        path += `?tab=1&order=to_time&arrangement=asc`
      } else if (node.id === "reports/debtors1") {
        path = path.slice(0, path.length - 1)
        path += "?sortby=unpaid-costs"
      } else if (node.id === "cars1") {
        path = path.slice(0, path.length - 1)
        path += "?status=in_use"
      } else if (node.id === "cars2") {
        path = path.slice(0, path.length - 1)
        path += "?status=repairing"
      } else if (node.id === "cars3") {
        path = path.slice(0, path.length - 1)
        path += "?status=in_stock"
      } else if (node.id === "cars4") {
        path = path.slice(0, path.length - 1)
        path += "?status=in_fine"
      }

      history.push(`/home/${path}`)
    },
    [history, tabNum, customDate]
  )

  const stats = useMemo(() => new Set([]), [])

  const styleWith = (id, fn) => {
    return fn({
      borderBottom: tabNum == id ? "2px solid #9333ea" : "2px solid #ffffff",
    })
  }

  const in_use_props = useSpring({
    width:
      stats.size === 1
        ? "25%"
        : carCount
        ? `${carCount.in_use_percentage}%`
        : "0%",
  })

  const in_fine_props = useSpring({
    width:
      stats.size === 1
        ? "25%"
        : carCount
        ? `${carCount.in_fine_percentage}%`
        : "0%",
  })

  const in_stock_props = useSpring({
    width:
      stats.size === 1
        ? "25%"
        : carCount
        ? `${carCount.in_stock_percentage}%`
        : "0%",
  })

  const on_repair_props = useSpring({
    width:
      stats.size === 1
        ? "25%"
        : carCount
        ? `${carCount.on_repair_percentage}%`
        : "0%",
  })

  useEffect(() => {
    carCount && stats.add(carCount.in_fine_percentage)
    carCount && stats.add(carCount.in_stock_percentage)
    carCount && stats.add(carCount.in_use_percentage)
    carCount && stats.add(carCount.on_repair_percentage)
  }, [carCount, stats])

  return (
    <div className="p-5 font-body space-y-4">
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4">
        {/* Left side of grid */}
        <Card title="">
          <div className="mb-6 flex" onClick={tabClickHandler}>
            <animated.span
              id="1"
              className={`py-2 mx-4 cursor-pointer`}
              style={styleWith(1, useSpring)}
            >
              {t("day")}
            </animated.span>
            <animated.span
              id="7"
              className={`py-2 mx-4 cursor-pointer`}
              style={styleWith(7, useSpring)}
            >
              {t("week")}
            </animated.span>
            <animated.span
              id="30"
              className={`${
                tabNum == 30
                  ? "border-b-2 border-solid border-purple-600"
                  : "border-b-2 border-solid border-white"
              } py-2 mx-4 cursor-pointer`}
              style={styleWith(30, useSpring)}
            >
              {t("month")}
            </animated.span>
            <RangePicker
              showToday={false}
              showWeekNumber
              dateInputPlaceholder={[t("from"), t("till")]}
              showOk={false}
              showClear
              placeholder={t("select.a.date")}
              hideTimePicker
              onChange={(val) => updateCustomDate(val)}
              style={{ minWidth: "17rem", marginLeft: "auto" }}
              outerClick={tabNum}
            />
          </div>

          {/* Total Cars */}
          <div
            className="w-4/4 bg-white flex items-center justify-between flex-wrap h-30 rounded-md p-4 mb-4 border cursor-pointer"
            onClick={cardClickHandler}
            id="cars"
          >
            <div className="flex items-center flex-wrap">
              <img
                src={CarIcon}
                alt=""
                width="50"
                height="50"
                style={{ marginRight: "2rem" }}
              />
              <div>{t("cars.total")}</div>
            </div>
            <div className="font-bold text-primary text-2xl w-auto">
              <CountUp
                start={0}
                end={carCount?.total_count}
                separator=" "
                duration={1}
                style={{ color: "#7E10E5" }}
                className="tabular-nums"
              />
            </div>
          </div>

          {/* Orders */}
          {orderReport && (
            <>
              <div
                className="w-4/4 bg-white flex items-center justify-between flex-wrap h-30 rounded-md p-4 mt-6 border cursor-pointer"
                onClick={cardClickHandler}
                id="orders"
              >
                <div className="flex items-center flex-wrap">
                  <img
                    src={OrdersIcon}
                    alt=""
                    width="50"
                    height="50"
                    style={{ marginRight: "2rem" }}
                  />
                  <div>{t("orders")}</div>
                </div>
                <div className="font-bold text-primary text-2xl w-auto">
                  <CountUp
                    start={0}
                    end={orderReport.total_order}
                    separator=" "
                    duration={1}
                    style={{ color: "#1AC19D" }}
                    className="tabular-nums"
                  />
                </div>
              </div>
            </>
          )}

          {/* Progress bar */}
          {carCount && (
            <>
              <div className="w-4/4 flex text-white mt-6">
                <animated.div
                  className="bg-green-500 text-center py-1 rounded-l-lg flex justify-center items-center h-8"
                  style={in_use_props}
                ></animated.div>

                <animated.div
                  className={`bg-yellow-500 text-center py-1 flex justify-center items-center h-8 ${
                    !carCount.in_stock_percentage &&
                    !carCount.in_fine_percentage
                      ? "rounded-r-lg"
                      : ""
                  } ${!carCount.in_use_percentage ? "rounded-l-lg" : ""}`}
                  style={on_repair_props}
                ></animated.div>

                <animated.div
                  className={`bg-orange-500 text-center py-1 flex justify-center items-center h-8 ${
                    !carCount.in_fine_percentage ? "rounded-r-lg" : ""
                  } ${
                    !carCount.on_repair_percentage &&
                    !carCount.in_use_percentage
                      ? "rounded-l-lg"
                      : ""
                  }`}
                  style={in_stock_props}
                ></animated.div>

                <animated.div
                  className="bg-red-500 text-center py-1 rounded-r-lg flex justify-center items-center h-8"
                  style={in_fine_props}
                ></animated.div>
              </div>

              {/* Stats */}
              <div className="w-4/4 flex flex-wrap justify-between">
                <div className="w-6/12 mt-2 px-2">
                  <div
                    className="flex justify-between cursor-pointer hover:text-purple-600 transition-all my-4"
                    onClick={cardClickHandler}
                    id="cars1"
                  >
                    <div className="flex">
                      <div
                        className="bg-green-500 w-6 rounded-full"
                        style={{ marginRight: "1rem" }}
                      >
                        {" "}
                      </div>
                      {t("cars.active")}
                    </div>
                    <div>
                      <CountUp
                        start={0}
                        separator=" "
                        end={carCount.in_use}
                        duration={1}
                        className="tabular-nums"
                      />{" "}
                      <span className="text-sm text-gray-400">
                        (
                        <CountUp
                          start={0}
                          separator=" "
                          end={carCount.in_use_percentage}
                          duration={1}
                          className="tabular-nums"
                        />
                        )%
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex justify-between cursor-pointer hover:text-purple-600 transition-all"
                    onClick={cardClickHandler}
                    id="cars2"
                  >
                    <div className="flex">
                      <div
                        className="bg-yellow-500 w-6 rounded-full"
                        style={{ marginRight: "1rem" }}
                      >
                        {" "}
                      </div>
                      {t("cars.repair")}
                    </div>
                    <div>
                      <CountUp
                        start={0}
                        separator=" "
                        end={carCount.on_repair}
                        duration={1}
                        className="tabular-nums"
                      />{" "}
                      <span className="text-sm text-gray-400">
                        (
                        <CountUp
                          start={0}
                          separator=" "
                          end={carCount.on_repair_percentage}
                          duration={1}
                          className="tabular-nums"
                        />
                        )%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-6/12 my-2 px-2">
                  <div
                    className="flex justify-between cursor-pointer hover:text-purple-600 transition-all my-4"
                    onClick={cardClickHandler}
                    id="cars3"
                  >
                    <div className="flex">
                      <div
                        className="bg-orange-500 w-6 rounded-full"
                        style={{ marginRight: "1rem" }}
                      >
                        {" "}
                      </div>
                      {t("cars.parking")}
                    </div>
                    <div>
                      <CountUp
                        start={0}
                        separator=" "
                        end={carCount.in_stock}
                        duration={1}
                        className="tabular-nums"
                      />{" "}
                      <span className="text-sm text-gray-400">
                        (
                        <CountUp
                          start={0}
                          separator=" "
                          end={carCount.in_stock_percentage}
                          duration={1}
                          className="tabular-nums"
                        />
                        )%
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex justify-between cursor-pointer hover:text-purple-600 transition-all"
                    onClick={cardClickHandler}
                    id="cars4"
                  >
                    <div className="flex">
                      <div
                        className="bg-red-500 w-6 rounded-full"
                        style={{ marginRight: "1rem" }}
                      >
                        {" "}
                      </div>
                      {t("cars.fined")}
                    </div>
                    <div>
                      <CountUp
                        start={0}
                        separator=" "
                        end={carCount.in_fine}
                        duration={1}
                        className="tabular-nums"
                      />{" "}
                      <span className="text-sm text-gray-400">
                        (
                        <CountUp
                          start={0}
                          separator=" "
                          end={carCount.in_fine_percentage}
                          duration={1}
                          className="tabular-nums"
                        />
                        )%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <hr className="mt-4" />

          {/* P&L */}
          {cashflow && (
            <div className="flex content-between items-center">
              <div
                className={`w-2/4 bg-white flex items-center justify-between flex-wrap h-18 rounded-md p-2 mt-6 border mr-4 ${
                  userType != "INVESTOR" ? "cursor-pointer" : ""
                }`}
                id="orders"
                onClick={() => {
                  if (userType != "INVESTOR") {
                    history.push(`/home/dashboard/cashflow${query}`)
                  }
                }}
              >
                <div className="flex items-center flex-wrap">
                  <img
                    src={upIcon}
                    alt=""
                    width="50"
                    height="50"
                    style={{ marginRight: "2rem" }}
                  />
                  <div>{t("income")}</div>
                </div>
                <div className="font-bold text-primary text-2xl w-auto">
                  <CountUp
                    start={0}
                    end={cashflow.in_come}
                    separator=","
                    duration={1}
                    className="tabular-nums"
                  />{" "}
                  {t("somm")}
                </div>
              </div>

              <div
                className={`w-2/4 bg-white flex items-center justify-between flex-wrap h-18 rounded-md p-2 mt-6 border ${
                  userType != "INVESTOR" ? "cursor-pointer" : ""
                }`}
                id="orders"
                onClick={() => {
                  if (userType != "INVESTOR") {
                    history.push(`/home/dashboard/cashflow${query}`)
                  }
                }}
              >
                <div className="flex items-center flex-wrap">
                  <img
                    src={downIcon}
                    alt=""
                    width="50"
                    height="50"
                    style={{ marginRight: "2rem" }}
                  />
                  <div>{t("expense")}</div>
                </div>
                <div className="font-bold text-primary text-2xl w-auto">
                  <CountUp
                    start={0}
                    end={cashflow.out_come}
                    separator=","
                    duration={1}
                    className="tabular-nums"
                  />{" "}
                  {t("somm")}
                </div>
              </div>
            </div>
          )}
        </Card>

        {/* Right side of grid */}
        {orderReport && clientCount && userType !== "INVESTOR" && (
          <>
            <div className="flex items-stretch flex-wrap gap-x-4">
              <div
                className="w-1/4 bg-white flex flex-wrap rounded-md px-4 py-3 shadow-lg cursor-pointer"
                onClick={cardClickHandler}
                id="reports/debtors"
              >
                <div className="flex flex-wrap py-2">
                  <img src={DollarIcon} alt="" width="75" height="75" />
                  <div className="py-2 h-16">{t("debtors.number")}</div>
                </div>
                <div className="font-bold text-primary text-3xl w-auto self-end">
                  <CountUp
                    start={0}
                    end={orderReport.debtors}
                    separator=" "
                    duration={1}
                    style={{ color: "#22C348" }}
                    className="tabular-nums"
                  />
                </div>
              </div>

              <div
                className="w-1/4 bg-white flex flex-wrap rounded-md px-4 py-3 shadow-lg cursor-pointer"
                onClick={cardClickHandler}
                id="reports/debtors1"
              >
                <div className="flex flex-wrap py-2">
                  <img src={WarningIcon} alt="" width="75" height="75" />
                  <div className="py-2 h-16">{t("unpaid.fines")}</div>
                </div>
                <div className="font-bold text-primary text-3xl w-auto self-end">
                  <CountUp
                    start={0}
                    end={orderReport.unpaid_costs}
                    separator=" "
                    duration={1}
                    style={{ color: "#F76659" }}
                    className="tabular-nums"
                  />
                </div>
              </div>

              <div
                className="w-1/4 bg-white flex flex-wrap rounded-md px-4 py-3 shadow-lg cursor-pointer"
                onClick={cardClickHandler}
                id="orders1"
              >
                <div className="flex flex-wrap py-2">
                  <img src={Car3Icon} alt="" width="75" height="75" />
                  <div className="py-2 h-16">{t("cars.expected.today")}</div>
                </div>
                <div className="font-bold text-primary text-3xl w-auto self-end">
                  <CountUp
                    start={0}
                    end={orderReport.expected_cars}
                    separator=" "
                    duration={1}
                    style={{ color: "#7e10e5" }}
                    className="tabular-nums"
                  />
                </div>
              </div>

              <div
                className="w-1/4 bg-white flex flex-wrap rounded-md px-4 py-3 mt-6 shadow-lg cursor-pointer"
                onClick={cardClickHandler}
                id="orders2"
              >
                <div className="flex flex-wrap py-2">
                  <img
                    src={Car2Icon}
                    alt=""
                    width="75"
                    height="75"
                    className="mr-6"
                  />
                  <div className="py-2 h-16">{t("booked")}</div>
                </div>
                <div className="font-bold text-primary text-3xl w-auto self-end">
                  <CountUp
                    end={orderReport.new_orders}
                    duration={1}
                    style={{ color: "#0e73f6" }}
                    className="tabular-nums"
                  />
                </div>
              </div>

              <div
                className="w-1/4 bg-white flex flex-wrap rounded-md px-4 py-3 mt-6 shadow-lg cursor-pointer"
                onClick={cardClickHandler}
                id="clients"
              >
                <div className="flex flex-wrap py-2">
                  <img
                    src={PeopleIcon}
                    alt=""
                    width="75"
                    height="75"
                    className="mr-20"
                  />
                  <div className="py-2 h-16">{t("clients")}</div>
                </div>
                <div className="font-bold text-primary text-3xl w-auto self-end">
                  <CountUp
                    start={0}
                    separator=" "
                    end={clientCount}
                    duration={1}
                    style={{ color: "#f8c51b" }}
                    className="tabular-nums"
                  />
                </div>
              </div>
              <div
                className="w-1/4 bg-white flex flex-wrap rounded-md px-4 py-3 mt-6 shadow-lg cursor-pointer"
                onClick={cardClickHandler}
                id="reports/loading-chart"
              >
                <div className="flex flex-wrap py-2">
                  <img
                    src={LoadingChart}
                    alt=""
                    width="75"
                    height="75"
                    className="mr-20"
                  />
                  <div className="py-2 h-16">{t("loading_chart")}</div>
                </div>
                <div className="font-bold text-primary text-3xl w-auto self-end">
                  <CountUp
                    start={0}
                    separator=" "
                    end={status.count}
                    duration={1}
                    style={{ color: "#f8c51b" }}
                    className="tabular-nums"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="grid grid-rows-2 grid-cols-3 grid-flow-col gap-4"></div>

      <TableComp
        title="Заканчивающиеся документы"
        headings={[
          "№",
          "Номер автомобиля",
          "Модель",
          "Инвестор",
          "Документы",
          "Срок действия",
        ]}
        sourceData={tableData}
      />
      <EmptyTable
        message="Нет инфо"
        isActive={!isLoadingTable && !tableData.length > 0}
        style={{ marginTop: "0" }}
      />

      {isLoadingTable && <Skeleton />}
      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(count / pageCount)}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changeOffset}
        />
      </div>
      <div className="grid grid-rows-2 grid-cols-3 grid-flow-col gap-4"></div>

      <TableComp
        title="Низкоходные машины"
        headings={[
          "№",
          "Модель",
          "Номер автомобиля",
          "Кол-во дней",
          "Инвестор",
        ]}
        sourceData={tableData2}
        hasRangePicker={true}
        updateDate={updateDate}
      />
      <EmptyTable
        message="Нет инфо"
        isActive={!isLoadingTable2 && !tableData2.length > 0}
        style={{ marginTop: "0" }}
      />

      {isLoadingTable2 && <Skeleton />}
      <div></div>
    </div>
  )
}
