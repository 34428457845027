import { useState, useEffect, useMemo, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useTheme } from "@material-ui/core/styles"
import { Formik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import SwipeableViews from "react-swipeable-views"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import { StyledTabs, StyledTab } from "../../components/StyledTabs"
import Context from "./Context"
import validateForm from "../../functions/validateForm"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import Filters from "../../components/Filters"
import { TabPanel } from "../../components/Tab/TabBody"
import GiveCar from "./tabs/GiveCar"
import ReceiveCar from "./tabs/ReceiveCar"
import TransactionHistory from "./tabs/TransactionHistory"
import Fines from "./tabs/Fines/"
import findDayDifference from "../../functions/findDayDifference"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import Alert from "../../components/Alert"
import downloadFile from "../../functions/downloadFile"
import parseQuery from "../../functions/parseQuery"
import Button from "../../components/Buttons"
import { addIcon } from "../../assets/icons/icons"
import { useDispatch } from "react-redux"
import { showAlert } from "../../redux/reducers/alertReducer"

function OrdersCreate() {
  let { tab, limit, offset, from_deposits, fines } = parseQuery()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const theme = useTheme()

  const userId = localStorage.getItem("userId")
  const userType = localStorage.getItem("userType")

  const checkboxRef = useRef()

  const [actualProlongDay, setActualProlongDay] = useState()
  const [value, setValue] = useState(
    from_deposits === "true" ? 1 : fines === "true" ? 4 : 0
  )
  const [loading, setLoading] = useState(false)
  const [isGettingAdditionalContract, setIsGettingAdditionalContract] =
    useState(false)
  const [isGettingContract, setIsGettingContract] = useState(false)
  const [isGettingAuthority, setIsGettingAuthority] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [tariffs, setTariffs] = useState([{ label: "Нет тарифов", value: "" }])
  const [tariffId, setTariffId] = useState("")
  const [additionalTariffs, setAdditionalTariffs] = useState([])
  const [cars, setCars] = useState([])
  const [client, setClient] = useState()
  const [clientOrders, setClientOrders] = useState(undefined)
  const [clients, setClients] = useState([])
  const [items, setItems] = useState({})
  const [clientData, setClientData] = useState({})
  const [initiatorData, setInitiatorData] = useState({})
  const [statuses, setStatuses] = useState([])
  const [listData, setListData] = useState({
    additional_tariffs: [],
  })

  const [initialValues, setInitialValues] = useState({
    additional_tariffs: [],

    car: undefined,
    client: undefined,
    tariff: undefined,
    sales_funnel: undefined,
    insurance: true,

    total_price: 0,

    status: { label: "Car selected", value: "car_selected" },
    source: "operator",
    from_time: null,
    to_time: null,
    date: [],

    start_date: "",
    day_count: undefined,
    discount: undefined,
    actual_prolong_day: undefined,
    estimated_prolong_day: undefined,
    branch: "",
  })
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [mapCars, setMapCars] = useState()
  const [updateTariff, setUpdateTariff] = useState()
  const [salesFunnelOptions, setSalesFunnelOptions] = useState([])
  const [insurance, setInsurance] = useState(true)
  const [branches, setBranches] = useState([])

  const cStatuses = useMemo(
    () => ["new", "client_took", "client_returned", "completed", "cancelled"],
    []
  )

  const map = useMemo(() => new Map(), [])

  const tariffCarMap = useMemo(() => {
    if (mapCars) {
      mapCars.forEach(({ id, state_number, tariff }) =>
        map.set(state_number, { value: tariff.id, label: tariff.name })
      )
      return map
    }
  }, [mapCars, map])

  useEffect(() => {
    if (tariffId && tariffCarMap) {
      axios.get(`/tariff/${tariffId}`).then((res) => {
        setUpdateTariff({
          label: res.name,
          value: res.id,
          price: res.price,
          insurance_price: res.insurance_price,
        })
      })
    }
  }, [tariffId, tariffCarMap, map])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/order/${id}`)
      .then((res) => {
        let totalInsurancePrice = 0
        let totalDiscountPrice = 0

        res.cards?.forEach((card) => {
          if (card.insurance)
            totalInsurancePrice +=
              card.tariff?.insurance_price * card.self_day_count
          totalDiscountPrice += card.discount * card.self_day_count
        })

        let data = {
          ...res,
          tariff: {
            label: res.tariff.name,
            value: res.tariff.id,
            price: +res.tariff.price,
            insurance_price: +res.tariff?.insurance_price,
          },
          totalInsurancePrice,
          totalDiscountPrice,
          status: { label: t(res.status.id), value: res.status.id },
          car: { label: res.car.state_number, value: res.car.id },
          client: {
            label: formatPhoneNumber(res.client.phone_number),
            value: res.client.id,
          },
          sales_funnel: {
            label: res.cause.name,
            value: res.cause.id,
          },
          branch: {
            label: res.branch.name,
            value: res.branch.id,
          },
        }

        getCars(res.tariff.id)
        getClientInfo(res.client.id)
        getClientOrders(res.client.id)
        setInitiatorData(res.initiator)
        setActualProlongDay(data.actual_prolong_day)
        setInitialValues(data)
        setClientData(res.client)
        setListData((old) => {
          return {
            ...old,
            additional_tariffs: res.additional_tariffs?.map(
              ({ id, name, price }) => ({
                label: name,
                value: id,
                price: +price,
              })
            ),
          }
        })
        setInsurance(res.insurance)
      })
      .finally(() => setIsGettingData(false))
  }

  const getItems = async (params = {}) => {
    let response = await axios.get(`/order`, {
      params,
    })

    setItems({
      data: response.data,
    })
  }

  const getTariffs = async () => {
    let res = await axios.get(`/tariff`)

    setTariffs((prev) => [
      ...prev,
      ...res.data.tariffs?.map(({ id, name, price, insurance_price }) => ({
        label: name,
        value: id,
        price,
        insurance_price,
      })),
    ])
  }

  const getClientInfo = (clientId) => {
    axios.get(`/client/${clientId}`).then((res) => setClient(res))
  }

  const getClientOrders = (clientId) => {
    axios.get(`/order?client_id=${clientId}`).then((res) => {
      setClientOrders(res.data.count ? res.data.orders : undefined)
    })
  }

  const getAdditionalTariffs = async () => {
    let res = await axios.get(`/additional-tariff?limit=10&offset=0`)

    setAdditionalTariffs(
      res.data.additional_tariffs?.map(({ id, name, price }) => ({
        label: name,
        value: id,
        price: price,
      }))
    )
  }

  const getCars = async (tariffId) => {
    if (tariffId) {
      let res = await axios.get(
        `/car-by-tariff?status=in_stock&tariff_id=${tariffId}`
      )

      setCars(
        res.cars?.map(({ id, state_number }) => ({
          label: state_number,
          value: id,
        }))
      )
    } else {
      let res = await axios.get(`/car?status=in_stock`)

      setCars(
        res.data.cars?.map(({ id, state_number }) => ({
          label: state_number,
          value: id,
        }))
      )
      setMapCars(res.data?.cars)
    }
  }

  const getClients = async () => {
    let res = await axios.get(`/client?limit=15&offset=0`)
    setClients(
      res.data.clients?.map(({ id, phone_number, is_blocked }) => ({
        label: formatPhoneNumber(phone_number),
        value: id,
        blocked: is_blocked === 1 ? 1 : 0,
      }))
    )
  }

  const getStatuses = async () => {
    let res = await axios.get(`/status?limit=10&offset=0`)

    setStatuses(
      res.data.statuses?.map(({ id, name }) => ({
        label: t(id),
        value: id,
      }))
    )
  }

  const getSalesFunnelOptions = async () => {
    let res = await axios.get(`/cause?limit=15&offset=0`)

    setSalesFunnelOptions(
      res.data.causes?.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    )
  }

  async function getBranches() {
    let response = await axios.get("/branch?limit=100&offset=0")

    setBranches(
      response.data.branches?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const generateAdditionalContract = (orderId) => {
    setIsGettingAdditionalContract(true)
    axios.get(`/additional-contract/${orderId}`).then((res) => {
      downloadFile(res.file_url, `Additional_Contract_${Date.now()}.docx`)
      setIsGettingAdditionalContract(false)
    })
  }

  const generateContract = (orderId) => {
    setIsGettingContract(true)
    axios.get(`/contract/${orderId}`).then((res) => {
      downloadFile(res.file_url, `Contract_${Date.now()}.docx`)
      setIsGettingContract(false)
    })
  }

  const generateAuthority = (orderId) => {
    setIsGettingAuthority(true)
    axios.get(`/authority/${orderId}`).then((res) => {
      downloadFile(res.file_url, `Authority_${Date.now()}.docx`)
      setIsGettingAuthority(false)
    })
  }

  const loadClientOptions = (inputValue, callback) => {
    let res = axios.get(`/client?limit=5&offset=0&search=${inputValue}`)
    callback(
      res.data?.clients?.map(({ id, phone_number, is_blocked }) => {
        return {
          value: id,
          label: phone_number,
          blocked: is_blocked,
        }
      })
    )
  }

  const loadCarOptions = async (inputValue, callback) => {
    if (tariffId) {
      let res = await axios.get(
        `/car-by-tariff?limit=5&offset=0&tariff_id=${tariffId}&search=${inputValue}&status=in_stock`
      )
      callback(
        res.cars?.map(({ id, state_number }) => {
          return {
            value: id,
            label: state_number,
          }
        })
      )
    } else {
      let res = await axios.get(
        `/car?limit=5&offset=0&search=${inputValue}&status=in_stock`
      )
      callback(
        res.data.cars?.map(({ id, state_number }) => {
          return {
            value: id,
            label: state_number,
          }
        })
      )
      setMapCars(res.data?.cars)
    }
  }

  const loadTariffOptions = async (inputValue, callback) => {
    let res = await axios.get(`/tariff?limit=5&offset=0&search=${inputValue}`)
    callback(
      res.data.tariffs?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        }
      })
    )
  }

  const loadAdditionalTariffOptions = async (inputValue, callback) => {
    let res = await axios.get(
      `/additional-tariff?limit=5&offset=0&search=${inputValue}`
    )
    callback(
      res.data.additional_tariffs?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    )
  }

  const loadSalesFunnelOptions = async (inputValue, callback) => {
    let res = await axios.get(`/cause?limit=5&offset=0&search=${inputValue}`)
    callback(
      res.data.causes?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        }
      })
    )
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    setBtnDisabled(true)

    if (client.is_blocked) {
      dispatch(showAlert(t("client.in.blacklist"), "error"))
      setLoading(false)
      setBtnDisabled(false)
      return
    }

    if (!values.tariff.value) {
      dispatch(showAlert(t("tariff.is.not.chosen"), "error"))
      setLoading(false)
      setBtnDisabled(false)
      return
    }

    if (params.id) {
      axios
        .put(`/order/${params.id}`, {
          ...values,
          additional_tariffs: listData.additional_tariffs?.map(
            ({ value }) => value
          ),
          tariff_id: values.tariff.value,
          car_id: values.car && values.car.value,
          status_id: values.status.value,
          client_id: values.client.value,
          branch_id: values.branch.value,
          start_date: moment(values.start_date).toISOString(),
          total_price:
            (listData.additional_tariffs?.reduce(
              (total, addTariff) => total + addTariff.price,
              0
            ) || 0) +
              values.tariff?.price *
                Math.abs(findDayDifference(values?.to_time, values?.from_time)) || 0,
          discount: values.discount === "" ? 0 : values.discount,
          estimated_prolong_day:
            values.estimated_prolong_day === ""
              ? 0
              : values.estimated_prolong_day,
          actual_prolong_day:
            values.actual_prolong_day === "" ? 0 : values.actual_prolong_day,
          cause_id: values.sales_funnel?.value || "",
          insurance: values?.insurance ? 1 : 0,
        })
        .then((res) => {
          history.goBack()
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    } else {
      axios
        .post("/order", {
          ...values,
          additional_tariffs: listData.additional_tariffs.map(
            ({ value }) => value
          ),
          tariff_id: values.tariff.value,
          car_id: values.car && values.car.value,
          client_id: values.client.value,
          branch_id: values.branch.value,
          status_id: values.status.value,
          initiator_id: userId,
          initiator_type: userType?.toLowerCase(),
          start_date: moment(values.start_date).toISOString(),
          total_price:
            (listData.additional_tariffs?.reduce(
              (total, addTariff) => total + addTariff.price,
              0
            ) || 0) +
              values.tariff?.price *
                findDayDifference(values?.to_time, values?.from_time) || 0,
          discount: values.discount === "" ? 0 : values.discount,
          estimated_prolong_day:
            values.estimated_prolong_day === ""
              ? 0
              : values.estimated_prolong_day,
          actual_prolong_day:
            values.actual_prolong_day === "" ? 0 : values.actual_prolong_day,
          cause_id: values.sales_funnel?.value || "",
          insurance: values?.insurance ? 1 : 0,
        })
        .then((res) => {
          history.push("/home/orders?tab=0")
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    }
  }

  const updateAdditionalTariffs = (val) => {
    setListData((old) => {
      return {
        ...old,
        additional_tariffs: val,
      }
    })
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    client: validateForm("mixed", t),
    tariff: validateForm("mixed", t),
    start_date: validateForm("mixed", t),
    day_count: validateForm("number", t),
    discount: validateForm("numberOptional", t),
    actual_prolong_day: validateForm("numberOptional", t),
    estimated_prolong_day: validateForm("numberOptional", t),
    branch: validateForm("mixed", t),
  })

  const routes = [
    {
      title: t("orders"),
      link: true,
      route: "/home/orders?tab=0",
    },

    {
      title: params.id ? initialValues.order_number : t("create"),
    },
  ]

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => setValue(newValue)

  const handleChangeIndex = (index) => setValue(index)

  const tabLabel = (text, isActive = false) => (
    <span className="px-1">{text}</span>
  )

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }

    // get all the data
    getTariffs()
    getAdditionalTariffs()
    getClients()
    getStatuses()
    getSalesFunnelOptions()
    getBranches()
  }, [])

  useEffect(() => {
    getItems({
      limit,
      offset: offset ?? 0,
      tab: tab ?? 0,
      status: cStatuses[tab ?? 0],
    })
    getCars()
  }, [cStatuses, limit, offset, tab])

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("order")}
                startAdornment={[
                  <Breadcrumb backButtonVisible routes={routes} />,
                ]}
                endAdornment={
                  value === 0
                    ? [
                        <CustomButton
                          shape="text"
                          color="text-error-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={cancelIcon}
                          onClick={() => history.goBack()}
                        >
                          {t("cancel")}
                        </CustomButton>,
                        <CustomButton
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                          disabled={btnDisabled}
                        >
                          {params.id ? t("save") : t("create")}
                        </CustomButton>,
                      ]
                    : value === 4
                    ? [
                        <Button
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          shape="text"
                          color="text-secondary"
                          icon={addIcon}
                          onClick={() =>
                            history.push(
                              `/home/orders/${params.id}/fines/create`
                            )
                          }
                        >
                          {t("create")}
                        </Button>,
                      ]
                    : null
                }
              />
              <Filters
                extra={
                  params.id &&
                  formik?.values?.car?.value && (
                    <div className="flex space-x-2">
                      {initialValues.status.value !== "new" &&
                        initialValues.actual_prolong_day > 0 && (
                          <CustomButton
                            className="border-2 border-primary-600"
                            color="text-primary-600"
                            loading={isGettingAdditionalContract}
                            textStyle={{
                              fontWeight: "500",
                              padding: "0.15rem 0",
                            }}
                            onClick={() =>
                              generateAdditionalContract(params.id)
                            }
                          >
                            {t("generate.additional-contract")}
                          </CustomButton>
                        )}
                      <CustomButton
                        className="border-2 border-primary-600"
                        color="text-primary-600"
                        loading={isGettingContract}
                        textStyle={{ fontWeight: "500", padding: "0.15rem 0" }}
                        onClick={() => generateContract(params.id)}
                      >
                        {t("generate.contract")}
                      </CustomButton>
                      <CustomButton
                        className="border-2 border-primary-600"
                        color="text-primary-600"
                        loading={isGettingAuthority}
                        textStyle={{ fontWeight: "500", padding: "0.15rem 0" }}
                        onClick={() => generateAuthority(params.id)}
                      >
                        {t("generate.authority")}
                      </CustomButton>
                    </div>
                  )
                }
              >
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{ children: <span className="w-2" /> }}
                >
                  <StyledTab
                    label={tabLabel(t("order.information"))}
                    {...a11yProps(0)}
                    style={{ width: "200px" }}
                  />
                  {params.id && (
                    <StyledTab
                      label={tabLabel(t("payment.history"))}
                      {...a11yProps(1)}
                    />
                  )}
                  {params.id &&
                    initialValues.status.value !== "new" &&
                    initialValues.status.value !== "car_selected" && (
                      <StyledTab
                        label={tabLabel(t("giving"))}
                        {...a11yProps(2)}
                      />
                    )}
                  {params.id &&
                    initialValues.status.value !== "new" &&
                    initialValues.status.value !== "car_selected" && (
                      <StyledTab
                        label={tabLabel(t("receiving"))}
                        {...a11yProps(3)}
                      />
                    )}
                  {params.id &&
                    initialValues.status.value === "client_returned" && (
                      <StyledTab
                        label={tabLabel(t("fines"))}
                        {...a11yProps(1)}
                      />
                    )}
                </StyledTabs>
              </Filters>
              <Alert
                style={{
                  top: "2px",
                  left: "45%",
                  marginLeft: "-100px",
                }}
              />
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Context
                    formik={formik}
                    tariffs={tariffs}
                    additionalTariffs={additionalTariffs}
                    cars={cars}
                    client={client}
                    clientOrders={clientOrders}
                    clients={clients}
                    items={items}
                    statuses={statuses}
                    listData={listData}
                    loadTariffOptions={loadTariffOptions}
                    loadAdditionalTariffOptions={loadAdditionalTariffOptions}
                    loadCarOptions={loadCarOptions}
                    initiatorData={initiatorData}
                    loadClientOptions={loadClientOptions}
                    updateAdditionalTariffs={updateAdditionalTariffs}
                    getCars={getCars}
                    getClientInfo={getClientInfo}
                    getClientOrders={getClientOrders}
                    setTariffId={setTariffId}
                    actualProlongDay={actualProlongDay}
                    tariffCarMap={tariffCarMap}
                    updateTariff={updateTariff}
                    loadSalesFunnelOptions={loadSalesFunnelOptions}
                    salesFunnelOptions={salesFunnelOptions}
                    checkboxRef={checkboxRef}
                    formInitialValues={initialValues}
                    setFormInitialValues={setInitialValues}
                    insurance={insurance}
                    setInsurance={setInsurance}
                    branches={branches}
                  />
                </TabPanel>
                {params.id && (
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <TransactionHistory client={clientData} />
                  </TabPanel>
                )}
                {params.id && (
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <GiveCar client={clientData} />
                  </TabPanel>
                )}
                {params.id && (
                  <TabPanel value={value} index={3} dir={theme.direction}>
                    <ReceiveCar client={clientData} />
                  </TabPanel>
                )}
                {params.id && (
                  <TabPanel value={value} index={4} dir={theme.direction}>
                    <Fines client={clientData} />
                  </TabPanel>
                )}
              </SwipeableViews>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default OrdersCreate
