import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import EditIcon from "@material-ui/icons/Edit"
import ClearIcon from "@material-ui/icons/Clear"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import moment from "moment"
import axios from "../../utils/axios"
import Button from "../../components/Buttons"
import Modal from "../../components/Modal"
import { StyledTab, StyledTabs } from "../../components/StyledTabs"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import differenceDate from "../../functions/differenceDate"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import Tag from "../../components/Tag"
import { StyledTableCell } from "../../components/StyledTableCell"
import { StyledPropMenu } from "../../components/StyledPropMenu"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import PriceInput from "../../components/Form/input/PriceInput"
import uzcardIcon from "../../assets/icons/uzcard.png"
import cashIcon from "../../assets/icons/cash.png"
import humoIcon from "../../assets/icons/humo.png"
import visaIcon from "../../assets/icons/visa.png"
import clickIcon from "../../assets/icons/click.png"
import paymeIcon from "../../assets/icons/payme.png"
import { showAlert } from "../../redux/reducers/alertReducer"
import EmptyTable from "../../components/EmptyTable"
import parseQuery from "../../functions/parseQuery"
import Skeleton from "../../components/Skeleton/ReportSkeleton"
import Textarea from "../../components/Textarea"

const paymentTypes = [
  {
    type: "cash",
    value: "cash",
    amount: 0,
    icon: cashIcon,
  },
  {
    type: "UzCard",
    value: "uzcard",
    amount: 0,
    icon: uzcardIcon,
  },
  {
    type: "Humo",
    value: "humo",
    amount: 0,
    icon: humoIcon,
  },
  {
    type: "Visa",
    value: "visa",
    amount: 0,
    icon: visaIcon,
  },
  {
    type: "Click",
    value: "click",
    amount: 0,
    icon: clickIcon,
  },
  {
    type: "Payme",
    value: "payme",
    amount: 0,
    icon: paymeIcon,
  },
]

const listRender = [
  {
    title: "edit",
    icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
    type: "edit",
  },
  {
    title: "delete",
    icon: <ClearIcon className="bg-error-100 rounded text-error-600 p-0.5" />,
    type: "delete",
  },
  {
    title: "cancel",
    icon: (
      <ErrorOutlineIcon className="bg-error-100 rounded text-error-600 p-0.5" />
    ),
    type: "cancel",
  },
]

function TableContext(props) {
  const {
    statuses,
    value,
    itemCounts,
    permissions,
    offset,
    items,
    pageCount,
    getItems,
    getItemCounts,
    handleChange,
    a11yProps,
    tabLabel,
    handleModal,
    setShowModal,
    changePaginationLocationQuery,
    showModal,
    isLoading,
    from_dashboard,
    order,
    from_time,
    to_time,
    arrangement,
    cStatuses,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  let { tab, limit } = parseQuery()

  const userType = localStorage.getItem("userType")

  const [comment, setComment] = useState("")
  const [btnDisabled, setBtnDisabled] = useState(false)
  const clientId = useSelector((state) => state.auth.clientId)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [depositAmount, setDepositAmount] = useState(0)
  const [dropList, setDropList] = useState({ target: null, id: "" })
  const [deposits, setDeposits] = useState()
  const [returnedMoney, setReturnedMoney] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  })

  useEffect(() => {
    setPaymentMethods(paymentTypes)
    addSelectedPayment(
      {
        type: "cash",
        value: "cash",
        amount: undefined,
        icon: cashIcon,
      },
      0
    )
  }, [])

  const getDeposits = useCallback(async (orderId) => {
    let response = await axios.get(
      `/payment-history?limit=10&offset=0&order_id=${orderId}&subcategory_id=993224db-d25f-43cb-bfea-a8ee842f34b8`
    )
    let anotherResponse = await axios.get(
      `/payment-history?limit=10&offset=0&order_id=${orderId}&subcategory_id=85e12a9d-5dc4-4c20-8d4d-a6015a19ed81`
    )

    let otherResponse = await axios.get(
      `/payment-history?limit=10&offset=0&order_id=${orderId}&subcategory_id=2b9064ab-09b8-414d-adb3-a8ee1a3b7863`
    )

    let deposit = await response.data?.histories?.reduce(
      (prev, cur) => prev + +cur.amount,
      0
    )

    let anotherDeposit = await anotherResponse.data?.histories?.reduce(
      (prev, cur) => prev + +cur.amount,
      0
    )

    let otherDeposit = await otherResponse.data?.histories?.reduce(
      (prev, cur) => prev + +cur.amount,
      0
    )

    setDeposits((prev) => {
      return {
        ...prev,
        [orderId]:
          deposit - anotherDeposit - otherDeposit
            ? deposit - anotherDeposit - otherDeposit
            : 0,
      }
    })
  }, [])

  useEffect(() => {
    if (items.data && items.data?.orders?.length) {
      items.data.orders?.forEach((order) => {
        getDeposits(order.id)
      })
    }
  }, [getDeposits, items.data])

  const getDepositAmount = async (orderId) => {
    let response = await axios.get(`/deposit?type=debit&order_id=${orderId}`)
    let anotherResponse = await axios.get(
      `/deposit?type=credit&order_id=${orderId}`
    )
    let deposit = await response.reduce((prev, cur) => prev + +cur, 0)

    let anotherDeposit = await anotherResponse.reduce(
      (prev, cur) => prev + +cur,
      0
    )
    setDepositAmount(deposit - anotherDeposit)
    // updatePayment(deposit?.toString(), 0)
  }

  // **** EVENTS *****
  const onModalSubmit = (orderId) => {
    if (
      depositAmount ==
      selectedPayments?.reduce((prev, cur) => prev + +cur.amount, 0)
    ) {
      axios
        .post(`/billing/deposit`, {
          cashier_id: clientId,
          category_id: 1,
          subaccount_category_id: "2b9064ab-09b8-414d-adb3-a8ee1a3b7863",
          order_id: orderId,
          payments: selectedPayments?.map(({ type, amount }) => ({
            payment_type: type,
            amount: amount || 0,
          })),
          comment: comment,
          status: "return_pay",
        })
        .then((res) => {
          console.log(res)
          getItems({
            limit,
            offset: offset ?? 0,
            tab: tab ?? 0,
            status: cStatuses[tab ?? 0],
            order: order,
            from_time,
            to_time,
            arrangement,
          })
          getItemCounts()
          if (items.data && items.data?.orders?.length) {
            items.data.orders?.forEach((order) => {
              getDeposits(order.id)
            })
          }
        })
      dispatch(showAlert(t("Вы успешно оплатили сумму депозита"), "success"))
    } else {
      // Alert to notify the user
      dispatch(
        showAlert(t("Пожалуйста, оплатите полную сумму депозита"), "warning")
      )
      setBtnDisabled(false)
    }
  }

  const onReturnMoneySubmit = (orderId, returnAmount) => {
    const paidAmount =
      selectedPayments?.reduce((prev, cur) => prev + +cur?.amount, 0) || 0
    if (returnAmount >= paidAmount) {
      axios
        .post(`/order-payment`, {
          cashier_id: clientId,
          order_id: orderId,
          paid_amount: paidAmount,
          payments: selectedPayments?.map(({ type, amount }) => ({
            payment_type: type,
            paid_amount: amount || 0,
          })),
          subaccount_category: "0fac19bc-d7e4-4b75-af89-cc21ee8c7495",
          comment: comment,
        })
        .then((res) => {
          getItems({
            limit,
            offset: offset ?? 0,
            tab: tab ?? 0,
            status: cStatuses[tab ?? 0],
            order: order,
            from_time,
            to_time,
            arrangement,
          })
        })
      dispatch(showAlert(t("Вы успешно вернули деньги"), "success"))
    } else
      dispatch(showAlert(t("Пожалуйста, не возвращайте больше суммы"), "error"))
  }

  const addSelectedPayment = (option, paymentMethodIndex) => {
    setSelectedPayments((prev) => [...prev, option])
    setPaymentMethods((prev) =>
      prev?.filter((item, i) => i !== paymentMethodIndex)
    )
  }

  const removePayment = (option, index) => {
    setSelectedPayments((prev) => prev?.filter((el, i) => i !== index))
    setPaymentMethods((prev) => [...prev, option])
  }

  const updatePayment = (input, paymentIndex) => {
    const num = input ? +input.replace(/\s+/g, "") : ""
    setSelectedPayments((prev) =>
      prev?.map((payment, i) => {
        return i === paymentIndex ? { ...payment, amount: num } : payment
      })
    )
  }

  const handleClickPayme = (id, price, phone) => {
    axios
      .get("/client-payme-url", {
        params: {
          order: id,
          amount: +price,
          phone: phone,
        },
      })
      .then(() => {
        dispatch(showAlert(t("sms.send.success"), "success"))
      })
      .catch((e) => {
        dispatch(showAlert(t("sms.send.fail"), "error"))
      })
  }

  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      // if edit, then go to edit page
      if (permissions.includes("orders_edit")) {
        setDropList((old) => {
          return { ...old, target: null }
        })
        return history.push(`/home/orders/${id}`)
      } else {
        dispatch(showAlert(t("no.permission"), "warning"))
      }
    } else if (type === "delete") {
      // if delete, then delete that specific item
      if (permissions.includes("orders_delete"))
        axios.delete(`order/${id}`).then((res) => {
          setDropList((old) => {
            return { ...old, target: null }
          })
          dispatch(showAlert(t("deleted.successfully"), "success"))
          getItems({
            limit,
            offset: offset ?? 0,
            tab: tab ?? 0,
            status: statuses[tab ?? 0],
          })
        })
    } else if (type === "cancel") {
      // if delete, then delete that specific item
      if (permissions.includes("orders_cancel")) {
        const query = tab === 1 ? "aborted" : "cancelled"
        axios
          .put(`order-status/${id}`, {
            status_id: query,
          })
          .then((res) => {
            setDropList((old) => {
              return { ...old, target: null }
            })
            dispatch(showAlert(t("Заказ успешно отменен"), "success"))
            getItems({
              limit,
              offset: offset ?? 0,
              tab: tab ?? 0,
              status: statuses[tab ?? 0],
            })
          })
      }
    }
  }

  return (
    <div className="px-5 py-4">
      {!from_dashboard && (
        <StyledTabs
          value={value}
          onChange={handleChange}
          centered={false}
          aria-label="full width tabs example"
          className="bg-white rounded"
        >
          {statuses.map((el, index) => (
            <StyledTab
              key={index}
              label={tabLabel(t(el), itemCounts[index], value === index)}
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      )}
      <TableContainer className="mt-2" elevation={0} component={Paper}>
        <Table aria-label="simple table" style={{ overflowX: "auto" }}>
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("order.number")}</StyledTableCell>
              <StyledTableCell>{t("client")}</StyledTableCell>
              {value !== 0 ? (
                <StyledTableCell>{t("date.from.to")}</StyledTableCell>
              ) : (
                <StyledTableCell style={{ width: "9%" }}>
                  {t("day.count")}
                </StyledTableCell>
              )}
              <StyledTableCell>{t("car.number")}</StyledTableCell>
              <StyledTableCell>{t("total.price")}</StyledTableCell>
              <StyledTableCell>{t("created.date")}</StyledTableCell>
              <StyledTableCell>{t("branch")}</StyledTableCell>
              <StyledTableCell>{t("creator")}</StyledTableCell>
              <StyledTableCell>{t("payment.status")}</StyledTableCell>
              {permissions.includes("orders_payment") &&
                (value === 0 || value === 2 || value === 1) && (
                  <StyledTableCell>{t("payment")}</StyledTableCell>
                )}
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.data && items.data?.orders?.length ? (
              items.data.orders?.map(
                (
                  {
                    id,
                    order_number,
                    client,
                    car,
                    created_at,
                    branch,
                    paid_price,
                    total_price,
                    from_time,
                    start_date,
                    day_count,
                    to_time,
                    is_paid,
                    is_cost_paid,
                    is_cost_exist,
                    initiator,
                    returned_money,
                    is_paid_date,
                    daily_price,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                    onClick={() => {
                      if (permissions.includes("orders_edit"))
                        return history.push(`/home/orders/${id}/?tab=${tab}`)
                    }}
                  >
                    <StyledTableCell>{order_number}</StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={client.is_blocked ? { color: "#DC2626" } : null}
                      >
                        {client.first_name} {client.last_name}
                      </div>
                      <div className="text-blue-600">
                        {formatPhoneNumber(client.phone_number)}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      {value === 4 ? (
                        <>
                          <div>{`${moment(start_date).format("DD/MM")}`}</div>
                          <div
                            style={{
                              color: moment(start_date)
                                .add(day_count, "days")
                                .isBefore(moment())
                                ? "red"
                                : "",
                            }}
                          >
                            {moment(start_date)
                              .add(day_count, "days")
                              .format("DD/MM")}
                          </div>
                        </>
                      ) : value !== 0 ? (
                        <>
                          <div>{`${moment(from_time).format("DD/MM")} `}</div>
                          <div
                            style={{
                              color: moment(to_time).isBefore(moment())
                                ? "red"
                                : "",
                            }}
                          >
                            {moment(to_time).format("DD/MM")}
                          </div>
                        </>
                      ) : (
                        <>
                          <div>{day_count}</div>
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{car.state_number}</StyledTableCell>
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(total_price)}
                    </StyledTableCell>

                    <StyledTableCell>
                      {dateTimeConvert(created_at)}
                    </StyledTableCell>
                    <StyledTableCell>{branch.name}</StyledTableCell>
                    <StyledTableCell>
                      {initiator.type && (
                        <Tag
                          shape="subtle"
                          size="large"
                          style={{ width: "160px" }}
                        >
                          {t(initiator.type).toLowerCase()}
                        </Tag>
                      )}
                      <p className="mt-1">{initiator.name}</p>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        textColor={
                          is_paid
                            ? is_paid === 1
                              ? "text-green-600"
                              : "text-amber-600"
                            : "text-red-600"
                        }
                        bgColor={
                          is_paid
                            ? is_paid === 1
                              ? "bg-green-100"
                              : "bg-amber-100"
                            : "bg-red-100"
                        }
                        size="large"
                        style={{ width: "110px" }}
                      >
                        {t(
                          is_paid
                            ? is_paid === 1
                              ? "paid"
                              : "partially.paid"
                            : "not.paid"
                        )}
                      </Tag>
                    </StyledTableCell>
                    {permissions.includes("orders_payment") && value === 0 ? (
                      <StyledTableCell style={{ width: "20%" }}>
                        <div className="flex space-x-1">
                          <Button
                            shape="outlined"
                            className="border border-gray-600"
                            disabled={
                              !car?.id ||
                              // paid_price <= total_price ||
                              is_paid === 0 ||
                              differenceDate(is_paid_date) ||
                              returned_money <= 0
                            }
                            style={{ borderRadius: "0.2rem", width: "100%" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleModal(index)
                              setDepositAmount(undefined)
                              // updatePayment(
                              //   (paid_price - total_price).toString(),
                              //   0
                              // )
                            }}
                          >
                            {t("return.money")}
                          </Button>
                          <Button
                            shape="outlined"
                            className="border border-gray-600"
                            style={{ borderRadius: "0.2rem", width: "100%" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              history.push(`/home/orders/${id}/order-payment`)
                            }}
                            disabled={!car?.id}
                          >
                            {t("make.payment")}
                          </Button>
                          {permissions.includes("payme_pay") && value === 0 && (
                            <Button
                              disabled={!car?.id || is_paid === 1}
                              shape="outlined"
                              className="border border-gray-600"
                              style={{ borderRadius: "0.2rem", width: "100%" }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleClickPayme(
                                  id,
                                  total_price - paid_price,
                                  client.phone_number
                                )
                              }}
                            >
                              <div className="my-auto mx-2">{t("pay")}</div>
                            </Button>
                          )}
                        </div>
                      </StyledTableCell>
                    ) : (
                      permissions.includes("orders_payment") &&
                      value === 2 && (
                        <StyledTableCell>
                          <div className="flex space-x-1">
                            <Button
                              shape="outlined"
                              className="border border-gray-600"
                              style={{
                                borderRadius: "0.2rem",
                                maxWidth: "100px",
                              }}
                              onClick={(e) => {
                                history.push(`/home/orders/${id}/fine-payment`)
                              }}
                              stopPropagination
                              disabled={
                                is_cost_paid === 1 || is_cost_exist !== 1
                              }
                            >
                              {t(
                                is_cost_paid === 1
                                  ? "fine.paid"
                                  : is_cost_exist !== 1
                                  ? "no.fine"
                                  : "fine.payment"
                              )}
                            </Button>
                            <Button
                              shape="outlined"
                              className="border border-gray-600"
                              disabled={
                                  !returned_money
                                  ? true
                                  : returnedMoney[index]
                                  ? true
                                  : false
                              }
                              style={{ borderRadius: "0.2rem", width: "100%" }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleModal(index)
                                setDepositAmount(undefined)
                                // updatePayment(returned_money.toString(), 0)
                              }}
                            >
                              .
                              {t("return.money")}
                            </Button>
                            <Button
                              shape="outlined"
                              className="border border-gray-600"
                              disabled={
                                (is_cost_exist && is_cost_paid !== 1) ||
                                returned_money
                              }
                              style={{
                                borderRadius: "0.2rem",
                                maxWidth: "100px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleModal(index)
                                getDepositAmount(id)
                              }}
                            >
                              {t("deposit.return")}
                            </Button>
                          </div>
                        </StyledTableCell>
                      )
                    )}
                    {permissions.includes("orders_payment") && value === 4 && (
                      <StyledTableCell style={{ width: "20%" }}>
                        <div className="flex space-x-1">
                          <Button
                            shape="outlined"
                            className="border border-gray-600"
                            disabled={paid_price <= 0}
                            style={{ borderRadius: "0.2rem", width: "100%" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleModal(index)
                              setDepositAmount(undefined)
                              // updatePayment(paid_price.toString(), 0)
                            }}
                          >
                            {t("return.money")}
                          </Button>
                          <Button
                            shape="outlined"
                            className="border border-gray-600"
                            disabled={
                              deposits
                                ? deposits[id] <= 0
                                  ? true
                                  : false
                                : true
                            }
                            style={{
                              borderRadius: "0.2rem",
                              maxWidth: "100px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleModal(index)
                              getDepositAmount(id)
                            }}
                          >
                            {t("deposit.return")}
                          </Button>
                        </div>
                      </StyledTableCell>
                    )}
                    {permissions.includes("orders_payment") && value === 1 ? (
                      <>
                        <StyledTableCell style={{ width: "10%" }}>
                          <Button
                            shape="outlined"
                            className="border border-gray-600"
                            style={{ borderRadius: "0.2rem", width: "100%" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              history.push(`/home/orders/${id}/order-payment`)
                            }}
                            disabled={is_paid === 1}
                          >
                            {t("make.payment")}
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "10%" }}>
                          <Button
                            // disabled={!car?.id || is_paid !== 1}
                            disabled={is_paid === 1}
                            shape="outlined"
                            className="border border-gray-600"
                            style={{ borderRadius: "0.2rem", width: "100%" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleClickPayme(
                                id,
                                total_price - paid_price,
                                client.phone_number
                              )
                            }}
                          >
                            <div className="my-auto mx-2">{t("pay")}</div>
                          </Button>
                        </StyledTableCell>
                      </>
                    ) : null}
                    <StyledTableCell
                      align="center"
                      style={{
                        width: "5%",
                        borderLeft: "1px solid #ddd",
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setDropList({
                          target: e.currentTarget,
                          id: id,
                        })
                      }}
                      className="cursor-pointer"
                    >
                      <MoreHorizIcon />
                      <StyledPropMenu
                        anchorEl={dropList.target}
                        open={Boolean(dropList.target)}
                        onClose={(e) => {
                          e.stopPropagation()

                          setDropList((old) => {
                            return { ...old, target: null }
                          })
                        }}
                      >
                        <div className="flex flex-col divide-y font-body text-sm">
                          {(!permissions.includes("orders_delete") &&
                          value !== 0
                            ? listRender.filter((el) => el.title !== "delete")
                            : !permissions.includes("orders_delete")
                            ? listRender.filter((el) => el.title !== "delete")
                            : value !== 0
                            ? listRender.filter((el) => el.title !== "cancel")
                            : listRender
                          )
                            ?.filter((el) => {
                              return permissions.includes("orders_" + el.title)
                            })
                            ?.map((el, index) =>
                              tab != 0 && el.title === "cancel" ? null : (
                                <div
                                  key={index}
                                  id={index}
                                  className="flex w-full cursor-pointer py-2 px-2 hover:bg-gray-100"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleMoreClick(dropList.id, el.type)
                                  }}
                                >
                                  <div className="rounded">{el.icon}</div>
                                  <div className="my-auto mx-2">
                                    {t(el.title)}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </StyledPropMenu>
                    </StyledTableCell>

                    <Modal
                      title={t("make.payment")}
                      onClick={(e) => e.stopPropagation()}
                      onClose={(e) => {
                        e.stopPropagation()
                        setShowModal(new Array(pageCount).fill(false))
                      }}
                      isOpen={showModal[index]}
                      className="cursor-default"
                      width="600px"
                    >
                      <div className="flex flex-col text-black font-medium mt-2">
                        {paymentMethods?.length !== 0 && (
                          <>
                            <div className="flex justify-between text-gray-600 py-1">
                              <p>{t("choose.payment.type")}</p>
                              <p className="text-gray-700">
                                {t(
                                  depositAmount !== 0
                                    ? "money.to.be.returned"
                                    : "deposit.amount"
                                )}{" "}
                                {convertToInternationalCurrencySystem(
                                  depositAmount
                                    ? depositAmount ?? paid_price - total_price
                                    : returned_money
                                )}
                              </p>
                            </div>

                            {depositAmount ? null : (
                              <div className="flex justify-end text-gray-600 py-1">
                                <p className="text-gray-700">
                                  {t("Дневная цена")}{" "}
                                  {convertToInternationalCurrencySystem(
                                    daily_price
                                  )}
                                </p>
                              </div>
                            )}

                            <div className="w-full py-2 flex flex-wrap items-baseline space-x-4">
                              {paymentMethods?.map((el, i) => (
                                <div
                                  key={index}
                                  id={i}
                                  className="flex flex-col items-center space-y-1"
                                  style={{
                                    marginLeft:
                                      i === 5 && i === paymentMethods.length - 1
                                        ? "0"
                                        : null,
                                  }}
                                >
                                  <span
                                    className={`flex justify-center border-2 
                                    rounded-md cursor-pointer px-4 py-2`}
                                    onClick={() => addSelectedPayment(el, i)}
                                    style={{
                                      width: "85px",
                                      height: "43px",
                                    }}
                                  >
                                    <img src={el.icon} alt="" />
                                  </span>
                                  <p className="font-normal">{t(el.label)}</p>
                                </div>
                              ))}
                            </div>
                            <hr className="my-2" />
                          </>
                        )}
                        {selectedPayments?.map((item, i) => (
                          <div
                            id={i}
                            className="w-full py-2 flex items-center space-x-4"
                          >
                            <div className="flex items-center space-x-3 w-2/3">
                              <span
                                className={`flex justify-center border-2 
                                          rounded-md 
                                          cursor-pointer px-4 py-2`}
                                style={{
                                  width: "85px",
                                  height: "40px",
                                  objectFit: "cover",
                                }}
                              >
                                <img src={item.icon} alt="" />
                              </span>
                              <p className="font-normal">{t(item.type)}</p>
                            </div>
                            {tab == 2 && userType === "CASHIER" ? (
                              <PriceInput
                                placeholder={t("enter.the.amount")}
                                onChange={(e) => {
                                  updatePayment(e.target.value, i)
                                }}
                              />
                            ) : (
                              <PriceInput
                                placeholder={t("enter.the.amount")}
                                onChange={(e) => {
                                  updatePayment(e.target.value, i)
                                }}
                              />
                            )}
                            <ClearIcon
                              className="cursor-pointer"
                              onClick={() => removePayment(item, i)}
                            />
                          </div>
                        ))}
                        {/* Comment */}
                        <div className="w-full py-2 flex items-baseline">
                          <p className="w-1/3">{t("comment")}:</p>
                          <div className="w-2/3">
                            <Textarea
                              type="text"
                              placeholder={t("leave.comment")}
                              style={{
                                minHeight: "100px",
                              }}
                              onChange={(val) => setComment(val.target.value)}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end pt-2">
                          <Button
                            color="primary-600"
                            size="medium"
                            disabled={btnDisabled}
                            onClick={() => {
                              setBtnDisabled(true)
                              depositAmount
                                ? onModalSubmit(id)
                                : onReturnMoneySubmit(id, returned_money)
                              setReturnedMoney((prev) => ({
                                ...prev,
                                [index]: true,
                              }))
                              setBtnDisabled(false)
                              setShowModal(new Array(pageCount).fill(false))
                            }}
                            className="py-2 px-4"
                            style={{ borderRadius: "3px" }}
                          >
                            {t("complete")}
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable
        message="Нет заказов"
        isActive={!isLoading && !items.data?.orders?.length > 0}
      />
      {isLoading && <Skeleton />}
      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(
            limit && !offset ? limit / pageCount : items.data?.count / pageCount
          )}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changePaginationLocationQuery}
        />
      </div>
    </div>
  )
}

export default TableContext
