import { useState, useEffect } from "react"
import Picker from "rc-calendar/lib/Picker"
import RangeCalendar from "rc-calendar/lib/RangeCalendar"
import { ruRU } from "rc-calendar/lib/locale/ru_RU"
import TimePickerPanel from "rc-time-picker/lib/Panel"
import { CalendarToday as CalendarIcon } from "@material-ui/icons"
import "rc-calendar/assets/index.css"
import "rc-time-picker/assets/index.css"

import moment from "moment"

import "moment/locale/ru"

const timePickerElement = (
  <TimePickerPanel
    defaultValue={[
      moment("00:00:00", "HH:mm:ss"),
      moment("23:59:59", "HH:mm:ss"),
    ]}
  />
)

function newArray(start, end) {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

function disabledTime(time, type) {
  if (type === "start") {
    return {
      disabledHours() {
        const hours = newArray(0, 60)
        hours.splice(20, 4)
        return hours
      },
      disabledMinutes(h) {
        if (h === 20) {
          return newArray(0, 31)
        } else if (h === 23) {
          return newArray(30, 60)
        }
        return []
      },
      disabledSeconds() {
        return [55, 56]
      },
    }
  }
  return {
    disabledHours() {
      const hours = newArray(0, 60)
      hours.splice(2, 6)
      return hours
    },
    disabledMinutes(h) {
      if (h === 20) {
        return newArray(0, 31)
      } else if (h === 23) {
        return newArray(30, 60)
      }
      return []
    },
    disabledSeconds() {
      return [55, 56]
    },
  }
}

export default function RangePicker({
  disabled,
  defaultValue = [],
  dateInputPlaceholder,
  hideTimePicker = false,
  onChange = () => {},
  style,
  outerClick,
}) {
  const [value, setValue] = useState([])
  const [hoverValue, setHoverValue] = useState([])

  useEffect(() => {
    setValue(defaultValue ?? undefined)
  }, [])

  useEffect(() => {
    if (outerClick) clearInput()
  }, [outerClick])

  const handleClick = (value) => {
    setValue(value)
    onChange(value)
  }

  const onHoverChange = (hoverValue) => {
    setHoverValue(hoverValue)
  }

  const clearInput = () => {
    setValue([])
    onChange([null, null])
  }
  // const state = this.state;
  const calendar = (
    <RangeCalendar
      hoverValue={hoverValue}
      onHoverChange={onHoverChange}
      showWeekNumber={false}
      dateInputPlaceholder={dateInputPlaceholder || ["start", "end"]}
      locale={ruRU}
      disabledTime={disabledTime}
      timePicker={!hideTimePicker ? timePickerElement : null}
      style={{ marginTop: "2rem", width: "100%" }}
    />
  )

  return (
    <Picker
      value={value}
      onChange={handleClick}
      animation="slide-up"
      calendar={calendar}
    >
      {({ value }) => {
        return (
          <div
            className={`
            border-solid
            border-2
            border-gray-100
            rounded-lg
            inline-block
            relative
            cursor-pointer
            bg-white
            px-2
            py-1
            items-center
            rounded
            text-body
            relative
            text-gray-600
            font-smaller
             ${disabled && "cursor-not-allowed opacity-40"}`}
            style={style}
          >
            <CalendarIcon
              style={{ fontSize: "14px", marginBottom: "2px" }}
              className="text-primary-600"
            />
          </div>
        )
      }}
    </Picker>
  )
}
