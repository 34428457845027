import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import AlertComponent from "../../components/Alert"
import { showAlert } from "../../redux/reducers/alertReducer"
import Context from "./context"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import moment from "moment"

function CarOwnersCreate() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [image, setImage] = useState("")

  const [initialValues, setInitialValues] = useState({
    description: "",
    address: "",
    name: "",
    date_of_birth: "",
    phone_number: "",
    additional_phone_numbers: [],
    photo: "",
  })

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = (id) => {
    setIsGettingData(true)
    axios
      .get(`/car-owner/${id}`)
      .then((res) => {
        setInitialValues({
          ...res,
          phone_number: formatPhoneNumber(res.phone_number),
        })
        setImage(res.photo)
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/car-owner/${params.id}`, {
          ...values,
          date_of_birth: values.date_of_birth
            ? moment(values.date_of_birth).toISOString()
            : "",
          photo: image,
          phone_number: values.phone_number.replace(/ +/g, ""),
        })
        .then((res) => {
          history.push("/home/settings/organizations/car-owners")
        })
        .catch((err) => {
          dispatch(showAlert(t(err.data?.error), "error"))
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/car-owner", {
          ...values,
          date_of_birth: moment(values.date_of_birth).toISOString(),
          photo: image,
          phone_number: values.phone_number.replace(/ +/g, ""),
        })
        .then((res) => {
          history.push("/home/settings/organizations/car-owners")
        })
        .catch((err) => {
          dispatch(showAlert(t(err.data?.error), "error"))
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
  })

  const routes = [
    {
      title: t("organizations"),
      link: true,
      route: "/home/settings/organizations",
    },
    {
      title: t("car-owners"),
      link: true,
      route: "/home/settings/organizations/car-owners",
    },
    {
      title: params.id ? initialValues.name : t("create"),
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("car-owner")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={
                  value === 0 && [
                    <CustomButton
                      textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                      shape="text"
                      color="text-error-600"
                      icon={cancelIcon}
                      onClick={() =>
                        history.push("/home/settings/organizations/car-owners")
                      }
                    >
                      {t("cancel")}
                    </CustomButton>,
                    <CustomButton
                      textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                      type="submit"
                      shape="text"
                      color="text-primary-600"
                      icon={
                        loading ? (
                          <CircularProgress color="inherit" size={14} />
                        ) : (
                          saveIcon
                        )
                      }
                    >
                      {params.id ? t("save") : t("create")}
                    </CustomButton>,
                  ]
                }
              />
              <AlertComponent
                style={{
                  top: "2px",
                  left: "45%",
                  marginLeft: "-100px",
                }}
              />
              <Context formik={formik} />
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default CarOwnersCreate
