import TableCell from "@material-ui/core/TableCell"
import { useTranslation } from "react-i18next"

const TableHeadCell = ({ col, row, title, width }) => {
  const { t } = useTranslation()

  return (
    <TableCell
      colSpan={col ? col : 1}
      rowSpan={row ? row : 1}
      align="center"
      style={{
        fontWeight: "600",
        borderRight: "1px solid #ddd",
        minWidth: width ? width : "auto",
        whiteSpace: "pre",
      }}
    >
      {t(title)}
    </TableCell>
  )
}

export default TableHeadCell
