import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";

import Skeleton from "../../components/Skeleton";
import axios from "../../utils/axios";
import Header from "../../components/Header/Header";
import CustomButton from "../../components/Buttons/index";
import validateForm from "../../functions/validateForm";
import Context from "./Context";
import { saveIcon } from "../../assets/icons/icons";

function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isGettingData, setIsGettingData] = useState(false);
  const [image, setImage] = useState("");
  const permissions = useSelector((state) => state.auth.permissions);

  const [contacts, setContacts] = useState([
    {
      id: "",
      name: "", //Служба поддержки
      contact_values: [{ id: "", value: "" }],
    },
  ]);

  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    contacts: [{ name: "", contact_values: [{ value: "" }] }],
  });

  useEffect(() => {
    getProperty();
  }, []);

  // **** FUNCTIONS ****
  const getProperty = () => {
    setIsGettingData(true);

    axios
      .get(`/about`)
      .then((res) => {
        let data = {
          ...res,
        };
        setInitialValues(data);
        setContacts(res.contacts);
        setImage(res.photo);
      })
      .finally(() => setIsGettingData(false));
  };

  // **** EVENTS ****
  const onSubmit = (values) => {
    axios
      .put(`/about`, {
        ...values,
        photo: image,
        contacts: contacts,
      })
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    title: validateForm("default", t),
    description: validateForm("default", t),
  });

  // Functions for Changing Contacts
  const addContact = () => {
    setContacts((old) => [
      ...old,
      { name: "", id: "", contact_values: [{ id: "", value: "" }] },
    ]);
  };

  const removeContact = (i) => {
    setContacts((old) => old.filter((el, index) => i !== index));
  };

  const updateContact = (val, i) => {
    setContacts((old) =>
      old.map((el, index) => (index === i ? { ...el, name: val } : el))
    );
  };

  // Functions for Changing Contact Values
  const addContactValue = (index) => {
    setContacts((old) =>
      old.map((el, i) =>
        i === index
          ? {
              ...el,
              contact_values: [...el.contact_values, { id: "", value: "" }],
            }
          : el
      )
    );
  };

  const removeContactValue = (contactIndex, index) => {
    setContacts((old) =>
      old.map((el, i) =>
        i === index
          ? {
              ...el,
              contact_values: el.contact_values.filter(
                (elm, contactI) => contactIndex !== contactI
              ),
            }
          : el
      )
    );
  };

  const updateContactValue = (val, contactIndex, index) => {
    setContacts((old) =>
      old.map((el, i) =>
        i === index
          ? {
              ...el,
              contact_values: el.contact_values.map((contactElm, contactI) =>
                contactI === contactIndex
                  ? { ...contactElm, value: val }
                  : contactElm
              ),
            }
          : el
      )
    );
  };

  return (
    <div>
      {!isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("about.us")}
                endAdornment={
                  permissions.includes("settings_about_us_edit")
                    ? [
                        <CustomButton
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                        >
                          {t("save")}
                        </CustomButton>,
                      ]
                    : ""
                }
              />

              <Context
                image={image}
                contacts={contacts}
                formik={formik}
                setImage={setImage}
                addContact={addContact}
                addContactValue={addContactValue}
                removeContact={removeContact}
                removeContactValue={removeContactValue}
                updateContact={updateContact}
                updateContactValue={updateContactValue}
              />
            </form>
          )}
        </Formik>
      ) : (
        <Skeleton />
      )}
    </div>
  );
}

export default App;
