function PriceDisplay({ className, text, price, numType, ...rest }) {
  return (
    <div
      className={`${className} flex items-center w-full text-gray-600 space-x-4`}
    >
      <div className="flex w-full items-center space-x-4">
        <p>{text}</p>
        <hr className="w-full border-0.5 border-dashed border-gray-400" />
      </div>
      <p className="w-3/4">
        <span className="text-lg">{numType}</span>
        &nbsp;
        {price}
      </p>
    </div>
  );
}

export default PriceDisplay;
