import { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import "./index.scss";

function Menu({
  title,
  children,
  className,
  isExpanded = false,
  onChange = function () {},
  ...rest
}) {
  const [expand, setExpand] = useState(isExpanded);

  useEffect(() => {
    setExpand(isExpanded);
    // onChange(expand)
  }, [isExpanded]);

  return (
    <div {...rest} className={`${className} w-full rounded-sm `}>
      <div
        className={`flex w-full p-3 justify-between cursor-pointer
        ${expand && "bg-background mb-2"}
        rounded-md
        hover:bg-primary-100
        transition ease-out
        duration-500
        `}
        onClick={onChange}
      >
        <div className={``}>{title}</div>
        <div
          className={`${
            expand ? "-rotate-180" : "rotate-0"
          }  transform transition duration-300 ease-in-out`}
        >
          <ExpandMoreIcon />
        </div>
      </div>

      <div className="flex flex-col space-y-2 children">
        {expand && children}
      </div>
    </div>
  );
}

Menu.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Menu;
