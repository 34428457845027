import { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import { StyledTabs, StyledTab, TabPanel } from "../../components/StyledTabs"
import Filters from "../../components/Filters"
import Context from "./Context"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import validateForm from "../../functions/validateForm"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import AlertComponent from "../../components/Alert"
import { showAlert } from "../../redux/reducers/alertReducer"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import { useSelector } from "react-redux"

function CashiersCreate() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const theme = useTheme()
  const location = useLocation()
  const permissions = useSelector((state) => state.auth.permissions)

  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [branches, setBranches] = useState([])
  const [cashboxes, setCashboxes] = useState([])

  const [image, setImage] = useState("")
  const [initialValues, setInitialValues] = useState({
    status: true,
    full_name: "",
    login: "",
    password: "",
    phone_number: "",
    branch: "",
    cashbox: "",
    photo: "",
    address: "",
    is_required: false,
  })

  useEffect(() => {
    getBranches()
    getCashboxes()
  }, [])

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    let [responseBranch, responseCashbox] = await Promise.all([
      axios.get("/branch?limit=100&offset=0").then((res) => res.data),
      axios.get("/cashbox?limit=100&offset=0").then((res) => res.data),
    ])

    let listOfBranches = responseBranch?.branches?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))

    let listOfCashboxes = responseCashbox?.cashboxes?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))

    setBranches(listOfBranches)
    setCashboxes(listOfCashboxes)

    await axios
      .get(`/system-user/${id}`)
      .then((res) => {
        let data = {
          ...res,
          phone_number: formatPhoneNumber(res.phone_number),
          branch: responseBranch.branches
            ?.filter((el) => el.id === res.branch.id)
            ?.map((el) => ({ label: el.name, value: el.id }))[0],
          cashbox: responseCashbox.cashboxes
            ?.filter((el) => el.id === res.cashbox_id)
            ?.map((el) => ({ label: el.name, value: el.id }))[0],
        }
        setInitialValues(data)
        setImage(res.photo)
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/system-user/${params.id}`, {
          ...values,
          photo: image,
          phone_number: values.phone_number.replace(/ +/g, ""),
          status: values.status ? 1 : 0,
          branch_id: values.branch.value,
          cashbox_id: values.cashbox.value,
          password: values.password || "",
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/roles/cashiers")
        })
        .catch((err) => dispatch(showAlert(t(err.data?.error), "error")))
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/system-user", {
          ...values,
          photo: image,
          type: "cashier",
          phone_number: values.phone_number.replace(/ +/g, ""),
          status: values.status ? 1 : 0,
          branch_id: values.branch.value,
          cashbox_id: values.cashbox.value,
          password: values.password || "",
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/roles/cashiers")
        })
        .catch((err) => dispatch(showAlert(t(err.data?.error), "error")))
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get Attribute Types and Categories
  const getBranches = async () => {
    let response = await axios.get("/branch?limit=100&offset=0")

    setBranches(
      response.data.branches?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const getCashboxes = async () => {
    let response = await axios.get(`/cashbox`, {
      params: {
        limit: 100,
        offset: 0,
      },
    })
    setCashboxes(
      response.data.cashboxes?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    full_name: validateForm("default", t),
    phone_number: validateForm("phone_number", t),
    branch: validateForm("mixed", t),
    cashbox: validateForm("mixed", t),
    login: validateForm("loginRegExp", t),
    password:
      (location.pathname.includes("settings/roles/cashiers/create") ||
        (params.id && permissions.includes("сменить пароль"))) &&
      validateForm("password", t),
  })

  const routes = [
    {
      title: "Роли",
      link: true,
      route: "/home/settings/roles",
    },
    {
      title: "Кассиры",
      link: true,
      route: "/home/settings/roles/cashiers",
    },
    {
      title: params.id ? initialValues.full_name : t("create"),
    },
  ]

  // Tabs

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>
  }

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("cashier")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/roles/cashiers")
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />
              <AlertComponent
                style={{
                  top: "2px",
                  left: "45%",
                  marginLeft: "-100px",
                }}
              />
              <Filters>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{ children: <span className="w-2" /> }}
                >
                  <StyledTab
                    label={tabLabel(t("general.information"))}
                    {...a11yProps(0)}
                    style={{ width: "100px" }}
                  />
                  <StyledTab
                    label={tabLabel(t("order.history"))}
                    {...a11yProps(1)}
                  />
                </StyledTabs>
              </Filters>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Context
                    formik={formik}
                    image={image}
                    branches={branches}
                    cashboxes={cashboxes}
                    setImage={setImage}
                  />
                </TabPanel>
              </SwipeableViews>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default CashiersCreate
