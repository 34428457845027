import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import { StyledTab, StyledTabs } from "../../components/StyledTabs"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import SearchIcon from "@material-ui/icons/Search"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import { StyledTableCell } from "../../components/StyledTableCell"
import Paper from "@material-ui/core/Paper"
import MoreClickHandler from "../../components/MoreClick"
import { addIcon } from "../../assets/icons/icons"
import EmptyTable from "../../components/EmptyTable"
import parseQuery from "../../functions/parseQuery"

var unique_investor_id = "f78d1058-0776-475a-9a5e-ea2594d33db9"

export default function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)
  const pageCount = 10

  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})

  let debounce = setTimeout(() => {}, 0)
  let { limit, offset, tab } = parseQuery()
  const value = +tab ?? 0

  useEffect(() => {
    getItems({
      limit: limit ?? pageCount,
      offset: offset ?? 0,
      category_id: value === 0 ? "3" : "2",
    })
  }, [value, limit, offset])

  // **** FUNCTIONS ****
  const getItems = async (params = {}) => {
    let response = await axios.get(`/billing/subaccount-category`, { params })
    setItems({
      data: response,
    })
    console.log(response)
    setIsLoading(false)
  }

  // **** EVENTS ****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        category_id: value === 0 ? "3" : "2",
        search: val,
      })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/billing/expense-types`,
      search: `?tab=${+tab ?? 0}&limit=${pageCount}&offset=${offset}`,
    })
  }

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const changeTabLocationQuery = (tabId) => {
    history.push({
      pathname: `/home/billing/expense-types`,
      search: `?tab=${tabId}`,
    })
  }

  const handleChange = (event, newValue) => {
    changeTabLocationQuery(newValue)
  }

  const tabLabel = (text, number = "", isActive = false) => {
    return (
      <span
        className={`px-1 ${
          !isActive ? "text-warmGray-500" : "text-primary-600"
        }`}
      >
        {text}
      </span>
    )
  }
  return (
    <div>
      <Header
        title={t("expense.type")}
        endAdornment={
          permissions.includes("billing_expense_types_create")
            ? [
                <Button
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() =>
                    history.push("/home/billing/expense-types/create")
                  }
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 py-4">
        <StyledTabs
          value={value}
          onChange={handleChange}
          centered={false}
          aria-label="full width tabs example"
          TabIndicatorProps={{ children: <span className="w-2" /> }}
          className="bg-white rounded"
        >
          <StyledTab
            label={tabLabel(t("employee_expenditure"), "", value === 0)}
            {...a11yProps(0)}
          />
          <StyledTab
            label={tabLabel(t("car_expenditure"), "", value === 1)}
            {...a11yProps(1)}
          />
        </StyledTabs>
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("category.type")}</StyledTableCell>
                <StyledTableCell>{t("created.date")}</StyledTableCell>

                {permissions.includes("billing_expense_types_delete") && (
                  <StyledTableCell></StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.data && items.data?.sub_account_categories?.length ? (
                items.data.sub_account_categories?.map(
                  ({ id, name, category_name, created_at }, index) => (
                    <TableRow
                      key={id}
                      className="hover:bg-gray-100 cursor-pointer transition ease-linear"
                      onClick={() => {
                        history.push(`/home/billing/expense-types/${id}`)
                      }}
                    >
                      <StyledTableCell>{name}</StyledTableCell>
                      <StyledTableCell>{t(category_name)}</StyledTableCell>
                      <StyledTableCell style={{ width: "300px" }}>
                        <div className="text-blue-600">
                          {dateTimeConvert(created_at)}
                        </div>
                      </StyledTableCell>

                      {permissions.includes("billing_expense_types_delete") &&
                        id !== unique_investor_id && (
                          <MoreClickHandler
                            id={id}
                            api="/billing/subaccount-category"
                            pushLocation="/home/billing/expense-types"
                            editPermission="billing_expense_types_edit"
                            deletePermission="billing_expense_types_delete"
                            getItems={() =>
                              getItems({
                                limit: limit ?? pageCount,
                                offset: offset ?? 0,
                                category_id: value === 0 ? "3" : "2",
                              })
                            }
                          />
                        )}
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable
          isActive={
            !isLoading && !items.data?.sub_account_categories?.length > 0
          }
        />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.data?.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}
