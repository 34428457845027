import React from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"

import moment from "moment"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import Tag from "../../components/Tag"
import { StyledTableCell } from "../../components/StyledTableCell"
import formatPhoneNumber from "../../functions/formatPhoneNumber"

function ClientsOrders({ orders = [] }) {
  const { t } = useTranslation()
  return (
    <div className="w-full py-4">
      <h6 className="inline-block rounded-t-md border text-gray-600 bg-white font-semibold px-4 py-1">
        Заказы клиента
      </h6>
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table" style={{ overflowX: "auto" }}>
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("order.number")}</StyledTableCell>
              <StyledTableCell>{t("client")}</StyledTableCell>

              <StyledTableCell>{t("date.from.to")}</StyledTableCell>

              {/* <StyledTableCell style={{ width: "9%" }}>
                  {t("date")}, {t("day.count")}
                </StyledTableCell> */}

              <StyledTableCell>{t("tariff")}</StyledTableCell>
              <StyledTableCell>{t("total.price")}</StyledTableCell>
              <StyledTableCell>{t("status")}</StyledTableCell>
              <StyledTableCell>{t("payment.status")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.length ? (
              orders?.map(
                (
                  {
                    id,
                    order_number,
                    client,
                    tariff,
                    created_at,
                    paid_price,
                    total_price,
                    from_time,
                    start_date,
                    day_count,
                    status,
                    to_time,
                    is_paid,
                    is_cost_paid,
                    is_cost_exist,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                    // onClick={() => history.push(`/home/orders/${id}`)}
                  >
                    <StyledTableCell>{order_number}</StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={client.is_blocked ? { color: "#DC2626" } : null}
                      >
                        {client.first_name} {client.last_name}
                      </div>
                      <div className="text-blue-600">
                        {formatPhoneNumber(client.phone_number)}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <>
                        <div>{`${moment(from_time).format("DD/MM")} `}</div>
                        <div>{moment(to_time).format("DD/MM")}</div>
                      </>

                      {/* <>
                          <div>{`${moment(start_date).format(
                            "YYYY-MM-DD"
                          )},`}</div>
                          <div>{`${day_count} дней`}</div>
                        </> */}
                    </StyledTableCell>

                    <StyledTableCell>{tariff.name}</StyledTableCell>
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(total_price)}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        textColor={
                          status.id === "new"
                            ? "text-blue-600"
                            : "text-yellow-600"
                        }
                        bgColor={
                          status.id === "new" ? "bg-blue-100" : "bg-yellow-100"
                        }
                        size="large"
                        style={{ width: "160px" }}
                      >
                        {t(status?.id)}
                      </Tag>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        textColor={
                          is_paid
                            ? is_paid === 1
                              ? "text-green-600"
                              : "text-amber-600"
                            : "text-red-600"
                        }
                        bgColor={
                          is_paid
                            ? is_paid === 1
                              ? "bg-green-100"
                              : "bg-amber-100"
                            : "bg-red-100"
                        }
                        size="large"
                        style={{ width: "160px" }}
                      >
                        {t(
                          is_paid
                            ? is_paid === 1
                              ? "paid"
                              : "partially.paid"
                            : "not.paid"
                        )}
                      </Tag>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ClientsOrders
