export const activeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#4094F7"
    />
    <circle cx="12" cy="12" r="4" fill="white" />
  </svg>
);

export const finishedIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7069 7.29303C18.8944 7.48056 18.9997 7.73487 18.9997 8.00003C18.9997 8.26519 18.8944 8.5195 18.7069 8.70703L10.7069 16.707C10.5194 16.8945 10.2651 16.9998 9.99992 16.9998C9.73475 16.9998 9.48045 16.8945 9.29292 16.707L5.29292 12.707C5.11076 12.5184 5.00997 12.2658 5.01224 12.0036C5.01452 11.7414 5.11969 11.4906 5.3051 11.3052C5.49051 11.1198 5.74132 11.0146 6.00352 11.0124C6.26571 11.0101 6.51832 11.1109 6.70692 11.293L9.99992 14.586L17.2929 7.29303C17.4804 7.10556 17.7348 7.00024 17.9999 7.00024C18.2651 7.00024 18.5194 7.10556 18.7069 7.29303Z"
      fill="#4094F7"
    />
  </svg>
);

export const errorIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.29303 6.29308C6.48056 6.10561 6.73487 6.00029 7.00003 6.00029C7.26519 6.00029 7.5195 6.10561 7.70703 6.29308L12 10.5861L16.293 6.29308C16.3853 6.19757 16.4956 6.12139 16.6176 6.06898C16.7396 6.01657 16.8709 5.98898 17.0036 5.98783C17.1364 5.98668 17.2681 6.01198 17.391 6.06226C17.5139 6.11254 17.6255 6.18679 17.7194 6.28069C17.8133 6.37458 17.8876 6.48623 17.9379 6.60913C17.9881 6.73202 18.0134 6.8637 18.0123 6.99648C18.0111 7.12926 17.9835 7.26048 17.9311 7.38249C17.8787 7.50449 17.8025 7.61483 17.707 7.70708L13.414 12.0001L17.707 16.2931C17.8892 16.4817 17.99 16.7343 17.9877 16.9965C17.9854 17.2587 17.8803 17.5095 17.6948 17.6949C17.5094 17.8803 17.2586 17.9855 16.9964 17.9878C16.7342 17.99 16.4816 17.8892 16.293 17.7071L12 13.4141L7.70703 17.7071C7.51843 17.8892 7.26583 17.99 7.00363 17.9878C6.74143 17.9855 6.49062 17.8803 6.30521 17.6949C6.1198 17.5095 6.01463 17.2587 6.01236 16.9965C6.01008 16.7343 6.11087 16.4817 6.29303 16.2931L10.586 12.0001L6.29303 7.70708C6.10556 7.51955 6.00024 7.26525 6.00024 7.00008C6.00024 6.73492 6.10556 6.48061 6.29303 6.29308Z"
      fill="#F76659"
    />
  </svg>
);

export const activeSmallIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="4" fill="#4094F7" />
    <circle cx="8" cy="8" r="7" stroke="#4094F7" stroke-width="2" />
  </svg>
);

export const errorSmallIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.29303 6.29308C6.48056 6.10561 6.73487 6.00029 7.00003 6.00029C7.26519 6.00029 7.5195 6.10561 7.70703 6.29308L12 10.5861L16.293 6.29308C16.3853 6.19757 16.4956 6.12139 16.6176 6.06898C16.7396 6.01657 16.8709 5.98898 17.0036 5.98783C17.1364 5.98668 17.2681 6.01198 17.391 6.06226C17.5139 6.11254 17.6255 6.18679 17.7194 6.28069C17.8133 6.37458 17.8876 6.48623 17.9379 6.60913C17.9881 6.73202 18.0134 6.8637 18.0123 6.99648C18.0111 7.12926 17.9835 7.26048 17.9311 7.38249C17.8787 7.50449 17.8025 7.61483 17.707 7.70708L13.414 12.0001L17.707 16.2931C17.8892 16.4817 17.99 16.7343 17.9877 16.9965C17.9854 17.2587 17.8803 17.5095 17.6948 17.6949C17.5094 17.8803 17.2586 17.9855 16.9964 17.9878C16.7342 17.99 16.4816 17.8892 16.293 17.7071L12 13.4141L7.70703 17.7071C7.51843 17.8892 7.26583 17.99 7.00363 17.9878C6.74143 17.9855 6.49062 17.8803 6.30521 17.6949C6.1198 17.5095 6.01463 17.2587 6.01236 16.9965C6.01008 16.7343 6.11087 16.4817 6.29303 16.2931L10.586 12.0001L6.29303 7.70708C6.10556 7.51955 6.00024 7.26525 6.00024 7.00008C6.00024 6.73492 6.10556 6.48061 6.29303 6.29308Z"
      fill="#F76659"
    />
  </svg>
);
