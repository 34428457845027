import ClearIcon from "@material-ui/icons/Clear";

export default function Modal(props) {
  const {
    children,
    title = "Modal dialog",
    isOpen = false,
    width = 500,
    onClose = function () {},
    primaryAction = null,
    secondaryAction = null,
    className,
    ...rest
  } = props;

  const btnOutlined = {
    height: "32px",
    width: "66px",
    borderRadius: "6px",
    border: "1px solid #DDE2E4",
    background: "transparent",
  };

  const btnPrimary = {
    height: "32px",
    width: "66px",
    borderRadius: "6px",
    border: "1px solid #4094F7",
    background: "#4094F7",
    color: "#FFF",
  };

  return (
    <div
      style={{ transition: "all .4s", opacity: isOpen ? 1 : 0 }}
      className="transition ease-in-out duration-100"
    >
      {isOpen && (
        <div>
          <div
            onClick={onClose}
            className="modal-overlay bg-gray-900 opacity-50 fixed inset-0 z-30"
          ></div>
          <div
            {...rest}
            className={`${className} flex justify-center items-center mx-auto fixed z-40`}
            style={{
              top: "25%",
              left: "30%",
            }}
          >
            <div
              className="modal-container flex flex-row transition duration-700 ease-in-out pt-4 px-4"
              style={{
                background: "#fff",
                borderRadius: "6px",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",

                width: width,
              }}
            >
              <div className="m-2"></div>
              <div className="flex flex-col text-sm font-semibold m-2 w-full">
                <h5 className="pb-2" style={{ fontSize: "16px" }}>
                  {title}
                </h5>
                <p className="my-1 w-full">{children}</p>
                {children !== "" && (
                  <div className="flex flex-row-reverse mt-2 w-full">
                    {primaryAction && (
                      <button style={btnPrimary} onClick={primaryAction}>
                        Action
                      </button>
                    )}
                    {secondaryAction && (
                      <button className="mx-2" style={btnOutlined}>
                        Action
                      </button>
                    )}
                  </div>
                )}
              </div>
              {children === "" && (
                <div className="flex flex-row text-sm mt-1">
                  {primaryAction && (
                    <button style={btnPrimary} onClick={primaryAction}>
                      Action
                    </button>
                  )}
                  {secondaryAction && (
                    <button className="mx-2" style={btnOutlined}>
                      Action
                    </button>
                  )}
                </div>
              )}
              <div className="cursor-pointer m-2 h-5" onClick={onClose}>
                <ClearIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
