import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import SwipeableViews from "react-swipeable-views"
import { Formik } from "formik"
import moment from "moment"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import validateForm from "../../functions/validateForm"
import Filters from "../../components/Filters"
import Select from "../../components/Select"
import { StyledTabs, StyledTab } from "../../components/StyledTabs"
import { TabPanel } from "../../components/Tab/TabBody"
import Context from "./Context"
import Transactions from "./Transactions"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import RangePicker from "../../components/DatePicker/RangePicker"
import { addIcon } from "../../assets/icons/icons"

function CashboxCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const theme = useTheme()

  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState("")
  const [subCategories, setSubCategories] = useState([])
  const [expenseType, setExpenseType] = useState()
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })
  const [paymentType, setPaymentType] = useState()

  const [initialValues, setInitialValues] = useState({
    name: "",
    account_number: undefined,
    branch: undefined,
  })

  useEffect(() => {
    getBranches()
  }, [branchId])

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
      getSubCategories()
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    let [branchList] = await Promise.all([
      axios.get("/branch?limit=100&offset=0").then((res) => res.data),
    ])

    let branches = branchList?.branches?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))

    setBranches(branches)

    await axios
      .get(`/cashbox/${id}`)
      .then((res) => {
        let data = {
          ...res,

          branch: branchList.branches
            ?.filter((el) => el.id === res.branch_id)
            ?.map((el) => ({ label: el.name, value: el.id }))[0],
        }
        setInitialValues(data)
        setBranchId(data.branch?.value)
      })
      .finally(() => setIsGettingData(false))
  }

  const getBranches = async () => {
    let response = await axios.get("/branch?limit=100&offset=0")

    setBranches(
      response.data.branches?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const getSubCategories = async () => {
    let response = await axios.get(`/billing/subaccount-category`, {
      params: {
        limit: 100,
        offset: 0,
      },
    })

    setSubCategories(
      response?.sub_account_categories?.map(({ id, name }) => ({
        label: t(name),
        value: id,
      }))
    )
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    setBtnDisabled(true)
    if (params.id) {
      axios
        .put(`/cashbox/${params.id}`, {
          ...values,
          branch_id: values.branch.value,
        })
        .then((res) => {
          history.push("/home/billing/cashbox")
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    } else {
      axios
        .post("/cashbox", {
          ...values,
          branch_id: values.branch.value,
        })
        .then((res) => {
          history.push("/home/billing/cashbox")
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    }
  }

  const updateExpenseType = (val) => setExpenseType(val)

  const routes = [
    {
      title: t("cashbox"),
      link: true,
      route: "/home/billing/cashbox",
    },

    {
      title: params.id ? initialValues.name : t("edit"),
    },
  ]

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    branch: validateForm("mixed", t),
  })

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>
  }

  const paymentTypes = [
    {
      label: t("cash"),
      value: "cash",
    },
    {
      label: t("humo"),
      value: "humo",
    },
    {
      label: t("click"),
      value: "click",
    },
    {
      label: t("uzcard"),
      value: "uzcard",
    },
    {
      label: t("visa"),
      value: "visa",
    },
    {
      label: t("payme"),
      value: "payme",
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("cashbox")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={
                  value === 0
                    ? [
                        <CustomButton
                          shape="text"
                          color="text-error-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={cancelIcon}
                          onClick={() => history.push("/home/billing/cashbox")}
                        >
                          {t("cancel")}
                        </CustomButton>,
                        <CustomButton
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                          disabled={btnDisabled}
                        >
                          {params.id ? t("save") : t("create")}
                        </CustomButton>,
                      ]
                    : value === 1
                    ? [
                        <CustomButton
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          shape="text"
                          color="text-secondary"
                          icon={addIcon}
                          onClick={() =>
                            history.push(
                              `/home/billing/cashbox/${params.id}/top-up/create`
                            )
                          }
                        >
                          {t("top-up")}
                        </CustomButton>,
                        // <CustomButton
                        //   textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                        //   shape="text"
                        //   color="text-secondary"
                        //   icon={addIcon}
                        //   onClick={() =>
                        //     history.push(
                        //       `/home/billing/cashbox/${params.id}/transactions/create`
                        //     )
                        //   }
                        // >
                        //   {t("payment_account")}
                        // </CustomButton>,
                      ]
                    : null
                }
              />
              <Filters
                extra={
                  value === 1 && (
                    <div className="flex space-x-2">
                      <Select
                        placeholder={t("payment.type")}
                        options={paymentTypes}
                        value={paymentType}
                        onChange={(val) => setPaymentType(val)}
                        isClearable
                        style={{ width: "150px" }}
                      />
                      <Select
                        placeholder={t("expense.type")}
                        options={subCategories}
                        value={expenseType}
                        onChange={(val) => updateExpenseType(val)}
                        isClearable
                        style={{ width: "200px" }}
                      />
                      <RangePicker
                        showToday={false}
                        showWeekNumber
                        dateInputPlaceholder={[t("from"), t("till")]}
                        showOk={false}
                        showClear
                        placeholder={t("select.a.date")}
                        hideTimePicker
                        onChange={(val) =>
                          setFromToDate({
                            start: moment(val[0]).toISOString() ?? "",
                            end: moment(val[1]).toISOString() ?? "",
                          })
                        }
                        renderFooter={() => <span>extra footer</span>}
                      />
                    </div>
                  )
                }
              >
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{ children: <span className="w-2" /> }}
                >
                  <StyledTab
                    label={tabLabel(t("general.information"))}
                    {...a11yProps(0)}
                    style={{ width: "200px" }}
                  />
                  {params.id && (
                    <StyledTab
                      label={tabLabel(t("transactions"))}
                      {...a11yProps(1)}
                    />
                  )}
                </StyledTabs>
              </Filters>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Context
                    branches={branches}
                    formik={formik}
                    setBranchId={setBranchId}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Transactions
                    expenseType={expenseType}
                    fromToDate={fromToDate}
                    paymentType={paymentType?.value}
                  />
                </TabPanel>
              </SwipeableViews>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default CashboxCreate
