import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import validateForm from "../../functions/validateForm"
import PriceInput from "../../components/Form/input/PriceInput"
import DatePicker from "../../components/DatePicker"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import cashIcon from "../../assets/icons/cash.png"
import clickIcon from "../../assets/icons/click.png"
import humoIcon from "../../assets/icons/humo.png"
import uzcardIcon from "../../assets/icons/uzcard.png"
import visaIcon from "../../assets/icons/visa.png"
import paymeIcon from "../../assets/icons/payme.png"
import Textarea from "../../components/Textarea"
import Breadcrumb from "../../components/Breadcrumb/index"
import CircularProgress from "@material-ui/core/CircularProgress"

const paymentOptions = [
  {
    type: "cash",
    icon: cashIcon,
    amount: 0,
  },
  {
    type: "click",
    icon: clickIcon,
    amount: 0,
  },
  {
    type: "humo",
    icon: humoIcon,
    amount: 0,
  },
  {
    type: "uzcard",
    icon: uzcardIcon,
    amount: 0,
  },
  {
    type: "visa",
    icon: visaIcon,
    amount: 0,
  },
  {
    type: "payme",
    icon: paymeIcon,
    amount: 0,
  },
]

const icons = {
  cash: cashIcon,
  click: clickIcon,
  humo: humoIcon,
  uzcard: uzcardIcon,
  visa: visaIcon,
  payme: paymeIcon,
}

function CashboxTopUpCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [payments, setPayments] = useState(paymentOptions)
  const [selectedPayment, setSelectedPayment] = useState()
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [initialValues, setInitialValues] = useState({
    date: "",
    comment: "",
  })

  useEffect(() => {
    setSelectedPayment({
      type: "cash",
      amount: undefined,
      icon: icons["cash"],
    })
    setPayments((old) => old.slice(1))
  }, [])

  // **** FUNCTIONS ****

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    setBtnDisabled(true)
    const payments = []
    const paymentsObj = {}
    if (selectedPayment.amount) {
      payments.push({
        amount: selectedPayment.amount,
        payment_type: selectedPayment.type,
      })
    }
    if (payments.length) {
      paymentsObj.payments = payments
    }
    axios
      .post("/billing/top-up", {
        ...values,
        ...paymentsObj,
      })
      .then((res) => {
        setLoading(false)
        setBtnDisabled(false)
      })
      .finally(() => {
        history.goBack()
      })
  }

  const updatePaymentType = (val) => {
    setSelectedPayment(val)
    setPayments((prev) => [...prev, selectedPayment])
  }

  const removePayment = (id) => {
    setPayments((old) => old?.filter((el, index) => index !== id))
  }

  const updatePaymentAmount = (input) => {
    setSelectedPayment((prev) => ({
      ...prev,
      amount: +input.replace(/\s+/g, ""),
    }))
  }
  // **** CONSTANTS ****
  const routes = [
    {
      title: t("cashbox"),
      link: true,
      route: "/home/billing/cashbox",
    },
    {
      title: params.id?.slice(0, 8),
      link: true,
      route: `/home/billing/cashbox/${params.id}`,
    },
    {
      title: t("transactions"),
      link: false,
      route: `/home/billing/cashbox/${params.id}/transactions`,
    },
    {
      title: t("create"),
    },
  ]

  const ValidationSchema = Yup.object().shape({
    date: validateForm("mixed", t),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ValidationSchema}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Header
            title={t("transaction")}
            startAdornment={[<Breadcrumb routes={routes} />]}
            endAdornment={[
              <CustomButton
                shape="text"
                color="text-error-600"
                textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                icon={cancelIcon}
                onClick={() => history.goBack()}
              >
                {t("cancel")}
              </CustomButton>,
              <CustomButton
                textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                type="submit"
                shape="text"
                color="text-primary-600"
                icon={
                  loading ? (
                    <CircularProgress color="inherit" size={14} />
                  ) : (
                    saveIcon
                  )
                }
                disabled={btnDisabled}
              >
                {t("make-payment")}
              </CustomButton>,
            ]}
          />

          <div className="p-4 w-full grid grid-cols-5 gap-4 box-border font-body text-sm">
            <Card title={t("general.information")} className="col-span-3">
              {/* Date */}
              <div className="w-full py-2 flex items-baseline">
                <div className="w-1/3">{t("date")}</div>
                <div className="w-2/3">
                  <Form.Item name="date" formik={formik}>
                    <DatePicker
                      placeholder="Выберите дату"
                      style={{ height: "35px" }}
                      value={
                        formik.values.date ? moment(formik.values.date) : null
                      }
                      onChange={(val) => {
                        formik.setFieldValue("date", val)
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              {/* Comment */}
              <div className="w-full py-2 flex items-baseline">
                <p className="w-1/3">{t("comment")}:</p>
                <div className="w-2/3">
                  <Form.Item name="comment" formik={formik}>
                    <Textarea
                      type="text"
                      placeholder={t("leave.comment")}
                      style={{
                        minHeight: "100px",
                      }}
                      {...formik.getFieldProps("comment")}
                      onChange={(val) => {
                        formik.setFieldValue("comment", val.target.value)
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </Card>
            <Card title={t("sum")} className="col-span-3">
              {/* Sub payments */}
              {selectedPayment && (
                <div className="w-full py-2 flex items-center text-gray-700">
                  <div className="flex w-1/3 items-center">
                    <span
                      className="flex justify-center 
                                    items-center border 
                                    rounded-md hover:bg-gray-100
                                    transition ease-linear
                                    cursor-pointer py-2 px-6 mr-4"
                      style={{ width: "85px", height: "40px" }}
                    >
                      <img src={selectedPayment?.icon} alt="" />
                    </span>
                    {t(selectedPayment.type)}
                  </div>
                  <div className="w-2/3">
                    <Form.Item formik={formik}>
                      <PriceInput
                        placeholder={t("enter.the.amount")}
                        value={selectedPayment?.amount || ""}
                        onChange={(e) => updatePaymentAmount(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
              {payments.length !== 0 && (
                <>
                  <hr className="my-4" />
                  <h6 className="font-semibold text-gray-700">
                    {t("choose.payment.type")}
                  </h6>

                  {/* Payment options */}
                  <div className="w-full py-2 flex items-center space-x-2">
                    {payments?.length &&
                      payments?.map((el, index) => (
                        <span
                          key={index}
                          className="flex justify-center 
                              items-center border cursor-pointer
                              rounded-md hover:bg-gray-100
                              transition ease-linear
                              py-2 px-6 mr-2 "
                          style={{ width: "85px", height: "40px" }}
                          onClick={() => {
                            updatePaymentType(el)
                            removePayment(index)
                          }}
                        >
                          <img src={el.icon} alt="" />
                        </span>
                      ))}
                  </div>
                </>
              )}
            </Card>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default CashboxTopUpCreate
