import { useState, useEffect } from "react"
import { useHistory, NavLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import AccountCircle from "@material-ui/icons/AccountCircle"
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import axios from "../../utils/axios"
import Avatar from "../Avatar/Index"
import "./index.scss"
import config from "../../config/defaultSettings"
import menu from "./menu"
import Menu from "../Menu/index"
import { CLEAR_ON_SIGNOUT } from "../../redux/constants"
import Button from "../Buttons"

// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";

const logoutIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 4L13.59 5.41L16.17 8H6V10H16.17L13.59 12.58L15 14L20 9L15 4ZM2 2H10V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H10V16H2V2Z"
      fill="#703581"
    />
  </svg>
)

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}))

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap()

  return <Tooltip placement="right" arrow classes={classes} {...props} />
}

export default function App() {
  // **** USE-HOOKS ****
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.userId)
  const permissions = useSelector((state) => state.auth.permissions)

  const [visible, setVisible] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [userImg, setUserImg] = useState("")

  // const [activeSidebar, setActiveSidebar] = useState(
  //   Array(menu.length).fill(false)
  // )
  const toggleSidebar = () => {
    if (selectedList.length) setVisible((prev) => !prev)
  }

  const [expand, setExpand] = useState(
    Array(menu[menu.length - 1].children.length).fill(false)
  )

  useEffect(() => {
    if (menu.length) {
      menu.forEach((el) => {
        const fatherPathname = el.path.split("/")[2]
        if (history.location.pathname.includes(fatherPathname)) {
          // el.path === history.location.pathname
          if (el.children && el.children.length) {
            setSelectedList(el.children)
            setVisible(true)
          }
        }
      })
    }
  }, [history])

  useEffect(() => {
    axios.get(`/system-user/${userId}`).then((res) => setUserImg(res.photo))
  }, [])

  const logoutHandler = () => {
    dispatch({ type: CLEAR_ON_SIGNOUT })
    localStorage.removeItem("token")
    localStorage.removeItem("refresh-token")
  }

  // **** FUNCTOINS ****
  const linkTo = (item) => {
    if (item.isChild) {
      setVisible(true)
      history.replace(item.path)
      return
    }
    if (item.children && item.children.length) {
      setVisible(true)
      setSelectedList(item.children)
      return
    }
    setSelectedList([])
    setVisible(false)
    return item.path
  }

  // const updateActiveSideBar = (index) => {
  //   setActiveSidebar((prev) =>
  //     prev.map((item, i) => (index === i ? !item : item))
  //   )
  // }

  const updateMenus = (index) => {
    setExpand((prev) => prev.map((item, i) => (index === i ? !item : item)))
  }

  // **** COMPONENTS ****
  const RenderSidebarItems = ({ items }) => {
    useEffect(() => {
      // setVisible(true)
    }, [])

    return (
      <ul
        className="space-y-2 text-sm mt-5 
      dashboard_list font-body rounded-md
      bg-background_2"
      >
        {items.map((el, i) => (
          <li key={el.title}>
            {el.children && permissions.includes(el.permission) ? (
              <span
                className={`
                spanITem flex items-center 
                space-x-3 text-gray-700 
                rounded-md focus:shadow-outline
                ${expand[i] && `bg-background`}
                `}
              >
                <Menu
                  title={el.title}
                  onChange={() => {
                    updateMenus(i)
                  }}
                  isExpanded={expand[i]}
                >
                  {el.children.map((item, index) =>
                    permissions.includes(item.permission) ? (
                      <li key={index}>
                        <NavLink activeClassName="is-active" to={item.path}>
                          <span
                            className={`
                            spanITem flex items-center 
                            text-gray-700 
                            rounded-md 
                            font-medium focus:shadow-outline
                            p-3
                             
                            hover:bg-primary-100
                            transition ease-out
                            duration-500
                            `}
                          >
                            <span>{item.title}</span>
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </Menu>
              </span>
            ) : permissions.includes(el.permission) ? (
              <NavLink
                activeClassName="is-active"
                onClick={() => {
                  linkTo(el)
                  history.location.pathname.includes("loading-chart" ) && toggleSidebar()
                  setExpand(
                    Array(menu[menu.length - 1].children.length).fill(false)
                  )
                }}
                to={el.path}
              >
                <span
                  className={`
                  spanITem flex items-center 
                  text-gray-700 p-3 
                  border
                  border-gray-50
                  border-opacity-0
                  rounded-md
                  hover:bg-primary-100
                  transition ease-out
                  duration-500
                  focus:shadow-outline`}
                >
                  <span>{el.title}</span>
                </span>
              </NavLink>
            ) : (
              ""
            )}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="flex h-full" style={{ height: "100%" }}>
      <div
        className="font-body flex flex-col items-center fixed top-0 bottom-0 left-0 justify-between bg-white"
        style={{ borderRight: "1px solid rgba(229, 233, 235, 0.75)" }}
      >
        <div>
          {/* Project logo */}
          <ul className="px-auto mb-5 mt-2 flex" onClick={toggleSidebar}>
            <Avatar
              size={32}
              src={config.project.logo}
              alt="Logo"
              className="mx-auto"
            />
          </ul>

          <ul className="space-y-2 text-sm items-center">
            {menu.slice(0, menu.length - 1).map((el, index) =>
              permissions.includes(el.permission) ? (
                <li key={el.title}>
                  <NavLink
                    exact={false}
                    activeClassName="is-active-sidebar"
                    onClick={() => {
                      setSelectedList([])
                      linkTo(el)
                    }}
                    to={el.path}
                  >
                    <BootstrapTooltip title={el.title}>
                      <span
                        className={`
                    active-sidebar w-12 h-12 flex 
                    items-center justify-center 
                    text-gray-700 m-1 rounded-md`}
                        // style={{ background: "#703581" }}
                      >
                        <span className="text-gray-600 flex items-center">
                          {el.icon}
                        </span>
                        {/* <span>{el.title}</span> */}
                      </span>
                    </BootstrapTooltip>
                  </NavLink>
                </li>
              ) : (
                ""
              )
            )}
          </ul>
        </div>
        {/* Modal */}
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="responsive-dialog-title"
          maxWidth="md"
          style={{ padding: "100px" }}
        >
          <div className="px-20 py-5">
            <DialogTitle id="alert-dialog-title">
              {t("are.you.sure.you.want.to.leave")}
            </DialogTitle>
            <DialogActions>
              <Button
                shape="outlined"
                style={{ borderRadius: "3px", padding: "0.45rem 1.25rem" }}
                onClick={() => setOpenModal(false)}
              >
                {t("no")}
              </Button>
              <Button
                shape="filled"
                color="bg-primary-600"
                style={{ borderRadius: "3px", padding: "0.5rem 1.25rem" }}
                onClick={() => logoutHandler()}
              >
                {t("yes.leave.detailed")}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        {/* Last item of menu (Settings) */}
        <div className="w-full flex flex-col items-center space-y-5">
          <ul
            className="
          space-y-2 items-end dashboard_list 
          font-body transition hover:text-white 
          ease-in-out transform cursor-pointer
          "
          >
            {permissions.includes(menu[menu.length - 1].permission) && (
              <NavLink
                exact={false}
                activeClassName="is-active-sidebar"
                onClick={() => {
                  linkTo(menu[menu.length - 1])
                }}
                to={menu[menu.length - 1].path}
              >
                <BootstrapTooltip title={menu[menu.length - 1].title}>
                  <span
                    className={`active-sidebar w-12 h-12 flex items-center justify-center text-gray-700 m-1 rounded-md`}
                  >
                    <span className="text-gray-600 flex items-center">
                      {menu[menu.length - 1].icon}
                    </span>
                  </span>
                </BootstrapTooltip>
              </NavLink>
            )}
          </ul>
          <div
            className={`${
              history.location.pathname === "/home/my-profile" &&
              "bg-primary-600"
            } w-10/12 text-center pt-1.5 rounded cursor-pointer`}
            onClick={() => {
              history.push("/home/my-profile")
              setVisible(false)
            }}
          >
            {!userImg ? (
              <AccountCircle
                style={{
                  fontSize: "40px",
                  paddingBottom: "5px",
                  color: `#${
                    history.location.pathname === "/home/my-profile"
                      ? "fff"
                      : "555"
                  }`,
                }}
              />
            ) : (
              <Avatar
                size={32}
                src={`https://cdn.car24.uz/car24/${userImg}`}
                alt="Logo"
              />
            )}
          </div>
          <BootstrapTooltip title="Выйти">
            <div
              onClick={() => setOpenModal(true)}
              className="flex justify-center cursor-pointer py-4"
            >
              {logoutIcon}
            </div>
          </BootstrapTooltip>
        </div>
      </div>
      <div
        className={`h-screen sidebar bg-white w-60 ${
          visible ? "p-1.5" : "inset-0 transform -translate-x-4 overflow-hidden"
        }`}
        style={{
          height: "100vh",
          overflowY: "scroll",
          marginLeft: 56,
          transition: "all 0.3s",
          width: visible ? "" : "0px",
          opacity: visible ? "1" : "0",
        }}
      >
        <div className="flex justify-between items-center w-full px-3">
          <span className="items-center">
            <h3 className="font-bold text-3xl font-poppins tracking-wide text-primary-600">
              {config.project.title.slice(0, 3)}
              <span style={{ color: "#8BC34A" }}>
                {config.project.title.slice(3)}
              </span>
            </h3>
          </span>
          <span
            className="flex items-center mt-1 cursor-pointer"
            onClick={() => toggleSidebar()}
          >
            <svg
              width="20"
              height="14"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.33333 10H10.5C10.9583 10 11.3333 9.625 11.3333 9.16667C11.3333 8.70833 10.9583 8.33333 10.5 8.33333H1.33333C0.875 8.33333 0.5 8.70833 0.5 9.16667C0.5 9.625 0.875 10 1.33333 10ZM1.33333 5.83333H8C8.45833 5.83333 8.83333 5.45833 8.83333 5C8.83333 4.54167 8.45833 4.16667 8 4.16667H1.33333C0.875 4.16667 0.5 4.54167 0.5 5C0.5 5.45833 0.875 5.83333 1.33333 5.83333ZM0.5 0.833333C0.5 1.29167 0.875 1.66667 1.33333 1.66667H10.5C10.9583 1.66667 11.3333 1.29167 11.3333 0.833333C11.3333 0.375 10.9583 0 10.5 0H1.33333C0.875 0 0.5 0.375 0.5 0.833333ZM14.9167 7.4L12.5167 5L14.9167 2.6C15.2417 2.275 15.2417 1.75 14.9167 1.425C14.5917 1.1 14.0667 1.1 13.7417 1.425L10.75 4.41667C10.425 4.74167 10.425 5.26667 10.75 5.59167L13.7417 8.58333C14.0667 8.90833 14.5917 8.90833 14.9167 8.58333C15.2333 8.25833 15.2417 7.725 14.9167 7.4Z"
                fill="#5B77A0"
              />
            </svg>
          </span>
        </div>
        <RenderSidebarItems items={selectedList} />
      </div>
    </div>
  )
}
