import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

import axios from "../../../utils/axios";
import formatPhoneNumber from "../../../functions/formatPhoneNumber";
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency";
import Tag from "../../../components/Tag";
import { StyledTableCell } from "../../../components/StyledTableCell";
import Pagination from "../../../components/Pagination";
import EmptyTable from "../../../components/EmptyTable";

const borderStyle = {
  new: "border-blue-600",
  client_took: "border-amber-600",
  client_returned: "border-yellow-600",
  completed: "border-green-600",
  cancelled: "border-red-600",
};

const textStyle = {
  new: "text-blue-600",
  client_took: "text-amber-600",
  client_returned: "text-yellow-600",
  completed: "text-green-600",
  cancelled: "text-red-600",
};

function OrdersHistory() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const permissions = useSelector((state) => state.auth.permissions);
  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(pageCount);

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getItems(params.id);
  }, []);

  const getItems = async (investorId) => {
    let response = await axios.get(`/order`, {
      params: {
        limit: pageCount,
        offset: pageLimit - pageCount,
        investor_id: investorId,
      },
    });
    console.log(response);
    setItems(response);
    setIsLoading(false);
  };

  return (
    <div>
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("order.number")}</StyledTableCell>
              <StyledTableCell>{t("client")}</StyledTableCell>
              <StyledTableCell>{t("date.from.to")}</StyledTableCell>
              <StyledTableCell>{t("tariff")}</StyledTableCell>
              <StyledTableCell>{t("total.price")}</StyledTableCell>
              <StyledTableCell>{t("status")}</StyledTableCell>
              <StyledTableCell>{t("payment.status")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.data && items.data?.orders?.length ? (
              items.data.orders?.map(
                (
                  {
                    id,
                    order_number,
                    client,
                    tariff,
                    created_at,
                    total_price,
                    from_time,
                    to_time,
                    is_paid,
                    status,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                    onClick={() => {
                      if (permissions.includes("orders_edit"))
                        return history.push(`/home/orders/${id}`);
                    }}
                  >
                    <StyledTableCell>{order_number}</StyledTableCell>
                    <StyledTableCell>
                      <div>
                        {client.first_name} {client.last_name}
                      </div>
                      <div className="text-blue-600">
                        {formatPhoneNumber(client.phone_number)}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div>{`${moment(from_time).format("DD/MM")} `}</div>
                      <div>{moment(to_time).format("DD/MM")}</div>
                    </StyledTableCell>

                    <StyledTableCell>{tariff.name}</StyledTableCell>
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(total_price)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag
                        shape="outlined"
                        size="large"
                        borderColor={borderStyle[status.id]}
                        textColor={textStyle[status.id]}
                      >
                        {status.name}
                      </Tag>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        textColor={
                          is_paid
                            ? is_paid === 1
                              ? "text-green-600"
                              : "text-amber-600"
                            : "text-red-600"
                        }
                        bgColor={
                          is_paid
                            ? is_paid === 1
                              ? "bg-green-100"
                              : "bg-amber-100"
                            : "bg-red-100"
                        }
                        size="large"
                        style={{ width: "160px" }}
                      >
                        {t(
                          is_paid
                            ? is_paid === 1
                              ? "paid"
                              : "partially.paid"
                            : "not.paid"
                        )}
                      </Tag>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable
        message="Нет заказов"
        isActive={!isLoading && !items.data?.orders?.length > 0}
      />
      {isLoading && (
        <div className="flex justify-center py-2">
          <CircularProgress />
        </div>
      )}
      <Pagination
        className="mt-5"
        count={items.data?.count ? items.data?.count : 1}
        pageCount={pageCount}
        onChange={(val) => {
          setPageLimit(val);
        }}
      />
    </div>
  );
}

export default OrdersHistory;
