import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import { useHistory, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "../../components/Card/index"
import DatePicker from "../../components/DatePicker"
import moment from "moment"

import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"

function InsuranceCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [date, setDate] = useState({ from_time: "", to_time: "" })
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    if (params.insurance_id) {
      getProperty(params.insurance_id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/insurance/${id}`)
      .then((res) => {
        let data = {
          ...res,
        }
        setInitialValues(data)
        setDate({ from_time: res.from_time, to_time: res.to_time })
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.insurance_id) {
      axios
        .put(`/insurance/${params.insurance_id}`, {
          ...values,
          car_id: `${params.id}`,
          from_time: date.from_time,
          to_time: date.to_time,
        })
        .then((res) => {
          console.log(res)
          history.push(`/home/cars/${params.id}`)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/insurance", {
          ...values,
          car_id: `${params.id}`,
          from_time: date.from_time,
          to_time: date.to_time,
        })
        .then((res) => {
          console.log(res)
          console.log(values, params.id)
          history.push(`/home/cars/${params.id}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    // status: Yup.bool().required(t("required.field.error")),
  })

  const routes = [
    {
      title: t("cars"),
      link: true,
      route: "/home/cars",
    },
    {
      title: t("car.history"),
      link: true,
      route: `/home/cars/${params.id}`,
    },
    {
      title: params.insurance_id ? t("edit") : t("create"),
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("insurance")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    size="large"
                    shape="text"
                    color="text-secondary"
                    onClick={() => history.push(`/home/cars/${params.id}`)}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    size="large"
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        <></>
                      )
                    }
                  >
                    {params.insurance_id ? t("edit") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")}>
                  {/* From time */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("from")}</div>
                    <div className="w-2/3">
                      <Form.Item formik={formik}>
                        <DatePicker
                          style={{ height: "35px" }}
                          onChange={(val) =>
                            setDate((old) => {
                              return {
                                ...old,
                                from_time: moment(val).toISOString(),
                              }
                            })
                          }
                          value={date.from_time ? moment(date.from_time) : null}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* To time */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("till")}</div>
                    <div className="w-2/3">
                      <Form.Item formik={formik}>
                        <DatePicker
                          style={{ height: "35px" }}
                          onChange={(val) =>
                            setDate((old) => {
                              return {
                                ...old,
                                to_time: moment(val).toISOString(),
                              }
                            })
                          }
                          value={date.to_time ? moment(date.to_time) : null}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default InsuranceCreate
