import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import Card from "../../components/Card/index"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import Select from "../../components/Select/index"
import Switch from "../../components/Switch"
import ImageUpload from "../../components/Upload/ImgUpload"
import AddIcon from "@material-ui/icons/Add"
import { useSelector } from "react-redux"

function Context(props) {
  const { setImage, image, formik, branches, cashboxes } = props

  const { t } = useTranslation()
  const params = useParams()
  const [isHidden, setIsHidden] = useState(true)

  const permissions = useSelector((state) => state.auth.permissions)

  return (
    <div className="w-full grid grid-cols-3 gap-4 box-border font-body text-sm">
      <Card className="col-span-1">
        <div className="flex flex-col space-y-6 items-center">
          <ImageUpload
            onChange={(val) => setImage(val.filename)}
            defaultImage={image}
            style={{ width: "165px" }}
            shape="rounded"
          >
            <div className="flex flex-col items-center justify-center space-y-4">
              <AddIcon style={{ fontSize: "200%" }} />
              <h5>{t("select.image")}</h5>
            </div>
          </ImageUpload>
          {/* Name */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">{t("first.name")}</div>
            <div>
              <Form.Item name="full_name" formik={formik}>
                <Input
                  id="full_name"
                  type="text"
                  maxLength="30"
                  {...formik.getFieldProps("full_name")}
                />
              </Form.Item>
            </div>
          </div>
          {/* Phone */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">{t("phone")}</div>
            <div>
              <Form.Item name="phone_number" formik={formik}>
                <Input
                  id="phone_number"
                  type="text"
                  {...formik.getFieldProps("phone_number")}
                  onChange={(e) => {
                    formik.setFieldValue("phone_number", e.target.value)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Branch */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">{t("branch")}</div>
            <div>
              <Form.Item name="branch" formik={formik}>
                <Select
                  id="branch"
                  options={branches}
                  {...formik.getFieldProps("branch")}
                  onChange={(val) => {
                    formik.setFieldValue("branch", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Cashbox */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">{t("cashbox")}</div>
            <div>
              <Form.Item name="cashbox" formik={formik}>
                <Select
                  id="cashbox"
                  options={cashboxes}
                  {...formik.getFieldProps("cashbox")}
                  onChange={(val) => {
                    formik.setFieldValue("cashbox", val)
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {/* Login */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">{t("username")}</div>
            <div>
              <Form.Item name="login" formik={formik}>
                <Input
                  id="login"
                  type="text"
                  maxLength="20"
                  {...formik.getFieldProps("login")}
                  disabled={
                    !window.location.href.includes("/roles/cashiers/create") &&
                    (!params.id || !permissions.includes("сменить пароль"))
                  }
                />
              </Form.Item>
            </div>
          </div>

          {/* Password */}
          {(window.location.href.includes("/roles/cashiers/create") ||
            (params.id && permissions.includes("сменить пароль"))) && (
            <div className="flex flex-col w-full space-y-1">
              <div className="text-gray-600">
                {t(
                  window.location.href.includes("/roles/cashiers/create")
                    ? "password"
                    : "password.change"
                )}
              </div>
              <div className="flex space-x-2 items-center">
                <div className="w-full">
                  <Form.Item name="password" formik={formik}>
                    <Input
                      id="password"
                      type={isHidden ? "password" : "text"}
                      maxLength="30"
                      {...formik.getFieldProps("password")}
                    />
                  </Form.Item>
                </div>

                <div
                  className="text-gray-500 cursor-pointer"
                  onClick={() => setIsHidden((prev) => !prev)}
                >
                  {!isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </div>
              </div>
            </div>
          )}

          {/* Status */}
          <div className="flex flex-col w-full space-y-1">
            <div className="text-gray-600">{t("status")}</div>
            <div className="flex w-2/3 space-x-4">
              <div>
                <Form.Item name="status" formik={formik}>
                  <Switch
                    id="status"
                    color="primary"
                    checked={formik.values.status}
                    onChange={(val) => formik.setFieldValue("status", val)}
                  />
                </Form.Item>
              </div>
              <h6 className="text-gray-600">
                {formik.values.status ? "Активный" : "Неактивный"}
              </h6>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Context
