import React from "react";

// export default class Card extends React.Component {
//   render() {
//     return (
//       <CardContent {...this.props} />
//     )
//   }
// }

export default function CardContent({
  title,
  children,
  extra,
  className,
  ...args
}) {
  return (
    <div className={`${className} rounded bg-white`} {...args}>
      {title || extra ? (
        <div className="px-6 py-2.5 text-gray-600 border-b flex justify-between">
          <div className="font-semibold">{title}</div>
          <div>{extra}</div>
        </div>
      ) : (
        <></>
      )}
      <div className="p-6">{children}</div>
    </div>
  );
}
