import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import AddIcon from "@material-ui/icons/Add"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import Textarea from "../../components/Textarea/index"
import Switch from "../../components/Switch"
import ImageUpload from "../../components/Upload/ImgUpload"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import { useDispatch } from "react-redux"
import { showAlert } from "../../redux/reducers/alertReducer"
import downloadFile from "../../functions/downloadFile"
import Filters from "../../components/Filters"
import parseQuery from "../../functions/parseQuery"
import Select from "../../components/Select/index"

var types = [
  { value: "give", label: "Вручение" },
  { value: "recieve", label: "Получение" },
  { value: "both", label: "Оба" },
]

var links = {
  dictionary: "cdn.car24.uz/car24/dictionary.docx",
  additionalContract: "cdn.car24.uz/car24/additional_contract.docx",
  contract: "cdn.car24.uz/car24/contract.docx",
  letterOfAuthority: "cdn.car24.uz/car24/letter_of_authority.docx",
  letterOfAuthorityFirm: "cdn.car24.uz/car24/letter_of_authorithy_firm.docx",
}

var linksMap = new Map()
linksMap.set("4a02510e-789f-4d9a-8780-ded0c249bbdf", links.additionalContract)
linksMap.set("1f47158f-ba28-43a2-9640-161772fd2903", links.contract)
linksMap.set("c334f4cc-5ba0-42e7-a0aa-02e3e56b0172", links.letterOfAuthority)
linksMap.set(
  "4fa309dc-90b2-42a0-9104-a3345596060a",
  links.letterOfAuthorityFirm
)

function DocumentsCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const { tab } = parseQuery()

  const [isGettingDictionary, setIsGettingDictionary] = useState(false)
  const [isGettingDoc, setIsGettingDoc] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [image, setImage] = useState("")
  const [initialValues, setInitialValues] = useState({
    //   general info
    title: "",
    description: "",
    type: "",
    status: 1,
  })
  const [fileUrl, setFileUrl] = useState()

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [params.id])

  // **** FUNCTIONS ****
  const getProperty = (id) => {
    setIsGettingData(true)
    axios
      .get(`/document/${id}`)
      .then((res) => {
        setInitialValues({
          ...res,
          type: types.filter((el) => el.value === res.type)
            ? types.filter((el) => el.value === res.type)[0]
            : "",
        })
        setImage(res.url)
      })
      .finally(() => setIsGettingData(false))
  }

  const generateDoc = (url, which) => {
    which === "dict" ? setIsGettingDictionary(true) : setIsGettingDoc(true)
    downloadFile(url)
    which === "dict" ? setIsGettingDictionary(false) : setIsGettingDoc(false)
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    var typeObj = {}
    if (values?.type) typeObj.type = values?.type.value
    if (params.id) {
      axios
        .put(`/document/${params.id}`, {
          ...values,
          ...typeObj,
          status: values.status ? 1 : 0,
          photo: image,
          url: fileUrl ? `https://${fileUrl}` : values.url,
        })
        .then((res) => {
          history.push("/home/settings/documents?tab=" + tab)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/document", {
          ...values,
          ...typeObj,
          status: values.status ? 1 : 0,
          photo: image,
          url: fileUrl ? `https://${fileUrl}` : values.url,
        })
        .then((res) => {
          history.push("/home/settings/documents?tab=" + tab)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    title: validateForm("default", t),
    type: +tab && validateForm("mixed", t),
  })

  const routes = [
    {
      title: t("documents"),
      link: true,
      route: "/home/settings/documents?tab=" + tab,
    },

    {
      title: params.id ? initialValues.title : t("create"),
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("document")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/documents?tab=" + tab)
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />
              <Filters
                extra={
                  params.id &&
                  !+tab && (
                    <div className="flex space-x-2">
                      <CustomButton
                        className="border-2 border-primary-600"
                        color="text-primary-600"
                        loading={isGettingDictionary}
                        textStyle={{ fontWeight: "500", padding: "0.15rem 0" }}
                        onClick={() => generateDoc(links.dictionary, "dict")}
                      >
                        {t(`Словарь`)}
                      </CustomButton>
                      <CustomButton
                        className="border-2 border-primary-600"
                        color="text-primary-600"
                        loading={isGettingDoc}
                        textStyle={{ fontWeight: "500", padding: "0.15rem 0" }}
                        onClick={() =>
                          generateDoc(linksMap.get(params.id), "doc")
                        }
                      >
                        {t(`Образец документа`)}
                      </CustomButton>
                    </div>
                  )
                }
              ></Filters>

              <div className="p-4 w-full grid grid-cols-4 gap-4 box-border font-body text-sm">
                <Card title={t("document.cover")} className="col-span-1">
                  {/* Photo */}
                  <div className="w-full pb-2 flex items-center justify-center">
                    <ImageUpload
                      onChange={(val) => setImage(val.filename)}
                      onStart={(val) => console.log(val)}
                      onFinish={(val) => console.log(val)}
                      onSuccess={(val) => setFileUrl(val.file_url)}
                      onError={(val) => console.log(val)}
                      acceptImgs={
                        +tab ? ["png", "jpg", "jpeg"] : ["doc", "docx"]
                      }
                      defaultImage={
                        image
                          ? image.substring(
                              "https://cdn.car24.uz/car24/".length
                            )
                          : ""
                      }
                      documentsPage
                      tab={tab}
                      dispatchError={() => {
                        dispatch(showAlert("only.word.pdf", "error"))
                      }}
                    >
                      <div className="flex flex-col mx-10 items-center justify-center space-y-4">
                        <AddIcon style={{ fontSize: "200%" }} />
                        <h5>{+tab ? t("select.image") : t("select.file")}</h5>
                      </div>
                    </ImageUpload>
                  </div>

                  {/* Is Multiple */}
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("status")}</div>
                    <div className="w-2/3">
                      <Form.Item name="status" formik={formik}>
                        <Switch
                          id="status"
                          color="primary"
                          checked={formik.values.status}
                          onChange={(val) =>
                            formik.setFieldValue("status", val)
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
                <Card title={t("document.content")} className="col-span-3">
                  {/* Name */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("title")}</div>
                    <div className="w-2/3">
                      <Form.Item name="title" formik={formik}>
                        <Input
                          id="title"
                          type="text"
                          {...formik.getFieldProps("title")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Document */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("description")}</div>
                    <div className="w-2/3">
                      <Form.Item name="description" formik={formik}>
                        <Textarea
                          id="description"
                          type="text"
                          style={{ minHeight: "150px" }}
                          {...formik.getFieldProps("description")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Type */}
                  {+tab ? (
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("type")}</div>
                      <div className="w-2/3">
                        <Form.Item name="type" formik={formik}>
                          <Select
                            options={types}
                            {...formik.getFieldProps("type")}
                            onChange={(val) => {
                              formik.setFieldValue("type", val)
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default DocumentsCreate
