import React from "react";

function EmptyImage({ title }) {
  return (
    <div
      className="
            flex items-center justify-center 
            h-28 w-28 
            border-2 border-dashed 
            rounded
            text-gray-600 text-xs px-2
            "
    >
      {title}
    </div>
  );
}

export default EmptyImage;
