const downloadFile = (url, filename) => {
  console.log(url)
  let element = document.createElement("a")
  element.setAttribute("href", `https://${url}`)
  element.setAttribute("download", filename)
  document.body.appendChild(element)
  element.click()
}

export async function downloadImage(imageSrc, filename) {
  const image = await fetch(imageSrc)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement("a")
  link.href = imageURL
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export default downloadFile
