import moment from "moment"

const differenceDate = (dateString) => {
  var createdDate = moment(dateString)
  var currentDate = moment()
  if (!dateString) return false
  return currentDate.diff(createdDate, "minutes") > 360
}

export default differenceDate
