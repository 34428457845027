import { useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import moment from "moment"
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import * as Yup from "yup"
import { Formik } from "formik"
import EmptyTable from "../../components/EmptyTable"
import axios from "../../utils/axios"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import PhoneInput from "../../components/Form/input/PhoneInput"
import AsyncSelect from "../../components/Select/AsyncSelect"
import DatePicker from "../../components/DatePicker/DisabledDatePicker"
import Tag from "../../components/Tag"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import AsyncCreatableSelect from "../../components/Select/CreatableSelect"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import PriceDisplay from "../../components/PriceDisplay"
import { showAlert } from "../../redux/reducers/alertReducer"
import Button from "../../components/Buttons"
import validateForm from "../../functions/validateForm"
import ClientsOrders from "./ClientsOrders"
import { useHistory } from "react-router-dom"
import Switch from "../../components/Switch"
import Select from "../../components/Select/index"
import { useLocation } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "7 !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "6px",
    padding: theme.spacing(2, 4, 3),
  },
}))

function Context(props) {
  const {
    formik,
    tariffs,
    additionalTariffs,
    cars,
    client,
    clients,
    clientOrders = undefined,
    listData,
    initiatorData,
    setTariffId,
    loadCarOptions = () => {},
    updateAdditionalTariffs = () => {},
    loadTariffOptions = () => {},
    loadAdditionalTariffOptions = () => {},
    loadSalesFunnelOptions = () => {},
    salesFunnelOptions,
    getCars = () => {},
    getClientInfo = () => {},
    getClientOrders = () => {},
    actualProlongDay,
    tariffCarMap,
    updateTariff,
    formInitialValues,
    setFormInitialValues,
    insurance,
    setInsurance,
    // branches,
    defaultBranch,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()
  const classes = useStyles()
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    phone_number: "",
    passport_pinfl: "",
  })
  const onCreateOrders = window.location.href.includes("orders/create")
    ? true
    : false
  const [showComments, setShowComments] = useState(
    onCreateOrders ? false : true
  )

  const commentRef = useRef()
  const commentListRef = useRef()

  const history = useHistory()

  // ******FUNCTIONS***********

  const getTotalPrice = (formik, listData, hasDiscount = false) => {
    if (insurance) {
      return convertToInternationalCurrencySystem(
        (listData.additional_tariffs?.reduce(
          (total, addTariff) =>
            total + +addTariff.price * formik.values?.day_count,
          0
        ) || 0) +
          (+formik.values.tariff?.price +
            +formik.values.tariff?.insurance_price) *
            formik.values?.day_count -
          (hasDiscount && formik.values?.discount * formik.values?.day_count) ||
          0
      )
    } else {
      return convertToInternationalCurrencySystem(
        (listData.additional_tariffs?.reduce(
          (total, addTariff) =>
            total + +addTariff.price * formik.values?.day_count,
          0
        ) || 0) +
          +formik.values.tariff?.price * formik.values?.day_count -
          (hasDiscount && formik.values?.discount * formik.values?.day_count) ||
          0
      )
    }
  }
  const filterClients = async (inputValue) => {
    let res = await axios.get(
      `/client?limit=5&offset=0&search=${inputValue.replace(/ +/g, "")}`
    )
    return res.data?.clients?.map(({ id, phone_number, is_blocked }) => ({
      value: id,
      label: formatPhoneNumber(phone_number),
      isBlocked: is_blocked,
    }))
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterClients(inputValue))
    })

  const onSubmit = (values) => {
    axios
      .post("/client", {
        ...values,
        phone_number:
          values.phone_number[0] !== "+"
            ? `+${values.phone_number}`
            : values.phone_number,
      })
      .then((res) => {
        formik.setFieldValue("client", {
          label: formatPhoneNumber(phoneNumber),
          value: res.message.id,
        })
        dispatch(showAlert("Клиент успешно создан", "success"))
        window.location.reload()
      })
      .catch((err) => {
        dispatch(showAlert(t(err.data?.error), "error"))
      })
      .finally(() => closeModal())
  }

  const handleModal = (input, formik) => {
    setPhoneNumber(input)
    setInitialValues((prev) => ({
      ...prev,
      phone_number: input.replace(/\D/g, ""),
    }))
    setIsOpen((prev) => !prev)
  }

  const closeModal = () => setIsOpen(false)

  const sendComment = (e) => {
    e.preventDefault()
    const userId = localStorage.getItem("userId")
    if (userId) {
      axios.get(`/system-user/${userId}`).then((res) => {
        const comment = {
          commentator_id: userId,
          client_id: client.id,
          commentator_name: res?.full_name,
          created_at: "",
          message: commentRef.current.value,
          order_id: params.id,
        }
        axios
          .post("/client/comment", comment)
          .then((response) => {
            getClientInfo(client.id)
            commentRef.current.value = ""
            commentListRef.current.scrollTo(
              0,
              commentListRef.current.scrollHeight
            )
          })
          .catch((err) => console.log(err))
      })
    }
  }

  const commentClickHandler = (e, orderId) => {
    history.push(`/home/orders/${orderId}`)
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    first_name: validateForm("first_name", t),
    phone_number: validateForm("phone_number", t),
    passport_pinfl: validateForm("passport_pinfl", t),
  })
  const userId = localStorage.getItem("userId")

  const branch = { label: "", value: "" }
  const getBranch = async () => {
    const branchId = localStorage.getItem("branchId")

    axios
      .get(`/branch/${branchId}`)
      .then((res) => {
        branch.label = res.name
        branch.value = res.id
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getBranch()
  }, [])

  useEffect(() => {
    if (updateTariff) {
      formik.setFieldValue("tariff", updateTariff)
    }
    if (location.pathname.includes("/home/orders/create")) {
      axios.get(`/system-user/${userId}`).then((res) =>
        formik.setFieldValue("branch", {
          label: res.branch.name,
          value: res.branch.id,
        })
      )
    }
  }, [updateTariff, defaultBranch, location.pathname, userId])
  return (
    <>
      <div className="w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
        <div className="">
          <Card title={t("general.information")}>
            {/* Status */}
            {params.id && (
              <div className="w-full py-2 flex items-center">
                <div className="w-1/3">{t("status")}</div>
                <div className="flex space-x-10">
                  <div className="w-2/3">
                    <Form.Item name="status" formik={formik}>
                      <Tag
                        shape="subtle"
                        size="large"
                        style={{ width: "150px" }}
                      >
                        {t(formik.values.status.value)}
                      </Tag>
                    </Form.Item>
                  </div>
                  <div>
                    {
                      <Tag
                        shape="subtle"
                        textColor={
                          formik.values.is_paid
                            ? formik.values.is_paid === 1
                              ? "text-green-600"
                              : "text-amber-600"
                            : "text-red-600"
                        }
                        bgColor={
                          formik.values.is_paid
                            ? formik.values.is_paid === 1
                              ? "bg-green-100"
                              : "bg-amber-100"
                            : "bg-red-100"
                        }
                        size="large"
                        style={{ width: "160px" }}
                      >
                        {t(
                          formik.values.is_paid
                            ? formik.values.is_paid === 1
                              ? "paid"
                              : "partially.paid"
                            : "not.paid"
                        )}
                      </Tag>
                    }
                  </div>
                </div>
              </div>
            )}
            {/* Creator Info */}
            {params.id && (
              <div className="w-full py-2 flex ">
                <div className="w-1/3">{t("creator")}</div>
                <div className="bg-background_2 border rounded w-2/3 p-2">
                  <div className="flex w-full">
                    <p className="w-1/2 text-gray-700 font-semibold">
                      {t("type")}
                    </p>
                    <p className="text-gray-600">
                      {t(initiatorData?.type).toLowerCase()}
                    </p>
                  </div>
                  <div className="flex w-full">
                    <p className="w-1/2 text-gray-700 font-semibold">
                      {t("first.name")}
                    </p>
                    <p className="text-gray-600">
                      {initiatorData?.name || t("no.name")}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* Tariff */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">&#42; {t("tariff")}</div>
              <div className="w-2/3">
                <Form.Item name="tariff" formik={formik}>
                  <AsyncSelect
                    isDisabled={
                      formik.values.status.value !== "new" && params.id
                    }
                    loadOptions={loadTariffOptions}
                    defaultOptions={tariffs}
                    placeholder=""
                    {...formik.getFieldProps("tariff")}
                    onChange={(val) => {
                      formik.setFieldValue("tariff", val)
                      setTariffId(val.value)
                      getCars(val.value)
                      formik.setFieldValue("car", "")
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Additional tariffs */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">{t("additional.tariffs")}</div>
              <div className="w-2/3">
                <Form.Item name="additional_tariffs" formik={formik}>
                  <AsyncSelect
                    isDisabled={
                      formik.values.status.value !== "new" && params.id
                    }
                    defaultOptions={additionalTariffs}
                    loadOptions={loadAdditionalTariffOptions}
                    onChange={(val) => {
                      updateAdditionalTariffs(val)
                      formik.setFieldValue("additional_tariffs", val)
                    }}
                    value={listData.additional_tariffs}
                    isMulti
                    hideSelectedOptions
                    closeMenuOnSelect={false}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Car */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">{t("car.number")}</div>
              <div className="w-2/3">
                <Form.Item name="car" formik={formik}>
                  <AsyncSelect
                    isDisabled={
                      formik.values.status.value !== "new" && params.id
                    }
                    loadOptions={loadCarOptions}
                    defaultOptions={cars}
                    placeholder=""
                    {...formik.getFieldProps("car")}
                    onChange={(val) => {
                      formik.setFieldValue("car", val)
                      !formik.values.tariff &&
                        formik.setFieldValue(
                          "tariff",
                          tariffCarMap.get(val.label)
                        )
                      tariffCarMap.get(val.label) &&
                        setTariffId(tariffCarMap.get(val.label).value)
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Client */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">&#42; {t("client")}</div>
              <div className="w-2/3">
                <Form.Item name="client" formik={formik}>
                  <AsyncCreatableSelect
                    isDisabled={
                      formik.values.status.value !== "new" && params.id
                    }
                    loadOptions={promiseOptions}
                    defaultOptions={clients}
                    placeholder=""
                    {...formik.getFieldProps("client")}
                    onChange={(val) => {
                      formik.setFieldValue("client", val)
                      getClientInfo(val.value)
                      getClientOrders(val.value)
                      onCreateOrders && setShowComments(true)
                    }}
                    // styles={customStyles}
                    onCreateOption={(val) => handleModal(val, formik)}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Client Info */}
            {client && (
              <div className="w-full py-2 flex ">
                <div className="w-1/3">{t("about.client")}</div>
                <div className="bg-background_2 border rounded w-2/3 p-2">
                  <div className="flex w-full">
                    <p className="w-1/2 text-gray-700 font-semibold">
                      {t("first.name")}
                    </p>
                    <p
                      className="text-gray-600"
                      style={client.is_blocked ? { color: "#DC2626" } : null}
                    >
                      {client?.first_name}
                    </p>
                  </div>
                  <div className="flex w-full">
                    <p className="w-1/2 text-gray-700 font-semibold">
                      {t("last.name")}
                    </p>
                    <p
                      className="text-gray-600"
                      style={client.is_blocked ? { color: "#DC2626" } : null}
                    >
                      {client?.last_name}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* Date */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">&#42; {t("date")}</div>
              <div className="w-2/3">
                <Form.Item name="start_date" formik={formik}>
                  <DatePicker
                    disabled={formik.values.status.value !== "new" && params.id}
                    placeholder={t("select.a.date")}
                    style={{ height: "35px" }}
                    value={
                      formik.values.start_date
                        ? moment(formik.values.start_date)
                        : null
                    }
                    onChange={(val) => {
                      formik.setFieldValue("start_date", val)
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Day count */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">&#42; {t("day.count")}</div>
              <div className="w-2/3">
                <Form.Item name="day_count" formik={formik}>
                  <Input
                    min="1"
                    type="number"
                    onKeyPress={(evt) => {
                      const charCodes = [65, 66, 67]
                      if (
                        evt.key.charCodeAt() < 48 ||
                        (evt.key.charCodeAt() > 57 &&
                          !charCodes.includes(evt.key.charCodeAt()))
                      )
                        evt.preventDefault()
                    }}
                    disabled={formik.values.status.value !== "new" && params.id}
                    {...formik.getFieldProps("day_count")}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Prolong day - actual */}
            {formik.values.status.value === "client_took" && (
              <div className="w-full py-2 flex items-baseline">
                <div className="w-1/3">{t("day.prolong")}</div>
                <div className="w-2/3">
                  <Form.Item name="actual_prolong_day" formik={formik}>
                    <Input
                      type="number"
                      onKeyPress={(evt) => {
                        const charCodes = [65, 66, 67]
                        if (
                          evt.key.charCodeAt() < 48 ||
                          (evt.key.charCodeAt() > 57 &&
                            !charCodes.includes(evt.key.charCodeAt()))
                        )
                          evt.preventDefault()
                      }}
                      {...formik.getFieldProps("actual_prolong_day")}
                      disabled={
                        moment(
                          formik?.values?.from_time +
                            (formik?.values?.day_count -
                              formik?.values?.actual_prolong_day)
                        ).isAfter(moment()) ||
                        formInitialValues.actual_prolong_day > 0
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            {/* Prolong day - estimated */}
            {(formik.values.status.value === "new" ||
              formik.values.status.value === "car_selected") && (
              <div className="w-full py-2 flex items-baseline">
                <div className="w-1/3">{t("day.prolong")}</div>
                <div className="w-2/3">
                  <Form.Item name="estimated_prolong_day" formik={formik}>
                    <Input
                      type="number"
                      onKeyPress={(evt) => {
                        const charCodes = [65, 66, 67]
                        if (
                          evt.key.charCodeAt() < 48 ||
                          (evt.key.charCodeAt() > 57 &&
                            !charCodes.includes(evt.key.charCodeAt()))
                        )
                          evt.preventDefault()
                      }}
                      {...formik.getFieldProps("estimated_prolong_day")}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            {/* Discount */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">{t("discount")}</div>
              <div className="w-2/3">
                <Form.Item name="discount" formik={formik}>
                  <Input
                    type="number"
                    onKeyPress={(evt) => {
                      const charCodes = [65, 66, 67]
                      if (
                        evt.key.charCodeAt() < 48 ||
                        (evt.key.charCodeAt() > 57 &&
                          !charCodes.includes(evt.key.charCodeAt()))
                      )
                        evt.preventDefault()
                    }}
                    disabled={
                      params.id &&
                      (formik.values.status.value === "client_returned" ||
                        formik.values.status.value === "completed" ||
                        formik.values.status.value === "cancelled" ||
                        moment(
                          formik?.values?.from_time +
                            (formik?.values?.day_count -
                              formik?.values?.actual_prolong_day)
                        ).isAfter(moment()) ||
                        formInitialValues.actual_prolong_day > 0)
                        ? true
                        : formik.values.status.value !== "new" &&
                          params.id &&
                          !formik.values.actual_prolong_day
                    }
                    {...formik.getFieldProps("discount")}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Sales Funnel */}
            <div className="w-full py-2 flex items-baseline">
              <div className="w-1/3">{t("sales.funnel")}</div>
              <div className="w-2/3">
                <Form.Item name="sales_funnel" formik={formik}>
                  <AsyncSelect
                    isDisabled={
                      formik?.values?.status?.value !== "new" && params.id
                    }
                    defaultOptions={salesFunnelOptions}
                    loadOptions={loadSalesFunnelOptions}
                    placeholder=""
                    {...formik.getFieldProps("sales_funnel")}
                    onChange={(val) => {
                      formik.setFieldValue("sales_funnel", val)
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            {/* Insurance */}
            <div className="flex w-full space-y-1 py-2 items-baseline">
              <div className="w-1/3 inline-block">{t("insurance")}</div>
              <div className="w-2/3 space-x-4">
                <Form.Item name="insurance" formik={formik}>
                  <Switch
                    id="insurance"
                    color="primary"
                    checked={formik.values.insurance}
                    onChange={(val) => {
                      formik.setFieldValue("insurance", val)
                      setInsurance(val)
                    }}
                    // disabled={
                    //   formik?.values?.status?.value !== "new" && params.id
                    // }
                    disabled={
                      params.id &&
                      (formik.values.status.value === "client_returned" ||
                        formik.values.status.value === "completed" ||
                        formik.values.status.value === "cancelled" ||
                        moment(
                          formik?.values?.from_time +
                            (formik?.values?.day_count -
                              formik?.values?.actual_prolong_day)
                        ).isAfter(moment()) ||
                        formInitialValues.actual_prolong_day > 0)
                        ? true
                        : formik.values.status.value !== "new" &&
                          params.id &&
                          !formik.values.actual_prolong_day
                    }
                  />
                </Form.Item>
              </div>
            </div>
            {/* Mechanic*/}
            <div className="w-full py-2 flex ">
              <div className="w-1/3">{t("Механик дал")}</div>
              <div className="bg-background_2 border rounded w-2/3 p-2">
                <div className="flex w-full">
                  <p className="w-1/2 text-gray-700 font-semibold">
                    {t("first.name")}
                  </p>
                  <p className="text-gray-600">
                    {formInitialValues?.mechanic?.full_name}
                  </p>
                </div>
                <div className="flex w-full">
                  <p className="w-1/2 text-gray-700 font-semibold">
                    {t("phone.number")}
                  </p>
                  <p className="text-gray-600">
                    {formInitialValues?.mechanic?.phone_number}
                  </p>
                </div>
              </div>
            </div>
            {/* Mechanic who received a car */}
            {formik.values.status.value === "client_returned" ||
            formik.values.status.value === "completed" ? (
              <div className="w-full py-2 flex ">
                <div className="w-1/3">{t("Механик принял")}</div>
                <div className="bg-background_2 border rounded w-2/3 p-2">
                  <div className="flex w-full">
                    <p className="w-1/2 text-gray-700 font-semibold">
                      {t("first.name")}
                    </p>
                    <p className="text-gray-600">
                      {formInitialValues?.recieve_mechanic?.full_name}
                    </p>
                  </div>
                  <div className="flex w-full">
                    <p className="w-1/2 text-gray-700 font-semibold">
                      {t("phone.number")}
                    </p>
                    <p className="text-gray-600">
                      {formInitialValues?.recieve_mechanic?.phone_number}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {/* Branch */}
            {formik?.values?.status?.value === "new" ||
            location.pathname.includes("/home/orders/create") ? (
              <div className="w-full py-2 flex items-baseline">
                <div className="w-1/3">{t("branch")}</div>
                <div className="w-2/3">
                  <Form.Item name="branch" formik={formik}>
                    <Select
                      isDisabled={true}
                      options={branch}
                      {...formik.getFieldProps("branch")}
                      onChange={(val) => {
                        formik.setFieldValue("branch", val)
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            ) : null}

            <Modal
              className={classes.modal}
              open={isOpen}
              onClose={closeModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={isOpen}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={ValidationSchema}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <div
                        className={classes.paper}
                        style={{
                          width: "600px",
                          outlineStyle: "none",
                        }}
                      >
                        <p className="text-gray-600 font-semibold">
                          {t("add.client")}
                        </p>
                        <div className="flex flex-col space-y-4 py-8">
                          {/* First Name */}
                          <div className="w-full flex items-baseline">
                            <div className="text-sm text-gray-600 w-1/4">
                              &#42; {t("first.name")}
                            </div>
                            <div className="w-3/4">
                              <Form.Item name="first_name" formik={formik}>
                                <Input
                                  placeholder={t("enter.client.name")}
                                  type="text"
                                  {...formik.getFieldProps("first_name")}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {/* Passport Pinfl */}
                          <div className="w-full flex items-baseline">
                            <div className="text-sm text-gray-600 w-1/4 whitespace-nowrap">
                              &#42; {t("passport.pinfl")}
                            </div>
                            <div className="w-3/4">
                              <Form.Item name="passport_pinfl" formik={formik}>
                                <PhoneInput
                                  mask="99999999999999"
                                  placeholder={t("enter.client.pinfl")}
                                  onChange={(val) => {
                                    formik.setFieldValue(
                                      "passport_pinfl",
                                      val.target.value.replace(/\D/g, "")
                                    )
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {/* Phone Number */}
                          <div className="w-full flex items-baseline">
                            <div className="text-sm text-gray-600 w-1/4">
                              &#42; {t("number")}
                            </div>
                            <div className="w-3/4">
                              <Form.Item name="phone_number" formik={formik}>
                                <PhoneInput
                                  mask="+999999999999999999"
                                  placeholder={t("enter.client.phone.number")}
                                  value={phoneNumber}
                                  onChange={(val) => {
                                    setPhoneNumber(val.target.value)
                                    formik.setFieldValue(
                                      "phone_number",
                                      val.target.value
                                    )
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <Button
                            className="py-2"
                            color="primary-600"
                            style={{ borderRadius: "3px" }}
                            type="submit"
                          >
                            {t("create")}
                          </Button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </Fade>
            </Modal>
          </Card>
          {((onCreateOrders && showComments) || !onCreateOrders) && (
            <Card
              title={t("comments")}
              style={{
                position: "relative",
                height: "24rem",
                marginTop: "1rem",
              }}
              className={onCreateOrders ? "" : "my-4"}
            >
              <div
                className="overflow-y-auto"
                style={{
                  maxHeight: `${
                    formik.values.status.value === "completed"
                      ? "14rem"
                      : "18rem"
                  }`,
                }}
                ref={commentListRef}
              >
                {client?.comments.map((comment) => (
                  <div
                    key={comment.commentator_id}
                    className="flex justify-between text-gray-600 my-2 cursor-pointer p-2"
                    onClick={(e) => commentClickHandler(e, comment.order_id)}
                  >
                    <div style={{ maxWidth: "74%" }}>
                      <div className="font-semibold my-1">
                        {comment.commentator_name}
                      </div>
                      <div className="w-11/12 break-words">
                        {comment.message}
                      </div>
                    </div>
                    <div className="px-2 self-end">
                      {moment(comment.created_at).format("YYYY-MM-DD | HH:MM")}
                    </div>
                  </div>
                ))}
                <EmptyTable
                  message="Без комментариев"
                  isActive={client?.comments.length === 0}
                  className="border-none flex flex-col justify-center items-center text-gray-400 w-50 h-24 my-16"
                />
              </div>
              {formik.values.status.value === "completed" && (
                <div
                  className="text-right mt-3 flex"
                  style={{
                    boxShadow: "0 -0.3rem 1rem 0 rgba(0,0,0,0.1)",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    padding: "0.5rem 1rem",
                    borderTop: "1px solid #ddd",
                    width: "100%",
                  }}
                >
                  <textarea
                    className="border rounded mx-1 px-2"
                    style={{
                      width: "100%",
                      height: "2.5rem",
                    }}
                    placeholder={t("add.comment")}
                    ref={commentRef}
                  />
                  <Button
                    className="py-2"
                    color="primary-600"
                    style={{ borderRadius: "3px", marginLeft: "0.7rem" }}
                    type="submit"
                    onClick={sendComment}
                  >
                    {t("send")}
                  </Button>
                </div>
              )}
            </Card>
          )}
        </div>
        <div>
          {window.location.href.includes("/orders/create") &&
            formik?.values?.tariff && (
              <Card title={t("tariffs.information")}>
                <div className="flex flex-col space-y-4">
                  {!params.id ||
                    (formik.values.status.value !== "new" && (
                      <div className="space-y-2">
                        <div className="flex w-full items-center text-gray-800">
                          <p className="font-semibold">{t("date")}:</p>
                          <p className="w-full"></p>
                          <div className="w-3/4">
                            <p>
                              {moment(formik?.values?.from_time).format(
                                "DD.MM.YYYY | HH:MM"
                              )}
                            </p>
                            <p>
                              {moment(formik?.values?.to_time).format(
                                "DD.MM.YYYY | HH:MM"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* Tariff */}
                  <div className="space-y-2">
                    <div className="flex w-full">
                      <p className="w-1/2">{t("tariff")}</p>
                    </div>

                    <PriceDisplay
                      numType="+"
                      text={formik?.values?.tariff?.label}
                      price={convertToInternationalCurrencySystem(
                        formik?.values?.tariff?.price *
                          (formik?.values?.day_count !== undefined &&
                            formik?.values?.day_count)
                      )}
                    />
                    {insurance ? (
                      <PriceDisplay
                        numType="+"
                        text={t("insurance.price")}
                        price={convertToInternationalCurrencySystem(
                          formik?.values?.tariff?.insurance_price *
                            (formik?.values?.day_count !== undefined &&
                              formik?.values?.day_count)
                        )}
                      />
                    ) : null}
                  </div>
                  {listData?.additional_tariffs?.length !== 0 && (
                    <div className="space-y-2">
                      <div className="flex w-full">
                        <p className="w-1/2">{t("additional.tariffs")}</p>
                      </div>
                      {listData?.additional_tariffs?.map((addTariff, index) => (
                        <PriceDisplay
                          numType="+"
                          text={
                            <p className="flex">
                              {index + 1}.{addTariff?.label}
                            </p>
                          }
                          price={convertToInternationalCurrencySystem(
                            addTariff.price *
                              (formik?.values?.day_count !== undefined &&
                                formik?.values?.day_count)
                          )}
                        />
                      ))}
                    </div>
                  )}
                </div>
                {/* Discount */}
                {formik.values?.discount > 0 && (
                  <div className="flex flex-col space-y-4">
                    <PriceDisplay
                      numType="-"
                      className="pt-4"
                      text="Скидка"
                      price={convertToInternationalCurrencySystem(
                        formik?.values?.day_count
                          ? formik?.values?.discount * formik?.values?.day_count
                          : 0
                      )}
                    />
                  </div>
                )}
                {/* Total price */}
                <div className="flex flex-col space-y-4">
                  <PriceDisplay
                    className="pt-4 text-gray-800 font-semibold"
                    text="Итого"
                    price={getTotalPrice(
                      formik,
                      listData,
                      formik.values?.discount > 0
                    )}
                  />
                </div>
              </Card>
            )}

          {formik?.values?.status?.value === "new" &&
            formik?.values?.cards?.length && (
              <>
                <Card className="mb-5">
                  <div className="flex flex-col space-y-3">
                    <PriceDisplay
                      text={t("insurance.price")}
                      price={convertToInternationalCurrencySystem(
                        formik.values.totalInsurancePrice
                      )}
                    />
                    <PriceDisplay
                      text={t("total.price")}
                      price={convertToInternationalCurrencySystem(
                        formik.values.total_price
                      )}
                    />
                    <PriceDisplay
                      className="pt-4"
                      text="Скидка"
                      price={convertToInternationalCurrencySystem(
                        formik.values.totalDiscountPrice
                      )}
                    />

                    <PriceDisplay
                      text={t("paid")}
                      price={convertToInternationalCurrencySystem(
                        formik.values.paid_price
                      )}
                    />

                    <PriceDisplay
                      className="pt-4 text-gray-800 font-semibold"
                      text="Остаток"
                      price={convertToInternationalCurrencySystem(
                        formik.values.total_price - formik.values.paid_price
                      )}
                    />
                  </div>
                </Card>
                {formik?.values?.cards?.map(
                  (card, index) =>
                    index === 0 && (
                      <Card title={t("tariffs.information")}>
                        <div className="flex flex-col space-y-4">
                          {!params.id ||
                            (formik.values.status.value !== "new" && (
                              <div className="space-y-2">
                                <div className="flex w-full items-center text-gray-800">
                                  <p className="font-semibold">{t("date")}:</p>
                                  <p className="w-full"></p>
                                  <div className="w-3/4">
                                    <p>
                                      {moment(formik?.values?.from_time).format(
                                        "DD.MM.YYYY | HH:MM"
                                      )}
                                    </p>
                                    <p>
                                      {moment(formik?.values?.to_time).format(
                                        "DD.MM.YYYY | HH:MM"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* Tariff */}
                          <div className="space-y-2">
                            <div className="flex w-full">
                              <p className="w-1/2">{t("tariff")}</p>
                            </div>

                            <PriceDisplay
                              numType="+"
                              text={formik?.values?.tariff?.label}
                              price={convertToInternationalCurrencySystem(
                                formik?.values?.tariff?.price *
                                  (formik?.values?.day_count !== undefined &&
                                    formik?.values?.day_count)
                              )}
                            />

                            {insurance ? (
                              <PriceDisplay
                                numType="+"
                                text={t("insurance.price")}
                                price={convertToInternationalCurrencySystem(
                                  formik?.values?.tariff?.insurance_price *
                                    (formik?.values?.day_count !== undefined &&
                                      formik?.values?.day_count)
                                )}
                              />
                            ) : null}
                          </div>
                          {listData?.additional_tariffs?.length !== 0 && (
                            <div className="space-y-2">
                              <div className="flex w-full">
                                <p className="w-1/2">
                                  {t("additional.tariffs")}
                                </p>
                              </div>
                              {listData?.additional_tariffs?.map(
                                (addTariff, index) => (
                                  <PriceDisplay
                                    numType="+"
                                    text={
                                      <p className="flex">
                                        {index + 1}.{addTariff?.label}
                                      </p>
                                    }
                                    price={convertToInternationalCurrencySystem(
                                      addTariff.price *
                                        (formik?.values?.day_count !==
                                          undefined &&
                                          formik?.values?.day_count)
                                    )}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </div>
                        {/* Discount */}
                        {formik.values?.discount > 0 && (
                          <div className="flex flex-col space-y-4">
                            <PriceDisplay
                              numType="-"
                              className="pt-4"
                              text="Скидка"
                              price={convertToInternationalCurrencySystem(
                                formik?.values?.day_count
                                  ? formik?.values?.discount *
                                      formik?.values?.day_count
                                  : 0
                              )}
                            />
                          </div>
                        )}

                        {/* Total price */}
                        <div className="flex flex-col space-y-4">
                          <PriceDisplay
                            className="pt-4 text-gray-800 font-semibold"
                            text="Итого"
                            price={getTotalPrice(
                              formik,
                              listData,
                              formik.values?.discount > 0
                            )}
                            // price={convertToInternationalCurrencySystem(
                            //   insurance
                            //     ? card.tariff.insurance_price *
                            //         formik?.values?.day_count +
                            //         card.tariff.price *
                            //           formik?.values?.day_count +
                            //         card.additional_tariffs.reduce(
                            //           (acc, curr) => {
                            //             if (curr.price) {
                            //               return (
                            //                 acc +
                            //                 curr.price *
                            //                   formik?.values?.day_count
                            //               )
                            //             }
                            //             return acc
                            //           },
                            //           0
                            //         ) -
                            //         formik?.values?.discount *
                            //           formik?.values?.day_count
                            //     : card.tariff.price *
                            //         formik?.values?.day_count +
                            //         card.additional_tariffs.reduce(
                            //           (acc, curr) => {
                            //             if (curr.price) {
                            //               return (
                            //                 acc +
                            //                 curr.price *
                            //                   formik?.values?.day_count
                            //               )
                            //             }
                            //             return acc
                            //           },
                            //           0
                            //         ) -
                            //         formik?.values?.discount *
                            //           formik?.values?.day_count
                            // )}
                          />
                        </div>
                      </Card>
                    )
                )}{" "}
              </>
            )}
          {formik?.values?.status?.value !== "new" &&
          formik?.values?.cards?.length &&
          formik?.values.actual_prolong_day &&
          actualProlongDay === 0
            ? formik?.values?.cards?.map(
                (card, index) =>
                  index === 0 && (
                    <Card title={t("new_tariff")} className="mb-4">
                      <div className="flex flex-col space-y-4">
                        {!params.id ||
                          (formik.values.status.value !== "new" && (
                            <div className="space-y-2">
                              <div className="flex w-full items-center text-gray-800">
                                <p className="font-semibold">{t("date")}:</p>
                                <p className="w-full"></p>
                                <div className="w-3/4">
                                  <p>
                                    {moment(
                                      formik?.values?.cards.length >= 1
                                        ? formik?.values?.cards[
                                            formik?.values?.cards.length - 1
                                          ].self_to_time
                                        : card.self_from_time
                                    ).format("DD.MM.YYYY | HH:MM")}
                                  </p>
                                  <p>
                                    {moment(formik?.values?.to_time)
                                      .add(
                                        formik?.values?.actual_prolong_day,
                                        "days"
                                      )
                                      .format("DD.MM.YYYY | HH:MM")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* Tariff */}
                        <div className="space-y-2">
                          <div className="flex w-full">
                            <p className="w-1/2">{t("tariff")}</p>
                          </div>

                          <PriceDisplay
                            numType="+"
                            text={formik?.values?.tariff?.label}
                            price={convertToInternationalCurrencySystem(
                              card.tariff.price *
                                formik?.values?.actual_prolong_day
                            )}
                          />

                          <PriceDisplay
                            numType="+"
                            text={t("insurance.price")}
                            price={convertToInternationalCurrencySystem(
                              card.tariff.insurance_price *
                                formik?.values?.actual_prolong_day
                            )}
                          />
                        </div>
                        {listData?.additional_tariffs?.length !== 0 && (
                          <div className="space-y-2">
                            <div className="flex w-full">
                              <p className="w-1/2">{t("additional.tariffs")}</p>
                            </div>
                            {listData?.additional_tariffs?.map(
                              (addTariff, index) => (
                                <PriceDisplay
                                  numType="+"
                                  text={
                                    <p className="flex">
                                      {index + 1}.{addTariff?.label}
                                    </p>
                                  }
                                  price={convertToInternationalCurrencySystem(
                                    addTariff.price *
                                      formik?.values?.actual_prolong_day
                                  )}
                                />
                              )
                            )}
                          </div>
                        )}
                      </div>
                      {/* Discount */}
                      {formik.values?.discount > 0 && (
                        <div className="flex flex-col space-y-4">
                          <PriceDisplay
                            numType="-"
                            className="pt-4"
                            text="Скидка"
                            price={convertToInternationalCurrencySystem(
                              formik?.values?.discount *
                                formik?.values?.actual_prolong_day
                            )}
                          />
                        </div>
                      )}
                      {/* Total price */}
                      <div className="flex flex-col space-y-4">
                        <PriceDisplay
                          className="pt-4 text-gray-800 font-semibold"
                          text="Итого"
                          price={convertToInternationalCurrencySystem(
                            card.tariff.insurance_price *
                              formik?.values?.actual_prolong_day +
                              card.tariff.price *
                                formik?.values?.actual_prolong_day +
                              card.additional_tariffs.reduce((acc, curr) => {
                                if (curr.price) {
                                  return (
                                    acc +
                                    curr.price *
                                      formik?.values?.actual_prolong_day
                                  )
                                }
                                return acc
                              }, 0) -
                              formik?.values?.discount *
                                formik?.values?.actual_prolong_day
                          )}
                        />
                      </div>
                    </Card>
                  )
              )
            : null}
          {formik?.values?.status?.value !== "new" &&
          actualProlongDay === 0 &&
          formik?.values?.cards?.length ? (
            <>
              <Card className="mb-5">
                <div className="flex flex-col space-y-3">
                  <PriceDisplay
                    text={t("insurance.price")}
                    price={convertToInternationalCurrencySystem(
                      formik.values.totalInsurancePrice
                    )}
                  />
                  <PriceDisplay
                    text={t("total.price")}
                    price={convertToInternationalCurrencySystem(
                      formik.values.total_price
                    )}
                  />
                  <PriceDisplay
                    className="pt-4"
                    text="Скидка"
                    price={convertToInternationalCurrencySystem(
                      formik.values.totalDiscountPrice
                    )}
                  />

                  <PriceDisplay
                    text={t("paid")}
                    price={convertToInternationalCurrencySystem(
                      formik.values.paid_price
                    )}
                  />

                  <PriceDisplay
                    className="pt-4 text-gray-800 font-semibold"
                    text="Остаток"
                    price={convertToInternationalCurrencySystem(
                      formik.values.total_price - formik.values.paid_price
                    )}
                  />
                </div>
              </Card>
              {formik?.values?.cards?.map(
                (card, index) =>
                  index === 0 && (
                    <Card title={t("tariffs.information")}>
                      <div className="flex flex-col space-y-4">
                        {!params.id ||
                          (formik.values.status.value !== "new" && (
                            <div className="space-y-2">
                              <div className="flex w-full items-center text-gray-800">
                                <p className="font-semibold">{t("date")}:</p>
                                <p className="w-full"></p>
                                <div className="w-3/4">
                                  <p>
                                    {moment(card.self_from_time).format(
                                      "DD.MM.YYYY | HH:MM"
                                    )}
                                  </p>
                                  <p>
                                    {moment(card.self_to_time).format(
                                      "DD.MM.YYYY | HH:MM"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* Tariff */}
                        <div className="space-y-2">
                          <div className="flex w-full">
                            <p className="w-1/2">{t("tariff")}</p>
                          </div>

                          <PriceDisplay
                            numType="+"
                            text={formik?.values?.tariff?.label}
                            price={convertToInternationalCurrencySystem(
                              card.tariff.price * card.self_day_count
                            )}
                          />

                          <PriceDisplay
                            numType="+"
                            text={t("insurance.price")}
                            price={convertToInternationalCurrencySystem(
                              card.tariff.insurance_price *
                                card.self_day_count *
                                card.insurance
                            )}
                          />
                        </div>
                        {listData?.additional_tariffs?.length !== 0 && (
                          <div className="space-y-2">
                            <div className="flex w-full">
                              <p className="w-1/2">{t("additional.tariffs")}</p>
                            </div>
                            {listData?.additional_tariffs?.map(
                              (addTariff, index) => (
                                <PriceDisplay
                                  numType="+"
                                  text={
                                    <p className="flex">
                                      {index + 1}.{addTariff?.label}
                                    </p>
                                  }
                                  price={convertToInternationalCurrencySystem(
                                    addTariff.price * card.self_day_count
                                  )}
                                />
                              )
                            )}
                          </div>
                        )}
                      </div>
                      {/* Discount */}
                      {card.discount > 0 && (
                        <div className="flex flex-col space-y-4">
                          <PriceDisplay
                            numType="-"
                            className="pt-4"
                            text="Скидка"
                            price={convertToInternationalCurrencySystem(
                              card.discount * card.self_day_count
                            )}
                          />
                        </div>
                      )}
                      {/* Total price */}
                      <div className="flex flex-col space-y-4">
                        <PriceDisplay
                          className="pt-4 text-gray-800 font-semibold"
                          text="Итого"
                          price={convertToInternationalCurrencySystem(
                            card.self_total_price
                          )}
                        />
                      </div>
                    </Card>
                  )
              )}
            </>
          ) : null}
          {formik?.values?.status?.value !== "new" &&
          actualProlongDay > 0 &&
          formik?.values?.actual_prolong_day &&
          formik?.values?.cards?.length ? (
            <>
              <Card className="mb-5">
                <div className="flex flex-col space-y-3">
                  <PriceDisplay
                    text={t("insurance.price")}
                    price={convertToInternationalCurrencySystem(
                      formik.values.totalInsurancePrice
                    )}
                  />
                  <PriceDisplay
                    text={t("total.price")}
                    price={convertToInternationalCurrencySystem(
                      formik.values.total_price
                    )}
                  />
                  <PriceDisplay
                    className="pt-4"
                    text="Скидка"
                    price={convertToInternationalCurrencySystem(
                      formik.values.totalDiscountPrice
                    )}
                  />

                  <PriceDisplay
                    text={t("paid")}
                    price={convertToInternationalCurrencySystem(
                      formik.values.paid_price
                    )}
                  />

                  <PriceDisplay
                    className="pt-4 text-gray-800 font-semibold"
                    text="Остаток"
                    price={convertToInternationalCurrencySystem(
                      formik.values.total_price - formik.values.paid_price
                    )}
                  />
                </div>
              </Card>
              {formik?.values?.cards?.map(
                (card, index) =>
                  index === 0 && (
                    <Card title={t("tariffs.information")}>
                      <div className="flex flex-col space-y-4">
                        {!params.id ||
                          (formik.values.status.value !== "new" && (
                            <div className="space-y-2">
                              <div className="flex w-full items-center text-gray-800">
                                <p className="font-semibold">{t("date")}:</p>
                                <p className="w-full"></p>
                                <div className="w-3/4">
                                  <p>
                                    {moment(
                                      formik?.values?.cards.length >= 1
                                        ? formik?.values?.cards[
                                            formik.values?.cards.length - 1
                                          ].self_to_time
                                        : card.self_from_time
                                    ).format("DD.MM.YYYY | HH:MM")}
                                  </p>
                                  <p>
                                    {moment(
                                      formik?.values?.cards[
                                        formik.values?.cards.length - 1
                                      ].self_to_time
                                    )
                                      .add(
                                        formik?.values?.actual_prolong_day,
                                        "days"
                                      )
                                      .format("DD.MM.YYYY | HH:MM")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* Tariff */}
                        <div className="space-y-2">
                          <div className="flex w-full">
                            <p className="w-1/2">{t("tariff")}</p>
                          </div>

                          <PriceDisplay
                            numType="+"
                            text={formik?.values?.tariff?.label}
                            price={convertToInternationalCurrencySystem(
                              card.tariff.price *
                                formik?.values?.actual_prolong_day
                            )}
                          />

                          <PriceDisplay
                            numType="+"
                            text={t("insurance.price")}
                            price={convertToInternationalCurrencySystem(
                              card.tariff.insurance_price *
                                formik?.values?.actual_prolong_day
                            )}
                          />
                        </div>
                        {listData?.additional_tariffs?.length !== 0 && (
                          <div className="space-y-2">
                            <div className="flex w-full">
                              <p className="w-1/2">{t("additional.tariffs")}</p>
                            </div>
                            {listData?.additional_tariffs?.map(
                              (addTariff, index) => (
                                <PriceDisplay
                                  numType="+"
                                  text={
                                    <p className="flex">
                                      {index + 1}.{addTariff?.label}
                                    </p>
                                  }
                                  price={convertToInternationalCurrencySystem(
                                    addTariff.price *
                                      formik?.values?.actual_prolong_day
                                  )}
                                />
                              )
                            )}
                          </div>
                        )}
                      </div>
                      {/* Discount */}
                      {card.discount > 0 && (
                        <div className="flex flex-col space-y-4">
                          <PriceDisplay
                            numType="-"
                            className="pt-4"
                            text="Скидка"
                            price={convertToInternationalCurrencySystem(
                              card.discount * card.self_day_count
                            )}
                          />
                        </div>
                      )}
                      {/* Total price */}
                      <div className="flex flex-col space-y-4">
                        <PriceDisplay
                          className="pt-4 text-gray-800 font-semibold"
                          text="Итого"
                          price={convertToInternationalCurrencySystem(
                            card.self_total_price
                          )}
                          // price={convertToInternationalCurrencySystem(
                          //   card.tariff.insurance_price *
                          //     formik?.values?.actual_prolong_day +
                          //     card.tariff.price *
                          //       formik?.values?.actual_prolong_day +
                          //     card.additional_tariffs.reduce((acc, curr) => {
                          //       if (curr.price) {
                          //         return (
                          //           acc +
                          //           curr.price *
                          //             formik?.values?.actual_prolong_day
                          //         )
                          //       }
                          //       return acc
                          //     }, 0) -
                          //     formik?.values?.discount *
                          //       formik?.values?.actual_prolong_day
                          // )}
                        />
                      </div>
                    </Card>
                  )
              )}
            </>
          ) : null}

          {formik?.values?.cards?.length &&
            formik?.values?.cards?.map(
              (card, index) =>
                index !== 0 && (
                  <Card title={t("tariffs.history")} className="mt-4">
                    <div className="flex flex-col space-y-4">
                      {!params.id ||
                        (formik.values.status.value !== "new" && (
                          <div className="space-y-2">
                            <div className="flex w-full items-center text-gray-800">
                              <p className="font-semibold">{t("date")}:</p>
                              <p className="w-full"></p>
                              <div className="w-3/4">
                                <p>
                                  {moment(card.self_from_time).format(
                                    "DD.MM.YYYY | HH:MM"
                                  )}
                                </p>
                                <p>
                                  {moment(card.self_to_time).format(
                                    "DD.MM.YYYY | HH:MM"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      {/* Tariff */}
                      <div className="space-y-2">
                        <div className="flex w-full">
                          <p className="w-1/2">{t("tariff")}</p>
                        </div>

                        <PriceDisplay
                          numType="+"
                          text={card.tariff.name}
                          price={convertToInternationalCurrencySystem(
                            card.tariff.price * card.self_day_count
                          )}
                        />

                        <PriceDisplay
                          numType="+"
                          text={t("insurance.price")}
                          price={
                            insurance
                              ? convertToInternationalCurrencySystem(
                                  card.tariff.insurance_price *
                                    card.self_day_count
                                )
                              : convertToInternationalCurrencySystem(
                                  card.tariff.insurance_price
                                )
                          }
                        />
                      </div>
                      {card.additional_tariffs.length !== 0 && (
                        <div className="space-y-2">
                          <div className="flex w-full">
                            <p className="w-1/2">{t("additional.tariffs")}</p>
                          </div>
                          {card.additional_tariffs.map((addTariff, index) => (
                            <PriceDisplay
                              numType="+"
                              text={
                                <p className="flex">
                                  {index + 1}.{addTariff?.name}
                                </p>
                              }
                              price={convertToInternationalCurrencySystem(
                                addTariff.price * card.self_day_count
                              )}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                    {/* Discount */}
                    {card.discount > 0 && (
                      <div className="flex flex-col space-y-4">
                        <PriceDisplay
                          numType="-"
                          className="pt-4"
                          text="Скидка"
                          price={convertToInternationalCurrencySystem(
                            card.discount * card.self_day_count
                          )}
                        />
                      </div>
                    )}
                    {/* Total price */}
                    <div className="flex flex-col space-y-4">
                      <PriceDisplay
                        className="pt-4 text-gray-800 font-semibold"
                        text="Итого"
                        price={convertToInternationalCurrencySystem(
                          card.self_total_price
                        )}

                        // price={convertToInternationalCurrencySystem(
                        //   card.tariff.insurance_price * card.self_day_count +
                        //     card.tariff.price * card.self_day_count +
                        //     card.additional_tariffs.reduce((acc, curr) => {
                        //       if (curr.price) {
                        //         return acc + curr.price * card.self_day_count
                        //       }
                        //       return acc
                        //     }, 0) -
                        //     card.discount * card.self_day_count
                        // )}
                      />
                    </div>
                  </Card>
                )
            )}
        </div>
      </div>
      {clientOrders && <ClientsOrders orders={clientOrders} />}
    </>
  )
}

export default Context
