import { emptyState } from "../../assets/icons/icons"

function EmptyTable({
  message = "No data",
  isActive = false,
  className,
  style,
}) {
  if (!isActive) return null
  return (
    <div
      className={
        className
          ? className
          : `
            flex flex-col 
            justify-center 
            items-center    
            text-gray-400
            border border-t-0
            border-gray-300
            space-y-2
            py-4 my-0.5
    `
      }
      style={{
        backgroundColor: "#fff",
        marginTop: typeof style?.marginTop === "string" ? style.marginTop : "",
      }}
    >
      {emptyState}
      <h6 className="font-normal">{message}</h6>
    </div>
  )
}

export default EmptyTable
