import React, { useState, useEffect } from "react"
import { YMaps, Map, Placemark } from "react-yandex-maps"

const mapState = {
  center: [41.311153, 69.279729],
  zoom: 9,
}

export default function App({ onChange = () => {}, location }) {
  const ymaps = React.useRef(null)
  const placemarkRef = React.useRef(null)
  const mapRef = React.useRef(null)
  const [address, setAddress] = React.useState("")
  const [selectedLocation, setSelectedLocation] = useState({})

  useEffect(() => {
    onChange(selectedLocation)
  }, [selectedLocation])

  const createPlacemark = (coords) => {
    return new ymaps.current.Placemark(
      coords,
      {
        iconCaption: "loading..",
      },
      {
        preset: "islands#blackDotIconWithCaption",
        draggable: true,
      }
    )
  }

  const getAddress = (coords) => {
    placemarkRef.current.properties.set("iconCaption", "")
    ymaps.current.geocode(coords).then((res) => {
      const firstGeoObject = res.geoObjects.get(0)

      const newAddress = [
        firstGeoObject.getLocalities().length
          ? firstGeoObject.getLocalities()
          : firstGeoObject.getAdministrativeAreas(),
        firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
      ]
        .filter(Boolean)
        .join(", ")

      setAddress(newAddress)

      placemarkRef.current.properties.set({
        iconCaption: newAddress,
        balloonContent: firstGeoObject.getAddressLine(),
      })
    })
  }

  const onMapClick = (e) => {
    const coords = e.get("coords")
    setSelectedLocation({ latitude: coords[0], longitude: coords[1] })

    if (placemarkRef.current) {
      placemarkRef.current.geometry.setCoordinates(coords)
    } else {
      placemarkRef.current = createPlacemark(coords)
      mapRef.current.geoObjects.add(placemarkRef.current)
      placemarkRef.current.events.add("dragend", function () {
        getAddress(placemarkRef.current.geometry.getCoordinates())
      })
    }
    getAddress(coords)
  }

  return (
    <YMaps query={{ apikey: "377281f7-9c93-41ac-b634-ade0331dca58" }}>
      <Map
        height="400px"
        width="100%"
        modules={["Placemark", "geocode", "geoObject.addon.balloon"]}
        instanceRef={mapRef}
        onLoad={(ympasInstance) => (ymaps.current = ympasInstance)}
        onClick={onMapClick}
        state={{
          center:
            location?.latitude !== 0
              ? [location?.latitude, location?.longitude]
              : [41.311153, 69.279729],
          zoom: 13,
        }}
      >
        <Placemark
          options={{
            preset: "islands#blackStretchyIcon",
          }}
          geometry={
            location?.latitude !== 0
              ? [location?.latitude, location?.longitude]
              : [41.311153, 69.279729]
          }
        />
      </Map>
      {address && (
        <div>
          <p>{address}</p>
        </div>
      )}
    </YMaps>
  )
}
