import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import { useHistory, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "../../components/Card/index"
import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../components/Form/Index"
import AddIcon from "@material-ui/icons/Add"
import Input from "../../components/Input/index"
import Select from "../../components/Select/index"
import ImageUpload from "../../components/Upload/ImgUpload"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"

const deleteIcon = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="6" fill="#F76659" />
    <path
      d="M14 27C14 28.1 14.9 29 16 29H24C25.1 29 26 28.1 26 27V17C26 15.9 25.1 15 24 15H16C14.9 15 14 15.9 14 17V27ZM26 12H23.5L22.79 11.29C22.61 11.11 22.35 11 22.09 11H17.91C17.65 11 17.39 11.11 17.21 11.29L16.5 12H14C13.45 12 13 12.45 13 13C13 13.55 13.45 14 14 14H26C26.55 14 27 13.55 27 13C27 12.45 26.55 12 26 12Z"
      fill="white"
    />
  </svg>
)

function AttributesCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [attributeCategories, setAttributeCategories] = useState([])
  const [image, setImage] = useState("")
  // const [attributeTypes, setAttributeTypes] = useState([])
  const [options, setOptions] = useState([
    {
      id: "",
      value: "",
    },
  ])
  const [initialValues, setInitialValues] = useState({
    attribute_category_id: "",
    name: "",
    // type: "",
    values: [{ value: "" }],
  })

  useEffect(() => {
    getAttributeCategory()
    // getAttributeType()
  }, [])

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    let [category, attributeType] = await Promise.all([
      axios
        .get("/attribute-category?limit=100&offset=0")
        .then((res) => res.data),
      // axios.get("/attribute-type?limit=100&offset=0").then((res) => res.data),
    ])

    // let types = attributeType?.attribute_types?.map(({ name, slug }) => ({
    //   label: name,
    //   value: slug,
    // }))
    let attributes = category?.categories?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))

    // setAttributeTypes(types)
    setAttributeCategories(attributes)

    await axios
      .get(`/attribute/${id}`)
      .then((res) => {
        let data = {
          ...res,
          // type: attributeType.attribute_types
          //   .filter((el) => el.slug === res.type)
          //   .map((el) => ({ label: el.name, value: el.slug }))[0],
          attribute_category_id: category?.categories
            .filter((el) => el.id === res.attribute_category_id)
            .map((el) => ({ label: el.name, value: el.id }))[0],
        }
        console.log(data)
        setInitialValues(data)
        setImage(res.photo)
        setOptions(res.values)
      })
      .finally(() => setIsGettingData(false))
  }

  // Get Attribute Types and Categories
  async function getAttributeCategory() {
    let response = await axios.get("/attribute-category?limit=100&offset=0")

    setAttributeCategories(
      response.data.categories?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }
  // async function getAttributeType() {
  //   let response = await axios.get("/attribute-type?limit=100&offset=0")

  //   setAttributeTypes(
  //     response.data.attribute_types?.map(({ name, slug }) => ({
  //       label: name,
  //       value: slug,
  //     }))
  //   )
  // }

  // Functions for Changing Options
  const addOption = () => {
    setOptions((old) => [...old, { value: "", id: "" }])
  }

  const removeOption = (i) => {
    setOptions((old) => old.filter((el, index) => i !== index))
  }

  const updateOption = (val, i) => {
    setOptions((old) =>
      old.map((el, index) => (index === i ? { ...el, value: val } : el))
    )
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/attribute/${params.id}`, {
          ...values,
          photo: image,
          values: options,
          // type: values.type.value,
          attribute_category_id: values.attribute_category_id.value,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/attributes")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/attribute", {
          ...values,
          photo: image,
          values: options.map((el) => el.value),
          // type: values.type.value,
          attribute_category_id: values.attribute_category_id.value,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/attributes")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const routes = [
    {
      title: t("attributes"),
      link: true,
      route: "/home/settings/attributes",
    },

    {
      title: params.id ? initialValues.name : t("edit"),
    },
  ]

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    attribute_category_id: validateForm("mixed", t),
    // type: validateForm("mixed", t),
    values: Yup.mixed().required(t("required.field.error")),
  })

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("attribute")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() => history.push("/home/settings/attributes")}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <div className="space-y-4">
                  <Card title={t("general.information")}>
                    {/* Photo */}
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("photo")}</div>
                      <div className="w-2/3">
                        <ImageUpload
                          onChange={(val) => setImage(val.filename)}
                          onStart={(val) => console.log(val)}
                          onFinish={(val) => console.log(val)}
                          onSuccess={(val) => console.log(val)}
                          onError={(val) => console.log(val)}
                          defaultImage={image}
                        >
                          <div className="flex flex-col items-center justify-center space-y-4">
                            <AddIcon style={{ fontSize: "200%" }} />
                            <h5>{t("select.image")}</h5>
                          </div>
                        </ImageUpload>
                      </div>
                    </div>
                    {/* Name */}
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("name")}</div>
                      <div className="w-2/3">
                        <Form.Item name="name" formik={formik}>
                          <Input
                            id="name"
                            type="text"
                            {...formik.getFieldProps("name")}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Attribute type */}
                    {/* <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("attribute.type")}</div>
                      <div className="w-2/3">
                        <Form.Item name="type" formik={formik}>
                          <Select
                            options={attributeTypes}
                            {...formik.getFieldProps("type")}
                            onChange={(val) => {
                              formik.setFieldValue("type", val)
                            }}Attribute type
                          />
                        </Form.Item>
                      </div>
                    </div> */}
                    {/* Attribute category */}
                    <div className="w-full py-2 flex items-baseline">
                      <div className="w-1/3">{t("attribute.category")}</div>
                      <div className="w-2/3">
                        <Form.Item name="attribute_category_id" formik={formik}>
                          <Select
                            options={attributeCategories}
                            {...formik.getFieldProps("attribute_category_id")}
                            onChange={(val) => {
                              formik.setFieldValue("attribute_category_id", val)
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Card>
                  <Card title={t("attribute.variants")}>
                    {options?.map((el, index) => (
                      <>
                        <div className="flex py-2 items-center" key={el.id}>
                          <div className="w-1/3">{t("option.name")}</div>
                          <div className="flex w-2/3 items-center space-x-2">
                            <div>
                              <Form.Item name={el.value} formik={formik}>
                                <Input
                                  value={el.value}
                                  onChange={(el) =>
                                    updateOption(el.target.value, index)
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div
                              className="px-0 cursor-pointer"
                              onClick={() => removeOption(index)}
                              style={{ scale: "0.78" }}
                            >
                              {deleteIcon}
                            </div>
                          </div>
                        </div>
                        <hr className="my-2" />
                      </>
                    ))}

                    <div
                      className="flex justify-center border-2 rounded py-1 mt-4 cursor-pointer"
                      onClick={addOption}
                    >
                      <AddIcon />
                    </div>
                  </Card>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default AttributesCreate
