import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"

function TariffsCategoryCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: "",
  })

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = (id) => {
    setIsGettingData(true)
    axios
      .get(`/tariff_category/${id}`)
      .then((res) => {
        setInitialValues({
          ...res,
        })
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/tariff_category/${params.id}`, {
          ...values,
        })
        .then((res) => {
          history.push("/home/settings/tariff-category")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/tariff_category", {
          ...values,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/tariff-category")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
  })

  const routes = [
    {
      title: t("tariff.categories"),
      link: true,
      route: "/home/settings/tariff-category",
    },

    {
      title: params.id ? initialValues.name : t("create"),
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("tariff.category")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/tariff-category")
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")}>
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("name")}</div>
                    <div className="w-2/3">
                      <Form.Item name="name" formik={formik}>
                        <Input
                          id="name"
                          type="text"
                          {...formik.getFieldProps("name")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default TariffsCategoryCreate
