import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Table from "@material-ui/core/Table"
import "./index.scss"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Filters from "../../components/Filters"
import AlertComponent from "../../components/Alert"
import EmptyTable from "../../components/EmptyTable"
import parseQuery from "../../functions/parseQuery"
import { Box, TableCell, Typography } from "@material-ui/core"
import RangePicker from "../../components/DatePicker/RangePicker"
import moment from "moment"

function LoadingChart() {
  const { t } = useTranslation()
  const history = useHistory()
  const [status, setStatus] = useState({})
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })
  const [isLoading, setIsLoading] = useState(true)

  const pageCount = 10

  let { limit, offset } = parseQuery()

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [])

  const updateDate = (val) =>
    setFromToDate({
      start: moment(val[0]).toISOString() ?? "",
      end: moment(val[1]).toISOString() ?? "",
    })

  const startMonth = moment().startOf("month").format("YYYY-MM-DD")
  const endMonth = moment().endOf("month").format("YYYY-MM-DD")
  const startMonthName = moment(
    fromToDate.start ? fromToDate?.start : startMonth
  ).format("MMMM")
  const endMonthName = moment(
    fromToDate.end !== "" ? fromToDate?.end : endMonth
  ).format("MMMM")

  // **** FUNCTIONS ****

  const getItems = async () => {
    let response = await axios.get(`/car-activity`, {
      params: {
        limit: "500",
        from_time: fromToDate.start !== "" ? fromToDate.start : startMonth,
        to_time: fromToDate.end !== "" ? fromToDate.end : endMonth,
      },
    })

    setStatus(response)
    setIsLoading(false)
  }
  useEffect(() => {
    getItems()
  }, [fromToDate])

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/reports/loading-chart`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header title={t("loading_chart")} />
      <Filters>
        <div className="flex space-x-2">
          <RangePicker
            showToday={false}
            showWeekNumber
            dateInputPlaceholder={[t("from"), t("till")]}
            showOk={false}
            showClear
            placeholder={t("select.a.date")}
            hideTimePicker
            onChange={(val) => updateDate(val)}
          />
          {startMonthName === endMonthName ? (
            <h2 className="title text-lg leading-6 font-semibold pr-4">
              {startMonthName}
            </h2>
          ) : (
            <h2 className="title text-lg leading-6 font-semibold pr-4">
              {startMonthName} - {endMonthName}
            </h2>
          )}
        </div>
      </Filters>

      <div className="px-5 pb-4">
        <TableContainer
          className="mt-4"
          elevation={0}
          component={Paper}
          style={{ overflowX: "scroll", height: "700px" }}
        >
          <Table aria-label="simple table">
            <TableHead className="table_head">
              <TableRow className="table_row">
                <TableCell
                  rowSpan={3}
                  style={{
                    fontWeight: "600",
                    borderRight: "1px solid #ddd",
                    width: "150px",
                  }}
                >
                  № Машины
                </TableCell>
              </TableRow>
              {status?.data?.car_activities &&
                Object.keys(status?.data?.car_activities[0]?.dates)?.map(
                  (item, index) => (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                        width: "10px",
                        padding: "8px",
                      }}
                    >
                      {item.substring(8, 10)}
                    </TableCell>
                  )
                )}
            </TableHead>
            <TableBody>
              {status?.data?.car_activities?.map((item) => (
                <TableRow style={{}} className="table_row">
                  <TableCell style={{ borderRight: "1px solid #ddd" }}>
                    {item.state_number}
                  </TableCell>
                  {Object.keys(item?.dates).map((key) => (
                    <TableCell
                      align="center"
                      style={
                        item.dates[key] === "inactive"
                          ? {
                              borderRight: "1px solid #ddd",
                              background: "#fff",
                            }
                          : item.dates[key] === "booked"
                          ? {
                              borderRight: "1px solid #ddd",
                              background: "#6BB3FA",
                            }
                          : item.dates[key] === "paid"
                          ? {
                              borderRight: "1px solid #ddd",
                              background: "#67E48B",
                            }
                          : item.dates[key] === "unpaid"
                          ? {
                              borderRight: "1px solid #ddd",
                              background: "#FA8D7F",
                            }
                          : null
                      }
                    ></TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          style={{ display: "flex", columnGap: "32px" }}
          mt={3}
          className="box"
        >
          <Typography>Дни активных проездов</Typography>
          <Typography>Забронированные дни</Typography>
          <Typography>Не оплаченные дни</Typography>
        </Box>
        <EmptyTable
          message="Нет тарифов"
          isActive={!isLoading && !status?.data?.car_activities?.length > 0}
        />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        {/* <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(status.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div> */}
      </div>
    </div>
  )
}

export default LoadingChart
