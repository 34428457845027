import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import SearchIcon from "@material-ui/icons/Search"
import Paper from "@material-ui/core/Paper"
import { CircularProgress } from "@material-ui/core"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"

import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import CustomButton from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import { StyledTableCell } from "../../components/StyledTableCell"
import Pagination from "../../components/Pagination/Pagination"
import Tag from "../../components/Tag"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import { addIcon } from "../../assets/icons/icons"
import parseQuery from "../../functions/parseQuery"

function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const [items, setItems] = useState({})

  const [isLoading, setIsLoading] = useState(true)
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10
  let debounce = setTimeout(() => {}, 0)
  let { limit, offset } = parseQuery()

  useEffect(() => {
    getItems({
      limit: limit ?? pageCount,
      offset: offset ?? 0,
      type: "salesman",
    })
  }, [])
  // **** FUNCTIONS ****
  const getItems = (params = {}) => {
    axios.get(`/system-user`, { params }).then((res) => {
      setItems(res.data)
      setIsLoading(false)
    })
  }

  // **** EVENTS ****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        search: val,
        type: "salesman",
      })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/settings/roles/salesmen`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("salesmen")}
        endAdornment={
          permissions.includes("settings_roles_salesmen_create")
            ? [
                <CustomButton
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() =>
                    history.push("/home/settings/roles/salesmen/create")
                  }
                >
                  {t("create")}
                </CustomButton>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 pb-4">
        <TableContainer className="mt-4" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("photo")}</StyledTableCell>
                <StyledTableCell>{t("mechanic")}</StyledTableCell>
                <StyledTableCell>{t("branch")}</StyledTableCell>
                <StyledTableCell>{t("status")}</StyledTableCell>
                {permissions.includes("settings_roles_salesmen_delete") && (
                  <StyledTableCell></StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.system_users && items.system_users.length ? (
                items.system_users.map(
                  (
                    {
                      id,
                      type,
                      status,
                      branch,
                      full_name,
                      phone_number,
                      photo,
                    },
                    index
                  ) => (
                    <TableRow
                      key={id}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        if (
                          permissions.includes("settings_roles_salesmen_edit")
                        )
                          return history.push(
                            `/home/settings/roles/salesmen/${id}`
                          )
                      }}
                    >
                      <StyledTableCell>
                        {photo ? (
                          <img
                            src={`https://cdn.car24.uz/car24/${photo}`}
                            alt="image"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <AccountCircleIcon style={{ fontSize: "300%" }} />
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {full_name}
                        <div className="flex text-blue-600">
                          {formatPhoneNumber(phone_number)}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{branch.name}</StyledTableCell>
                      <StyledTableCell>
                        <Tag
                          shape="subtle"
                          color={status ? "blue" : "error"}
                          size="large"
                        >
                          {status ? "Активный" : "Неактивный"}
                        </Tag>
                      </StyledTableCell>
                      {permissions.includes(
                        "settings_roles_salesmen_delete"
                      ) && (
                        <MoreClickHandler
                          id={id}
                          api="/system-user"
                          params={{
                            limit: limit ?? pageCount,
                            offset: offset ?? 0,
                            type: "salesman",
                          }}
                          pushLocation="/home/settings/roles/salesmen"
                          editPermission="settings_roles_salesmen_edit"
                          deletePermission="settings_roles_salesmen_delete"
                          getItems={() =>
                            getItems({
                              limit: limit ?? pageCount,
                              offset: offset ?? 0,
                              type: "salesman",
                            })
                          }
                        />
                      )}
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}

export default App
