import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'

import authReducer from './authReducer'
import counterReducer from "./counterReducer";
import alertReducer from './alertReducer';


const authPersistConfig = {
  key: 'auth',
  storage,
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  counter: counterReducer,
  alert: alertReducer
})

export default rootReducer
