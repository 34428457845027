import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import moment from "moment"
import "moment/locale/ru"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons"
import Select from "../../components/Select"
import Filters from "../../components/Filters"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import { StyledTableCell } from "../../components/StyledTableCell"
import AlertComponent from "../../components/Alert"
import { StyledTab, StyledTabs } from "../../components/StyledTabs"
import { addIcon } from "../../assets/icons/icons"
import RangePicker from "../../components/DatePicker/RangePicker"
import EmptyTable from "../../components/EmptyTable"
import cashIcon from "../../assets/icons/cash.png"
import clickIcon from "../../assets/icons/click.png"
import humoIcon from "../../assets/icons/humo.png"
import uzcardIcon from "../../assets/icons/uzcard.png"
import visaIcon from "../../assets/icons/visa.png"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import paymeIcon from "../../assets/icons/payme.png"
import parseQuery from "../../functions/parseQuery"

const icons = {
  cash: cashIcon,
  click: clickIcon,
  humo: humoIcon,
  uzcard: uzcardIcon,
  visa: visaIcon,
  payme: paymeIcon,
}

export default function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)
  const pageCount = 10
  const userType = localStorage.getItem("userType")

  const [investors, setInvestors] = useState()
  const [cars, setCars] = useState()
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})
  const [subCategories, setSubCategories] = useState([])
  const [expenseType, setExpenseType] = useState()
  const [paymentType, setPaymentType] = useState()

  const { limit, offset, tab } = parseQuery()

  useEffect(() => {
    getItems({
      limit: limit ?? pageCount,
      offset: offset ?? 0,
      tab: tab ?? 0,
      category_id: tab === "0" ? "3" : "2",
      start_date: fromToDate.start,
      end_date: fromToDate.end,
      subcategory_id: expenseType?.value,
      payment_type: paymentType?.value,
    })
    getSubCategories()
    getInvestors() + tab && getCars()
  }, [expenseType, paymentType, fromToDate, limit, offset, tab])

  // **** FUNCTIONS ****
  const getItems = async (params = {}) => {
    let response = await axios.get(`/billing/transaction`, {
      params,
    })

    setItems(response)
    setIsLoading(false)
  }

  const getSubCategories = async () => {
    let response = await axios.get(`/billing/subaccount-category`, {
      params: {
        limit: 100,
        offset: 0,
        category_id: tab === "0" ? "3" : "2",
      },
    })

    setSubCategories(
      response?.sub_account_categories?.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    )
  }

  const getInvestors = async () => {
    let response = await axios.get(`/investor?limit=1000`)
    setInvestors(response.data.investors)
  }

  const getCars = async () => {
    let response = await axios.get(`/car?limit=1000`)
    setCars(response.data.cars)
  }

  const updateExpenseType = (val) => setExpenseType(val)

  const updatePaymentType = (val) => setPaymentType(val)

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    changeTabLocationQuery(newValue)
    setFromToDate({ start: "", end: "" })
    updateExpenseType(null)
  }

  const tabLabel = (text, number = "", isActive = false) => {
    return (
      <span
        className={`px-1 ${
          !isActive ? "text-warmGray-500" : "text-primary-600"
        }`}
      >
        {text}
      </span>
    )
  }

  // **** EVENTS ****
  const changeTabLocationQuery = (tabId) => {
    history.push({
      pathname: `/home/billing/expenses`,
      search: `?tab=${tabId}`,
    })
  }

  const changePaginationLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/billing/expenses`,
      search: `?limit=${pageCount}&offset=${offset}&tab=${tab ?? 0}`,
    })
  }

  const paymentTypes = [
    {
      label: t("cash"),
      value: "cash",
    },
    {
      label: t("humo"),
      value: "humo",
    },
    {
      label: t("click"),
      value: "click",
    },
    {
      label: t("uzcard"),
      value: "uzcard",
    },
    {
      label: t("visa"),
      value: "visa",
    },
    {
      label: t("payme"),
      value: "payme",
    },
  ]
  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("costs")}
        endAdornment={
          permissions.includes("billing_expenses_create") &&
          userType === "CASHIER"
            ? [
                <Button
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() => history.push(`/home/billing/expenses/create`)}
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters
        extra={
          <div className="flex space-x-2">
            <Select
              placeholder={t("expense.type")}
              options={subCategories}
              value={expenseType}
              onChange={(val) => updateExpenseType(val)}
              isClearable
              style={{ width: "170px" }}
            />
            <Select
              placeholder={t("payment.type")}
              options={paymentTypes}
              value={paymentType}
              onChange={(val) => updatePaymentType(val)}
              isClearable
              style={{ width: "150px" }}
            />
            <RangePicker
              showToday={false}
              showWeekNumber
              dateInputPlaceholder={[t("from"), t("till")]}
              showOk={false}
              showClear
              placeholder={t("select.a.date")}
              hideTimePicker
              onChange={(val) =>
                setFromToDate({
                  start: moment(val[0]).toISOString() ?? "",
                  end: moment(val[1]).toISOString() ?? "",
                })
              }
            />
          </div>
        }
      ></Filters>

      <div className="px-5 py-4">
        <StyledTabs
          value={+tab}
          onChange={handleChange}
          centered={false}
          aria-label="full width tabs example"
          TabIndicatorProps={{ children: <span className="w-2" /> }}
          className="bg-white rounded"
        >
          <StyledTab
            label={tabLabel(t("employee_expenditure"), "", +tab === 0)}
            {...a11yProps(0)}
          />
          <StyledTab
            label={tabLabel(t("car_expenditure"), "", +tab === 1)}
            {...a11yProps(1)}
          />
        </StyledTabs>
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("expense.type")}</StyledTableCell>
                <StyledTableCell>{t("sum")}</StyledTableCell>
                <StyledTableCell>{t("date")}</StyledTableCell>
                <StyledTableCell>
                  {+tab ? t("car") : t("investor")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.data && items?.data?.billing_transactions?.length ? (
                items.data?.billing_transactions?.map(
                  (
                    {
                      id,
                      date,
                      subaccount_category,
                      payments,
                      investor_id,
                      state_number,
                    },
                    index
                  ) => (
                    <TableRow
                      key={id}
                      className="hover:bg-gray-100 cursor-pointer transition ease-linear"
                      onClick={() => {
                        history.push(`/home/billing/expenses/${id}`)
                      }}
                    >
                      <StyledTableCell>
                        {subaccount_category.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="flex space-x-4 items-center">
                          <span
                            className="flex justify-center 
                                    items-center border 
                                    rounded-md
                                    py-2 px-6  "
                            style={{ width: "85px", height: "40px" }}
                          >
                            <img src={icons[payments[0].payment_type]} alt="" />
                          </span>
                          <p>
                            {convertToInternationalCurrencySystem(
                              payments[0].amount
                            )}
                          </p>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="text-blue-600">
                          {dateTimeConvert(date)}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {state_number}
                      </StyledTableCell>
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable
          isActive={
            !isLoading && !items?.data?.billing_transactions?.length > 0
          }
          message="Нет инфо"
        />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.data?.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changePaginationLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}
