import { useState } from "react"
import Upload from "."
import { CircularProgress } from "@material-ui/core"
import axios from "../../utils/axios"
import VisibilityIcon from "@material-ui/icons/Visibility"
import DeleteIcon from "@material-ui/icons/Delete"
import pdfIcon from "../../assets/icons/pdf.png"
import wordIcon from "../../assets/icons/word.png"
import { useLocation } from "react-router-dom/cjs/react-router-dom"

export default function ImgUpload({
  onChange = () => {},
  onStart = () => {},
  onFinish = () => {},
  onError = () => {},
  onSuccess = () => {},
  defaultImage,
  shape,
  children,
  acceptImgs = ["png", "jpeg", "webp", "svg+xml"],
  action = "/upload",
  maxSize,
  minSize,
  documentsPage,
  dispatchError,
  tab,
  ...props
}) {
  const [loading, setLoading] = useState(null)

  const [imgUrl, setImgUrl] = useState(
    defaultImage 
        ? `https://test.cdn.car24.udevs.io/car24/${defaultImage}` 
        : ""
  )

  const onFileDrag = (files) => {
    setLoading(true)
    if (documentsPage && !+tab) {
      if (
        files[0].type.includes("msword") ||
        files[0].type.includes("officedocument.word")
      ) {
        onStart(files)
        const formData = new FormData()
        formData.append("file", files[0])
        axios
          .post_default(action, formData)
          .then((res) => {
            setImgUrl("https://" + res.file_url)
            onChange(res)
            onSuccess(res)
          })
          .catch((err) => onError(err))
          .finally(() => {
            setLoading(false)
            onFinish("finished")
          })
      } else {
        setLoading(false)
        dispatchError()
      }
    } else {
      onStart(files)
      const formData = new FormData()
      formData.append("file", files[0])
      axios
        .post_default(action, formData)
        .then((res) => {
          setImgUrl("https://" + res.file_url)
          onChange(res)
          onSuccess(res)
        })
        .catch((err) => onError(err))
        .finally(() => {
          setLoading(false)
          onFinish("finished")
        })
    }
  }

  const onImgDelete = (event) => {
    event.stopPropagation()
    setImgUrl("")
    onChange("")
  }

  const Img = ({ types }) => {
    return (
      <div className="img-content">
        <img
          alt="uploaded file"
          src={
            imgUrl.includes(".pdf")
              ? pdfIcon
              : imgUrl.includes(".doc") || imgUrl.includes(".docx")
              ? wordIcon
              : imgUrl
          }
          style={{
            borderRadius: shape === "rounded" ? "50%" : "6px",
            height: "100%",
            width: shape === "rounded" && "155px",
            objectFit: "cover",
          }}
        />
        <div
          className="img-buttons"
          style={{
            borderRadius: shape === "rounded" ? "50%" : "6px",
            height: shape === "rounded" && "155px",
            width: shape === "rounded" && "155px",
          }}
        >
          <VisibilityIcon
            style={{ color: "#fff", fontSize: 20 }}
            onClick={(e) => {
              e.stopPropagation()
              window.open(imgUrl, "_blank")
            }}
          />
          <DeleteIcon
            style={{ color: "#fff", fontSize: 20, marginLeft: 10 }}
            onClick={props.disabled ? () => {} : onImgDelete}
          />
        </div>
      </div>
    )
  }

  return (
    <Upload
      multiple={false}
      onChange={onFileDrag}
      accept={acceptImgs.map((val) => "image/" + val)}
      maxSize={maxSize}
      minSize={minSize}
      shape={shape}
      {...props}
    >
      {loading ? (
        <CircularProgress size={30} />
      ) : imgUrl ? (
        <Img types={acceptImgs} />
      ) : (
        children
      )}
    </Upload>
  )
}
