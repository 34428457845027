import { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import moment from "moment"
import axios from "../../../../utils/axios"
import Header from "../../../../components/Header/Header"
import Filters from "../../../../components/Filters"
import convertToInternationalCurrencySystem from "../../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../../components/EmptyTable"
import RangePicker from "../../../../components/DatePicker/RangePicker"
import ConvertToExcel from "../../../../components/ConvertToExcel"
import downloadFile from "../../../../functions/downloadFile"
import ReportSkeleton from "../../../../components/Skeleton/ReportSkeleton"

const InvestorCarList = () => {
  const { t } = useTranslation()
  const params = useParams()
  const [items, setItems] = useState([])
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })

  useEffect(() => {
    const getItems = async () => {
      let response = await axios.get(`/report/car`, {
        params: {
          start_date: fromToDate.start,
          end_date: fromToDate.end,
          investor_id: params.id,
        },
      })
      setItems(response)
      setIsLoading(false)
    }
    getItems()
  }, [fromToDate, params.id])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/report/car-excel`, {
        params: {
          start_date: fromToDate.start,
          end_date: fromToDate.end,
          investor_id: params.id,
        },
      })
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const updateDate = (val) =>
    setFromToDate({
      start: moment(val[0]).toISOString() ?? "",
      end: moment(val[1]).toISOString() ?? "",
    })

  const visibleCosts = useMemo(() => {
    const result = []

    items.forEach((item) => {
      item.costs?.forEach((cost) => {
        if (cost.Amount > 0) result.push(cost.Name)
      })
    })

    return result
  }, [items])

  return (
    <div>
      <Header title={t("investorCarListReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        <RangePicker
          showToday={false}
          showWeekNumber
          dateInputPlaceholder={[t("from"), t("till")]}
          showOk={false}
          showClear
          placeholder={t("select.a.date")}
          hideTimePicker
          onChange={(val) => updateDate(val)}
        />
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  rowSpan={3}
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                >
                  {t("Машина")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                >
                  {t("Номер")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                >
                  {t("Сумма")}
                </TableCell>
                {items[0]?.costs
                  ?.filter((cost) => visibleCosts?.includes(cost?.Name))
                  .map((el, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {el.Name}
                    </TableCell>
                  ))}
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                >
                  {t("Расход сумма")}
                </TableCell>
              </TableRow>
            </TableHead>

            {items?.map((item, index) => (
              <TableRow
                key={index}
                style={{
                  background: index % 2 === 0 && "#F6F8F9",
                }}
              >
                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {item?.model}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  <div className="text-blue-500 rounded border">
                    {item?.state_number}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.total_income)}
                </TableCell>
                {item?.costs
                  ?.filter((cost) => visibleCosts?.includes(cost?.Name))
                  .map((el, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      {convertToInternationalCurrencySystem(el.Amount)}
                    </TableCell>
                  ))}
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(
                    item?.costs?.reduce((acc, curr) => acc + curr.Amount, 0)
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет отчетов"
          isActive={!isLoading && !items?.length > 0}
        />
        {isLoading && <ReportSkeleton />}
      </div>
    </div>
  )
}

export default InvestorCarList
