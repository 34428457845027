import { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import SearchIcon from "@material-ui/icons/Search"
import { CircularProgress } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"

import carIcon from "../../assets/settingsIcons/car.png"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import CustomButton from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import { StyledTableCell } from "../../components/StyledTableCell"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import EmptyTable from "../../components/EmptyTable"
import { addIcon } from "../../assets/icons/icons"
import parseQuery from "../../functions/parseQuery"

function Companies() {
  const { t } = useTranslation()
  const history = useHistory()
  const [items, setItems] = useState({})

  const [isLoading, setIsLoading] = useState(true)
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10

  let debounce = setTimeout(() => {}, 0)
  let { limit, offset } = parseQuery()

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [])

  // **** FUNCTIONS ****
  const getItems = (params = {}) => {
    axios.get(`/brand`, { params }).then((res) => {
      setItems(res.data)
      setIsLoading(false)
    })
  }

  // **** EVENTS ****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, search: val })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/settings/catalog/brands`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("brands")}
        endAdornment={
          permissions.includes("settings_catalog_brands_create")
            ? [
                <CustomButton
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() =>
                    history.push("/home/settings/catalog/brands/create")
                  }
                >
                  {t("create")}
                </CustomButton>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 pb-4">
        <TableContainer className="mt-4" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("photo")}</StyledTableCell>
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("created.date")}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.brands && items.brands?.length ? (
                items.brands.map(({ id, photo, name, created_at }, index) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                    onClick={() => {
                      if (permissions.includes("settings_catalog_brands_edit"))
                        return history.push(
                          `/home/settings/catalog/brands/${id}`
                        )
                    }}
                  >
                    <StyledTableCell>
                      <img
                        alt="ad-img"
                        width={50}
                        src={
                          photo
                            ? `https://cdn.car24.uz/car24/${photo}`
                            : carIcon
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>{name}</StyledTableCell>
                    <StyledTableCell>
                      <div className="flex text-blue-600">
                        {dateTimeConvert(created_at)}
                      </div>
                    </StyledTableCell>
                    <MoreClickHandler
                      id={id}
                      api="/brand"
                      pushLocation="/home/settings/catalog/brands"
                      editPermission="settings_catalog_brands_edit"
                      deletePermission="settings_catalog_brands_delete"
                      getItems={() =>
                        getItems({
                          limit: limit ?? pageCount,
                          offset: offset ?? 0,
                        })
                      }
                    />
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable isActive={!isLoading && !items.brands?.length > 0} />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}

export default Companies
