import React, { useState } from "react"
import axios from "../../../utils/axios"
import Upload from "../index"
import { CircularProgress } from "@material-ui/core"
import FileDownloadIcon from "@material-ui/icons/CloudDownload"
import DeleteIcon from "@material-ui/icons/Delete"
import pdfIcon from "../../../assets/icons/pdf.png"
import wordIcon from "../../../assets/icons/word.png"
import { downloadImage } from "../../../functions/downloadFile"

function FileUpload({
  onChange = () => {},
  onStart = () => {},
  onFinish = () => {},
  onError = () => {},
  onSuccess = () => {},
  defaultFile,
  shape,
  children,
  action = "/upload",
  maxSize,
  minSize,
  ...props
}) {
  const [loading, setLoading] = useState(false)

  const [fileUrl, setFileUrl] = useState(
    defaultFile ? `https://${defaultFile}` : ""
  )

  const onFileDrag = (files) => {
    console.log(files)
    setLoading(true)
    onStart(files)
    const formData = new FormData()
    formData.append("file", files[0])
    axios
      .post_default(action, formData)
      .then((res) => {
        setFileUrl("https://" + res.file_url)
        console.log(res.file_url)
        onChange(res)
        onSuccess(res)
      })
      .catch((err) => onError(err))
      .finally(() => {
        setLoading(false)
        onFinish("finished")
      })
  }

  const onFileDelete = (event) => {
    event.stopPropagation()
    setFileUrl("")
    onChange("")
  }

  const Img = () => {
    return (
      <div className="img-content">
        <img
          alt="uploded img"
          src={
            fileUrl.slice(-3) === "pdf"
              ? pdfIcon
              : fileUrl.slice(-3) === "doc"
              ? wordIcon
              : fileUrl
          }
          style={{
            borderRadius: shape === "rounded" ? "50%" : "6px",
            height: "100%",
            width: shape === "rounded" && "155px",
            objectFit: "cover",
          }}
        />
        <div
          className="img-buttons"
          style={{
            borderRadius: shape === "rounded" ? "50%" : "6px",
            height: shape === "rounded" && "155px",
            width: shape === "rounded" && "155px",
          }}
        >
          <FileDownloadIcon
            style={{ color: "#fff", fontSize: 20 }}
            onClick={(e) => {
              e.stopPropagation()
              let ext = fileUrl.slice(-4)
              downloadImage(fileUrl, `User_File_${Date.now() + ext}`)
            }}
          />
          <DeleteIcon
            style={{ color: "#fff", fontSize: 20, marginLeft: 10 }}
            onClick={onFileDelete}
          />
        </div>
      </div>
    )
  }

  return (
    <Upload
      multiple={false}
      onChange={onFileDrag}
      maxSize={maxSize}
      minSize={minSize}
      shape={shape}
      {...props}
    >
      {loading ? <CircularProgress size={30} /> : fileUrl ? <Img /> : children}
    </Upload>
  )
}

export default FileUpload
