import * as Yup from "yup"

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const loginRegExp = /^[a-zA-Z][a-zA-Z0-9_]{5,29}$/

export default function validateForm(name, t) {
  const YupValidation = {
    default: Yup.string()
      .trim(t("spaces.error"))
      .strict(true)
      .required(t("required.field.error")),
    number: Yup.number()
      .strict(true)
      .max(1000000000, t("range.max"))
      .required(t("required.field.error")),
    numberOptional: Yup.number().strict(false).max(100000000, t("range.max")),
    car_number: Yup.string()
      .min(10, t("invalid.car.number"))
      .max(11, t("invalid.car.number"))
      .required(t("required.field.error")),
    mixed: Yup.mixed().strict(true).required(t("required.field.error")),
    array: Yup.array().of(
      Yup.string()
        .min(5, t("range.min"))
        .max(19, t("range.max"))
        .matches(phoneRegExp, t("invalid.number"))
        .required(t("required.field.error"))
    ),
    multiple_select: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        })
      )
      .required(t("required.field.error")),
    first_name: Yup.string()
      .trim(t("spaces.error"))
      .strict(true)
      .required(t("required.field.error")),
    passport_pinfl: Yup.string()
      .min(14, t("pinfl.min"))
      .max(14, t("pinfl.min"))
      .trim(t("spaces.error"))
      .strict(true)
      .required(t("required.field.error")),
    last_name: Yup.string().trim(t("spaces.error")).strict(true),
    middle_name: Yup.string().trim(t("spaces.error")).strict(true),
    login: Yup.string()
      .required(t("required.field.error"))
      .min(6, t("login.too.short"))
      .matches(/[a-zA-Z]/, t("only.latin.letters")),
    loginRegExp: Yup.string()
      .required(t("required.field.error"))
      .min(6, t("login.too.short"))
      .matches(loginRegExp, t("invalid.login")),
    password: Yup.string()
      .min(6, t("password.too.short"))
      .required(t("required.field.error")),
    address: Yup.string().trim(t("spaces.error")).strict(true),
    email: Yup.string()
      .email(t("invalid.email"))
      .required(t("required.field.error")),
    phone_number: Yup.string()
      .min(5, t("range.min"))
      .max(19, t("invalid.number"))
      .matches(phoneRegExp, t("invalid.number"))
      .required(t("required.field.error")),
    account_number: Yup.string()
      .min(20, t("invalid.number"))
      .max(20, t("invalid.number"))
      .required(t("required.field.error")),
    passport_number: Yup.string()
      .trim(t("spaces.error"))
      .strict(true)
      .min(9, t("invalid.passport.number"))
      .max(9, t("invalid.passport.number")),
    driving_license_number: Yup.string().trim(t("spaces.error")).strict(true),
    inn: Yup.string()
      .trim(t("spaces.error"))
      .strict(true)
      .min(9, t("invalid.inn"))
      .max(9, t("invalid.inn"))
      .matches(/^\d+$/, t("invalid.inn"))
      .required(t("required.field.error")),
    price: Yup.number()
      .required(t("required.field.error"))
      .test("Is positive?", t("invalid.price"), (value) => value > 0),
    emailOptional: Yup.string().email(t("invalid.email")),
    director: Yup.string().trim(t("spaces.error")).strict(true),
  }

  return YupValidation[name]
}
