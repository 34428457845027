import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import AlertComponent from "../../components/Alert"
import { showAlert } from "../../redux/reducers/alertReducer"
import { StyledTab, StyledTabs, TabPanel } from "../../components/StyledTabs"
import Filters from "../../components/Filters"
import SwipeableViews from "react-swipeable-views"
import Context from "./tabs/Context"
import { useTheme } from "@material-ui/core/styles"
import OrdersHistory from "./tabs/OrdersHistory"
import CarHistory from "./tabs/CarHistory"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import { useSelector } from "react-redux"
import moment from "moment"

function InvestorsCreate() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const theme = useTheme()
  const location = useLocation()

  const permissions = useSelector((state) => state.auth.permissions)

  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [image, setImage] = useState("")

  const [initialValues, setInitialValues] = useState({
    login: "",
    description: "",
    address: "",
    password: "",
    status: false,
    name: "",
    date_of_birth: "",
    phone_number: "",
    additional_phone_numbers: [],
    photo: "",
  })

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = (id) => {
    setIsGettingData(true)
    axios
      .get(`/investor/${id}`)
      .then((res) => {
        setInitialValues({
          ...res,
          phone_number: formatPhoneNumber(res.phone_number),
        })
        setImage(res.photo)
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/investor/${params.id}`, {
          ...values,
          date_of_birth: values.date_of_birth
            ? moment(values.date_of_birth).toISOString()
            : "",
          photo: image,
          phone_number: values.phone_number.replace(/ +/g, ""),
          status: values.status ? 1 : 0,
          password: values.password || "",
        })
        .then((res) => {
          history.push("/home/settings/organizations/investors")
        })
        .catch((err) => {
          dispatch(showAlert(t(err.data?.error || err?.error), "error"))
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/investor", {
          ...values,
          date_of_birth: moment(values.date_of_birth).toISOString(),
          photo: image,
          phone_number: values.phone_number.replace(/ +/g, ""),
          status: values.status ? 1 : 0,
          password: values.password || "",
        })
        .then((res) => {
          history.push("/home/settings/organizations/investors")
        })
        .catch((err) => {
          dispatch(showAlert(t(err.data?.error || err?.error), "error"))
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    date_of_birth: validateForm("", t),
    login: validateForm("login", t),
    password:
      location.pathname.includes("settings/organizations/investors/create") &&
      params.id &&
      permissions.includes("сменить пароль") &&
      validateForm("password", t),
    phone_number: validateForm("phone_number", t),
    additional_phone_numbers: validateForm("array", t),
  })

  const routes = [
    {
      title: t("organizations"),
      link: true,
      route: "/home/settings/organizations",
    },
    {
      title: t("investors"),
      link: true,
      route: "/home/settings/organizations/investors",
    },
    {
      title: params.id ? initialValues.name : t("create"),
    },
  ]

  // **** TABS **** //
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>
  }

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("investor")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={
                  value === 0 && [
                    <CustomButton
                      textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                      shape="text"
                      color="text-error-600"
                      icon={cancelIcon}
                      onClick={() =>
                        history.push("/home/settings/organizations/investors")
                      }
                    >
                      {t("cancel")}
                    </CustomButton>,
                    <CustomButton
                      textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                      type="submit"
                      shape="text"
                      color="text-primary-600"
                      icon={
                        loading ? (
                          <CircularProgress color="inherit" size={14} />
                        ) : (
                          saveIcon
                        )
                      }
                    >
                      {params.id ? t("save") : t("create")}
                    </CustomButton>,
                  ]
                }
              />
              <Filters>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{ children: <span className="w-2" /> }}
                  className="rounded"
                >
                  <StyledTab
                    label={tabLabel(t("about.branch"))}
                    {...a11yProps(0)}
                  />
                  {params.id && (
                    <StyledTab
                      label={tabLabel(t("order.history"))}
                      {...a11yProps(1)}
                    />
                  )}
                  {params.id && (
                    <StyledTab label={tabLabel(t("cars"))} {...a11yProps(2)} />
                  )}
                </StyledTabs>
              </Filters>
              <AlertComponent
                style={{
                  top: "2px",
                  left: "45%",
                  marginLeft: "-100px",
                }}
              />
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                  <Context formik={formik} />
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <OrdersHistory />
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <CarHistory />
                </TabPanel>
              </SwipeableViews>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default InvestorsCreate
