import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { animated, useTransition } from "react-spring"
import authRoutes from "./auth-routes"
import dashboardRoutes from "./dashboard-routes"
import fallbackRoutes from "./fallback-routes"
import DashboardLayout from "../containers/RouterLayout"
import FallbackLayout from "../containers/EmptyLayout"
import Fallback403 from "../views/exceptions/Fallback403.jsx"

const noAccessComponent = () => (
  <>
    <Fallback403 />
  </>
)

const layouts = [
  {
    component: DashboardLayout,
    path: "/home",
    routes: dashboardRoutes,
    private: true,
  },
  {
    component: FallbackLayout,
    path: "/extra",
    routes: fallbackRoutes,
    private: false,
  },
]

const AppRouter = () => {
  const token = localStorage.getItem("token")
  const permissions = useSelector((state) => state.auth.permissions)
  const location = useLocation()
  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  if (!token)
    return (
      <Switch>
        {authRoutes.map((route) => (
          <Route
            path={route.path}
            exact={route.exact}
            key={route.id}
            render={(routeProps) => (
              <route.layout history={routeProps.history}>
                <route.component {...routeProps} />
              </route.layout>
            )}
          />
        ))}
        <Redirect to="/auth/login" />
      </Switch>
    )

  return (
    <Switch>
      {layouts.map((layout, index) => (
        <Route
          key={index}
          path={layout.path}
          render={(routeProps) => (
            <layout.component>
              {transitions((props, item) => (
                <animated.div style={props}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <Switch location={item}>
                        {layout.routes.map((route) => (
                          <Route
                            key={route.id}
                            path={route.path}
                            component={
                              !permissions.includes(route.permission)
                                ? noAccessComponent
                                : route.component
                            }
                            exact
                          />
                        ))}
                      </Switch>
                    </div>
                  </div>
                </animated.div>
              ))}
            </layout.component>
          )}
        ></Route>
      ))}
      <Redirect to="/home/dashboard" />
    </Switch>
  )
}

export default AppRouter
