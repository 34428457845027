import {
  SET_AUTH_CREDENTIALS,
  SET_AUTH_TOKENS,
  CLEAR_ON_SIGNOUT,
  IS_LOADING,
  REFRESH_ACCESS_TOKEN,
} from "../constants"

const INITIAL_STATE = {
  phoneNumber: "",
  accessToken: "",
  refreshToken: "",
  clientId: "",
  userId: "",
  roleId: "",
  userType: "",
  isLoading: false,
  permissions: [],
}

export default function authReducer(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_AUTH_CREDENTIALS:
      return {
        ...state,
        phoneNumber: payload,
      }
    case SET_AUTH_TOKENS:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        clientId: payload.clientId,
        userId: payload.userId,
        roleId: payload.roleId,
        userType: payload.userType,
        permissions: payload.permissions,
      }
    case REFRESH_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      }
    case CLEAR_ON_SIGNOUT:
      return INITIAL_STATE
    default:
      return state
  }
}
