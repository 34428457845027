import "./index.scss"
export default function Input({
  icon = "",
  className,
  style,
  disabled,
  error,
  maxLength = "100",
  ...rest
}) {
  return (
    <div
      className={`
                border
                bg-white
                flex
                space-x-2
                items-center
                rounded
                text-body
                relative
                w-full
                text-gray-600
                font-smaller
                focus-within:outline-none
                focus-within:ring-1
                focus-within:ring-blue-600
                transition ease-linear
                hover:border-gray-400
                ${error ? "border-red-600" : "border-gray-200"}
                ${className}
            `}
      style={{
        ...style,
        height: "33px",
        padding: "0 10px",
        ":focus": {
          border: "2px solid rgba(64, 148, 247, 0.5)",
          boxShadow:
            "var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(text-gray-500)",
        },
      }}
    >
      <div>{icon}</div>
      <input
        className="w-full"
        type="text"
        maxLength={maxLength}
        min="0"
        max="1000000000"
        {...rest}
      ></input>
      {disabled ? (
        <div
          className="absolute inset-0 rounded cursor-not-allowed"
          style={{ backgroundColor: "rgba(221, 226, 228, 0.5)", margin: 0 }}
        />
      ) : null}
    </div>
  )
}
