import TableCell from "@material-ui/core/TableCell"

const TableBodyCell = ({ title, fontWeight }) => {
  return (
    <TableCell
      align="center"
      style={{
        fontWeight: fontWeight ? "600" : "500",
        borderRight: "1px solid #ddd",
        whiteSpace: "pre",
      }}
    >
      {title}
    </TableCell>
  )
}

export default TableBodyCell