import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import { CircularProgress } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import Paper from "@material-ui/core/Paper"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import CustomButton from "../../components/Buttons"
import { StyledTableCell } from "../../components/StyledTableCell"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import Pagination from "../../components/Pagination/Pagination"
import Tag from "../../components/Tag"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import branchIcon from "../../assets/settingsIcons/branch.png"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import EmptyTable from "../../components/EmptyTable"
import { addIcon } from "../../assets/icons/icons"
import parseQuery from "../../functions/parseQuery"

function Branches() {
  const { t } = useTranslation()
  const history = useHistory()
  const [items, setItems] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10

  let debounce = setTimeout(() => {}, 0)
  let { limit, offset } = parseQuery()

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [])

  // **** FUNCTIONS ****
  const getItems = (params = {}) => {
    axios.get(`/branch`, { params }).then((res) => {
      setItems(res.data)
      setIsLoading(false)
    })
  }

  // **** EVENTS *****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, search: val })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/settings/organizations/branches`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  const onRowClick = (id) => {
    if (permissions.includes("settings_organizations_branches_edit")) {
      return history.push(`/home/settings/organizations/branches/${id}`)
    }
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("branches")}
        endAdornment={
          permissions.includes("settings_organizations_branches_create")
            ? [
                <CustomButton
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() =>
                    history.push("/home/settings/organizations/branches/create")
                  }
                >
                  {t("create")}
                </CustomButton>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 pb-4">
        <TableContainer className="mt-4" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("photo")}</StyledTableCell>
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("contact.number")}</StyledTableCell>
                <StyledTableCell>{t("address")}</StyledTableCell>
                <StyledTableCell>{t("status")}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.branches && items.branches?.length ? (
                items.branches?.map(
                  (
                    { id, address, status, name, phone_number, photo },
                    index
                  ) => (
                    <TableRow
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer"
                      style={{ background: index % 2 === 0 && "#F6F8F9" }}
                      onClick={() => onRowClick(id)}
                    >
                      <StyledTableCell>
                        <img
                          src={
                            photo
                              ? `https://cdn.car24.uz/car24/${photo}`
                              : branchIcon
                          }
                          width="50"
                          alt=""
                        />
                      </StyledTableCell>
                      <StyledTableCell>{name}</StyledTableCell>
                      <StyledTableCell>
                        {formatPhoneNumber(phone_number)}
                      </StyledTableCell>

                      <StyledTableCell>{address}</StyledTableCell>
                      <StyledTableCell>
                        <Tag
                          shape="subtle"
                          color={status ? "blue" : "error"}
                          size="large"
                        >
                          {status ? "Активный" : "Неактивный"}
                        </Tag>
                      </StyledTableCell>

                      <MoreClickHandler
                        id={id}
                        api="/branch"
                        pushLocation="/home/settings/organizations/branches"
                        editPermission="settings_organizations_branches_edit"
                        deletePermission="settings_organizations_branches_delete"
                        getItems={() =>
                          getItems({
                            limit: limit ?? pageCount,
                            offset: offset ?? 0,
                          })
                        }
                      />
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет филиалов"
          isActive={!isLoading && !items.branches?.length > 0}
        />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}

export default Branches
