import Pagination from "@material-ui/lab/Pagination";

function SimplePagination({ defaultPage, count, onChange }) {
  return (
    <Pagination
      className="mt-5"
      defaultPage={defaultPage}
      count={count}
      variant="outlined"
      shape="rounded"
      color="primary"
      onChange={onChange}
    />
  );
}

export default SimplePagination;
