import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import moment from "moment"

import Pagination from "../../components/Pagination/Pagination"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import Tag from "../../components/Tag"
import { StyledTableCell } from "../../components/StyledTableCell"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import EmptyTable from "../../components/EmptyTable"
import parseQuery from "../../functions/parseQuery"

function TableContext(props) {
  const {
    offset,
    permissions,
    items,
    pageCount,
    changePaginationLocationQuery,
    isLoading,
  } = props

  const { t } = useTranslation()
  const history = useHistory()

  let { limit } = parseQuery()

  return (
    <div className="px-5 py-4">
      <TableContainer className="mt-2" elevation={0} component={Paper}>
        <Table aria-label="simple table" style={{ overflowX: "auto" }}>
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("order.number")}</StyledTableCell>
              <StyledTableCell>{t("client")}</StyledTableCell> {/* */}
              <StyledTableCell style={{ width: "9%" }}>
                {t("date")}, {t("day.count")}
              </StyledTableCell>
              <StyledTableCell>{t("tariff")}</StyledTableCell>
              <StyledTableCell>{t("total.price")}</StyledTableCell>
              <StyledTableCell>{t("creator")}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.data && items.data?.orders?.length ? (
              items.data.orders?.map(
                (
                  {
                    id,
                    order_number,
                    client,
                    tariff,
                    created_at,
                    paid_price,
                    total_price,
                    from_time,
                    start_date,
                    day_count,
                    to_time,
                    is_paid,
                    is_cost_paid,
                    is_cost_exist,
                    initiator,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                    onClick={() => {
                      if (permissions.includes("orders_edit"))
                        return history.push(`/home/orders/${id}`)
                    }}
                  >
                    <StyledTableCell>{order_number}</StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={client.is_blocked ? { color: "#DC2626" } : null}
                      >
                        {client.first_name} {client.last_name}
                      </div>
                      <div className="text-blue-600">
                        {formatPhoneNumber(client.phone_number)}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div>{`${moment(start_date).format("YYYY-MM-DD")},`}</div>
                      <div>{`${day_count} дней`}</div>
                    </StyledTableCell>

                    <StyledTableCell>{tariff.name}</StyledTableCell>
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(total_price)}
                    </StyledTableCell>

                    <StyledTableCell>
                      {initiator.type && (
                        <Tag
                          shape="subtle"
                          size="large"
                          style={{ width: "160px" }}
                        >
                          {t(initiator.type).toLowerCase()}
                        </Tag>
                      )}
                      <p className="mt-1">{initiator.name}</p>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable
        message="Нет заказов"
        isActive={!isLoading && !items.data?.orders?.length > 0}
      />
      {isLoading && (
        <div className="flex justify-center py-2">
          <CircularProgress />
        </div>
      )}
      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(
            limit && !offset ? limit / pageCount : items.data?.count / pageCount
          )}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changePaginationLocationQuery}
        />
      </div>
    </div>
  )
}

export default TableContext
