const userType = localStorage.getItem("userType")

const icon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM5 14C4.45 14 4 13.55 4 13V10C4 9.45 4.45 9 5 9C5.55 9 6 9.45 6 10V13C6 13.55 5.55 14 5 14ZM9 14C8.45 14 8 13.55 8 13V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V13C10 13.55 9.55 14 9 14ZM9 9C8.45 9 8 8.55 8 8C8 7.45 8.45 7 9 7C9.55 7 10 7.45 10 8C10 8.55 9.55 9 9 9ZM13 14C12.45 14 12 13.55 12 13V5C12 4.45 12.45 4 13 4C13.55 4 14 4.45 14 5V13C14 13.55 13.55 14 13 14Z"
      fill="#6E8BB7"
    />
  </svg>
)

const menu = [
  // Dashboard
  {
    title: "Дашборд",
    path: "/home/dashboard",
    permission: "dashboard",
    isActive: false,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
          fill="#6E8BB7"
        />
      </svg>
    ),
  },

  // Orders
  {
    title: "Заказы",
    path: "/home/orders?tab=0",
    isActive: false,
    permission: "orders",
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 1C0 1.55 0.45 2 1 2H2L5.6 9.59L4.25 12.03C3.52 13.37 4.48 15 6 15H17C17.55 15 18 14.55 18 14C18 13.45 17.55 13 17 13H6L7.1 11H14.55C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C20.25 2.82 19.77 2 19.01 2H4.21L3.54 0.57C3.38 0.22 3.02 0 2.64 0H1C0.45 0 0 0.45 0 1ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z"
          fill="#6E8BB7"
        />
      </svg>
    ),
  },

  // Clients
  {
    title: "Клиенты",
    path: "/home/clients?tab=0",
    isActive: false,
    permission: "clients",
    icon: (
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 3C8 3.79565 7.68393 4.55871 7.12132 5.12132C6.55871 5.68393 5.79565 6 5 6C4.20435 6 3.44129 5.68393 2.87868 5.12132C2.31607 4.55871 2 3.79565 2 3C2 2.20435 2.31607 1.44129 2.87868 0.87868C3.44129 0.316071 4.20435 0 5 0C5.79565 0 6.55871 0.316071 7.12132 0.87868C7.68393 1.44129 8 2.20435 8 3ZM16 3C16 3.39397 15.9224 3.78407 15.7716 4.14805C15.6209 4.51203 15.3999 4.84274 15.1213 5.12132C14.8427 5.3999 14.512 5.62087 14.1481 5.77164C13.7841 5.9224 13.394 6 13 6C12.606 6 12.2159 5.9224 11.8519 5.77164C11.488 5.62087 11.1573 5.3999 10.8787 5.12132C10.6001 4.84274 10.3791 4.51203 10.2284 4.14805C10.0776 3.78407 10 3.39397 10 3C10 2.20435 10.3161 1.44129 10.8787 0.87868C11.4413 0.316071 12.2044 0 13 0C13.7956 0 14.5587 0.316071 15.1213 0.87868C15.6839 1.44129 16 2.20435 16 3ZM11.93 14C11.976 13.673 12 13.34 12 13C12.0023 11.4289 11.4737 9.90302 10.5 8.67C11.2601 8.23116 12.1223 8.00013 13 8.00013C13.8776 8.00012 14.7399 8.23114 15.4999 8.66997C16.26 9.1088 16.8912 9.73997 17.3301 10.5C17.7689 11.2601 18 12.1223 18 13V14H11.93ZM5 8C6.32608 8 7.59785 8.52678 8.53553 9.46447C9.47322 10.4021 10 11.6739 10 13V14H0V13C0 11.6739 0.526784 10.4021 1.46447 9.46447C2.40215 8.52678 3.67392 8 5 8Z"
          fill="#6E8BB7"
        />
      </svg>
    ),
  },

  // Billing info
  {
    title: "Биллинг",
    path: "/home/billing",
    isActive: false,
    permission: "billing",
    icon: (
      <svg
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.8 7.9C3.53 7.31 2.8 6.7 2.8 5.75C2.8 4.66 3.81 3.9 5.5 3.9C6.92 3.9 7.63 4.44 7.89 5.3C8.01 5.7 8.34 6 8.76 6H9.06C9.72 6 10.19 5.35 9.96 4.73C9.54 3.55 8.56 2.57 7 2.19V1.5C7 0.67 6.33 0 5.5 0C4.67 0 4 0.67 4 1.5V2.16C2.06 2.58 0.5 3.84 0.5 5.77C0.5 8.08 2.41 9.23 5.2 9.9C7.7 10.5 8.2 11.38 8.2 12.31C8.2 13 7.71 14.1 5.5 14.1C3.85 14.1 3 13.51 2.67 12.67C2.52 12.28 2.18 12 1.77 12H1.49C0.82 12 0.35 12.68 0.6 13.3C1.17 14.69 2.5 15.51 4 15.83V16.5C4 17.33 4.67 18 5.5 18C6.33 18 7 17.33 7 16.5V15.85C8.95 15.48 10.5 14.35 10.5 12.3C10.5 9.46 8.07 8.49 5.8 7.9Z"
          fill="#6E8BB7"
        />
      </svg>
    ),
    children: [
      {
        title: "Расход",
        path: "/home/billing/expenses?tab=0",
        permission: "billing_expenses",
        isChild: true,
        icon: icon,
      },
      {
        title: "Тип расхода",
        path: "/home/billing/expense-types?tab=0",
        permission: "billing_expense_types",
        isChild: true,
        icon: icon,
      },
      {
        title: "Касса",
        path: "/home/billing/cashbox",
        permission: "billing_cashbox",
        isChild: true,
        icon: icon,
      },
      {
        title: "Авто Расходы",
        path: "/home/billing/car-expenses",
        permission: "billing_cashbox",
        isChild: true,
        icon: icon,
      },
    ],
  },

  // Reports
  {
    title: "Отчеты",
    path: "/home/reports",
    isActive: false,
    permission: "reports",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM5 14C4.45 14 4 13.55 4 13V10C4 9.45 4.45 9 5 9C5.55 9 6 9.45 6 10V13C6 13.55 5.55 14 5 14ZM9 14C8.45 14 8 13.55 8 13V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V13C10 13.55 9.55 14 9 14ZM9 9C8.45 9 8 8.55 8 8C8 7.45 8.45 7 9 7C9.55 7 10 7.45 10 8C10 8.55 9.55 9 9 9ZM13 14C12.45 14 12 13.55 12 13V5C12 4.45 12.45 4 13 4C13.55 4 14 4.45 14 5V13C14 13.55 13.55 14 13 14Z"
          fill="#6E8BB7"
        />
      </svg>
    ),
    children: [
      {
        title: "Общий отчет",
        path: "/home/reports/total-reports",
        permission: "reports",
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по филиалам",
        path: "/home/reports/branches",
        permission: "reports_by_branches",
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по категориям",
        path: "/home/reports/categories",
        permission: "reports_by_cars",
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по моделям",
        path: "/home/reports/models",
        permission: "reports_by_cars",
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по тарифам",
        path: "/home/reports/tariffs",
        permission: "reports_by_tariffs",
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по категориям тарифов",
        path: "/home/reports/tariff-category",
        permission: "reports_by_tariffs",
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по инвесторам",
        path: "/home/reports/investors",
        permission: "reports_by_investors",
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по автомобилям инвестора",
        path: "/home/reports/investor_cars", // orders
        permission: "reports_by_orders", // investor_cars
        isChild: true,
        icon: icon,
      },
      {
        title: "Отчет по доле инвестора",
        path: "/home/reports/investor_share",
        permission: "reports_by_orders",
        isChild: true,
        icon: icon,
      },
      {
        title: "График загружености",
        path: "/home/reports/loading-chart",
        permission: "reports_by_orders",
        isChild: true,
        icon: icon,
      },
      userType !== "INVESTOR" && {
        title: "Список должников",
        path: "/home/reports/debtors",
        permission: "reports_by_orders",
        isChild: true,
        icon: icon,
      },
      // userType !== "INVESTOR" && {
      //   title: "Cashflow",
      //   path: "/home/reports/report/cashflow",
      //   permission: "reports_by_orders",
      //   isChild: true,
      //   icon: icon,
      // },
      // userType !== "INVESTOR" && {
      //   title: "P&L",
      //   path: "/home/reports/report/pl",
      //   permission: "reports_by_orders",
      //   isChild: true,
      //   icon: icon,
      // },
      userType !== "INVESTOR" && {
        title: "Отчет по заказам",
        path: "/home/reports/orders",
        permission: "reports_by_orders",
        isChild: true,
        icon: icon,
      },
      userType !== "INVESTOR" && {
        title: "Отчет по депозитам",
        path: "/home/reports/deposits",
        permission: "reports_by_orders",
        isChild: true,
        icon: icon,
      },
      userType !== "INVESTOR" && {
        title: "Отчет по воронке продаж",
        path: "/home/reports/sales-funnel",
        permission: "reports_by_orders",
        isChild: true,
        icon: icon,
      },
      userType !== "INVESTOR" && {
        title: "Отчет по РФМ анализ",
        path: "/home/reports/rfm-analysis",
        permission: "reports_by_orders",
        isChild: true,
        icon: icon,
      },
    ],
  },

  // Cars
  {
    title: "Машины",
    path: "/home/cars",
    isActive: false,
    permission: "cars",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5H6.5C5.84 5 5.29 5.42 5.08 6.01L3.11 11.68C3.04 11.89 3 12.11 3 12.34V19.5C3 20.33 3.67 21 4.5 21C5.33 21 6 20.33 6 19.5V19H18V19.5C18 20.32 18.67 21 19.5 21C20.32 21 21 20.33 21 19.5V12.34C21 12.12 20.96 11.89 20.89 11.68L18.92 6.01ZM6.5 16C5.67 16 5 15.33 5 14.5C5 13.67 5.67 13 6.5 13C7.33 13 8 13.67 8 14.5C8 15.33 7.33 16 6.5 16ZM17.5 16C16.67 16 16 15.33 16 14.5C16 13.67 16.67 13 17.5 13C18.33 13 19 13.67 19 14.5C19 15.33 18.33 16 17.5 16ZM5 11L6.27 7.18C6.41 6.78 6.79 6.5 7.22 6.5H16.78C17.21 6.5 17.59 6.78 17.73 7.18L19 11H5Z"
          fill="#6E8BB7"
        />
      </svg>
    ),
  },

  // Settings
  {
    title: "Настройки",
    path: "/home/settings",
    isActive: false,
    permission: "settings",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.192 9.81666C15.2254 9.54999 15.2504 9.28332 15.2504 8.99999C15.2504 8.71666 15.2254 8.44999 15.192 8.18332L16.9504 6.80832C17.1087 6.68332 17.1504 6.45832 17.0504 6.27499L15.3837 3.39166C15.2837 3.20832 15.0587 3.14166 14.8754 3.20832L12.8004 4.04166C12.367 3.70832 11.9004 3.43332 11.392 3.22499L11.0754 1.01666C11.0504 0.816657 10.8754 0.666656 10.667 0.666656H7.33369C7.12535 0.666656 6.95035 0.816657 6.92535 1.01666L6.60869 3.22499C6.10035 3.43332 5.63369 3.71666 5.20035 4.04166L3.12535 3.20832C2.93369 3.13332 2.71702 3.20832 2.61702 3.39166L0.950354 6.27499C0.842021 6.45832 0.892021 6.68332 1.05035 6.80832L2.80869 8.18332C2.77535 8.44999 2.75035 8.72499 2.75035 8.99999C2.75035 9.27499 2.77535 9.54999 2.80869 9.81666L1.05035 11.1917C0.892021 11.3167 0.850354 11.5417 0.950354 11.725L2.61702 14.6083C2.71702 14.7917 2.94202 14.8583 3.12535 14.7917L5.20035 13.9583C5.63369 14.2917 6.10035 14.5667 6.60869 14.775L6.92535 16.9833C6.95035 17.1833 7.12535 17.3333 7.33369 17.3333H10.667C10.8754 17.3333 11.0504 17.1833 11.0754 16.9833L11.392 14.775C11.9004 14.5667 12.367 14.2833 12.8004 13.9583L14.8754 14.7917C15.067 14.8667 15.2837 14.7917 15.3837 14.6083L17.0504 11.725C17.1504 11.5417 17.1087 11.3167 16.9504 11.1917L15.192 9.81666ZM9.00035 11.9167C7.39202 11.9167 6.08369 10.6083 6.08369 8.99999C6.08369 7.39166 7.39202 6.08332 9.00035 6.08332C10.6087 6.08332 11.917 7.39166 11.917 8.99999C11.917 10.6083 10.6087 11.9167 9.00035 11.9167Z"
          fill="#6E8BB7"
        />
      </svg>
    ),
    children: [
      // Attributes
      {
        title: "Аттрибуты",
        path: "/home/settings/attributes",
        permission: "settings_attributes",
        isChild: true,
        icon: icon,
      },
      // Attribute Category
      {
        title: "Категория аттрибута",
        path: "/home/settings/attribute-category",
        permission: "settings_attribute_categories",
        isChild: true,
        icon: icon,
      },
      // Payment Account
      // {
      //   title: "Расчетный счет",
      //   path: "/home/settings/payment-account",
      //   permission: "settings_attributes",
      //   isChild: true,
      //   icon: icon,
      // },
      // Tariffs
      {
        title: "Тарифы",
        path: "/home/settings/tariffs",
        permission: "settings_tariffs",
        isChild: true,
        icon: icon,
      },
      // Tariff Category
      {
        title: "Категория тарифов",
        path: "/home/settings/tariff-category",
        permission: "settings_attribute_categories",
        isChild: true,
        icon: icon,
      },
      // Additional Tariffs
      {
        title: "Дополнительные тарифы",
        path: "/home/settings/additional-tariffs",
        permission: "settings_additional_tariffs",
        isChild: true,
        icon: icon,
      },
      // Costs
      {
        title: "Расходы",
        path: "/home/settings/costs",
        permission: "settings_costs",
        isChild: true,
        icon: icon,
      },
      // Organizations
      {
        title: "Организации",
        path: `/home/settings/organizations`,
        permission: "settings_organizations",
        isChild: true,
        icon: icon,
        children: [
          // Company
          // {
          //   title: "Компании",
          //   path: "/home/settings/organizations/companies",
          //   permission: "settings_organizations_companies",
          //   isChild: true,
          //   icon: icon,
          // },
          // Firm
          {
            title: "Фирм",
            path: "/home/settings/organizations/firms",
            permission: "settings_organizations_companies",
            isChild: true,
            icon: icon,
          },
          // Branch
          {
            title: "Филиалы",
            path: "/home/settings/organizations/branches",
            permission: "settings_organizations_branches",
            isChild: true,
            icon: icon,
          },
          // Investor
          {
            title: "Инвесторы",
            path: "/home/settings/organizations/investors",
            permission: "settings_organizations_investors",
            isChild: true,
            icon: icon,
          },
          // Car Owner
          {
            title: "Владельцы",
            path: "/home/settings/organizations/car-owners",
            permission: "settings_organizations_investors",
            isChild: true,
            icon: icon,
          },
        ],
      },

      // Roles
      {
        title: "Роли",
        path: "/home/settings/roles",
        permission: "settings_roles",
        isChild: true,
        icon: icon,
        children: [
          // Admins
          {
            title: "Админы",
            path: "/home/settings/roles/admins",
            permission: "settings_roles_admins",
            isChild: true,
            icon: icon,
          },
          // Mechanics
          {
            title: "Механики",
            path: "/home/settings/roles/mechanics",
            permission: "settings_roles_mechanics",
            isChild: true,
            icon: icon,
          },
          // Mechanics
          {
            title: "Продавцы",
            path: "/home/settings/roles/salesmen",
            permission: "settings_roles_salesmen",
            isChild: true,
            icon: icon,
          },
          // Cashiers
          {
            title: "Кассиры",
            path: "/home/settings/roles/cashiers",
            permission: "settings_roles_cashiers",
            isChild: true,
            icon: icon,
          },
          // Operators
          {
            title: "Операторы",
            path: "/home/settings/roles/operators",
            permission: "settings_roles_operators",
            isChild: true,
            icon: icon,
          },
        ],
      },

      // Catalog
      {
        title: "Каталоги",
        path: "/home/settings/catalog",
        permission: "settings_catalog",
        isChild: true,
        icon: icon,
        children: [
          // Body Type
          {
            title: "Тип кузова",
            path: "/home/settings/catalog/body-type",
            permission: "settings_catalog_categories",

            isChild: true,
            icon: icon,
          },

          // Brands
          {
            title: "Брэнды",
            path: "/home/settings/catalog/brands",
            permission: "settings_catalog_brands",
            isChild: true,
            icon: icon,
          },

          // Categories
          {
            title: "Категории",
            path: "/home/settings/catalog/categories",
            permission: "settings_catalog_models",
            isChild: true,
            icon: icon,
          },
          // Models
          {
            title: "Модели",
            path: "/home/settings/catalog/models",
            permission: "settings_catalog_categories",

            isChild: true,
            icon: icon,
          },
        ],
      },
      // Sales Funnel
      {
        title: "Воронка продаж",
        path: "/home/settings/sales-funnel",
        permission: "settings_attribute_categories",
        isChild: true,
        icon: icon,
      },
      // Documents
      {
        title: "Документы",
        path: "/home/settings/documents?tab=0",
        permission: "settings_documents",
        isChild: true,
        icon: icon,
      },
      // About Us
      {
        title: "О нас",
        path: "/home/settings/about-us",
        permission: "settings_about_us",
        isChild: true,
        icon: icon,
      },
      // Confidentiality
      {
        title: "Конфиденциальность",
        path: "/home/settings/confidentiality",
        permission: "settings_confidentiality",
        isChild: true,
        icon: icon,
      },

      // Permissions
      {
        title: "Разрешения",
        path: "/home/settings/permissions",
        permission: "settings_permissions",
        isChild: true,
        icon: icon,
      },
    ],
  },
]

export default menu
