import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import moment from "moment"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Filters from "../../components/Filters"
import { StyledTabs, StyledTab } from "../../components/StyledTabs"
import { TabPanel } from "../../components/Tab/TabBody"
import SwipeableViews from "react-swipeable-views"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import { showAlert } from "../../redux/reducers/alertReducer"
import AlertComponent from "../../components/Alert"
import GeneralInfo from "./tabs/GeneralInfo"
import { useTheme } from "@material-ui/core/styles"
import ClientOrders from "./tabs/ClientOrders"
import ClientOldOrders from "./tabs/ClientOldOrders"

function ClientsCreate() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const theme = useTheme()
  const location = useLocation()

  // Tabs
  const [value, setValue] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [image, setImage] = useState("")
  const [drivingLicense, setDrivingLicense] = useState("")
  const [passport, setPassport] = useState("")
  const [propiska, setPropiska] = useState("")

  const [initialValues, setInitialValues] = useState({
    // general info
    photo: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    address: "",
    phone_number: "",
    additional_phone_numbers: [],
    is_blocked: 0,
    // technical info
    driving_license: "",
    driving_license_number: "",
    driving_license_expire_date: "",
    driving_license_given_date: "",
    driving_license_given_place: "",
    passport: "",
    passport_pinfl: "",
    propiska: "",
  })

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = (id) => {
    setIsGettingData(true)
    axios
      .get(`/client/${id}`)
      .then((res) => {
        setInitialValues({
          ...res,
          phone_number: res.phone_number,
          passport_pinfl: res?.passport_pinfl?.replace(/\s+/g, ""),
        })
        setImage(res.photo)
        setDrivingLicense(res.driving_license)
        setPassport(res.passport)
        setPropiska(res.propiska)
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    setBtnDisabled(true)
    let data = {
      ...values,
      phone_number: values.phone_number.replace(/ +/g, ""),
      photo: image,
      driving_license: drivingLicense,
      passport: passport,
      propiska: propiska,
      driving_license_expire_date: moment(
        values.driving_license_expire_date
      ).toISOString(),
      driving_license_given_date: moment(
        values.driving_license_given_date
      ).toISOString(),
    }

    if (params.id) {
      axios
        .put(`/client/${params.id}`, data)
        .then((res) => {
          history.push("/home/clients?tab=0")
        })
        .catch((err) => {
          dispatch(showAlert(t(err.data?.error), "error"))
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    } else {
      axios
        .post("/client", data)
        .then((res) => {
          history.push("/home/clients?tab=0")
        })
        .catch((err) => {
          dispatch(showAlert(t(err.data?.error), "error"))
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    photo: validateForm("photo", t),
    first_name: validateForm("first_name", t),
    last_name: validateForm("last_name", t),
    middle_name: validateForm("middle_name", t),
    phone_number: validateForm("phone_number", t),
    address: validateForm("address", t),
    driving_license: validateForm("driving_license", t),
    driving_license_number: validateForm("driving_license_number", t),
    passport: validateForm("passport", t),
    passport_pinfl: validateForm("passport_pinfl", t),
    propiska: validateForm("propiska", t),
    additional_phone_numbers: validateForm("array", t),
  })

  const routes = [
    {
      title: t("clients"),
      link: true,
      route: "/home/clients",
    },

    {
      title: params.id
        ? initialValues.first_name + " " + initialValues.last_name
        : t("create"),
    },
  ]

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => setValue(newValue)

  const handleChangeIndex = (index) => setValue(index)

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>
  }

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("client")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    shape="text"
                    color="text-error-600"
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    icon={cancelIcon}
                    onClick={() => history.push("/home/clients")}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                    disabled={btnDisabled}
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />
              <Filters>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{ children: <span className="w-2" /> }}
                >
                  <StyledTab
                    label={tabLabel(t("about.client"))}
                    {...a11yProps(0)}
                    style={{ width: "100px" }}
                  />
                  {!location.pathname.includes("/home/clients/create") && (
                    <StyledTab
                      label={tabLabel(t("order.history"))}
                      {...a11yProps(1)}
                    />
                  )}
                   {!location.pathname.includes("/home/clients/create") && (
                    <StyledTab
                      label={tabLabel(t("Старая история заказов"))}
                      {...a11yProps(2)}
                    />
                  )}
                </StyledTabs>
              </Filters>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <GeneralInfo
                    formik={formik}
                    comments={initialValues.comments}
                    params={params}
                    setInitialValues={setInitialValues}
                    onImage={(image) => setImage(image)}
                    onDrivingLicense={(driving_license) =>
                      setDrivingLicense(driving_license)
                    }
                    onPassport={(passport) => setPassport(passport)}
                    onPropiska={(propiska) => setPropiska(propiska)}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <ClientOrders />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <ClientOldOrders />
                </TabPanel>
              </SwipeableViews>

              <AlertComponent
                style={{
                  top: "2px",
                  left: "45%",
                  marginLeft: "-100px",
                }}
              />
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default ClientsCreate
