import styled from "styled-components";

import InputMask from "react-input-mask";
import MaskedInput from "react-text-mask";

export const StyledInputPhone = styled(InputMask)`
  height: 32px;
  padding: 0px 14px;
  border: 1px solid rgba(229, 231, 235);
  border-radius: 0.2rem;
  background: #fff;

  margin: 0;
  width: 100%;
  line-height: 24px;
  font-size: 12.5px;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: linear;
  transition-duration: 150ms;
  &:hover {
    border: 1px solid #b3b3b3;
  }
  &:focus-within {
    box-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width))
      var(rgba(59, 130, 246, var(--tw-ring-opacity)));

    border: 2px solid rgba(64, 148, 247, 1);
  }
  &:disabled {
    background: rgba(246, 248, 249, 1);
  }
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.006em;
  }
`;

export const StyledInputPrice = styled(MaskedInput)`
  height: 32px;
  padding: 0px 14px;
  border: 1px solid rgba(229, 231, 235);
  border-radius: 0.2rem;
  background: #fff;

  margin: 0;
  width: 100%;
  line-height: 24px;
  font-size: 12.5px;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: linear;
  transition-duration: 150ms;
  &:hover {
    border: 1px solid #b3b3b3;
  }
  &:focus-within {
    box-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width))
      var(rgba(59, 130, 246, var(--tw-ring-opacity)));

    border: 2px solid rgba(64, 148, 247, 1);
  }
  &:disabled {
    background: rgba(246, 248, 249, 1);
  }
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12.5px;
    line-height: 24px;
    letter-spacing: -0.006em;
  }
`;
