import { useState } from "react"
import Upload from "."
import { CircularProgress } from "@material-ui/core"
import axios from "../../utils/axios"
import VisibilityIcon from "@material-ui/icons/Visibility"
import DeleteIcon from "@material-ui/icons/Delete"
import pdfIcon from "../../assets/icons/pdf.png"
import wordIcon from "../../assets/icons/word.png"

// const acceptImg = ["image/png", "image/jpeg", "image/webp"]
export default function ImgUpload({
  onChange = () => {},
  onStart = () => {},
  onFinish = () => {},
  onError = () => {},
  onSuccess = () => {},
  defaultImage,
  shape,
  children,
  acceptImgs = ["png", "jpeg", "webp", "svg+xml"],
  action = "/upload",
  maxSize,
  minSize,
  ...props
}) {
  const [loading, setLoading] = useState(null)
  const [imgUrl, setImgUrl] = useState(
    defaultImage ? `https://${defaultImage}` : ""
  )

  const onFileDrag = (files) => {
    console.log(files)
    setLoading(true)
    onStart(files)
    const formData = new FormData()
    formData.append("file", files[0])
    axios
      .post_default(action, formData)
      .then((res) => {
        setImgUrl("https://" + res.file_url)
        onChange(res)
        onSuccess(res)
      })
      .catch((err) => onError(err))
      .finally(() => {
        setLoading(false)
        onFinish("finished")
      })
  }

  const onImgDelete = (event) => {
    event.stopPropagation()
    setImgUrl("")
    onChange("")
  }

  const Img = ({ types }) => {
    return (
      <div className="img-content">
        <img
          alt="uploded img"
          src={
            imgUrl.slice(-3) === "pdf"
              ? pdfIcon
              : imgUrl.slice(-3) === "doc"
              ? wordIcon
              : imgUrl
          }
          style={{
            borderRadius: shape === "rounded" ? "50%" : "6px",
            height: "100%",
            width: shape === "rounded" && "155px",
            objectFit: "cover",
          }}
        />
        <div
          className="img-buttons"
          style={{
            borderRadius: shape === "rounded" ? "50%" : "6px",
            height: shape === "rounded" && "155px",
            width: shape === "rounded" && "155px",
          }}
        >
          <VisibilityIcon
            style={{ color: "#fff", fontSize: 20 }}
            onClick={(e) => {
              e.stopPropagation()
              window.open(imgUrl, "_blank")
            }}
          />
          <DeleteIcon
            style={{ color: "#fff", fontSize: 20, marginLeft: 10 }}
            onClick={onImgDelete}
          />
        </div>
      </div>
    )
  }

  return (
    <Upload
      multiple={false}
      onChange={onFileDrag}
      accept={acceptImgs.map((val) => "image/" + val)}
      maxSize={maxSize}
      minSize={minSize}
      shape={shape}
      {...props}
    >
      {loading ? (
        <CircularProgress size={30} />
      ) : imgUrl ? (
        <Img types={acceptImgs} />
      ) : (
        children
      )}
    </Upload>
  )
}
