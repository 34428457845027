import { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import CircularProgress from "@material-ui/core/CircularProgress"
import axios from "../../../../utils/axios"
import Header from "../../../../components/Header/Header"
import CustomButton from "../../../../components/Buttons/index"
import Card from "../../../../components/Card/index"
import Form from "../../../../components/Form/Index"
import validateForm from "../../../../functions/validateForm"
import DatePicker from "../../../../components/DatePicker"
import { cancelIcon, saveIcon } from "../../../../assets/icons/icons"
import Textarea from "../../../../components/Textarea"
import Breadcrumb from "../../../../components/Breadcrumb/index"
import Input from "../../../../components/Input/index"
import AsyncSelect from "../../../../components/Select/AsyncSelect"
import { FieldArray } from "formik"
import AddIcon from "@material-ui/icons/Add"
import Button from "../../../../components/Buttons/index"
import DeleteIcon from "@material-ui/icons/Delete"
import "react-image-lightbox/style.css"
import ImageUpload from "../../../../components/Upload/ImgUpload"

const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 10.8332H10.8334V14.9998C10.8334 15.4582 10.4584 15.8332 10.0001 15.8332C9.54175 15.8332 9.16675 15.4582 9.16675 14.9998V10.8332H5.00008C4.54175 10.8332 4.16675 10.4582 4.16675 9.99984C4.16675 9.5415 4.54175 9.1665 5.00008 9.1665H9.16675V4.99984C9.16675 4.5415 9.54175 4.1665 10.0001 4.1665C10.4584 4.1665 10.8334 4.5415 10.8334 4.99984V9.1665H15.0001C15.4584 9.1665 15.8334 9.5415 15.8334 9.99984C15.8334 10.4582 15.4584 10.8332 15.0001 10.8332Z"
      fill="#703581"
    />
  </svg>
)

function Fine() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [names, setNames] = useState([])
  const [initialValues, setInitialValues] = useState({
    images: [{ comment: "", image: "" }],
    name: "",
    price: "",
    date: "",
    comment: "",
  })

  useEffect(() => {
    if (params.fine_id) {
      getProperty(params.fine_id)
    }
    getNames()
  }, [params.fine_id])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/order-cost/${id}`)
      .then((res) => {
        let data = {
          ...res,
          name: { label: res.name, value: res.cost_id },
          date: res.date,
          price: res.price,
          comment: res.comment,
          images: res?.images?.length
            ? res?.images
            : [{ comment: "", image: "" }],
        }
        setInitialValues(data)
        setImages(res.images.map((el) => el.image))
      })
      .finally(() => setIsGettingData(false))
  }

  const loadNameOptions = async (inputValue, callback) => {
    let res = await axios.get(`/cost?limit=5&offset=0&search=${inputValue}`)
    callback(
      res.data.costs?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        }
      })
    )
  }

  const getNames = async () => {
    let res = await axios.get(`/cost`)

    setNames((prev) => [
      ...prev,
      ...res.data.costs?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ])
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    setBtnDisabled(true)

    const data = {
      ...values,
      name: values.name.label,
      comment: values.comment,
      cost_id: values.name.value,
      order_id: params.id,
    }
    if (params.fine_id) {
      axios
        .put(`/order-cost/${values.id}`, data)
        .then((res) => {
          setLoading(false)
          setBtnDisabled(false)
        })
        .finally(() => {
          history.goBack()
        })
    } else {
      axios
        .post(`/order-cost`, data)
        .then((res) => {
          setLoading(false)
          setBtnDisabled(false)
        })
        .finally(() => {
          history.goBack()
        })
    }
  }

  // **** CONSTANTS ****
  const routes = useMemo(() => {
    const arr = []
    if (params.fine_id) {
      arr.push([
        {
          title: params.fine_id?.slice(0, 8),
          link: true,
          route: `/home/orders/${params.id}/fines/${params.fine_id}`,
        },
        {
          title: t("edit"),
        },
      ])
    } else {
      arr.push([
        {
          title: t("create"),
        },
      ])
    }
    const routes = [
      {
        title: t("orders"),
        link: true,
        route: `/home/orders`,
      },
      {
        title: params.id?.slice(0, 8),
        link: true,
        route: `/home/orders/${params.id}`,
      },
      {
        title: t("fines"),
        link: true,
        route: `/home/orders/${params.id}/`,
      },
    ]
    return routes.concat(...arr)
  }, [params.id, params.fine_id, t])

  const ValidationSchema = Yup.object().shape({
    name: validateForm("mixed", t),
    price: validateForm("number", t),
    date: validateForm("mixed", t),
  })

  return (
    <>
      {!params.fine_id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("fine")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={
                  params.fine_id
                    ? [
                        <CustomButton
                          size="large"
                          shape="text"
                          color="text-secondary"
                          onClick={() => history.goBack()}
                        >
                          {t("back")}
                        </CustomButton>,
                      ]
                    : params.fine_id
                    ? [
                        <CustomButton
                          shape="text"
                          color="text-error-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={cancelIcon}
                          onClick={() => history.goBack()}
                        >
                          {t("cancel")}
                        </CustomButton>,
                        <CustomButton
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                          disabled={btnDisabled}
                        >
                          {t("save")}
                        </CustomButton>,
                      ]
                    : [
                        <CustomButton
                          shape="text"
                          color="text-error-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={cancelIcon}
                          onClick={() => history.goBack()}
                        >
                          {t("cancel")}
                        </CustomButton>,
                        <CustomButton
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                          disabled={btnDisabled}
                        >
                          {t("create")}
                        </CustomButton>,
                      ]
                }
              />

              <div className="p-4 w-full grid grid-cols-5 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="col-span-3">
                  {/* Images */}
                  <FieldArray name="images">
                    {({ insert, remove, push }) => (
                      <div>
                        {formik?.values?.images?.length > 0 &&
                          formik?.values?.images?.map((number, index) => (
                            <div className="row" key={index}>
                              {/* Additional image */}
                              <div className="w-full py-2 flex items-center">
                                <div className="w-1/3">{t("photo")}</div>
                                <div
                                  className="w-2/3 flex flex-col items-center flex-wrap min-h-full"
                                  style={{ border: "1px dashed #d5dadd" }}
                                >
                                  <Form.FieldArrayItem
                                    name={`images`}
                                    formik={formik}
                                    index={index}
                                  >
                                    <ImageUpload
                                      onStart={(val) => console.log(val)}
                                      onFinish={(val) => console.log(val)}
                                      onSuccess={(val) => console.log(val)}
                                      onError={(val) => console.log(val)}
                                      defaultImage={images[index]}
                                      className="w-full"
                                      onChange={(val) => {
                                        setImages((prev) => {
                                          var [...cp] = prev
                                          cp[index] = val.filename || ""
                                          return cp
                                        })
                                        formik.setFieldValue(
                                          `images.${index}.image`,
                                          val.filename || ""
                                        )
                                      }}
                                      disabled={params.fine_id}
                                    >
                                      <div className="flex flex-col items-center justify-center space-y-4 w-full">
                                        <AddIcon style={{ fontSize: "200%" }} />
                                        <h5>{t("select.image")}</h5>
                                      </div>
                                    </ImageUpload>
                                    <Textarea
                                      style={{ minHeight: "90px" }}
                                      placeholder={t("add.comment")}
                                      {...formik.getFieldProps(
                                        `images.${index}.comment`
                                      )}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `images.${index}.comment`,
                                          e.target.value
                                        )
                                      }}
                                      disabled={params.fine_id}
                                    />
                                  </Form.FieldArrayItem>
                                </div>
                                {/* Delete Button */}
                                <div
                                  className="w-0.5/12 p-0.5 cursor-pointer rounded border border-red-300 text-red-300 ml-4"
                                  onClick={() => {
                                    if (!params.fine_id) {
                                      remove(index)
                                      setImages((prev) => {
                                        var [...cp] = prev
                                        return cp.filter(
                                          (item, i) => index !== i
                                        )
                                      })
                                    }
                                  }}
                                  style={{ scale: "0.78" }}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* Add Btn */}
                        <div
                          className="ml-auto border-2 border-dashed rounded flex justify-center mt-4 space-x-2 cursor-pointer w-2/3"
                          onClick={() => {
                            if (!params.fine_id) {
                              push({ comment: "", image: "" })
                            }
                          }}
                        >
                          <Button
                            icon={addIcon}
                            shape="text"
                            color="text-primary-600"
                            disabled={params.fine_id}
                          >
                            {t("add")}
                          </Button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {/* Name */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("name")}</div>
                    <div className="w-2/3">
                      <Form.Item name="name" formik={formik}>
                        <AsyncSelect
                          isDisabled={params.fine_id}
                          loadOptions={loadNameOptions}
                          defaultOptions={names}
                          placeholder=""
                          {...formik.getFieldProps("name")}
                          onChange={(val) => {
                            formik.setFieldValue("name", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Price */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("price")}</div>
                    <div className="w-2/3">
                      <Form.Item name="price" formik={formik}>
                        <Input
                          min="1"
                          type="number"
                          disabled={params.fine_id}
                          {...formik.getFieldProps("price")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Date */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("date")}</div>
                    <div className="w-2/3">
                      <Form.Item name="date" formik={formik}>
                        <DatePicker
                          disabled={params.fine_id}
                          placeholder="Выберите дату"
                          style={{ height: "35px" }}
                          value={
                            formik.values.date
                              ? moment(formik.values.date)
                              : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue("date", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Comment */}
                  <div className="w-full py-2 flex items-baseline">
                    <p className="w-1/3">{t("comment")}:</p>
                    <div className="w-2/3">
                      <Textarea
                        disabled={params.fine_id}
                        type="text"
                        placeholder={t("leave.comment")}
                        style={{
                          minHeight: "100px",
                        }}
                        {...formik.getFieldProps("comment")}
                        onChange={(e) =>
                          formik.setFieldValue("comment", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {/* Fine */}
                  <div className="w-full py-2 flex items-baseline">
                    <p className="w-1/3">{t("payment.status")}:</p>
                    <div className="w-2/3">
                      {initialValues.is_paid ? (
                        <div className="text-green-600">{t("paid")}</div>
                      ) : (
                        <div className="text-red-600">{t("not.paid")}</div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  )
}

export default Fine
