import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function Billing() {
  const history = useHistory();

  useEffect(() => {
    history.push("/home/billing/expenses?tab=0");
  }, []);
  return <div></div>;
}

export default Billing;
