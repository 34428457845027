import { useState, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import CircularProgress from "@material-ui/core/CircularProgress"
import AddIcon from "@material-ui/icons/Add"
import { Formik } from "formik"
import * as Yup from "yup"
import Select from "../../components/Select/index"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import TextArea from "../../components/Textarea"
import Switch from "../../components/Switch"
import ImageUpload from "../../components/Upload/ImgUpload"
import CategoryCard from "./CategoryCard"
import validateForm from "../../functions/validateForm"
import { addIcon, cancelIcon, saveIcon } from "../../assets/icons/icons"
import PriceInput from "../../components/Form/input/PriceInput"
import { showAlert } from "../../redux/reducers/alertReducer"
import AlertComponent from "../../components/Alert"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import { FieldArray } from "formik"
import MultiImgUpload from "../../components/MultiImgUpload/index"

function TariffsCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const [tariffCategories, setTariffCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [attributeCategories, setAttributeCategories] = useState([])
  const [categories, setCategories] = useState([{ label: "", value: "" }])
  const [isGettingData, setIsGettingData] = useState(false)
  const [image, setImage] = useState("")
  const [multiImgs, setMultiImgs] = useState([])
  const [modelAttributes, setModelAttributes] = useState([])
  const [initialValues, setInitialValues] = useState({
    name: "",
    price: undefined,
    daily_limit: undefined,
    over_limit: undefined,
    insurance_price: undefined,
    category_id: "",
    description: "",
    photo: "",
    photos: [""],
    status: true,

    attribute_categories: [],
  })

  useEffect(() => {
    getAttributeCategory()
  }, [])

  useEffect(() => {
    let filteredCategories = attributeCategories
    modelAttributes?.forEach((el, index) => {
      filteredCategories = filteredCategories.filter(
        (item) => item.value !== el.attribute_category.value
      )
    })
    setCategories(filteredCategories)
  }, [attributeCategories, modelAttributes])

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    } else {
      setCategories(attributeCategories)
    }
    getTariffCategories()
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    let tariff_category_res = await axios.get(
      "/tariff_category?limit=100&offset=0"
    )

    await axios
      .get(`/tariff/${id}`)
      .then((res) => {
        const category = tariff_category_res?.data.tariff_categories.find(
          (el) => el.id === res.category.id
        )
        let data = {
          ...res,
          category_id: category
            ? { label: category.name, value: category.id }
            : "",
          photos: [""],
        }
        setMultiImgs(res.photos)
        setInitialValues(data)
        setModelAttributes(
          res.attribute_categories?.map(({ id, name, attributes }) => ({
            attribute_category: { value: id, label: name },
            attributes: attributes?.map(({ id, name, value }) => ({
              value: id,
              label: name,
              selected_value: { label: value.name, value: value.id },
            })),
            options: [],
          })) ?? []
        )
        setImage(res.photo)
      })
      .finally(() => setIsGettingData(false))
  }

  const uniqueImgNames = useMemo(() => {
    return Array.from(new Set(multiImgs))
  }, [multiImgs])

  // **** EVENTS ****
  const onSubmit = (values) => {
    try {
      values.attribute_categories = modelAttributes?.map(
        ({ attribute_category, attributes }) => ({
          id: attribute_category.value,
          attributes: attributes?.map(({ value, selected_value }) => ({
            attribute_id: value,
            value_id: selected_value.value,
          })),
        })
      )
    } catch (error) {
      return dispatch(showAlert(t("attributes.alert")))
    }

    setLoading(true)
    if (typeof values.price === "string")
      values.price = +values.price.replace(/\s+/g, "")
    if (typeof values.daily_limit === "string")
      values.daily_limit = +values.daily_limit.replace(/\s+/g, "")
    if (typeof values.over_limit === "string")
      values.over_limit = +values.over_limit.replace(/\s+/g, "")
    if (typeof values.insurance_price === "string")
      values.insurance_price = +values.insurance_price.replace(/\s+/g, "")

    const computedValues = {
      ...values,
      photo: image,
      status: values.status ? 1 : 0,
      category_id: values.category_id.value,
      photos: uniqueImgNames,
    }

    if (params.id) {
      axios
        .put(`/tariff/${params.id}`, computedValues)
        .then((res) => {
          history.push("/home/settings/tariffs")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/tariff", computedValues)
        .then((res) => {
          history.push("/home/settings/tariffs")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get Attribute Categories and Tariffs
  async function getAttributeCategory() {
    let response = await axios.get("/attribute-category?limit=100&offset=0")

    setAttributeCategories(
      response.data.categories?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  async function getTariffCategories() {
    let response = await axios.get("/tariff_category?limit=100&offset=0")

    setTariffCategories(
      response.data.tariff_categories?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  // Functions for Changing Categories
  const addCategory = () => {
    setModelAttributes((old) => [
      ...old,
      {
        attribute_category: { value: "", label: "" },
        attributes: [{ value: "", label: "", values: [] }],
        options: [],
      },
    ])
  }

  const removeCategory = (i) => {
    setModelAttributes((old) => old.filter((el, index) => i !== index))
  }

  const updateCategory = async (val, i) => {
    let response = await axios.get(
      `/attribute?attribute_category_id=${val.value}&limit=100&offset=0`
    )

    const newModelAttr = modelAttributes?.map((el, index) =>
      index === i
        ? {
            ...el,
            attribute_category: { value: val.value, label: val.label },
            options: response.data.attributes?.map(({ name, id }) => ({
              label: name,
              value: id,
            })),
            attributes: [{ value: "", label: "", selected_value: {} }],
          }
        : el
    )

    setModelAttributes(newModelAttr)

    let filteredCategories = attributeCategories
    newModelAttr.forEach((el, index) => {
      filteredCategories = filteredCategories.filter(
        (item) => item.value !== el.value
      )
    })
    setCategories(filteredCategories)
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    price: validateForm("price", t),
    category_id: validateForm("mixed", t),
  })

  const routes = [
    {
      title: t("tariffs"),
      link: true,
      route: "/home/settings/tariffs",
    },

    {
      title: params.id ? initialValues.name : t("create"),
    },
  ]

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("tariffs")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() => history.push("/home/settings/tariffs")}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full flex flex-col gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="w-1/2">
                  {/* Photo */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("photo")}</div>
                    <div className="w-2/3">
                      <Form.Item name="photo" formik={formik}>
                        <ImageUpload
                          onChange={(val) => setImage(val.filename)}
                          onStart={(val) => console.log(val)}
                          onFinish={(val) => console.log(val)}
                          onSuccess={(val) => console.log(val)}
                          onError={(val) => console.log(val)}
                          defaultImage={image}
                        >
                          <div className="flex flex-col mx-8 items-center justify-center space-y-4">
                            <AddIcon style={{ fontSize: "200%" }} />
                            <h5>{t("select.image")}</h5>
                          </div>
                        </ImageUpload>
                      </Form.Item>
                    </div>
                  </div>

                  {/* Additional images */}
                  <FieldArray name="photos">
                    {({ insert, remove, push }) => (
                      <div>
                        {formik?.values?.photos?.length > 0 &&
                          formik?.values?.photos?.map((number, index) => (
                            <div className="row" key={index}>
                              {/* Additional image */}
                              <div className="w-full py-2 flex items-baseline">
                                <div className="w-1/3">
                                  {t("photo.additional")}
                                </div>
                                <div
                                  className="w-2/3 flex items-center flex-wrap min-h-full"
                                  style={{ border: "1px dashed #d5dadd" }}
                                >
                                  <Form.FieldArrayItem
                                    name={`photos`}
                                    formik={formik}
                                    index={index}
                                  >
                                    <MultiImgUpload
                                      onStart={(val) => console.log(val)}
                                      onFinish={(val) => console.log(val)}
                                      onSuccess={(val) => console.log(val)}
                                      onError={(val) => console.log(val)}
                                      defaultImage={multiImgs}
                                      multiple
                                      id={`photos.${index}`}
                                      {...formik.getFieldProps(
                                        `photos.${index}`
                                      )}
                                      onChange={(val) => {
                                        setMultiImgs(val)
                                      }}
                                    >
                                      <div className="flex flex-col mx-8 items-center justify-center space-y-4">
                                        <AddIcon style={{ fontSize: "200%" }} />
                                      </div>
                                    </MultiImgUpload>
                                  </Form.FieldArrayItem>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </FieldArray>
                  {/* Name */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("name")}</div>
                    <div className="w-2/3">
                      <Form.Item name="name" formik={formik}>
                        <Input
                          id="name"
                          type="text"
                          {...formik.getFieldProps("name")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Price */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("price")}</div>
                    <div className="w-2/3">
                      <Form.Item name="price" formik={formik}>
                        <PriceInput {...formik.getFieldProps("price")} />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Daily limit */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("daily.limit")}</div>
                    <div className="w-2/3">
                      <Form.Item name="daily_limit" formik={formik}>
                        <PriceInput {...formik.getFieldProps("daily_limit")} />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Over limit */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("over.limit")}</div>
                    <div className="w-2/3">
                      <Form.Item name="over_limit" formik={formik}>
                        <PriceInput {...formik.getFieldProps("over_limit")} />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Insurance price */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("insurance.price")}</div>
                    <div className="w-2/3">
                      <Form.Item name="insurance_price" formik={formik}>
                        <PriceInput
                          {...formik.getFieldProps("insurance_price")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Tariff category */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("tariff.category")}</div>
                    <div className="w-2/3">
                      <Form.Item name="category_id" formik={formik}>
                        <Select
                          options={tariffCategories}
                          {...formik.getFieldProps("category_id")}
                          onChange={(val) => {
                            formik.setFieldValue("category_id", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Description */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("description")}</div>
                    <div className="w-2/3">
                      <Form.Item name="description" formik={formik}>
                        <TextArea
                          id="description"
                          type="text"
                          {...formik.getFieldProps("description")}
                          style={{ minHeight: "100px" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Status */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("status")}</div>
                    <div className="flex w-2/3 space-x-4">
                      <div>
                        <Form.Item name="status" formik={formik}>
                          <Switch
                            id="status"
                            color="primary"
                            checked={formik.values.status}
                            onChange={(val) =>
                              formik.setFieldValue("status", val)
                            }
                          />
                        </Form.Item>
                      </div>
                      <div>
                        {formik.values.status ? t("active") : t("inactive")}
                      </div>
                    </div>
                  </div>
                </Card>

                {/* Attribute categories */}
                <div className="flex w-1/2 bg-white rounded justify-center py-2">
                  <h5 className="font-semibold">{t("attribute.categories")}</h5>
                </div>
                {modelAttributes && modelAttributes.length
                  ? modelAttributes.map((el, index) => (
                      <CategoryCard
                        categories={categories}
                        modelAttributes={modelAttributes}
                        removeCategory={removeCategory}
                        updateCategory={updateCategory}
                        setModelAttributes={setModelAttributes}
                        categoryIndex={index}
                        formik={formik}
                        el={el}
                      />
                    ))
                  : ""}
                <div
                  className="flex w-1/2 bg-white rounded justify-center py-2 cursor-pointer"
                  onClick={addCategory}
                >
                  <CustomButton
                    icon={addIcon}
                    shape="text"
                    color="text-primary-600"
                  >
                    {t("add.category")}
                  </CustomButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default TariffsCreate
