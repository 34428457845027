import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import ClearIcon from "@material-ui/icons/Clear"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import Select from "../../components/Select/index"
import validateForm from "../../functions/validateForm"
import { addIcon, cancelIcon, saveIcon } from "../../assets/icons/icons"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import AsyncSelect from "../../components/Select/AsyncSelect"

function CategoriesCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [attributeCategories, setAttributeCategories] = useState([])
  const [categories, setCategories] = useState([{ label: "", value: "" }])
  const [tariffs, setTariffs] = useState([])
  const [models, setModels] = useState([])

  const [isGettingData, setIsGettingData] = useState(false)
  const [modelAttributes, setModelAttributes] = useState([
    {
      attribute_category: { value: "", label: "" },
      attributes: [], /// ===> []
      options: [], // ====> res
    },
  ])
  const [initialValues, setInitialValues] = useState({
    name: "",
    model: undefined,
    tariff: undefined,

    model_attributes: [
      {
        attribute_category: { value: "", label: "" },
        attributes: [{ value: undefined, label: "" }],
      },
    ],
  })

  useEffect(() => {
    getAttributeCategory()
    getModels()
    getTariffs()
  }, [])

  useEffect(() => {
    console.log(attributeCategories, "Attribute Categories")
    let filteredCategories = attributeCategories
    modelAttributes.forEach((el, index) => {
      filteredCategories = filteredCategories.filter(
        (item) => item.value !== el.attribute_category.value
      )
    })
    setCategories(filteredCategories)
  }, [attributeCategories, modelAttributes])

  useEffect(() => {
    console.log(params)
    if (params.id) {
      getProperty(params.id)
      console.log(modelAttributes)
    } else {
      setCategories(attributeCategories)
    }
    console.log(categories, "Categories")
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    let [response] = await Promise.all([
      axios.get("/tariff?limit=100&offset=0").then((res) => res.data),
    ])
    await axios
      .get(`/model/${id}`)
      .then((res) => {
        let data = {
          ...res,
          model: { label: res?.model?.name, value: res?.model?.id },
          tariff: response?.tariffs
            .filter((el) => el.id === res.tariff.id)
            .map((el) => ({ label: el.name, value: el.id }))[0],
        }

        setInitialValues(data)
        if (res.model_attributes !== null) {
          setModelAttributes(
            res.model_attributes.map((el, index) => ({
              // set attribute category
              attribute_category: {
                value: el.attribute_category.id,
                label: el.attribute_category.name,
              },

              // set attributes
              attributes: el.attributes.map((el, id) => ({
                value: el.id,
                label: el.name,
              })),
            }))
          )
        }
      })
      .finally(() => setIsGettingData(false))
  }

  const loadModelOptions = async (inputValue, callback) => {
    let res = await axios.get(`/model1?limit=5&offset=0&search=${inputValue}`)
    callback(
      res.data.categories?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        }
      })
    )
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/model/${params.id}`, {
          ...values,
          model_id: values.model.value,
          tariff_id: values.tariff.value,
          attribute_categories: modelAttributes.map((el, index) => ({
            // set attribute category
            attribute_category_id: el.attribute_category.value,

            // set attributes
            attributes: el.attributes.map((el) => el.value),
          })),
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/catalog/categories")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/model", {
          ...values,
          model_id: values.model.value,
          tariff_id: values.tariff.value,
          attribute_categories: modelAttributes.map((el, index) => ({
            // set attribute category
            attribute_category_id: el.attribute_category.value,

            // set attributes
            attributes: el.attributes.map((el) => el.value),
          })),
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/catalog/categories")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get Attribute Categories and Tariffs
  async function getAttributeCategory() {
    let response = await axios.get("/attribute-category?limit=100&offset=0")

    setAttributeCategories(
      response.data.categories.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  async function getTariffs() {
    let response = await axios.get("/tariff?limit=100&offset=0")

    setTariffs(
      response.data.tariffs.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  async function getModels() {
    let response = await axios.get("/model1?limit=100&offset=0")

    setModels(
      response?.data?.categories.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    )
  }

  // Functions for Changing Contacts
  const addCategory = () => {
    setModelAttributes((old) => [
      ...old,
      {
        attribute_category: { value: "", label: "" },
        attributes: [],
      },
    ])
  }

  const removeCategory = (i) => {
    setModelAttributes((old) => old.filter((el, index) => i !== index))
  }

  const updateCategory = async (val, i) => {
    let response = await axios.get(
      `/attribute?attribute_category_id=${val.value}&limit=100&offset=0`
    )

    setModelAttributes((old) =>
      old.map((el, index) =>
        index === i
          ? {
              ...el,
              attribute_category: { value: val.value, label: val.label },
              options: response.data.attributes?.map(({ name, id }) => ({
                label: name,
                value: id,
              })),
            }
          : el
      )
    )

    const newModelAttr = modelAttributes.map((el, index) =>
      index === i
        ? {
            ...el,
            attribute_category: { value: val.value, label: val.label },
            options: response.data.attributes?.map(({ name, id }) => ({
              label: name,
              value: id,
            })),
          }
        : el
    )

    let filteredCategories = attributeCategories
    newModelAttr.forEach((el, index) => {
      filteredCategories = filteredCategories.filter(
        (item) => item.value !== el.attribute_category.value
      )
    })
    setCategories(filteredCategories)

    if (modelAttributes[i].attributes) {
      setModelAttributes((old) =>
        old.map((el, index) =>
          index === i
            ? {
                ...el,
                attribute_category: { value: val.value, label: val.label },
                options: response.data.attributes?.map(({ name, id }) => ({
                  label: name,
                  value: id,
                })),
              }
            : el
        )
      )
    }
  }

  const updateAttributes = (val, index) => {
    setModelAttributes((old) =>
      old.map((el, i) =>
        i === index
          ? {
              ...el,
              attributes: val,
            }
          : el
      )
    )
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    model: validateForm("mixed", t),
    tariff: validateForm("mixed", t),
  })

  const routes = [
    {
      title: t("catalog"),
      link: true,
      route: "/home/settings/catalog",
    },
    {
      title: t("categories"),
      link: true,
      route: "/home/settings/catalog/categories",
    },
    {
      title: params.id ? initialValues.name : t("create"),
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("category")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/catalog/categories")
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full flex flex-col gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="w-1/2">
                  {/* Name */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("name")}</div>
                    <div className="w-2/3">
                      <Form.Item name="name" formik={formik}>
                        <Input
                          id="name"
                          type="text"
                          {...formik.getFieldProps("name")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Model */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("model")}</div>
                    <div className="w-2/3">
                      <Form.Item name="model" formik={formik}>
                        <AsyncSelect
                          loadOptions={loadModelOptions}
                          defaultOptions={models}
                          placeholder=""
                          {...formik.getFieldProps("model")}
                          onChange={(val) => {
                            formik.setFieldValue("model", val)
                            // getModels(val.value)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Tariff */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("tariff")}</div>
                    <div className="w-2/3">
                      <Form.Item name="tariff" formik={formik}>
                        <Select
                          hideSelectedOptions
                          options={tariffs}
                          {...formik.getFieldProps("tariff")}
                          onChange={(val) => {
                            formik.setFieldValue("tariff", val)
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>

                {/* Attribute categories */}
                <div className="flex w-1/2 bg-white rounded justify-center py-2">
                  <h5 className="font-semibold">{t("attribute.categories")}</h5>
                </div>
                {modelAttributes && modelAttributes.length
                  ? modelAttributes.map((el, index) => (
                      <Card className="w-1/2">
                        {/* Delete Button */}
                        <div className="relative">
                          <ClearIcon
                            onClick={() => removeCategory(index)}
                            className="
                              absolute -top-4 -right-4 
                              cursor-pointer border-2 
                              rounded bg-gray-100
                              text-gray-600
                              "
                          />
                        </div>

                        <div className="w-full py-2 items-baseline" key={index}>
                          <div className="w-full py-2 flex items-baseline">
                            <div className="w-1/3">
                              {t("attribute.category")} {index + 1}
                            </div>
                            <div className="w-2/3">
                              <Form.Item
                                name="attribute_category_id"
                                formik={formik}
                              >
                                <Select
                                  options={categories}
                                  value={
                                    modelAttributes[index].attribute_category
                                  }
                                  onChange={(val) => {
                                    updateAttributes([], index)
                                    updateCategory(val, index)
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {el.attributes ? (
                            <div className="w-full py-2 flex items-baseline">
                              <div className="w-1/3">{t("attributes")}</div>
                              <div className="w-2/3">
                                <Form.Item name="attribute_id" formik={formik}>
                                  <Select
                                    hideSelectedOptions
                                    id="attribute_id"
                                    options={el.options}
                                    onChange={
                                      (val) => updateAttributes(val, index)
                                      // console.log(val, "attributes")
                                    }
                                    value={modelAttributes[index].attributes}
                                    isMulti
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Card>
                    ))
                  : ""}
                <div
                  className="flex w-1/2 bg-white rounded justify-center py-2 cursor-pointer"
                  onClick={addCategory}
                >
                  <CustomButton
                    icon={addIcon}
                    shape="text"
                    color="text-primary-600"
                  >
                    {t("add.category")}
                  </CustomButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default CategoriesCreate
