import React, { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import SearchIcon from "@material-ui/icons/Search"
import Paper from "@material-ui/core/Paper"

import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Button from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import { StyledTableCell } from "../../components/StyledTableCell"
import cashIcon from "../../assets/settingsIcons/cash.png"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import EmptyTable from "../../components/EmptyTable"
import { addIcon } from "../../assets/icons/icons"
import parseQuery from "../../functions/parseQuery"

export default function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10

  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})

  let debounce = setTimeout(() => {}, 0)
  let { limit, offset } = parseQuery()

  useEffect(() => {
    getItems({ limit, offset })
  }, [])

  // **** FUNCTIONS ****
  const getItems = async (params = {}) => {
    let response = await axios.get(`/cost`, { params })
    setItems({
      data: response.data,
    })
    setIsLoading(false)
  }

  // **** EVENTS ****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, search: val })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/settings/costs`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("costs")}
        endAdornment={
          permissions.includes("settings_costs_create")
            ? [
                <Button
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() => history.push("/home/settings/costs/create")}
                >
                  {t("create")}
                </Button>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 py-4">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("photo")}</StyledTableCell>
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("created.date")}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.data && items.data?.costs?.length ? (
                items.data.costs.map(
                  ({ id, name, photo, created_at }, index) => (
                    <TableRow
                      key={id}
                      className="hover:bg-gray-100 cursor-pointer"
                      style={{ background: index % 2 === 0 && "#F6F8F9" }}
                      onClick={() => {
                        history.push(`/home/settings/costs/${id}`)
                      }}
                    >
                      <StyledTableCell>
                        <img
                          src={
                            photo
                              ? `https://cdn.car24.uz/car24/${photo}`
                              : cashIcon
                          }
                          width="50"
                          alt="image"
                        />
                      </StyledTableCell>
                      <StyledTableCell>{name}</StyledTableCell>

                      <StyledTableCell>
                        <div className="text-blue-600">
                          {dateTimeConvert(created_at)}
                        </div>
                      </StyledTableCell>

                      {permissions.includes("settings_costs_delete") && (
                        <MoreClickHandler
                          id={id}
                          api="/cost"
                          pushLocation="/home/settings/costs"
                          editPermission="settings_costs_edit"
                          deletePermission="settings_costs_delete"
                          getItems={() => getItems({ limit, offset })}
                        />
                      )}
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет расходов"
          isActive={!isLoading && !items.data?.costs?.length > 0}
        />
        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.data?.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}
