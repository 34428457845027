import { useState, useCallback } from "react"
import TableCell from "@material-ui/core/TableCell"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import filterIcon from "../../assets/icons/filter.svg"
import BackdropPortal from "../../components/BackdropPortal/BackdropPortal"

const TableHeadFilterCell = (props) => {
  const { row = 1, col = 1, title, url, paramKey, dataKey, filter } = props
  const { t } = useTranslation()

  const [data, setData] = useState()

  const getData = useCallback((params = {}) => {
    axios.get(`/${url}`, { params }).then((res) => {
      setData(res.data[dataKey])
    })
  }, [])

  // const onDataSearch = (val) => {
  //   clearTimeout(debounce)
  //   debounce = setTimeout(() => {
  //     getData({ search: val })
  //   }, 300)
  // }

  return (
    <TableCell
      colSpan={col}
      rowSpan={row}
      align="center"
      style={{
        fontWeight: "600",
        borderRight: "1px solid #ddd",
        whiteSpace: "pre",
      }}
    >
      <div className="flex justify-between items-center whitespace-pre">
        <div className="mr-2">{t(title)}</div>
        <div
          className="border-solid border-2 border-gray-100 rounded-lg inline-block p-2 cursor-pointer w-8 relative"
          onClick={() => getData()}
        >
          <img src={filterIcon} alt="icon" />
          {data && (
            <>
              <BackdropPortal
                onConfirm={(e) => {
                  e.stopPropagation()
                  setData(undefined)
                }}
              />
              <div className="border-solid border-1 bg-white w-80 shadow-xl absolute top-8 left-0 z-50 min-h-32 max-h-40 overflow-auto p-2">
                <p
                  className="hover:bg-gray-100 p-2 cursor-pointer"
                  onClick={(e) => {
                    filter(e, "", paramKey)
                    setData(undefined)
                  }}
                >
                  {t("filter.clear")}
                </p>
                {data.map((item, index) => (
                  <p
                    key={index}
                    className="hover:bg-gray-100 p-2 cursor-pointer text-left"
                    onClick={(e) => {
                      filter(e, item.id, paramKey)
                      setData(undefined)
                    }}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </TableCell>
  )
}

export default TableHeadFilterCell