import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import { useHistory, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "../../components/Card/index"
import DatePicker from "../../components/DatePicker"
import moment from "moment"
// import Skeleton from "@material-ui/lab/Skeleton";

import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"

function OilChangeCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [date, setDate] = useState("")
  const [initialValues, setInitialValues] = useState({
    mileage: undefined,
    next_oil_change_mileage: undefined,
  })

  useEffect(() => {
    if (params.oil_id) {
      getProperty(params.oil_id)
    }
  }, [])

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/oil-change/${id}`)
      .then((res) => {
        let data = {
          ...res,
        }
        setInitialValues(data)
        setDate(res.date)
      })
      .finally(() => setIsGettingData(false))
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.oil_id) {
      axios
        .put(`/oil-change/${params.oil_id}`, {
          ...values,
          car_id: `${params.id}`,
          date: date,
        })
        .then((res) => {
          console.log(res)
          history.push(`/home/cars/${params.id}`)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/oil-change", {
          ...values,
          car_id: `${params.id}`,
          date: date,
        })
        .then((res) => {
          console.log(res)
          console.log(values, params.id)
          history.push(`/home/cars/${params.id}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    // status: Yup.bool().required(t("required.field.error")),
  })

  const routes = [
    {
      title: t("cars"),
      link: true,
      route: "/home/cars",
    },
    {
      title: t("car.history"),
      link: true,
      route: `/home/cars/${params.id}`,
    },
    {
      title: params.oil_id ? t("edit") : t("create"),
    },
  ]

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("oil.change")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    size="large"
                    shape="text"
                    color="text-secondary"
                    onClick={() => history.push(`/home/cars/${params.id}`)}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    size="large"
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    // onClick={onSubmit}
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        <></>
                      )
                    }
                  >
                    {params.oil_id ? t("edit") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")}>
                  {/* Mileage */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("mileage")}</div>
                    <div className="w-2/3">
                      <Form.Item name="mileage" formik={formik}>
                        <Input
                          id="mileage"
                          type="number"
                          {...formik.getFieldProps("mileage")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Probeg */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("next_oil_change_mileage")}</div>
                    <div className="w-2/3">
                      <Form.Item name="next_oil_change_mileage" formik={formik}>
                        <Input
                          id="next_oil_change_mileage"
                          type="number"
                          {...formik.getFieldProps("next_oil_change_mileage")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full py-2 flex items-baseline">
                    {/* Date */}
                    <div className="w-1/3">{t("date")}</div>
                    <div className="w-2/3">
                      <Form.Item formik={formik}>
                        <DatePicker
                          style={{ height: "35px" }}
                          onChange={(val) => setDate(moment(val).toISOString())}
                          value={date ? moment(date) : null}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default OilChangeCreate
