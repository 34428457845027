import { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import EmptyTable from "../../../components/EmptyTable"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"

let boldCells = [
  "Количество заказов",
  "Доход от аренды машин",
  "Пополнение",
  "Количество доп. продаж",
  "Доп. продаж",
  "Общие штрафы",
  "Общий доход",
  "Расходы компании",
  "Расходы на машину",
  "Общый расход",
  "Прибыль / Убыток",
]

var nonCurrencyCells = ["Количество заказов", "Количество доп. продаж"]

const PLReport = () => {
  const { t } = useTranslation()

  const [months, setMonths] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)

  const firstColCells = useMemo(() => {
    let company = {},
      car = {},
      fine = {}

    if (months.september !== undefined) {
      const { company_expenditures, car_expenditures, costs } =
        months?.september
      company_expenditures?.forEach((expenditure) => {
        company[expenditure.name] = expenditure.name
      })
      car_expenditures?.forEach((expenditure) => {
        car[expenditure.name] = expenditure.name
      })
      costs?.forEach((cost) => {
        fine[cost.name] = cost.name
      })
    }

    const fines = Object.keys(fine).map((propName) => ({
      [propName]: fine[propName],
    }))
    const companyItems = Object.keys(company).map((propName) => ({
      [propName]: company[propName],
    }))
    const carItems = Object.keys(car).map((propName) => ({
      [propName]: car[propName],
    }))

    return [
      { Доход: "Доход" },
      { "Количество заказов": "order_count" },
      { "Доход от аренды машин": "total_order_price" },
      { "Количество доп. продаж": "total_additional_tariff_count" },
      { "Доп. продаж": "total_additional_tariff_price" },
      { Пополнение: "top_up" },
      { "Общие штрафы": "total_costs" },
      ...fines,
      { "Общий доход": "total_income" },
      { Расходы: "Расходы" },
      { "Расходы компании": "total_company_expenditure" },
      ...companyItems,
      { "Расходы на машину": "total_car_expenditure" },
      ...carItems,
      { "Общый расход": "total_expenditure" },
      { "Прибыль / Убыток": "profit" },
    ]
  }, [months])

  const copiedMonths = useMemo(() => {
    if (months.september !== undefined) {
      const { ...copiedMonths } = months
      Object.keys(months).forEach((month) => {
        months[month].company_expenditures?.forEach((expenditure) => {
          copiedMonths[month][expenditure.name] = expenditure.amount
        })
        months[month].car_expenditures?.forEach((expenditure) => {
          copiedMonths[month][expenditure.name] = expenditure.amount
        })
        months[month].costs?.forEach((cost) => {
          copiedMonths[month][cost.name] = cost.amount
        })
        delete copiedMonths[month].company_expenditures
        delete copiedMonths[month].car_expenditures
        delete copiedMonths[month].costs
      })
      return copiedMonths
    }
    return undefined
  }, [months])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/pandl-excel`)
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  var modData = useMemo(() => {
    if (months.january) {
      var january = new Set()
      var february = new Set()
      var march = new Set()
      var april = new Set()
      var may = new Set()
      var june = new Set()
      var july = new Set()
      var august = new Set()
      var september = new Set()
      var october = new Set()
      var november = new Set()
      var december = new Set()

      Object.keys(months?.january).forEach((field) => {
        january.add(months?.january[field])
      })

      Object.keys(months?.february).forEach((field) => {
        february.add(months?.february[field])
      })

      Object.keys(months?.march).forEach((field) => {
        march.add(months?.march[field])
      })

      Object.keys(months?.april).forEach((field) => {
        april.add(months?.april[field])
      })

      Object.keys(months?.may).forEach((field) => {
        may.add(months?.may[field])
      })

      Object.keys(months?.june).forEach((field) => {
        june.add(months?.june[field])
      })

      Object.keys(months?.july).forEach((field) => {
        july.add(months?.july[field])
      })

      Object.keys(months?.august).forEach((field) => {
        august.add(months?.august[field])
      })

      Object.keys(months?.september).forEach((field) => {
        september.add(months?.september[field])
      })

      Object.keys(months?.october).forEach((field) => {
        october.add(months?.october[field])
      })

      Object.keys(months?.november).forEach((field) => {
        november.add(months?.november[field])
      })

      Object.keys(months?.december).forEach((field) => {
        december.add(months?.december[field])
      })

      return {
        january: january.has(0) && january.size === 1 ? false : true,
        february: february.has(0) && february.size === 1 ? false : true,
        march: march.has(0) && march.size === 1 ? false : true,
        april: april.has(0) && april.size === 1 ? false : true,
        may: may.has(0) && may.size === 1 ? false : true,
        june: june.has(0) && june.size === 1 ? false : true,
        july: july.has(0) && july.size === 1 ? false : true,
        august: august.has(0) && august.size === 1 ? false : true,
        september: september.has(0) && september.size === 1 ? false : true,
        october: october.has(0) && october.size === 1 ? false : true,
        november: november.has(0) && november.size === 1 ? false : true,
        december: december.has(0) && december.size === 1 ? false : true,
      }
    }
  }, [months])

  useEffect(() => {
    const getMonths = async () => {
      let response = await axios.get(`/pandl`)
      setMonths(response)
      setIsLoading(false)
    }
    getMonths()
  }, [])

  return (
    <>
      <Header title={t("PLReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      ></Filters>

      {!isLoading ? (
        <div className="px-4 py-6 bg-white m-2">
          <TableContainer className="mt-2" elevation={0} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  >
                    {t("")}
                  </TableCell>
                  {modData.january && (
                    <TableCell
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("january")}
                    </TableCell>
                  )}
                  {modData.february && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("february")}
                    </TableCell>
                  )}
                  {modData.march && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("march")}
                    </TableCell>
                  )}
                  {modData.april && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("april")}
                    </TableCell>
                  )}
                  {modData.may && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("may")}
                    </TableCell>
                  )}
                  {modData.june && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("june")}
                    </TableCell>
                  )}
                  {modData.july && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("july")}
                    </TableCell>
                  )}
                  {modData.august && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("august")}
                    </TableCell>
                  )}
                  {modData.september && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("september")}
                    </TableCell>
                  )}
                  {modData.october && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("october")}
                    </TableCell>
                  )}
                  {modData.november && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("november")}
                    </TableCell>
                  )}
                  {modData.december && (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {t("december")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              {/* first column cells */}
              {firstColCells.map((colCell, index) => (
                <TableRow key={"row" + index}>
                  {colCell.hasOwnProperty("Доход") ||
                  colCell.hasOwnProperty("Расходы") ? (
                    <TableCell
                      key={index}
                      align="center"
                      style={{
                        borderRight: "1px solid #ddd",
                        background: "#eee",
                      }}
                    >
                      {colCell["Доход"] || colCell["Расходы"]}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={index}
                      align="center"
                      style={{
                        borderRight: "1px solid #ddd",
                        fontWeight: boldCells.includes(Object.keys(colCell)[0])
                          ? "bold"
                          : null,
                      }}
                    >
                      {Object.keys(colCell)[0]}
                    </TableCell>
                  )}
                  {copiedMonths &&
                    Object.keys(copiedMonths).map((month) => {
                      if (!modData[month]) return null
                      return colCell.hasOwnProperty("Доход") ||
                        colCell.hasOwnProperty("Расходы") ? (
                        <TableCell
                          key={index}
                          align="center"
                          style={{
                            borderRight: "1px solid #ddd",
                            background: "#eee",
                          }}
                        ></TableCell>
                      ) : (
                        Object.keys(colCell).map((item) => (
                          <TableCell
                            key={month}
                            align="center"
                            style={{
                              borderRight: "1px solid #ddd",
                              fontWeight: boldCells.includes(
                                Object.keys(colCell)[0]
                              )
                                ? "bold"
                                : null,
                            }}
                          >
                            {Object.keys(firstColCells[index]).map(
                              (propName) => {
                                if (
                                  nonCurrencyCells.includes(
                                    Object.keys(colCell)[0]
                                  )
                                ) {
                                  return copiedMonths[month][
                                    firstColCells[index][propName]
                                  ]
                                }
                                return convertToInternationalCurrencySystem(
                                  copiedMonths[month][
                                    firstColCells[index][propName]
                                  ]
                                )
                              }
                            )}
                          </TableCell>
                        ))
                      )
                    })}
                </TableRow>
              ))}
            </Table>
          </TableContainer>
          <EmptyTable
            message="Нет отчета"
            isActive={
              !isLoading &&
              copiedMonths &&
              !Object.keys(copiedMonths)?.length > 0
            }
          />
        </div>
      ) : (
        <ReportSkeleton />
      )}
    </>
  )
}

export default PLReport
