import { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import EmptyTable from "../../../components/EmptyTable"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

var mapper = new Map([
  [1, "january"],
  [2, "february"],
  [3, "march"],
  [4, "april"],
  [5, "may"],
  [6, "june"],
  [7, "july"],
  [8, "august"],
  [9, "september"],
  [10, "october"],
  [11, "november"],
  [12, "december"],
])

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordionSummary-content": {
      margin: "0",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0",
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const CashflowReport = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [months, setMonths] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)

  const orderedData = useMemo(() => {
    var {
      order,
      top_up,
      cost,
      total_income,
      company_expenditure,
      car_expenditure,
      total_expenditure,
      profit,
      initial_balance,
      closing_balance,
    } = months

    var orderedData = [
      { name: "Доход от аренды машин", ...order },
      { name: "Пополнение", ...top_up },
      { name: "Общие штрафы", ...cost },
      { name: "Общий доход", ...total_income },
      { name: "Расходы компании", ...company_expenditure },
      { name: "Расходы на машину", ...car_expenditure },
      { name: "Общый расход", ...total_expenditure },
      { name: "Чистый денежный поток", ...profit },
      { name: "Начальное сальдо", ...initial_balance },
      { name: "Конечное сальдо", ...closing_balance },
    ]
    return orderedData
  }, [months])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/cashflow-excel`)
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  useEffect(() => {
    const getMonths = async () => {
      let response = await axios.get(`/cashflow`)
      setMonths(response)
      setIsLoading(false)
    }
    getMonths()
  }, [])

  return (
    <>
      <Header title={t("cashflowReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      ></Filters>

      {!isLoading ? (
        <div className="px-4 py-6 bg-white m-2">
          <TableContainer className="mt-2" elevation={0} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "300px",
                    }}
                  >
                    {t("")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "201px",
                    }}
                  >
                    {t("january")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("february")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("march")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("april")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("may")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("june")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("july")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("august")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("september")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("october")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "200px",
                    }}
                  >
                    {t("november")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #ddd",
                      minWidth: "236px",
                    }}
                  >
                    {t("december")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableRow>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((_, index) => (
                  <TableCell
                    key={Math.random().toString()}
                    align="center"
                    style={{
                      borderRight: "1px solid #ddd",
                      background: "#eee",
                    }}
                  >
                    {index == 0 ? "Доход" : ""}
                  </TableCell>
                ))}
              </TableRow>
              {orderedData.map((rowData, index) => (
                <>
                  {rowData.name == "Расходы компании" ? (
                    <TableRow>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                        (_, index) => (
                          <TableCell
                            key={Math.random().toString()}
                            align="center"
                            style={{
                              borderRight: "1px solid #ddd",
                              background: "#eee",
                            }}
                          >
                            {index == 0 ? "Расходы" : ""}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ) : null}
                  <TableRow key={rowData.name + index}>
                    <TableCell colSpan={13} padding="none">
                      <div className={classes.root}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                                (_, index) => (
                                  <TableCell
                                    key={Math.random().toString()}
                                    align="center"
                                    style={{
                                      borderLeft:
                                        index == 0 ? "none" : "1px solid #ddd",
                                      borderBottom: "none",
                                      minWidth: index == 0 ? "300px" : "200px",
                                      fontWeight: "600",
                                      whiteSpace: "pre",
                                    }}
                                  >
                                    {index == 0
                                      ? rowData.name
                                      : convertToInternationalCurrencySystem(
                                          rowData.total_price[mapper.get(index)]
                                        )}
                                  </TableCell>
                                )
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {Object.keys(rowData.payment_types).map(
                                (payment_type) => (
                                  <TableRow key={Math.random().toString()}>
                                    {[
                                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                    ].map((_, index) => (
                                      <TableCell
                                        key={Math.random().toString()}
                                        align="center"
                                        style={{
                                          borderLeft:
                                            index == 0
                                              ? "none"
                                              : "1px solid #ddd",
                                          minWidth:
                                            index == 0 ? "300px" : "200px",
                                          whiteSpace: "pre",
                                          borderTop: "1px solid #ddd",
                                        }}
                                      >
                                        {index == 0
                                          ? t(payment_type)
                                          : convertToInternationalCurrencySystem(
                                              rowData.payment_types[
                                                payment_type
                                              ][mapper.get(index)]
                                            )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                )
                              )}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        {rowData.expenditures
                          ? rowData.expenditures.map((expenditure) => (
                              <TableRow key={Math.random().toString()}>
                                {[
                                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                ].map((_, index) => (
                                  <TableCell
                                    key={Math.random().toString()}
                                    align="center"
                                    style={{
                                      borderLeft:
                                        index == 0 ? "none" : "1px solid #ddd",
                                      minWidth: index == 0 ? "300px" : "200px",
                                      whiteSpace: "pre",
                                    }}
                                  >
                                    {index == 0
                                      ? expenditure.name
                                      : convertToInternationalCurrencySystem(
                                          expenditure.amount[mapper.get(index)]
                                        )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))
                          : null}
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </Table>
          </TableContainer>
          <EmptyTable
            message="Нет отчета"
            isActive={!isLoading && months && !Object.keys(months)?.length > 0}
          />
        </div>
      ) : (
        <ReportSkeleton />
      )}
    </>
  )
}

export default CashflowReport
