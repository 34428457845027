import moment from "moment";

const findDayDifference = (date1, date2) => {
  const diffTime = moment(date2).diff(moment(date1));
  const diffDuration = moment.duration(diffTime);
  const diffDays = diffDuration.days();

  console.log(diffDays, "days");
  return diffDays;
};

export default findDayDifference;
