import { useState, useEffect, useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import moment from "moment"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import RangePicker from "../../../components/DatePicker/RangePicker"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import parseQuery from "../../../functions/parseQuery"
import Tag from "../../../components/Tag"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import TableHeadCell from "../../../components/TableCells/TableHeadCell"
import TableHeadSearchCell from "../../../components/TableCells/TableHeadSearchCell"
import TableHeadClientSearchCell from "../../../components/TableCells/TableHeadClientSearchCell"
import TableHeadFilterCell from "../../../components/TableCells/TableHeadFilterCell"
import TableHeadFilterCellCustomList from "../../../components/TableCells/TableHeadFilterCellCustomList"
import TableHeadCalendarCell from "../../../components/TableCells/TableHeadCalendarCell"
import TableBodyCell from "../../../components/TableCells/TableBodyCell"

const useStyles = makeStyles({
  root: {
    transition: "all .1s cubic-bezier(0.94, 0.05, 0.99, 0.02);",
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
})

function provideCycler() {
  function* generator(val = "") {
    var mapper = new Map([
      [0, null],
      [1, "asc"],
      [2, "desc"],
    ])
    var count = 1
    while (true) {
      yield mapper.get(count)
      if (count < 2) count++
      else count = 0
    }
  }
  var it = generator()
  return function (val) {
    return it.next(val).value
  }
}

var cycle

const initialState = {
  tariff_price: {
    order: null,
    arrangement: null,
  },
  // add_tariff_price: {
  //   order: null,
  //   arrangement: null,
  // },
  discount: {
    order: null,
    arrangement: null,
  },
  deposit: {
    order: null,
    arrangement: null,
  },
  total_price: {
    order: null,
    arrangement: null,
  },
  cash: {
    order: null,
    arrangement: null,
  },
  uzcard: {
    order: null,
    arrangement: null,
  },
  humo: {
    order: null,
    arrangement: null,
  },
  payme: {
    order: null,
    arrangement: null,
  },
  click: {
    order: null,
    arrangement: null,
  },
  visa: {
    order: null,
    arrangement: null,
  },
}

const reducerFn = (state, { column, arrangement }) => {
  switch (arrangement) {
    case "asc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case "desc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case null:
      return initialState
    default:
      throw new Error("Error in reducer")
  }
}

const paymentStatusList = [
  { id: "0", name: "Не оплачен" },
  { id: "1", name: "Оплачено" },
]

const OrdersReport = () => {
  const { t } = useTranslation()
  let { limit, offset } = parseQuery()
  const pageCount = 10
  const classes = useStyles()

  const [query, setQuery] = useState()
  const [items, setItems] = useState([])
  const [excelParams, setExcelParams] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })
  const [count, setCount] = useState()
  const [lastClickedArrow, setLastClickedArrow] = useState()
  const [state, dispatch] = useReducer(reducerFn, initialState)
  const [page, setPage] = useState(1)

  const getItems = useCallback(async (params = {}) => {
    let response = await axios.get(`/big-report`, { params })

    setItems(await response.orders)
    setCount(await response.count)
    setExcelParams(params)
    setIsLoading(false)
  }, [])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/big-report-excel`, { excelParams })
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const filterHandler = (e, id, paramKey) => {
    const param = {}
    param[paramKey] = String(id)
    e.stopPropagation()
    getItems(param)
    setQuery(param)
    setPage(1)
  }

  const datePickerHandler = (val) => {
    getItems(val)
    setPage(1)
    setQuery(val)
  }

  const updateDate = (val) =>
    setFromToDate({
      start: moment(val[0]).toISOString() ?? "",
      end: moment(val[1]).toISOString() ?? "",
    })

  const handleChangePage = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, ...query })
      setPage(value)
    },
    [getItems, limit, query]
  )

  const SorterArrow = useCallback(
    ({ column }) => {
      return state[column]?.arrangement === null ? (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer"
          classes={{ root: classes.root }}
          color="disabled"
          fontSize="medium"
          onClick={(e) => {
            if (lastClickedArrow !== e.target.closest(".arrow").id) {
              setLastClickedArrow(e.target.closest(".arrow").id)
              cycle = provideCycler()
            }
            dispatch({ column, arrangement: cycle() })
          }}
        />
      ) : state[column]?.arrangement === "desc" ? (
        <ArrowDownwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      ) : (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      )
    },
    [classes.root, state, lastClickedArrow]
  )

  useEffect(() => {
    getItems({ from_time: fromToDate.start, to_time: fromToDate.end, limit: 10, offset: 0 })
  }, [getItems, fromToDate])

  useEffect(() => {
    var i, obj
    for (i in state) {
      if (state.hasOwnProperty(i) && state[i].order && state[i].arrangement) {
        obj = state[i]
      }
    }
    getItems({
      limit: pageCount,
      offset: offset ?? 0,
      [obj?.order]: obj?.arrangement,
    })
  }, [offset, state])

  return (
    <div>
      <Header title={t("ordersReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        <RangePicker
          showToday={false}
          showWeekNumber
          dateInputPlaceholder={[t("from"), t("till")]}
          showOk={false}
          showClear
          placeholder={t("select.a.date")}
          hideTimePicker
          onChange={(val) => updateDate(val)}
        />
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer
          className="mt-2"
          elevation={0}
          component={Paper}
          style={{ height: "70vh" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell row={2} title="&#8470;" />
                <TableHeadSearchCell
                  title="order.number"
                  url="order"
                  dataKey="orders"
                  paramKey="order_number"
                  filterItemKey="order_number"
                  itemKey="order_number"
                  filter={filterHandler}
                />
                <TableHeadClientSearchCell
                  title="client"
                  url="client"
                  paramKey="client_name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="phone.number"
                  url="client"
                  dataKey="clients"
                  paramKey="phone_number"
                  filterItemKey="phone_number"
                  itemKey="phone_number"
                  filter={filterHandler}
                />
                <TableHeadFilterCell
                  title="status"
                  url="status"
                  dataKey="statuses"
                  paramKey="status"
                  filterItemKey="statuses"
                  itemKey="statuses"
                  filter={filterHandler}
                />
                <TableHeadCalendarCell
                  title="status.date"
                  startKey="from_time"
                  endKey="to_time"
                  datePicker={datePickerHandler}
                />
                <TableHeadSearchCell
                  title="car.model"
                  url="model1"
                  dataKey="categories"
                  paramKey="model_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="car.number"
                  url="car"
                  dataKey="cars"
                  paramKey="car_id"
                  filterItemKey="id"
                  itemKey="state_number"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="tariff"
                  url="tariff"
                  dataKey="tariffs"
                  paramKey="tariff_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or00"
                >
                  {t("tariff.price")}
                  {<SorterArrow column="tariff_price" />}
                </TableCell>
                <TableHeadCell title="tariffs.additional" />
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or01"
                >
                  {t("additional.tariffs.summ")}
                  {/* {<SorterArrow column="add_tariff_price" />} */}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or02"
                >
                  {t("discount")}
                  {<SorterArrow column="discount" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or03"
                >
                  {t("deposit")}
                  {<SorterArrow column="deposit" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or04"
                >
                  {t("overall.money")}
                  {<SorterArrow column="total_price" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or05"
                >
                  {t("cash")}
                  {<SorterArrow column="cash" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or06"
                >
                  {t("uzcard")}
                  {<SorterArrow column="uzcard" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="0r07"
                >
                  {t("humo")}
                  {<SorterArrow column="humo" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or08"
                >
                  {t("payme")}
                  {<SorterArrow column="payme" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or09"
                >
                  {t("click")}
                  {<SorterArrow column="click" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="or10"
                >
                  {t("visa")}
                  {<SorterArrow column="visa" />}
                </TableCell>
                <TableHeadFilterCellCustomList
                  title="payment.status"
                  data={paymentStatusList}
                  paramKey="is_paid"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="creator"
                  url="system-user"
                  dataKey="system_users"
                  paramKey="cashier_id"
                  filterItemKey="id"
                  itemKey="full_name"
                  filter={filterHandler}
                />
                <TableCell
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                >
                  {t("comments")}
                </TableCell>
              </TableRow>
            </TableHead>
            {items?.map((item, index) => (
              <TableRow
                style={{ background: index % 2 === 0 && "#F6F8F9" }}
                key={index}
              >
                <TableBodyCell title={index + 1} fontWeight="600" />
                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {item.order_number}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {item?.client.last_name}
                  {"\n"}
                  {item?.client.first_name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.client.phone_number}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd", whiteSpace: "pre" }}
                >
                  <Tag
                    shape="subtle"
                    textColor={
                      item?.status === "not_paid"
                        ? "text-gray-900"
                        : item?.status === "new"
                        ? "text-blue-600"
                        : item?.status === "client_took"
                        ? "text-amber-600"
                        : item?.status === "client_returned"
                        ? "text-purple-600"
                        : item?.status === "completed"
                        ? "text-green-600"
                        : "text-red-600"
                    }
                    bgColor={
                      item?.status === "not_paid"
                        ? "bg-gray-300"
                        : item?.status === "new"
                        ? "bg-blue-100"
                        : item?.status === "client_took"
                        ? "bg-amber-100"
                        : item?.status === "client_returned"
                        ? "bg-purple-100"
                        : item?.status === "completed"
                        ? "bg-green-50"
                        : "bg-red-100"
                    }
                    size="large"
                  >
                    {t(item?.status)}
                  </Tag>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {moment(item?.date_status).format("MM.DD.YYYY / HH:mm")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.model.name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  <Tag
                    shape="outlined"
                    size="large"
                    style={{ width: "150px" }}
                    borderColor="border-gray-300"
                    textColor="text-blue-500"
                  >
                    {item?.car.state_number}
                  </Tag>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.tariff.name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.tariff.price)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.additional_tariffs.map((additionalTariff, index) => (
                    <p key={index}>{additionalTariff.name}</p>
                  ))}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.additional_tariffs.map((additionalTariff, index) => (
                    <p key={index}>
                      {convertToInternationalCurrencySystem(
                        additionalTariff.price
                      )}
                    </p>
                  ))}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.discount)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.deposit)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.total_price)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.cash)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.uzcard)}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {convertToInternationalCurrencySystem(item?.humo)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.payme)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.click)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.visa)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  <Tag
                    shape="subtle"
                    size="large"
                    textColor={
                      item?.is_paid
                        ? item?.is_paid === 1
                          ? "text-green-600"
                          : "text-amber-600"
                        : "text-red-600"
                    }
                    bgColor={
                      item?.is_paid
                        ? item?.is_paid === 1
                          ? "bg-green-100"
                          : "bg-amber-100"
                        : "bg-red-100"
                    }
                    style={{ width: "110px" }}
                  >
                    {item?.is_paid === 1
                      ? t("paid")
                      : item?.is_paid === 3
                      ? t("partially.paid")
                      : t("not.paid")}
                  </Tag>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.initiator.name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.comment}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <EmptyTable
            message="Нет отчетов"
            isActive={!isLoading && !items?.length > 0}
            className="
            flex flex-col 
            justify-center 
            items-center    
            text-gray-400
            space-y-2
            py-44 my-2"
          />
          {isLoading && <ReportSkeleton />}
        </TableContainer>
        <div className="flex justify-end">
        <Pagination
            className="mt-5"
            color="primary"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(count / pageCount)}
            page={page}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  )
}

export default OrdersReport
