import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import { useHistory, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "../../components/Card/index"
import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import Textarea from "../../components/Textarea"
import Filters from "../../components/Filters"
import { StyledTab, StyledTabs } from "../../components/StyledTabs"
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@material-ui/core/styles"
import { TabPanel } from "../../components/Tab/TabBody"
import parseQuery from "../../functions/parseQuery"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import Paper from "@material-ui/core/Paper"
import TableContainer from "@material-ui/core/TableContainer"
import { StyledTableCell } from "../../components/StyledTableCell"
import dateTimeConvert from "../../functions/dateTimeConvert"
import EmptyTable from "../../components/EmptyTable"
import Pagination from "../../components/Pagination/Pagination"
import cashIcon from "../../assets/icons/cash.png"
import clickIcon from "../../assets/icons/click.png"
import humoIcon from "../../assets/icons/humo.png"
import uzcardIcon from "../../assets/icons/uzcard.png"
import visaIcon from "../../assets/icons/visa.png"
import paymeIcon from "../../assets/icons/payme.png"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"

const icons = {
  cash: cashIcon,
  click: clickIcon,
  humo: humoIcon,
  uzcard: uzcardIcon,
  visa: visaIcon,
  payme: paymeIcon,
}

function PaymentAccountCreate() {
  const { limit, offset } = parseQuery()
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const theme = useTheme()
  const pageCount = 10

  const [items, setItems] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
  })

  // **** FUNCTIONS ****
  const getProperty = async (id) => {
    setIsGettingData(true)

    await axios
      .get(`/billing/account/${id}`)
      .then((res) => {
        let data = {
          ...res,
        }
        setInitialValues(data)
      })
      .finally(() => setIsGettingData(false))
  }

  const getItems = useCallback((params = { category_id: 5 }) => {
    axios.get(`/billing/transaction`, { params }).then((res) => {
      setItems({
        data: res.data,
      })
      setIsLoading(false)
    })
  }, [])

  const handleChange = (event, newValue) => setValue(newValue)

  const handleChangeIndex = (index) => setValue(index)

  const changeOffset = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        category_id: 5,
      })
    },
    [getItems, limit]
  )

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/billing/account/${params.id}`, {
          ...values,
          account_group_id: 6,
        })
        .then((res) => {
          history.push("/home/settings/payment-account")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/billing/account", {
          ...values,
          account_group_id: 6,
        })
        .then((res) => {
          history.push("/home/settings/payment-account")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const routes = [
    {
      title: t("payment-account"),
      link: true,
      route: "/home/settings/payment-account",
    },

    {
      title: params.id ? t("edit") : t("create"),
    },
  ]

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    description: validateForm("default", t),
  })

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const tabLabel = (text, number = "", isActive = false) => {
    return (
      <span
        className={`px-1 ${
          !isActive ? "text-warmGray-500" : "text-primary-600"
        }`}
      >
        {text}
      </span>
    )
  }

  // const changeTabLocationQuery = (tabId) => {
  //   history.push({
  //     pathname: `/home/settings/payment-account`,
  //     search: `?tab=${tabId}`,
  //   })
  // }

  // const handleChange = (event, newValue) => {
  //   changeTabLocationQuery(newValue)
  // }

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        category_id: 5,
      })
    }
  }, [limit, offset, params.id, getItems])

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("payment-account")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/payment-account")
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />
              <Filters>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{ children: <span className="w-2" /> }}
                  className="bg-white rounded"
                >
                  <StyledTab
                    label={tabLabel(t("general.information"), "", value == 0)}
                    {...a11yProps(0)}
                  />
                  {params.id && (
                    <StyledTab
                      label={tabLabel(t("transaction.history"), "", value == 1)}
                      {...a11yProps(1)}
                    />
                  )}
                </StyledTabs>
              </Filters>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                    <div className="space-y-4">
                      <Card title={t("general.information")}>
                        {/* Name */}
                        <div className="w-full py-2 flex items-baseline">
                          <div className="w-1/3">{t("name")}</div>
                          <div className="w-2/3">
                            <Form.Item name="name" formik={formik}>
                              <Input
                                id="name"
                                type="text"
                                {...formik.getFieldProps("name")}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="w-full py-2 flex items-baseline">
                          <div className="w-1/3">{t("description")}</div>
                          <div className="w-2/3">
                            <Form.Item name="description" formik={formik}>
                              <Textarea
                                id="description"
                                type="text"
                                {...formik.getFieldProps("description")}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </TabPanel>
                {params.id && (
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <div className="px-5 pb-4">
                      <TableContainer
                        className="mt-4"
                        elevation={0}
                        component={Paper}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow className="bg-gray-50">
                              <StyledTableCell>
                                {t("expense.type")}
                              </StyledTableCell>
                              <StyledTableCell>{t("sum")}</StyledTableCell>
                              <StyledTableCell>{t("date")}</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {items?.data &&
                            items?.data?.billing_transactions?.length ? (
                              items.data?.billing_transactions?.map(
                                (
                                  { id, date, subaccount_category, payments },
                                  index
                                ) => (
                                  <TableRow
                                    key={id}
                                    className="hover:bg-gray-100 cursor-pointer transition ease-linear"
                                    onClick={() => {
                                      history.push(
                                        `/home/billing/expenses/${id}`
                                      )
                                    }}
                                  >
                                    <StyledTableCell>
                                      {subaccount_category.name}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <div className="flex space-x-4 items-center">
                                        <span
                                          className="flex justify-center 
                                    items-center border 
                                    rounded-md
                                    py-2 px-6  "
                                          style={{
                                            width: "85px",
                                            height: "40px",
                                          }}
                                        >
                                          <img
                                            src={
                                              icons[payments[0].payment_type]
                                            }
                                            alt=""
                                          />
                                        </span>
                                        <p>
                                          {convertToInternationalCurrencySystem(
                                            payments[0].amount
                                          )}
                                        </p>
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <div className="text-blue-600">
                                        {dateTimeConvert(date)}
                                      </div>
                                    </StyledTableCell>
                                  </TableRow>
                                )
                              )
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <EmptyTable
                        isActive={
                          !isLoading &&
                          !items?.data?.billing_transactions?.length > 0
                        }
                        message="Нет инфо"
                      />
                      {isLoading && (
                        <div className="flex justify-center py-2">
                          <CircularProgress />
                        </div>
                      )}
                      <div className="flex justify-end">
                        <Pagination
                          className="mt-5"
                          defaultPage={offset ? offset / 10 + 1 : 1}
                          count={Math.ceil(items?.data?.count / pageCount)}
                          variant="outlined"
                          shape="rounded"
                          color="primary"
                          onChange={changeOffset}
                        />
                      </div>
                    </div>
                  </TabPanel>
                )}
              </SwipeableViews>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default PaymentAccountCreate
