import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export default function CustomSkeleton() {
  return (
    <div>
      <SkeletonTheme color="#ddd" highlightColor="#fff">
        <Skeleton width="100%" height={64} />
      </SkeletonTheme>
      <SkeletonTheme color="#ddd" highlightColor="#fff">
        <div className="p-4 w-full grid grid-cols-2 gap-4 box-border">
          <div>
            <Skeleton
              color="#202020"
              highlightColor="#444"
              width="100%"
              height={500}
            />
          </div>
          <div>
            <Skeleton
              color="#202020"
              highlightColor="#444"
              width="100%"
              height={500}
            />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  )
}
