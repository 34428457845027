import { ADD_NEW_ALERT, DELETE_ALERT } from "../constants";

const INITIAL_STATE = {
  alerts: [],
};

export default function alertReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case ADD_NEW_ALERT:
      return {
        ...state,
        alerts:
          state.alerts.length < 1
            ? [...state.alerts, { id: new Date().getTime(), ...payload }]
            : [...state.alerts],
      };

    case DELETE_ALERT:
      console.log("PAYLOAD", payload);
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== payload),
      };

    default:
      return state;
  }
}

export const showAlert = (title, type = "error") => ({
  type: ADD_NEW_ALERT,
  payload: { title, type },
});
export const deleteAlert = (id) => ({ type: DELETE_ALERT, payload: id });
