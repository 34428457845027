import { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"

import axios from "../../../utils/axios"
import dateTimeConvert from "../../../functions/dateTimeConvert"
import Tag from "../../../components/Tag"
import { StyledTableCell } from "../../../components/StyledTableCell"
import Pagination from "../../../components/Pagination"
import EmptyTable from "../../../components/EmptyTable"

function CarHistory() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const permissions = useSelector((state) => state.auth.permissions)
  const pageCount = 10
  const [pageLimit, setPageLimit] = useState(pageCount)

  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState([])

  useEffect(() => {
    getItems(params.id)
  }, [])

  const getItems = async (investorId) => {
    let response = await axios.get(`/car`, {
      params: {
        limit: pageCount,
        offset: pageLimit - pageCount,
        investor_id: investorId,
      },
    })
    console.log(response)
    setItems(response)
    setIsLoading(false)
  }

  return (
    <div>
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("number")}</StyledTableCell>

              <StyledTableCell>{t("model")}</StyledTableCell>

              <StyledTableCell>{t("brand")}</StyledTableCell>
              <StyledTableCell>{t("investor")}</StyledTableCell>

              <StyledTableCell>Срок тех. осмотра</StyledTableCell>
              <StyledTableCell>Срок страховки</StyledTableCell>
              <StyledTableCell>{t("status")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.data && items.data?.cars?.length ? (
              items.data.cars?.map(
                (
                  {
                    id,
                    brand,
                    model,
                    investor,
                    state_number,
                    insurance_expire_date,
                    tech_passport_expire_date,
                    status,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                    onClick={() => {
                      if (permissions.includes("cars_edit"))
                        return history.push(`/home/cars/${id}`)
                    }}
                  >
                    <StyledTableCell style={{ width: "10%" }}>
                      {state_number}
                    </StyledTableCell>
                    <StyledTableCell>{model.name}</StyledTableCell>
                    <StyledTableCell>{brand.name}</StyledTableCell>
                    <StyledTableCell>{investor.name}</StyledTableCell>
                    <StyledTableCell>
                      {dateTimeConvert(insurance_expire_date)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {dateTimeConvert(tech_passport_expire_date)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        color={
                          status !== "repairing"
                            ? status !== "in_use"
                              ? "blue"
                              : "green"
                            : "amber"
                        }
                        size="large"
                        style={{ width: "150px" }}
                      >
                        {t(status)}
                      </Tag>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable
        message="Нет машин"
        isActive={!isLoading && !items.data?.cars?.length > 0}
      />
      {isLoading && (
        <div className="flex justify-center py-2">
          <CircularProgress />
        </div>
      )}
      <Pagination
        className="mt-5"
        count={items.data?.count ? items.data?.count : 1}
        pageCount={pageCount}
        onChange={(val) => {
          setPageLimit(val)
        }}
      />
    </div>
  )
}

export default CarHistory
