import axios from "../../utils/axios"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import * as Yup from "yup"
import { CircularProgress } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { saveIcon } from "../../assets/icons/icons"
import CustomButton from "../../components/Buttons/index"
import Skeleton from "../../components/Skeleton"
import Card from "../../components/Card/index"
import Header from "../../components/Header/Header"
import AlertComponent from "../../components/Alert"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import ImageUpload from "../../components/Upload/ImgUpload"
import Select from "../../components/Select"
import validateForm from "../../functions/validateForm"
import formatPhoneNumber from "../../functions/formatPhoneNumber"

export default function MyProfile({ id }) {
  const { t } = useTranslation()

  const userId = useSelector((state) => state.auth.userId)

  const [btnDisabled, setBtnDisabled] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [branches, setBranches] = useState([])
  const [image, setImage] = useState("")
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    status: true,
    full_name: "",
    phone_number: "",
    login: "",
    branch: "",
    photo: "",
  })

  const userType = localStorage.getItem("userType")

  useEffect(() => {
    getProperty(userId)
  }, [])

  const getProperty = async (id) => {
    setIsGettingData(true)

    const url =
      userType === "INVESTOR" ? `/investor/${id}` : `/system-user/${id}`

    if (userType === "INVESTOR") {
      await axios
        .get(url)
        .then((res) => {
          let data = {
            ...res,
            phone_number: formatPhoneNumber(res.phone_number),
            full_name: res.name,
          }
          setInitialValues(data)
          setImage(res.photo)
        })
        .finally(() => setIsGettingData(false))
    } else {
      let [response] = await Promise.all([
        axios.get("/branch?limit=100&offset=0").then((res) => res.data),
      ])

      let listOfBranches = response?.branches?.map(({ name, id }) => ({
        label: name,
        value: id,
      }))

      setBranches(listOfBranches)

      await axios
        .get(url)
        .then((res) => {
          let data = {
            ...res,
            phone_number: formatPhoneNumber(res.phone_number),
            branch:
              userType === "INVESTOR"
                ? null
                : response.branches
                    ?.filter((el) => el.id === res.branch.id)
                    ?.map((el) => ({ label: el.name, value: el.id }))[0],
          }
          setInitialValues(data)
          setImage(res.photo)
        })
        .finally(() => setIsGettingData(false))
    }
  }

  // **** EVENTS **** //
  const onSubmit = (values) => {
    setBtnDisabled(true)
    const url =
      userType === "INVESTOR" ? `/investor/${userId}` : `/system-user/${userId}`
    const data =
      userType !== "INVESTOR"
        ? { ...values, photo: image, branch_id: values.branch.value }
        : {
            ...values,
            photo: image,
          }
    axios
      .put(url, {
        ...data,
      })
      .then((res) => {
        getProperty(userId)
      })
      .finally(() => {
        setLoading(false)
        setBtnDisabled(false)
      })
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    full_name: validateForm("default", t),
    phone_number: validateForm("phone_number", t),
    branch: userType !== "INVESTOR" && validateForm("mixed", t),
    login: validateForm("login", t),
  })

  return (
    <div>
      {!isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("my.profile")}
                endAdornment={[
                  <CustomButton
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                    disabled={btnDisabled}
                  >
                    {t("save")}
                  </CustomButton>,
                ]}
              />
              <AlertComponent
                style={{
                  top: "2px",
                  left: "45%",
                  marginLeft: "-100px",
                }}
              />
              <div className="p-4 w-full grid grid-cols-3 gap-4 box-border font-body text-sm">
                <Card title="Общие сведения" className="col-span-2">
                  <div className="flex space-x-6">
                    {/* Photo */}
                    <div className="flex flex-col w-1/4">
                      <ImageUpload
                        onChange={(val) => setImage(val.filename)}
                        onStart={(val) => console.log(val)}
                        onFinish={(val) => console.log(val)}
                        onSuccess={(val) => console.log(val)}
                        onError={(val) => console.log(val)}
                        defaultImage={image}
                      >
                        <div className="flex flex-col items-center justify-center space-y-4">
                          <AddIcon style={{ fontSize: "200%" }} />
                          <h5>{t("select.image")}</h5>
                        </div>
                      </ImageUpload>
                    </div>
                    <div className="flex flex-col w-3/4">
                      {/* Name */}
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("first.name")}</div>
                        <div className="w-2/3">
                          <Form.Item name="full_name" formik={formik}>
                            <Input
                              id="full_name"
                              type="text"
                              maxLength="30"
                              {...formik.getFieldProps("full_name")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/* Phone */}
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("phone")}</div>
                        <div className="w-2/3">
                          <Form.Item name="phone_number" formik={formik}>
                            <Input
                              id="phone_number"
                              type="text"
                              {...formik.getFieldProps("phone_number")}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "phone_number",
                                  e.target.value
                                )
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      {/* Branch */}
                      {userType !== "INVESTOR" && (
                        <div className="w-full py-2 flex items-baseline">
                          <div className="w-1/3">{t("branch")}</div>
                          <div className="w-2/3">
                            <Form.Item name="branch" formik={formik}>
                              <Select
                                id="branch"
                                isDisabled={true}
                                options={branches}
                                {...formik.getFieldProps("branch")}
                                onChange={(val) => {
                                  formik.setFieldValue("branch", val)
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      )}
                      {/* Login */}
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("username")}</div>
                        <div className="w-2/3">
                          <Form.Item name="login" formik={formik}>
                            <Input
                              disabled={true}
                              id="login"
                              type="text"
                              {...formik.getFieldProps("login")}
                              onChange={(val) =>
                                formik.setFieldValue(
                                  "login",
                                  val.target.value.trim()
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Skeleton />
      )}
    </div>
  )
}
