import { withStyles } from "@material-ui/styles";
import Menu from "@material-ui/core/Menu";

export const StyledPropMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    padding: "0px 5px",
    fontSize: "12px",
    fontFamily: "Inter",
    marginTop: "0px",
    borderRadius: 6,
    boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034)`,
  },
})((props) => (
  <Menu
    className="scroll_menu_list shadow-lg"
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  >
    {props.children}
  </Menu>
));
