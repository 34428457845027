import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import Paper from "@material-ui/core/Paper"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import SearchIcon from "@material-ui/icons/Search"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import CustomButton from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import { StyledTableCell } from "../../components/StyledTableCell"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import { addIcon } from "../../assets/icons/icons"
import EmptyTable from "../../components/EmptyTable"
import parseQuery from "../../functions/parseQuery"

function PaymentAccount() {
  let { limit, offset } = parseQuery()
  const { t } = useTranslation()
  const history = useHistory()

  const [items, setItems] = useState({})

  const [isLoading, setIsLoading] = useState(true)
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10

  let debounce = setTimeout(() => {}, 0)

  useEffect(() => {
    getItems({
      limit: limit ?? pageCount,
      offset: offset ?? 0,
      account_group_id: 6,
    })
  }, [limit, offset])

  // **** FUNCTIONS ****
  const getItems = (params = { account_group_id: 6 }) => {
    axios.get(`/billing/account`, { params }).then((res) => {
      setItems({
        data: res.data,
      })
      setIsLoading(false)
    })
  }

  // **** EVENTS ****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({
        limit: limit ?? pageCount,
        offset: offset ?? 0,
        account_group_id: 6,
        search: val,
      })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/settings/payment-account`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("payment-account")}
        endAdornment={
          permissions.includes("settings_attributes_create")
            ? [
                <CustomButton
                  shape="text"
                  color="text-secondary"
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  icon={addIcon}
                  onClick={() =>
                    history.push("/home/settings/payment-account/create")
                  }
                >
                  {t("create")}
                </CustomButton>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>
      <div className="px-5 pb-4">
        <TableContainer className="mt-4" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50">
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("payment-account")}</StyledTableCell>
                <StyledTableCell align="right">
                  {t("created.date")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.data && items.data?.accounts?.length ? (
                items.data.accounts.map(
                  ({ created_at, name, number }, index) => (
                    <TableRow
                      key={number}
                      className="hover:bg-gray-100 cursor-pointer"
                      style={{ background: index % 2 === 0 && "#F6F8F9" }}
                      onClick={() => {
                        if (permissions.includes("settings_attributes_edit"))
                          return history.push(
                            `/home/settings/payment-account/${number}`
                          )
                      }}
                    >
                      <StyledTableCell className="">{name}</StyledTableCell>
                      <StyledTableCell className="">{number}</StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="text-blue-600">
                          {dateTimeConvert(created_at)}
                        </div>
                      </StyledTableCell>
                      <MoreClickHandler
                        id={number}
                        api="/billing/account"
                        pushLocation="/home/settings/payment-account"
                        editPermission="settings_attributes_edit"
                        deletePermission="settings_attributes_delete"
                        getItems={() =>
                          getItems({
                            limit: limit ?? pageCount,
                            offset: offset ?? 0,
                            account_group_id: 6,
                          })
                        }
                      />
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <EmptyTable
          message="Нет инфо"
          isActive={!isLoading && !items.data?.accounts?.length > 0}
        />

        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.data?.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentAccount
