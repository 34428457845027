import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import axios from "../../utils/axios"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import Pagination from "../../components/Pagination/Pagination"
import moment from "moment"
import { StyledTableCell } from "../../components/StyledTableCell"
import Paper from "@material-ui/core/Paper"
import EmptyTable from "../../components/EmptyTable"

function Maintenance({ limit, offset, count, pageCount, setCount }) {
  const { t } = useTranslation()
  const params = useParams()

  const [items, setItems] = useState({})

  // **** FUNCTIONS ****
  const getItems = useCallback(
    async (queryParams = {}) => {
      let response = await axios.get(`/repair?car_id=${params.id}`, {
        params: queryParams,
      })
      setItems(response.data)
      setCount(response.data.count)
    },
    [params.id, setCount]
  )

  const changeOffset = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
    },
    [getItems, limit, pageCount]
  )

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [getItems, limit, pageCount, offset])

  return (
    <>
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>№</StyledTableCell>
              <StyledTableCell>{t("from")}</StyledTableCell>
              <StyledTableCell>{t("till")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.repairs && items.repairs?.length ? (
              items.repairs.map(({ id, from_time, to_time }, index) => (
                <TableRow key={id} className="hover:bg-gray-100">
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>
                    {moment(from_time).format("yyyy-MM-DD")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(to_time).format("yyyy-MM-DD")}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <EmptyTable message="Нет историй" isActive={!items.repairs?.length > 0} />
      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(count / pageCount)}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changeOffset}
        />
      </div>
    </>
  )
}

export default Maintenance
