import { useState, useEffect, useMemo, useRef, useCallback } from "react"
import Textarea from "../../components/Textarea"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import CircularProgress from "@material-ui/core/CircularProgress"
import ClearIcon from "@material-ui/icons/Clear"
import axios from "../../utils/axios"
import { showAlert } from "../../redux/reducers/alertReducer"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Card from "../../components/Card/index"
import Form from "../../components/Form/Index"
import PriceInput from "../../components/Form/input/PriceInput"
import cashIcon from "../../assets/icons/cash.png"
import humoIcon from "../../assets/icons/humo.png"
import uzcardIcon from "../../assets/icons/uzcard.png"
import visaIcon from "../../assets/icons/visa.png"
import depositIcon from "../../assets/icons/deposit.png"
import AlertComponent from "../../components/Alert"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import { useReactToPrint } from "react-to-print"
import Cheque from "../../components/Cheque/Cheque"

const paymentOptions = [
  {
    type: "cash",
    icon: cashIcon,
    amount: undefined,
  },
  {
    type: "humo",
    icon: humoIcon,
    amount: undefined,
  },
  {
    type: "uzcard",
    icon: uzcardIcon,
    amount: undefined,
  },
  {
    type: "visa",
    icon: visaIcon,
    amount: undefined,
  },
  {
    type: "deposit",
    icon: depositIcon,
    amount: undefined,
  },
]

function CostsCreate() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const clientId = useSelector((state) => state.auth.clientId)
  const userId = useSelector((state) => state.auth.userId)
  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [items, setItems] = useState([])
  const [totalDebt, setTotalDebt] = useState()
  const [depositAmount, setDepositAmount] = useState(0)
  const [depositInputValue, setDepositInputValue] = useState({})
  const [comment, setComment] = useState("")
  const [isPartPrice, setIsPartPrice] = useState(true)
  const [allPaid, setAllPaid] = useState(true)
  const [isDepositValid, setIsDepositValid] = useState(true)
  const [chequeData, setChequeData] = useState()
  const [cashierName, setCashierName] = useState()
  const [changedPayment, setChangedPayment] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const chequeRef = useRef()

  const getDepositAmount = async (orderId) => {
    let response = await axios.get(`/deposit?type=debit&order_id=${orderId}`)
    let anotherResponse = await axios.get(
      `/deposit?type=credit&order_id=${orderId}`
    )
    let deposit = await response.reduce((prev, cur) => prev + +cur, 0)

    let anotherDeposit = await anotherResponse.reduce(
      (prev, cur) => prev + +cur,
      0
    )
    setDepositAmount(deposit - anotherDeposit)
  }

  // **** FUNCTIONS ****
  const getProperty = useCallback(() => {
    setIsGettingData(true)
    axios
      .get(`/cost-by-order`, {
        params: {
          order_id: params.id,
        },
      })
      .then((res) => {
        setTotalDebt(
          res.data?.costs?.map((cost) =>
            cost.expenses?.reduce((prev, cur) => prev + +cur?.price, 0)
          )
        )
        setItems(res.data?.costs)
        setItems((old) =>
          old?.map((cost, index) => ({
            ...cost,
            selectedPayments: [
              {
                type: "cash",
                icon: cashIcon,
                // amount: res.data?.costs
                //   ?.map((cost) =>
                //     cost.expenses?.reduce((prev, cur) => prev + +cur?.price, 0)
                //   )
                //   [index].toString(),
                amount: undefined,
              },
            ],
            paymentMethods: paymentOptions.slice(1),
          }))
        )
        // set total debt to later check if all of them is paid
      })
      .finally(() => setIsGettingData(false))
  }, [params.id])

  const getCashierName = (id) => {
    axios
      .get(`/system-user/${id}`)
      .then((res) => {
        setCashierName(res.full_name)
      })
      .finally(() => setIsGettingData(false))
  }

  useEffect(() => {
    getCashierName(userId)
    return () => {
      setCashierName(undefined)
    }
  }, [userId])

  const calculateTotalPrice = () => {
    return items.reduce((acc, curr) => {
      if (curr.selectedPayments) {
        return (
          curr.selectedPayments.reduce((acc, curr) => {
            if (curr.amount) {
              return acc + +curr.amount
            } else {
              return acc
            }
          }, 0) + acc
        )
      } else {
        return acc
      }
    }, 0)
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    if (allPaid && isDepositValid && !isPartPrice) {
      setLoading(true)
      setBtnDisabled(true)

      axios
        .post(`/billing/cost-payment`, {
          order_id: params.id,
          cashier_id: clientId,
          comment: comment,
          costs: items
            .filter(
              (item, index) =>
                items[index]?.selectedPayments?.reduce(
                  (prev, cur) => prev + (+cur?.amount || 0),
                  0
                ) >= item?.expenses?.reduce((prev, cur) => prev + +cur.price, 0)
            )
            .map((cost) => ({
              cost_type: cost.cost_id,
              cost_id: cost.expenses[0]?.id,
              payments: cost.selectedPayments
                ?.filter(({ amount, type }) => {
                  return amount !== undefined
                })
                ?.map((payment) => ({
                  payment_type: payment.type,
                  amount: +payment.amount,
                })),
            })),
        })
        .then((res) => {
          getChequeData(params.id)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
          setBtnDisabled(false)
        })
    } else {
      if (!isDepositValid) {
        dispatch(showAlert("Недостаточно сумма депозита", "warning"))
      } else if (isPartPrice) {
        dispatch(showAlert("Извините, нельзя оплатить часть суммы или больше", "warning"))
      } else if (!allPaid) {
        dispatch(
          showAlert("Пожалуйста, оплатите все штрафы", "warning")
        )
      } 
    }
  }

  const getChequeData = async (orderId) => {
    setIsGettingData(true)
    axios
      .get(`/cheque?&order_id=${orderId}`)
      .catch((error) => {
        console.log(error)
      })
      .then((res) => {
        setChequeData(res)
        setIsGettingData(false)
      })
      .finally(history.push("/home/orders?tab=2"))
  }

  const generateCheque = useReactToPrint({
    content: () => chequeRef.current,
    onAfterPrint: () => history.push("/home/orders?tab=2"),
    removeAfterPrint: true,
  })

  useEffect(() => {
    if (chequeData) {
      generateCheque()
    }
    return () => {
      setChequeData(undefined)
    }
  }, [chequeData, generateCheque])

  const addSelectedPayment = (val, index, paymentIndex) => {
    setItems((old) =>
      old?.map((el, id) =>
        id === index
          ? {
              ...el,
              selectedPayments: [
                ...el.selectedPayments,
                { type: val.type, amount: undefined, icon: val.icon },
              ],
              paymentMethods: el.paymentMethods?.filter(
                (item, i) => i !== paymentIndex
              ),
            }
          : el
      )
    )
  }

  const removeSelectedPayment = (val, costIndex, paymentIndex) => {
    if (val.type === "deposit") {
      setDepositInputValue((old) => ({ ...old, [costIndex]: 0 }))
    }
    setItems((old) =>
      old?.map((el, index) =>
        costIndex === index
          ? {
              ...el,
              selectedPayments: el.selectedPayments?.filter(
                (payment, i) => i !== paymentIndex
              ),
              paymentMethods: [
                ...el.paymentMethods,
                { type: val.type, amount: undefined, icon: val.icon },
              ],
            }
          : el
      )
    )
  }

  const updatePayments = (input, costIndex, paymentIndex, paymentType) => {
    const num = input ? +input.replace(/\s+/g, "") : ""

    if (paymentType === "deposit") {
      setDepositInputValue((old) => ({ ...old, [costIndex]: num }))
    }

    setItems((old) =>
      old?.map((el, elIndex) =>
        elIndex === costIndex
          ? {
              ...el,
              selectedPayments: el.selectedPayments?.map((item, itemIndex) =>
                itemIndex === paymentIndex ? { ...item, amount: num } : item
              ),
            }
          : el
      )
    )
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({})

  const routes = [
    {
      title: t("orders"),
      link: true,
      route: "/home/orders?tab=0",
    },
    {
      title: t("payments"),
    },
  ]

  const depositCount = useMemo(() => {
    let result = 0
    for (let key in depositInputValue) {
      result += depositInputValue[key]
    }
    return result
  }, [depositInputValue])

  useEffect(() => {
    if (depositCount > depositAmount) {
      setIsDepositValid(false)
    } else {
      setIsDepositValid(true)
    }

    const eachCostTotal =
      items &&
      items.map((cost) =>
        cost?.expenses?.reduce((prev, cur) => prev + +cur?.price, 0)
      )
    const eachCostPaid =
      items &&
      items.map((cost) =>
        cost.selectedPayments?.reduce(
          (prev, cur) => prev + (+cur?.amount || 0),
          0
        )
      )

    if (
      eachCostTotal &&
      eachCostPaid.some(
        (item, index) => eachCostTotal[index] != item && item > 0
      )
    ) {
      setIsPartPrice(true)
    } else {
      setIsPartPrice(false)
    }

    if (
      eachCostTotal &&
      eachCostPaid.every((item, index) => item == eachCostTotal[index])
    ) {
      setAllPaid(true)
    } else {
      setAllPaid(false)
    }
  }, [items, depositAmount, depositCount])

  useEffect(() => {
    if (params.id) {
      getProperty()
      getDepositAmount(params.id)
    }
  }, [getProperty, params.id])

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("fine.payment")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    size="large"
                    shape="text"
                    color="text-secondary"
                    onClick={() => history.goBack()}
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    size="large"
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        <></>
                      )
                    }
                    disabled={btnDisabled}
                  >
                    {t("make.payment")}
                  </CustomButton>,
                ]}
              />
              <AlertComponent
                style={{
                  top: "2px",
                  left: "50%",
                  marginLeft: "-100px",
                }}
              />
              <div className="pt-4 pl-4 pr-2 w-1/2 text-sm">
                <Card title={t("deposit")}>
                  <div
                    className="flex space-x-8 font-semibold text-gray-600"
                    style={{ fontSize: "16px" }}
                  >
                    <p>({t("deposit.amount")})</p>
                    <p style={{color: isDepositValid ? '#52525b' : "#ef4444"}}>
                      {`${convertToInternationalCurrencySystem(
                        depositAmount - depositCount
                      )} ${isDepositValid ? '' : '(Недостаточно сумма депозита)'}`}
                    </p>
                  </div>
                </Card>
              </div>
              <div className="p-4 w-full grid grid-cols-2 grid-flow-row auto-rows-auto gap-4 box-border font-body text-sm">
                {items?.map((cost, i) => (
                  <div>
                    <Card title={t(cost.cost_name)}>
                      <div className="space-y-4 ">
                        <div
                          className="flex space-x-8 font-semibold text-gray-600"
                          style={{ fontSize: "16px" }}
                        >
                          <p>({t("overall.money")})</p>
                          <p>
                            {convertToInternationalCurrencySystem(
                              cost.expenses?.reduce(
                                (prev, cur) => prev + +cur?.price,
                                0
                              )
                            )}
                          </p>

                          {cost.selectedPayments?.reduce(
                            (prev, cur) => prev + +cur?.amount,
                            0
                          ) < totalDebt[i] && (
                            <div className="flex flex-col font-light text-sm items-end">
                              <p>
                                {convertToInternationalCurrencySystem(
                                  (cost.selectedPayments?.reduce(
                                    (prev, cur) => prev + +cur?.amount,
                                    0
                                  ) || 0) - totalDebt[i]
                                )}
                              </p>
                              <p>{t("shortage")}</p>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col space-y-1 text-md text-gray-600">
                          {cost.expenses?.map((expense, i) => (
                            <div className="flex space-x-8">
                              <h6>
                                {cost.cost_name} {i + 1}
                              </h6>
                              <p>
                                {convertToInternationalCurrencySystem(
                                  expense.price
                                )}
                              </p>
                            </div>
                          ))}
                        </div>
                        {/* Sub payments */}
                        <div className="py-2 space-y-2">
                          {cost.selectedPayments &&
                            cost.selectedPayments?.map((el, index) => (
                              <div className="w-full flex text-gray-700">
                                <div className="flex w-1/3 items-center">
                                  <span
                                    className="flex justify-center 
                                      items-center border 
                                      rounded-md hover:bg-gray-100
                                      transition ease-linear
                                      cursor-pointer py-2 px-6 mr-4"
                                    style={{ width: "85px", height: "40px" }}
                                    onClick={() =>
                                      removeSelectedPayment(el, i, index)
                                    }
                                  >
                                    <img src={el.icon} alt="" />
                                  </span>
                                  {t(el.type)}
                                </div>
                                <div className="flex w-2/3 items-center space-x-1">
                                  <div className="w-full">
                                    <Form.Item
                                      name={`subpayment[${index}]`}
                                      formik={formik}
                                    >
                                      <PriceInput
                                        // type="number"
                                        placeholder={t("enter.the.amount")}
                                        value={el.amount || ''}
                                        // disabled={depositCount > depositAmount}
                                        onChange={(e) => {
                                          setChangedPayment(false)
                                          updatePayments(
                                            e.target.value,
                                            i,
                                            index,
                                            el.type
                                          )
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div
                                    className="border rounded cursor-pointer
                                          transition  ease-linear hover:border-gray-400
                                        "
                                    style={{ padding: "0.185rem" }}
                                    onClick={() => {
                                      setChangedPayment(true)
                                      removeSelectedPayment(el, i, index)
                                    }}
                                  >
                                    <ClearIcon
                                      className="text-gray-400 "
                                      style={{ fontSize: "25px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          {cost?.paymentMethods &&
                            cost?.paymentMethods.length !== 0 && (
                              <>
                                <h6 className="font-semibold text-gray-700 py-2">
                                  {t("add.payment.option")}
                                </h6>
                                {/* Payment options */}
                                <div className="w-full py-2 flex items-center space-x-2">
                                  {cost?.paymentMethods?.length &&
                                    cost?.paymentMethods?.map((el, index) => (
                                      <span
                                        className="flex justify-center 
                                      rounded-md hover:bg-gray-100
                                      transition ease-linear
                                      items-center border cursor-pointer
                                      py-2 px-6"
                                        style={{
                                          width: "85px",
                                          height: "40px",
                                        }}
                                        onClick={() =>
                                          addSelectedPayment(el, i, index)
                                        }
                                      >
                                        <img src={el.icon} alt="" />
                                      </span>
                                    ))}
                                </div>
                              </>
                            )}
                        </div>
                        {/* Comment */}
                        <div>
                          <div className="flex w-full text-md text-gray-600">
                            <p className="w-1/2 mr-8">{t("comment")}:</p>
                            <Textarea
                              type="text"
                              placeholder={t("leave.comment")}
                              style={{
                                minHeight: "100px",
                                width: "100%",
                              }}
                              className={"w-full"}
                              onChange={(val) => setComment(val.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
      <div className="hidden">
        {chequeData && cashierName && (
          <Cheque
            ref={chequeRef}
            cheque_no={chequeData?.cheque_no}
            firm={chequeData?.firm}
            client={chequeData?.client}
            cash={items.reduce((acc, curr) => {
              if (curr.selectedPayments) {
                const newCurr = curr.selectedPayments.find(
                  (payment) => payment.type === "cash"
                )
                if (newCurr) {
                  return acc + +newCurr.amount
                } else {
                  return acc
                }
              } else {
                return acc
              }
            }, 0)}
            uzcard={items.reduce((acc, curr) => {
              if (curr.selectedPayments) {
                const newCurr = curr.selectedPayments.find(
                  (payment) => payment.type === "uzcard"
                )
                if (newCurr) {
                  return acc + +newCurr.amount
                } else {
                  return acc
                }
              } else {
                return acc
              }
            }, 0)}
            humo={items.reduce((acc, curr) => {
              if (curr.selectedPayments) {
                const newCurr = curr.selectedPayments.find(
                  (payment) => payment.type === "humo"
                )
                if (newCurr) {
                  return acc + +newCurr.amount
                } else {
                  return acc
                }
              } else {
                return acc
              }
            }, 0)}
            visa={items.reduce((acc, curr) => {
              if (curr.selectedPayments) {
                const newCurr = curr.selectedPayments.find(
                  (payment) => payment.type === "visa"
                )
                if (newCurr) {
                  return acc + +newCurr.amount
                } else {
                  return acc
                }
              } else {
                return acc
              }
            }, 0)}
            discount={chequeData?.discount}
            deposit={depositAmount}
            service_payment={
              calculateTotalPrice() -
              Math.round((calculateTotalPrice() * 0.15) / 1.15)
            }
            qqs={Math.round((calculateTotalPrice() * 0.15) / 1.15)}
            total_price={calculateTotalPrice() + depositAmount}
          />
        )}
      </div>
    </div>
  )
}

export default CostsCreate
