import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../utils/axios"
import { useHistory, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "../../components/Card/index"
import YandexMaps from "../../components/YandexMaps"
import { useDispatch } from "react-redux"
import { CLEAR_ON_SIGNOUT } from "../../redux/constants"
import { useSelector } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
// import PhoneInput from "../../components/Form/input/PhoneInput"
// import Select from "../../components/Select/index"
import Switch from "../../components/Switch"
import ImageUpload from "../../components/Upload/ImgUpload"
import AddIcon from "@material-ui/icons/Add"
import validateForm from "../../functions/validateForm"
import formatPhoneNumber from "../../functions/formatPhoneNumber"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import TableSkeleton from "../../components/Skeleton/TableSkeleton"
import parseQuery from "../../functions/parseQuery"
import TableContext from "./TableContext"

function BranchesCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [image, setImage] = useState("")
  // const [companies, setCompanies] = useState([])
  const [location, setLocation] = useState({})
  const [mapInfo, setMapInfo] = useState()
  const [items, setItems] = useState({})
  const [initialValues, setInitialValues] = useState({
    status: false,
    name: "",
    phone_number: "",
    photo: "",
    address: "",
    // company_id: undefined,
    is_required: false,
  })

  console.log("image", image)

  let { limit, offset, order, arrangement } = parseQuery()

  // **** FUNCTIONS ****
  const logoutHandler = useCallback(() => {
    dispatch({ type: CLEAR_ON_SIGNOUT })
    localStorage.removeItem("token")
    localStorage.removeItem("refresh-token")
  }, [dispatch])

  const changePaginationLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount
    getItems({ limit: pageCount, offset, order, arrangement })
  }

  const getProperty = async (id) => {
    // let [company] = await Promise.all([
    //   axios.get("/company?limit=100&offset=0").then((res) => res.data),
    // ])

    setIsGettingData(true)
    axios
      .get(`/branch/${id}`)
      .then((res) => {
        setInitialValues({
          ...res,
          // company_id: company.companies
          //   .filter((el) => el.id === res.company_id)
          //   .map((el) => ({ label: el.name, value: el.id }))[0],
          phone_number: formatPhoneNumber(res.phone_number),
        })

        setImage(res.photo)
        setMapInfo(res.location)
      })
      .finally(() => setIsGettingData(false))
  }

  // const getCompanies = async () => {
  //   let response = await axios.get(`/company?limit=10&offset=0`)

  //   setCompanies(
  //     response.data.companies.map(({ id, name }) => ({
  //       label: name,
  //       value: id,
  //     }))
  //   )
  // }

  const getItems = useCallback(
    async (params = {}) => {
      let response = await axios.get(`/order`, {
        params,
      })

      if (response.status == "401") {
        logoutHandler()
      } else {
        setItems({
          data: response.data,
        })
        setIsGettingData(false)
      }
    },
    [logoutHandler]
  )

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    if (params.id) {
      axios
        .put(`/branch/${params.id}`, {
          ...values,
          photo: image,
          location: location,
          phone_number: values.phone_number.replace(/ +/g, ""),
          status: values.status ? 1 : 0,
          // company_id: values.company_id.value,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/organizations/branches")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/branch", {
          ...values,
          photo: image,
          location: location,
          phone_number: values.phone_number.replace(/ +/g, ""),
          status: values.status ? 1 : 0,
          // company_id: values.company_id.value,
        })
        .then((res) => {
          console.log(res)
          history.push("/home/settings/organizations/branches")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    phone_number: validateForm("phone_number", t),
    // company_id: validateForm("mixed", t),
    address: validateForm("default", t),
  })

  const routes = [
    {
      title: t("organizations"),
      link: true,
      route: "/home/settings/organizations",
    },
    {
      title: t("branches"),
      link: true,
      route: "/home/settings/organizations/branches",
    },
    {
      title: params.id ? initialValues.name : t("create"),
    },
  ]

  useEffect(() => {
    ;(async () => {
      if (params.id) {
        getProperty(params.id)
      }
      // getCompanies().then((res) =>
      //   getItems({
      //     limit,
      //     offset: offset ?? 0,
      //     order: order,
      //     arrangement,
      //   })
      // )
    })()
  }, [params.id, getItems, arrangement, limit, offset, order])

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("branch")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={[
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    shape="text"
                    color="text-error-600"
                    icon={cancelIcon}
                    onClick={() =>
                      history.push("/home/settings/organizations/branches")
                    }
                  >
                    {t("cancel")}
                  </CustomButton>,
                  <CustomButton
                    textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                    type="submit"
                    shape="text"
                    color="text-primary-600"
                    icon={
                      loading ? (
                        <CircularProgress color="inherit" size={14} />
                      ) : (
                        saveIcon
                      )
                    }
                  >
                    {params.id ? t("save") : t("create")}
                  </CustomButton>,
                ]}
              />

              <div className="p-4 w-full grid grid-cols-3 gap-4 box-border font-body text-sm">
                <Card title={t("general.information")} className="col-span-2">
                  <div className="flex">
                    {/* Photo */}
                    <div className="flex flex-col w-1/4 mr-4">
                      <ImageUpload
                        onChange={(val) => setImage(val.filename)}
                        onStart={(val) => console.log(val)}
                        onFinish={(val) => console.log(val)}
                        onSuccess={(val) => console.log(val)}
                        onError={(val) => console.log(val)}
                        defaultImage={image}
                      >
                        <div className="flex flex-col items-center justify-center space-y-4">
                          <AddIcon style={{ fontSize: "200%" }} />
                          <h5>{t("select.image")}</h5>
                        </div>
                      </ImageUpload>
                    </div>

                    <div className="flex flex-col w-3/4">
                      {/* Name */}
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("name")}</div>
                        <div className="w-2/3">
                          <Form.Item name="name" formik={formik}>
                            <Input
                              id="name"
                              type="text"
                              {...formik.getFieldProps("name")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/* Phone */}
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("phone")}</div>
                        <div className="w-2/3">
                          <Form.Item name="phone_number" formik={formik}>
                            <Input
                              id="phone_number"
                              type="text"
                              {...formik.getFieldProps("phone_number")}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "phone_number",
                                  e.target.value
                                )
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/* Company */}
                      {/* <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("company")}</div>
                        <div className="w-2/3">
                          <Form.Item name="company_id" formik={formik}>
                            <Select
                              id="company_id"
                              isDisabled={params.id}
                              options={companies}
                              {...formik.getFieldProps("company_id")}
                              onChange={(val) => {
                                formik.setFieldValue("company_id", val)
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div> */}
                      {/* Address */}
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("address")}</div>
                        <div className="w-2/3">
                          <Form.Item name="address" formik={formik}>
                            <Input
                              id="address"
                              type="text"
                              {...formik.getFieldProps("address")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/* Status */}
                      <div className="w-full py-2 flex items-baseline">
                        <div className="w-1/3">{t("status")}</div>
                        <div className="flex w-2/3 space-x-4">
                          <div>
                            <Form.Item name="status" formik={formik}>
                              <Switch
                                id="status"
                                color="primary"
                                checked={formik.values.status}
                                onChange={(val) =>
                                  formik.setFieldValue("status", val)
                                }
                              />
                            </Form.Item>
                          </div>
                          <h5>
                            {formik.values.status ? t("active") : t("inactive")}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card title={t("location")} className="col-span-2">
                  <YandexMaps
                    onChange={(e) => setLocation(e)}
                    location={mapInfo}
                  />
                </Card>
                <Card title={t("orders")} className="col-span-3">
                  <TableContext
                    offset={offset}
                    permissions={permissions}
                    items={items}
                    pageCount={pageCount}
                    changePaginationLocationQuery={
                      changePaginationLocationQuery
                    }
                    isLoading={isGettingData}
                  />
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <TableSkeleton />
      )}
    </div>
  )
}

export default BranchesCreate
