import { stackMessages } from "./stack_messages"
import { errorHandler } from "./axios"

function connectSocket() {
  const token = localStorage.getItem("token")
  const ws = new WebSocket(
    `${
      process.env.REACT_APP_WEBSOCKET_BASE_URL
    }?Authorization=` + token
  )

  ws.onopen = function () {
    console.log("Connected to the websocket")
    ws.send("we have been connected to the web-socket :)")
  }

  ws.onmessage = function (mes) {
    try {
      const data = JSON.parse(mes.data)
      stackMessages.add(data)
    } catch (err) {
      console.log(err)
    }
  }

  ws.onclose = function (e) {
    console.log(`Socket is closed.`, e.reason)
    connectSocket()
  }

  ws.onerror = function (err) {
    console.error("Socket encountered error: ", err.message, "Closing socket")
    ws.close()
    errorHandler({ response: { status: 403 } })
  }
  return ws
}

export { connectSocket }
