import React, { useState, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import SearchIcon from "@material-ui/icons/Search"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import CircularProgress from "@material-ui/core/CircularProgress"

import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import CustomButton from "../../components/Buttons"
import Input from "../../components/Input"
import Filters from "../../components/Filters"
import { StyledTableCell } from "../../components/StyledTableCell"
import Pagination from "../../components/Pagination/Pagination"
import dateTimeConvert from "../../functions/dateTimeConvert"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import MoreClickHandler from "../../components/MoreClick"
import AlertComponent from "../../components/Alert"
import { addIcon } from "../../assets/icons/icons"
import EmptyTable from "../../components/EmptyTable"
import parseQuery from "../../functions/parseQuery"

function App() {
  const { t } = useTranslation()
  const history = useHistory()
  const [items, setItems] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const permissions = useSelector((state) => state.auth.permissions)

  const pageCount = 10

  let debounce = setTimeout(() => {}, 0)
  let { limit, offset } = parseQuery()

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [])

  // **** FUNCTIONS ****
  const getItems = (params = {}) => {
    axios.get(`/additional-tariff`, { params }).then((res) => {
      setItems(res.data)
      setIsLoading(false)
    })
  }

  // **** EVENTS ****
  const onSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, search: val })
    }, 300)
  }

  const changeLocationQuery = (event, value) => {
    let offset = (value - 1) * pageCount

    history.push({
      pathname: `/home/settings/additional-tariffs`,
      search: `?limit=${pageCount}&offset=${offset}`,
    })
  }

  return (
    <div>
      <AlertComponent
        style={{
          top: "2px",
          left: "50%",
          marginLeft: "-100px",
        }}
      />
      <Header
        title={t("additional.tariffs")}
        endAdornment={
          permissions.includes("settings_additional_tariffs_create")
            ? [
                <CustomButton
                  textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                  shape="text"
                  color="text-secondary"
                  icon={addIcon}
                  onClick={() =>
                    history.push("/home/settings/additional-tariffs/create")
                  }
                >
                  {t("create")}
                </CustomButton>,
              ]
            : ""
        }
      />
      <Filters>
        <div className="flex space-x-2">
          <Input
            icon={<SearchIcon className="text-gray-400" />}
            placeholder={t("search...")}
            style={{ width: "350px" }}
            onChange={(val) => {
              onSearch(val.target.value)
              setIsLoading(true)
            }}
          />
        </div>
      </Filters>

      <div className="px-5 pb-4">
        <TableContainer className="mt-4" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-gray-50 ">
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("price")}</StyledTableCell>
                <StyledTableCell>{t("created.date")}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.additional_tariffs && items.additional_tariffs?.length ? (
                items.additional_tariffs.map(
                  ({ id, name, price, created_at }, index) => (
                    <TableRow
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer"
                      style={{ background: index % 2 === 0 && "#F6F8F9" }}
                      onClick={() => {
                        if (
                          permissions.includes(
                            "settings_additional_tariffs_edit"
                          )
                        )
                          return history.push(
                            `/home/settings/additional-tariffs/${id}`
                          )
                      }}
                    >
                      <StyledTableCell>{name}</StyledTableCell>
                      <StyledTableCell>
                        {convertToInternationalCurrencySystem(price)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="text-blue-600">
                          {dateTimeConvert(created_at)}
                        </div>
                      </StyledTableCell>

                      <MoreClickHandler
                        id={id}
                        api="/additional-tariff"
                        pushLocation="/home/settings/additional-tariffs"
                        editPermission="settings_additional_tariffs_edit"
                        deletePermission="settings_additional_tariffs_delete"
                        getItems={() =>
                          getItems({
                            limit: limit ?? pageCount,
                            offset: offset ?? 0,
                          })
                        }
                      />
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <EmptyTable
          message="Нет тарифов"
          isActive={!isLoading && !items.additional_tariffs?.length > 0}
        />

        {isLoading && (
          <div className="flex justify-center py-2">
            <CircularProgress />
          </div>
        )}
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(items.count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeLocationQuery}
          />
        </div>
      </div>
    </div>
  )
}

export default App
