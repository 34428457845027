import { useState, useEffect, useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import moment from "moment"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import RangePicker from "../../../components/DatePicker/RangePicker"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import parseQuery from "../../../functions/parseQuery"
import Tag from "../../../components/Tag"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import TableHeadCell from "../../../components/TableCells/TableHeadCell"
import TableHeadSearchCell from "../../../components/TableCells/TableHeadSearchCell"
import TableHeadClientSearchCell from "../../../components/TableCells/TableHeadClientSearchCell"
import TableHeadFilterCell from "../../../components/TableCells/TableHeadFilterCell"
import TableHeadFilterCellCustomList from "../../../components/TableCells/TableHeadFilterCellCustomList"
import TableHeadCalendarCell from "../../../components/TableCells/TableHeadCalendarCell"
import TableBodyCell from "../../../components/TableCells/TableBodyCell"

const useStyles = makeStyles({
  root: {
    transition: "all .1s cubic-bezier(0.94, 0.05, 0.99, 0.02);",
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
})

function provideCycler() {
  function* generator(val = "") {
    var mapper = new Map([
      [0, null],
      [1, "asc"],
      [2, "desc"],
    ])
    var count = 1
    while (true) {
      yield mapper.get(count)
      if (count < 2) count++
      else count = 0
    }
  }
  var it = generator()
  return function (val) {
    return it.next(val).value
  }
}

var cycle

const initialState = {
  tariff_price: {
    order: null,
    arrangement: null,
  },
  add_tariff_price: {
    order: null,
    arrangement: null,
  },
  deposit: {
    order: null,
    arrangement: null,
  },
  discount: {
    order: null,
    arrangement: null,
  },
  obshiy_summa: {
    order: null,
    arrangement: null,
  },
  returned_money: {
    order: null,
    arrangement: null,
  },
  cost_paid: {
    order: null,
    arrangement: null,
  },
  investor_price: {
    order: null,
    arrangement: null,
  },
  company_price: {
    order: null,
    arrangement: null,
  },
  insurance_price: {
    order: null,
    arrangement: null,
  },
  cash: {
    order: null,
    arrangement: null,
  },
  uzcard: {
    order: null,
    arrangement: null,
  },
  humo: {
    order: null,
    arrangement: null,
  },
  payme: {
    order: null,
    arrangement: null,
  },
  click: {
    order: null,
    arrangement: null,
  },
  visa: {
    order: null,
    arrangement: null,
  },
  paid_price: {
    order: null,
    arrangement: null,
  },
  cost_price: {
    order: null,
    arrangement: null,
  },
  excess_deposit: {
    order: null,
    arrangement: null,
  },
}

const reducerFn = (state, { column, arrangement }) => {
  switch (arrangement) {
    case "asc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case "desc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case null:
      return initialState
    default:
      throw new Error("Error in reducer")
  }
}

const sourceOrderList = [
  { id: "operator", name: "operator" },
  { id: "app", name: "app" },
]

const paymentStatusList = [
  { id: "paid", name: "Оплачено" },
  { id: "not_paid", name: "Не оплачен" },
]

const depositStatusList = [
  { id: "paid", name: "Оплачено" },
  { id: "not_paid", name: "Не оплачен" },
  { id: "partially_paid", name: "Частично оплачен" },
]

const TotalReport = () => {
  const { t } = useTranslation()
  let { limit, offset } = parseQuery()
  const pageCount = 10
  const classes = useStyles()

  const [query, setQuery] = useState({})
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })
  const [count, setCount] = useState()
  const [lastClickedArrow, setLastClickedArrow] = useState()
  const [state, dispatch] = useReducer(reducerFn, initialState)
  const [page, setPage] = useState(1)

  const getItems = useCallback(async (params = {}) => {
    let response = await axios.get(`/total-report`, { params })

    setItems(await response.order)
    setCount(await response.count)
    setIsLoading(false)
  }, [])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/total-report-excel`)
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const filterHandler = (e, id, paramKey) => {
    const param = {}
    param[paramKey] = String(id)
    e.stopPropagation()
    getItems(param)
    setQuery(param)
    setPage(1)
  }

  const datePickerHandler = (val) => {
    getItems(val)
    setPage(1)
    setQuery(val)
  }

  // const updateDate = (val) =>
  //   setFromToDate({
  //     start: moment(val[0]).toISOString() ?? "",
  //     end: moment(val[1]).toISOString() ?? "",
  //   })

  const handleChangePage = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, ...query })
      setPage(value)
    },
    [getItems, limit, query]
  )

  const SorterArrow = useCallback(
    ({ column }) => {
      return state[column].arrangement === null ? (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer"
          classes={{ root: classes.root }}
          color="disabled"
          fontSize="medium"
          onClick={(e) => {
            if (lastClickedArrow !== e.target.closest(".arrow").id) {
              setLastClickedArrow(e.target.closest(".arrow").id)
              cycle = provideCycler()
            }
            dispatch({ column, arrangement: cycle() })
          }}
        />
      ) : state[column].arrangement === "desc" ? (
        <ArrowDownwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      ) : (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      )
    },
    [classes.root, state, lastClickedArrow]
  )

  // useEffect(() => {
  //   getItems()
  // }, [getItems])

  // useEffect(() => {
  //   getItems({ from_time: fromToDate.start, to_time: fromToDate.end })
  // }, [getItems, fromToDate])

  useEffect(() => {
    var i, obj
    for (i in state) {
      if (state.hasOwnProperty(i) && state[i].order && state[i].arrangement) {
        obj = state[i]
      }
    }
    getItems({
      limit: pageCount,
      offset: offset ?? 0,
      [obj?.order]: obj?.arrangement,
    })
  }, [offset, state])

  return (
    <div>
      <Header title={t("ordersReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        {/* <RangePicker
          showToday={false}
          showWeekNumber
          dateInputPlaceholder={[t("from"), t("till")]}
          showOk={false}
          showClear
          placeholder={t("select.a.date")}
          hideTimePicker
          onChange={(val) => updateDate(val)}
        /> */}
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer
          className="mt-2"
          elevation={0}
          component={Paper}
          // style={{ height: "70vh" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell row={2} title="&#8470;" />
                <TableHeadCell col={2} title="client" />
                <TableHeadCell col={15} title="Данные заказа" />
                <TableHeadCell col={10} title="cash" />
                <TableHeadCell col={8} title="payment.type" />
                <TableHeadCell col={3} title="Долг" />
                <TableHeadCell col={2} title="deposit" />
              </TableRow>

              <TableRow>
                <TableHeadClientSearchCell
                  title="client"
                  url="client"
                  paramKey="client_name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="phone.number"
                  url="client"
                  dataKey="clients"
                  paramKey="phone_number"
                  filterItemKey="phone_number"
                  itemKey="phone_number"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="order.number"
                  url="order"
                  dataKey="orders"
                  paramKey="order_number"
                  filterItemKey="order_number"
                  itemKey="order_number"
                  filter={filterHandler}
                />
                <TableHeadFilterCell
                  title="status"
                  url="status"
                  dataKey="statuses"
                  paramKey="status_order"
                  filterItemKey="statuses"
                  itemKey="statuses"
                  filter={filterHandler}
                />
                <TableHeadCalendarCell
                  title="status.date"
                  startKey="from_date"
                  endKey="to_date"
                  datePicker={datePickerHandler}
                />
                <TableHeadSearchCell
                  title="branch"
                  url="branch"
                  dataKey="branches"
                  paramKey="branch_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadFilterCellCustomList
                  title="Источник заказа"
                  data={sourceOrderList}
                  paramKey="source"
                  filter={filterHandler}
                />
                <TableHeadFilterCell
                  title="Канал привлечения"
                  url="cause"
                  dataKey="causes"
                  paramKey="cause_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="Категория машины"
                  url="model"
                  dataKey="models"
                  paramKey="category_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="car.model"
                  url="model1"
                  dataKey="categories"
                  paramKey="model_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="car.number"
                  url="car"
                  dataKey="cars"
                  paramKey="state_number"
                  filterItemKey="state_number"
                  itemKey="state_number"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="investor"
                  url="investor"
                  dataKey="investors"
                  paramKey="investor_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadSearchCell
                  title="creator"
                  url="system-user"
                  dataKey="system_users"
                  paramKey="cashier_id"
                  filterItemKey="id"
                  itemKey="full_name"
                  filter={filterHandler}
                />
                <TableHeadCell title="Количество дней" />
                <TableHeadSearchCell
                  title="tariff"
                  url="tariff"
                  dataKey="tariffs"
                  paramKey="tariff_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadCell title="tariffs.additional" />
                <TableHeadCell title="Коментарии" />

                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow000"
                >
                  {t("tariff.price")}
                  {<SorterArrow column="tariff_price" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow001"
                >
                  {t("additional.tariffs.summ")}
                  {<SorterArrow column="add_tariff_price" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow002"
                >
                  {t("deposit")}
                  {<SorterArrow column="deposit" />}
                </TableCell>
                {/* <TableHeadSearchCell
                  title="discount"
                  url="client"
                  filter={filterHandler}
                /> */}
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow003"
                >
                  {t("Сумма скидки")}
                  {<SorterArrow column="discount" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow004"
                >
                  {t("overall.money")}
                  {<SorterArrow column="obshiy_summa" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow0041"
                >
                  {t("Оставшиеся деньги клиента")}
                  {<SorterArrow column="returned_money" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow0042"
                >
                  {t("Деньги от штрафов")}
                  {<SorterArrow column="cost_paid" />}
                </TableCell>

                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow005"
                >
                  {t("investor.sum")}
                  {<SorterArrow column="investor_price" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow006"
                >
                  {t("company.sum")}
                  {<SorterArrow column="company_price" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow007"
                >
                  {t("insurance_price")}
                  {<SorterArrow column="insurance_price" />}
                </TableCell>

                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow008"
                >
                  {t("cash")}
                  {<SorterArrow column="cash" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow009"
                >
                  {t("uzcard")}
                  {<SorterArrow column="uzcard" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow010"
                >
                  {t("humo")}
                  {<SorterArrow column="humo" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow011"
                >
                  {t("payme")}
                  {<SorterArrow column="payme" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow012"
                >
                  {t("click")}
                  {<SorterArrow column="click" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow013"
                >
                  {t("visa")}
                  {<SorterArrow column="visa" />}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow014"
                >
                  {t("Оплаченный сумма")}
                  {<SorterArrow column="paid_price" />}
                </TableCell>
                <TableHeadFilterCellCustomList
                  title="payment.status"
                  data={paymentStatusList}
                  paramKey="status_price"
                  filter={filterHandler}
                />

                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow015"
                >
                  {t("debt.sum")}
                  {<SorterArrow column="cost_price" />}
                </TableCell>
                <TableHeadCell title="Причина долга" />
                <TableHeadCalendarCell
                  title="Дата задолженности"
                  startKey="cost_start_date"
                  endKey="cost_end_date"
                  datePicker={datePickerHandler}
                />

                <TableHeadFilterCellCustomList
                  title="Статус депозита"
                  data={depositStatusList}
                  paramKey="deposit_status"
                  filter={filterHandler}
                />
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow016"
                >
                  {t("Остаток депозит")}
                  {<SorterArrow column="excess_deposit" />}
                </TableCell>
              </TableRow>
            </TableHead>
            {items?.map((item, index) => (
              <TableRow
                style={{ background: index % 2 === 0 && "#F6F8F9" }}
                key={index}
              >
                <TableBodyCell title={index + 1} fontWeight="600" />
                <TableBodyCell title={item?.client_name} />
                <TableBodyCell title={item?.client?.phone_number} />
                <TableBodyCell title={item?.order_number} />
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd", whiteSpace: "pre" }}
                >
                  <Tag
                    shape="subtle"
                    textColor={
                      item?.status_id === "not_paid"
                        ? "text-gray-900"
                        : item?.status_id === "new"
                        ? "text-blue-600"
                        : item?.status_id === "client_took"
                        ? "text-amber-600"
                        : item?.status_id === "client_returned"
                        ? "text-purple-600"
                        : item?.status_id === "completed"
                        ? "text-green-600"
                        : "text-red-600"
                    }
                    bgColor={
                      item?.status_id === "not_paid"
                        ? "bg-gray-300"
                        : item?.status_id === "new"
                        ? "bg-blue-100"
                        : item?.status_id === "client_took"
                        ? "bg-amber-100"
                        : item?.status_id === "client_returned"
                        ? "bg-purple-100"
                        : item?.status_id === "completed"
                        ? "bg-green-50"
                        : "bg-red-100"
                    }
                    size="large"
                  >
                    {t(item?.status_id)}
                  </Tag>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd", whiteSpace: "pre" }}
                >
                  {moment(item?.updated_at).format("DD.MM.YYYY / HH:mm")}
                </TableCell>
                <TableBodyCell title={item?.branch?.name} />
                <TableBodyCell title={item?.source} />
                <TableBodyCell title={item?.cause?.name} />
                <TableBodyCell title={item?.car?.category?.name} />
                <TableBodyCell title={item?.car?.model?.name} />
                <TableBodyCell title={item?.car?.state_number} />
                <TableBodyCell title={item?.investor?.name} />
                <TableBodyCell
                  title={
                    item?.order_payment
                      ? item?.order_payment[0].cashier?.full_name
                      : ""
                  }
                />
                <TableBodyCell title={item?.day_count} />
                <TableBodyCell title={item?.tariff?.name} />
                <TableBodyCell
                  title={item?.additional_tariff_name?.map(
                    (tariffItem) => `${tariffItem}\n`
                  )}
                />
                <TableBodyCell
                  title={item?.comment?.map(
                    (commentItem) => `${commentItem.comment}\n`
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.tariff?.price
                  )}
                />
                <TableBodyCell title={item?.add_tariff_price} />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.deposit?.price
                  )}
                />
                {/* <TableBodyCell title={item?.discount_percentage + " %"} /> */}
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(item?.discount)}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.total_price
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.returned_money
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.cost_paid
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.investor_price
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.company_price
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.insurance_price
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.payment?.filter(
                      (item) => item.payment_type === "cash"
                    )[0]?.amount
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.payment?.filter(
                      (item) => item.payment_type === "uzcard"
                    )[0]?.amount
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.payment?.filter(
                      (item) => item.payment_type === "humo"
                    )[0]?.amount
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.payment?.filter(
                      (item) => item.payment_type === "payme"
                    )[0]?.amount
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.payment?.filter(
                      (item) => item.payment_type === "click"
                    )[0]?.amount
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.payment?.filter(
                      (item) => item.payment_type === "visa"
                    )[0]?.amount
                  )}
                />
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(item?.paid_price)}
                />
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  <Tag
                    shape="subtle"
                    size="large"
                    textColor={
                      item?.is_paid
                        ? item?.is_paid === 1
                          ? "text-green-600"
                          : "text-amber-600"
                        : "text-red-600"
                    }
                    bgColor={
                      item?.is_paid
                        ? item?.is_paid === 1
                          ? "bg-green-100"
                          : "bg-amber-100"
                        : "bg-red-100"
                    }
                  >
                    {item?.is_paid === 1
                      ? t("paid")
                      : item?.is_paid === 2
                      ? t("partially.paid")
                      : t("not.paid")}
                  </Tag>
                </TableCell>
                <TableBodyCell title={item?.cost_price} />
                <TableBodyCell
                  title={item?.order_cost?.map(
                    (commentItem) => `${commentItem.comment}\n`
                  )}
                />
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd", whiteSpace: "pre" }}
                >
                  {item?.order_cost &&
                    moment(item?.order_cost[0].created_at).format(
                      "DD.MM.YYYY / HH:mm"
                    )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  <Tag
                    shape="subtle"
                    size="large"
                    textColor={
                      item?.deposit?.status
                        ? item?.deposit?.status === "paid"
                          ? "text-green-600"
                          : item?.deposit?.status === "partially_paid"
                          ? "text-amber-600"
                          : "text-red-600"
                        : ""
                    }
                    bgColor={
                      item?.deposit?.status
                        ? item?.deposit?.status === "paid"
                          ? "bg-green-100"
                          : item?.deposit?.status === "partially_paid"
                          ? "bg-amber-100"
                          : "bg-red-100"
                        : ""
                    }
                  >
                    {item?.deposit?.status
                      ? item?.deposit?.status === "paid"
                        ? t("paid")
                        : item?.deposit?.status === "partially_paid"
                        ? t("partially.paid")
                        : t("not.paid")
                      : ""}
                  </Tag>
                </TableCell>
                <TableBodyCell
                  title={convertToInternationalCurrencySystem(
                    item?.deposit?.excess_deposit
                  )}
                />
              </TableRow>
            ))}
          </Table>
          <EmptyTable
            message="Нет отчетов"
            isActive={!isLoading && !items?.length > 0}
            className="
            flex flex-col 
            justify-center
            items-center    
            text-gray-400
            space-y-2
            py-44 my-2"
          />
          {isLoading && <ReportSkeleton />}
        </TableContainer>
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            color="primary"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(count / pageCount)}
            page={page}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  )
}

export default TotalReport
