import { useEffect } from "react"
import Layout from "../components/Layout/index.jsx"
import Sidebar from "../components/Sidebar/index.jsx"
import { connectSocket } from "../utils/socket_init.js"
import { connect } from "../utils/stack_messages"

export default function DashboardLayout({ children }) {
  useEffect(() => {
    console.log("Socket connected ............")
    var ws = connectSocket()
    connect("messages")
    setInterval(() => {
      ws.send("PING")
    }, 30000)
  }, [])

  return (
    <div>
      <Layout sidebar={<Sidebar />}>{children}</Layout>
    </div>
  )
}
