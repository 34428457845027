import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";

import { deleteAlert } from "../../redux/reducers/alertReducer";

let timeout;

const AlertElement = ({ id, title, type }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    timeout = setTimeout(() => {
      dispatch(deleteAlert(id));
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="py-1">
      <Alert
        severity={type}
        style={{ padding: "10px 30px" }}
        className="shake-animation"
        onClose={() => dispatch(deleteAlert(id))}
      >
        {title}
      </Alert>
    </div>
  );
};

export default AlertElement;
