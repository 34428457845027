import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Formik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import axios from "../../utils/axios"
import Header from "../../components/Header/Header"
import Breadcrumb from "../../components/Breadcrumb/index"
import CustomButton from "../../components/Buttons/index"
import Filters from "../../components/Filters"
import defaultSetting from "../../config/defaultSettings"
import { TabPanel } from "../../components/Tab/TabBody"
import SwipeableViews from "react-swipeable-views"
import GeneralInfo from "./tabs/GeneralInfo"
import CarHistory from "./tabs/CarHistory"
import OrderHistory from "./tabs/OrderHistory"
import { useSelector } from "react-redux"
import { StyledTabs, StyledTab } from "../../components/StyledTabs"
import validateForm from "../../functions/validateForm"
import { cancelIcon, saveIcon } from "../../assets/icons/icons"
import ExpendituresHistory from "./tabs/ExpendituresHistory"

const numberTypes = [
  {
    label: "01 111 AAA",
    value: "gov_number",
  },
  {
    label: "01 A 111 AA",
    value: "own_number",
  },
]

var iValues = {
  brand: undefined,
  model: undefined,
  category: undefined,
  firm: undefined,
  investor: undefined,
  investor_share: "",
  owner: "",
  session_id: "",

  car_attribute_categories: [],
  // dynamically set initial values for model attributes
  // "Мощность двигателя": undefined,
  // "Обьем двигателя": undefined,
  // Цвет: undefined,

  number_type: { label: "01 A 111 AA", value: "own_number" },
  state_number: "",
  mileage: "",

  registration_certificate: {
    certificate_number: "",
    given_date: "",
    given_place: "",
  },
  tone_expire_date: "",
  made_year: "",
}

function CarsCreate() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const theme = useTheme()
  const location = useLocation()

  // Tabs
  const [value, setValue] = useState(0)
  const [insideValue, setInsideValue] = useState(0)

  const [loading, setLoading] = useState(false)
  const [isGettingData, setIsGettingData] = useState(false)
  const [oilChangeHistories, setOilChangeHistories] = useState([])
  const [orderHistories, setOrderHistories] = useState([])
  const [models, setModels] = useState([])
  const [brands, setBrands] = useState([])
  const [colorAttributes, setColorAttributes] = useState([])
  const [categories, setCategories] = useState([])
  const [firms, setFirms] = useState([])
  const [investors, setInvestors] = useState([])
  const [owners, setOwners] = useState([{ label: "Нет владельца", value: "" }])
  const [modelAttributes, setModelAttributes] = useState([])
  const [attributeValidations, setAttributeValidations] = useState({})
  const [techPassInsuranceDate, setTechPassInsuranceDate] = useState({})
  const [investorSelected, setInvestorSelected] = useState()
  const [initialValues, setInitialValues] = useState(iValues)

  const permissions = useSelector((state) => state.auth.permissions)

  useEffect(() => {
    if (params.id) {
      getProperty(params.id)
    }
    getModels()
    getBrands()
    getCategories()
    getFirms()
    getInvestors()
    getOwners()
    getAttribute()
  }, [])

  useEffect(() => {
    let validations = {}
    let dynamicAttributes = {}
    modelAttributes?.forEach(({ attributes }) => {
      attributes?.forEach((attribute) => {
        validations[attribute.name] = validateForm("mixed", t)
        dynamicAttributes[attribute.name] = undefined
      })
    })
    setInitialValues((prev) => ({ ...dynamicAttributes, ...prev }))
    setAttributeValidations(validations)
  }, [modelAttributes, t])

  // **** FUNCTIONS ****
  const getProperty = (id) => {
    setIsGettingData(true)
    axios
      .get(`/car/${id}`)
      .then((res) => {
        let attributes = {}
        res?.car_attribute_categories?.forEach(({ car_attributes }) => {
          car_attributes?.forEach((attribute) => {
            attributes[attribute.name] = {
              label: attribute.value.value,
              value: attribute.value.id,
            }
          })
        })

        res?.investor?.name && setInvestorSelected(true)

        setInitialValues({
          ...res,
          number_type:
            res.state_number.charAt(3) >= "0" &&
            res.state_number.charAt(3) <= "9"
              ? numberTypes[0]
              : numberTypes[1],
          brand: res?.brand?.name
            ? { value: res.brand.id, label: res.brand.name }
            : null,
          firm: res?.firm?.name
            ? { value: res.firm.id, label: res.firm.name }
            : null,
          category: res?.category?.name
            ? { value: res.category.id, label: res.category.name }
            : null,
          investor: res?.investor?.name
            ? { value: res.investor.id, label: res.investor.name }
            : null,
          investor_share: res?.investor_percentage
            ? res?.investor_percentage + ""
            : "",
          owner: res?.owner?.name
            ? { value: res.owner.id, label: res.owner.name }
            : null,
          model: res?.model?.name
            ? { value: res.model.id, label: res.model.name }
            : null,
          // dynamically set model atttributes here
          ...attributes,
          color: res?.color ? { value: res.color, label: res.color } : null,
        })

        setModelAttributes(
          res.car_attribute_categories?.map(({ id, name, car_attributes }) => ({
            attribute_category: { id: id, name: name },
            attributes: car_attributes?.map(({ id, name, value }) => ({
              id: id,
              name: name,
              value: { value: value.id, label: value.value },
            })),
          }))
        )
      })
      .finally(() => setIsGettingData(false))
  }

  const getModels = () => {
    axios.get("/model?limit=100&offset=0").then((res) =>
      setModels(
        res.data.models.map(({ name, id }) => ({
          label: name,
          value: id,
        }))
      )
    )
  }
  const getAttribute = () => {
    axios.get(`/attribute/${defaultSetting.ColorAttributeId}`).then((res) => {
      setColorAttributes(
        res.values.map(({ value, id }) => ({
          label: value.trim(),
          value: id,
        }))
      )
    })
  }
  const getBrands = () => {
    axios.get("/brand?limit=100&offset=0").then((res) =>
      setBrands(
        res.data.brands.map(({ name, id }) => ({
          label: name,
          value: id,
        }))
      )
    )
  }
  const getCategories = () => {
    axios.get("/category?limit=100&offset=0").then((res) =>
      setCategories(
        res.data.categories.map(({ name, id }) => ({
          label: name,
          value: id,
        }))
      )
    )
  }

  const getFirms = () => {
    axios.get("/firm?limit=100&offset=0").then((res) =>
      setFirms(
        res.data.firms.map(({ name, id }) => ({
          label: name,
          value: id,
        }))
      )
    )
  }

  const getInvestors = () => {
    axios.get("/investor?limit=100&offset=0").then((res) => {
      const comingInvestors = res.data.investors.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
      setInvestors((prev) => {
        return [...prev, ...comingInvestors]
      })
    })
  }

  const getOwners = () => {
    axios.get("/car-owner?limit=100&offset=0").then((res) =>
      setOwners((prev) => [
        ...prev,
        ...res.data.owners.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
      ])
    )
  }

  // **** EVENTS ****
  const onSubmit = (values) => {
    setLoading(true)
    let data = {
      ...values,

      brand_id: values?.brand?.value || "",
      model_id: values?.model?.value || "",
      category_id: values?.category?.value || "",
      firm_id: values?.firm?.value || "",
      investor_id: values?.investor?.value || "",
      owner: values?.owner?.value || "",
      investor_percentage: Number(values?.investor_share) || 0,
      color: values?.color?.label || "",
      tone_expire_date: values?.tone_expire_date
        ? moment(values?.tone_expire_date).add(5, "hours")
        : "",
      made_year: values?.made_year,
      engine_power: values?.engine_power,
      engine_volume: values?.engine_volume,

      car_attribute_categories: modelAttributes
        ? modelAttributes.map(({ attribute_category, attributes }) => ({
            attribute_category_id: attribute_category.id,
            car_attributes: attributes.map(({ id, value }) => ({
              attribute_id: id,
              attribute_value_id: value?.value,
            })),
          }))
        : [],

      registration_certificate: {
        certificate_number:
          values?.registration_certificate?.certificate_number,
        given_date: values?.registration_certificate?.given_date,
        given_place: values?.registration_certificate?.given_place,
      },
      from_time: values?.from_time || "",
      to_time: values?.to_time || "",
    }
    if (params.id) {
      axios
        .put(`/car/${params.id}`, data)
        .then((res) => {
          history.goBack("/home/cars")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      axios
        .post("/car", data)
        .then((res) => {
          history.goBack("/home/cars")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const routes = [
    {
      title: t("cars"),
      link: true,
      route: "/home/cars",
    },
    {
      title: params.id ? initialValues.state_number : t("create"),
    },
  ]

  // **** CONSTANTS ****
  const ValidationSchema = Yup.lazy((values) => {
    switch (typeof values.model) {
      case "undefined":
        return defaultValidation
      case "object":
        return withAttributeValidations
      default:
        return defaultValidation
    }
  })

  const defaultValidation = Yup.object().shape({
    brand: validateForm("mixed", t),
    category: validateForm("mixed", t),
    firm: validateForm("mixed", t),
    investor: validateForm("mixed", t),
    model: validateForm("mixed", t),
    state_number: validateForm("car_number", t),
    mileage: validateForm("number", t),
    made_year: validateForm("mixed", t),
    investor_share: investorSelected && validateForm("default", t),
    investor: validateForm("mixed", t),
  })

  const withAttributeValidations = Yup.object().shape({
    brand: validateForm("mixed", t),
    category: validateForm("mixed", t),
    firm: validateForm("mixed", t),
    investor: validateForm("mixed", t),
    model: validateForm("mixed", t),
    state_number: validateForm("car_number", t),
    mileage: validateForm("number", t),
    made_year: validateForm("mixed", t),
    investor_share: investorSelected && validateForm("default", t),
    // dynamically set model attributes here
    ...attributeValidations,
    investor: validateForm("mixed", t),
  })

  // Tabs
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => setValue(newValue)

  const handleChangeIndex = (index) => setValue(index)

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>
  }

  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("car")}
                startAdornment={[<Breadcrumb routes={routes} />]}
                endAdornment={
                  value === 0
                    ? permissions.includes("cars_edit") && [
                        <CustomButton
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          shape="text"
                          color="text-error-600"
                          icon={cancelIcon}
                          onClick={() => history.push("/home/cars")}
                        >
                          {t("cancel")}
                        </CustomButton>,
                        <CustomButton
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                        >
                          {params.id ? t("save") : t("create")}
                        </CustomButton>,
                      ]
                    : value === 1
                    ? [
                        <CustomButton
                          disabled={insideValue === 3}
                          size="large"
                          shape="text"
                          color="text-primary-600"
                          onClick={() =>
                            history.push(
                              `/home/cars/${params.id}/${
                                insideValue === 0
                                  ? "oil-change"
                                  : insideValue === 1
                                  ? "insurance"
                                  : "tech-inspection"
                                // ? "tech-inspection"
                                // : "maintenance"
                              }/create`
                            )
                          }
                        >
                          {t("create")}
                        </CustomButton>,
                      ]
                    : ""
                }
              />
              <Filters>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{ children: <span className="w-2" /> }}
                >
                  <StyledTab
                    label={tabLabel(t("about.car"))}
                    {...a11yProps(0)}
                    style={{ width: "100px" }}
                  />
                  {!location.pathname.includes("/home/cars/create") && [
                    <StyledTab
                      label={tabLabel(t("car.history"))}
                      {...a11yProps(1)}
                    />,
                    <StyledTab
                      label={tabLabel(t("order.history"))}
                      {...a11yProps(2)}
                    />,
                    <StyledTab
                      label={tabLabel(t("expenditures.history"))}
                      {...a11yProps(3)}
                    />,
                  ]}
                </StyledTabs>
              </Filters>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <GeneralInfo
                    formik={formik}
                    params={params}
                    models={models}
                    brands={brands}
                    colorAttributes={colorAttributes}
                    categories={categories}
                    firms={firms}
                    investors={investors}
                    owners={owners}
                    modelAttributes={modelAttributes}
                    numberTypes={numberTypes}
                    setModelAttributes={setModelAttributes}
                    setColorAttributes={setColorAttributes}
                    setInitialValues={setInitialValues}
                    techPassInsuranceDate={techPassInsuranceDate}
                    setTechPassInsuranceDate={setTechPassInsuranceDate}
                    setInvestorSelected={setInvestorSelected}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <CarHistory
                    params={params}
                    styledTabs={StyledTabs}
                    styledTab={StyledTab}
                    a11yProps={a11yProps}
                    oilChangeHistories={oilChangeHistories}
                    onChange={(val) => setInsideValue(val)}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <OrderHistory
                    orderHistories={orderHistories}
                    history={history}
                  />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                  <ExpendituresHistory />
                </TabPanel>
              </SwipeableViews>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </div>
  )
}

export default CarsCreate
