import React from "react"
import moment from "moment"
import styles from "./Cheque.module.css"

function Cheque(
  {
    cheque_no,
    firm,
    client,
    tariff_price,
    cash,
    uzcard,
    humo,
    visa,
    discount,
    deposit,
    service_payment,
    qqs,
    total_price,
  },
  ref
) {
  return (
    <div className={styles.cheque} ref={ref}>
      <div className={styles.container}>
        <h1 className={styles.h1}>{firm}</h1>
        <h1 className={styles.h1}>TOSHKENT SHAHRI</h1>
        <p className={styles.number}>Tel: +99871 200 60 50</p>
        <div className={styles.item}>
          CHEK № <span>{cheque_no}</span>
        </div>
        <div className={styles.item}>
          SANA <span>{moment().format("DD.MM.YYYY HH:mm:ss")}</span>
        </div>
        <h2 className={styles.h2}>AVTO IJARA</h2>
        <div className={styles.item}>
          KIMDAN <span>{client}</span>
        </div>
        {tariff_price ? (
          <div className={styles.item}>
            TARIF <span>{tariff_price}</span>
          </div>
        ) : null}
        {cash ? (
          <div className={styles.item}>
            NAQD <span>{cash}</span>
          </div>
        ) : null}
        {uzcard ? (
          <div className={styles.item}>
            UZCARD <span>{uzcard}</span>
          </div>
        ) : null}
        {humo ? (
          <div className={styles.item}>
            HUMO <span>{humo}</span>
          </div>
        ) : null}
        {visa ? (
          <div className={styles.item}>
            VISA <span>{visa}</span>
          </div>
        ) : null}
        {discount ? (
          <div className={styles.item}>
            CHEGIRMA <span>{discount}</span>
          </div>
        ) : null}
        {deposit ? (
          <div className={styles.item}>
            DEPOZIT <span>{deposit}</span>
          </div>
        ) : null}
        <div className={styles.item}>
          XIZMAT NARXI <span>{service_payment}</span>
        </div>
        <div className={styles.item}>
          QQS <span>{qqs}</span>
        </div>
        <div className={styles.item}>
          JAMI TO'LOV<span>{total_price}</span>
        </div>
        <p className={styles.footer}>TANLOVINGIZ UCHUN RAHMAT!!!</p>
      </div>
    </div>
  )
}

export default React.forwardRef(Cheque)
