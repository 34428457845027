import React from "react";
import { useTranslation } from "react-i18next";

import Card from "../../components/Card/index";
import Form from "../../components/Form/Index";
import Input from "../../components/Input/index";
import Select from "../../components/Select/index";

function Context(props) {
  const { branches, formik, setBranchId } = props;

  const { t } = useTranslation();

  return (
    <div className="w-full grid grid-cols-2 gap-4 box-border font-body text-sm pb-24">
      <Card title={t("general.information")} className="text-sm">
        {/* Name */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3">{t("name")}</div>
          <div className="w-2/3">
            <Form.Item name="name" formik={formik}>
              <Input id="name" type="text" {...formik.getFieldProps("name")} />
            </Form.Item>
          </div>
        </div>
        {/* Branch */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3">{t("branch")}</div>
          <div className="w-2/3">
            <Form.Item name="branch" formik={formik}>
              <Select
                options={branches}
                {...formik.getFieldProps("branch")}
                onChange={(val) => {
                  formik.setFieldValue("branch", val);
                  setBranchId(val.value);
                }}
              />
            </Form.Item>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Context;
