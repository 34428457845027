import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

import axios from "../../../utils/axios";
import Tag from "../../../components/Tag";
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency";
import formatPhoneNumber from "../../../functions/formatPhoneNumber";
import { StyledTableCell } from "../../../components/StyledTableCell";
import EmptyTable from "../../../components/EmptyTable";

function ClientOrders() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const [orders, setOrders] = useState([]);

  useEffect(() => getClientOrders(), [])

  const getClientOrders = () => {
    axios.get(`/order?client_id=${params.id}&status=old`).then((res) => {
      setOrders(res.data.count ? res.data.orders : undefined);
    });
  };
  
  return (
    <div className="w-full">
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table" style={{ overflowX: "auto" }}>
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("order.number")}</StyledTableCell>
              <StyledTableCell>{t("client")}</StyledTableCell>
              <StyledTableCell>{t("date.from.to")}</StyledTableCell>
              <StyledTableCell>{t("tariff")}</StyledTableCell>
              <StyledTableCell>{t("total.price")}</StyledTableCell>
              <StyledTableCell>{t("status")}</StyledTableCell>
              <StyledTableCell>{t("payment.status")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.length ? (
              orders?.map(
                (
                  {
                    id,
                    order_number,
                    client,
                    tariff,
                    total_price,
                    from_time,
                    status,
                    to_time,
                    is_paid,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                    onClick={() => history.push(`/home/orders/${id}`)}
                  >
                    <StyledTableCell>{order_number}</StyledTableCell>
                    <StyledTableCell>
                      <div>
                        {client.first_name} {client.last_name}
                      </div>
                      <div className="text-blue-600">
                        {formatPhoneNumber(client.phone_number)}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <>
                        <div>{`${moment(from_time).format("DD/MM")} `}</div>
                        <div>{moment(to_time).format("DD/MM")}</div>
                      </>
                    </StyledTableCell>

                    <StyledTableCell>{tariff.name}</StyledTableCell>
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(total_price)}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        textColor={
                          status.id === "new"
                            ? "text-blue-600"
                            : "text-yellow-600"
                        }
                        bgColor={
                          status.id === "new" ? "bg-blue-100" : "bg-yellow-100"
                        }
                        size="large"
                        style={{ width: "160px" }}
                      >
                        {t(status?.id)}
                      </Tag>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        textColor={
                          is_paid
                            ? is_paid === 1
                              ? "text-green-600"
                              : "text-amber-600"
                            : "text-red-600"
                        }
                        bgColor={
                          is_paid
                            ? is_paid === 1
                              ? "bg-green-100"
                              : "bg-amber-100"
                            : "bg-red-100"
                        }
                        size="large"
                        style={{ width: "160px" }}
                      >
                        {t(
                          is_paid
                            ? is_paid === 1
                              ? "paid"
                              : "partially.paid"
                            : "not.paid"
                        )}
                      </Tag>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable message="Нет заказов" isActive={!orders?.length > 0} />
    </div>
  );
}

export default ClientOrders;
