export const brandLogo = (
  <svg
    width="36"
    height="28"
    viewBox="0 0 36 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.47567 6.03918L0.818841 7.71854C0.314451 7.91231 0.0622559 8.42904 0.0622559 8.94576C0.0622559 9.46249 0.377499 9.97921 0.818841 10.173L3.52994 11.4002L9.83481 8.49363L4.47567 6.03918Z"
      fill="#4E7BAB"
    />
    <path
      d="M12.3575 9.78577L6.11566 12.6278L9.3942 14.1133L15.5099 11.2068L12.3575 9.78577Z"
      fill="#4E7BAB"
    />
    <path
      d="M18.032 12.4984L11.9793 15.3404L17.4645 17.8594C17.6537 17.924 17.8428 17.9886 18.032 17.9886C18.2211 17.9886 18.4103 17.924 18.5994 17.8594L24.1477 15.3404L18.032 12.4984Z"
      fill="#4E7BAB"
    />
    <path
      d="M15.4469 5.97483L12.3575 7.39582L6.99835 4.87679L10.0247 3.52039L15.4469 5.97483Z"
      fill="#4E7BAB"
    />
    <path
      d="M21.1211 8.62302L18.0317 10.1086L14.9423 8.62302L18.0317 7.20203L21.1211 8.62302Z"
      fill="#4E7BAB"
    />
    <path
      d="M23.6434 9.78577L20.491 11.2713L26.5436 14.1133L29.8222 12.6278L23.6434 9.78577Z"
      fill="#4E7BAB"
    />
    <path
      d="M23.391 2.35768L17.9688 4.81212L12.6096 2.29309L17.4644 0.097008C17.7796 -0.0321732 18.221 -0.0321732 18.5362 0.097008L23.391 2.35768Z"
      fill="#4E7BAB"
    />
    <path
      d="M29.0025 4.87679L23.6434 7.39582L20.6171 5.97483L26.0393 3.52039L29.0025 4.87679Z"
      fill="#4E7BAB"
    />
    <path
      d="M35.9377 8.94576C35.9377 9.46249 35.6225 9.97921 35.1811 10.173L32.47 11.4002L26.2282 8.55822L31.5874 6.03918L35.2442 7.71854C35.6225 7.9769 35.9377 8.42904 35.9377 8.94576Z"
      fill="#4E7BAB"
    />
    <path
      d="M35.1812 12.6923L34.8029 12.4985L18.5363 19.991C18.3472 20.0556 18.158 20.1202 17.9689 20.1202C17.7797 20.1202 17.5906 20.0556 17.4015 19.991L1.19793 12.5631L0.756585 12.7569C0.315244 12.9507 0 13.4674 0 13.9841C0 14.5008 0.315244 15.0176 0.756585 15.2113L17.4645 22.833C17.6537 22.8976 17.8428 22.9622 18.0319 22.9622C18.2211 22.9622 18.4102 22.8976 18.5994 22.833L35.1812 15.2113C35.6225 15.0176 35.9378 14.5008 35.9378 13.9841C35.9378 13.4028 35.6225 12.9507 35.1812 12.6923Z"
      fill="#4E7BAB"
    />
    <path
      d="M35.1804 17.7946L34.7391 17.6008L18.5355 25.0288C18.3464 25.0934 18.1572 25.1579 17.9681 25.1579C17.779 25.1579 17.5898 25.0934 17.4007 25.0288L1.26018 17.5363L0.818841 17.7946C0.3775 17.9884 0.0622559 18.5051 0.0622559 19.0218C0.0622559 19.5386 0.3775 20.0553 0.818841 20.2491L17.4637 27.8707C17.6529 27.9353 17.842 27.9999 18.0312 27.9999C18.2203 27.9999 18.4094 27.9353 18.5986 27.8707L35.2435 20.1845C35.6848 19.9907 36 19.474 36 18.9572C36 18.4405 35.6218 17.9884 35.1804 17.7946Z"
      fill="#4E7BAB"
    />
  </svg>
);

export const moneyIcon = (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.83335 7.08333C2.94169 6.59167 2.33335 6.08333 2.33335 5.29167C2.33335 4.38333 3.17502 3.75 4.58335 3.75C5.76669 3.75 6.35835 4.2 6.57502 4.91667C6.67502 5.25 6.95002 5.5 7.30002 5.5H7.55002C8.10002 5.5 8.49169 4.95833 8.30002 4.44167C7.95002 3.45833 7.13335 2.64167 5.83335 2.325V1.75C5.83335 1.05833 5.27502 0.5 4.58335 0.5C3.89169 0.5 3.33335 1.05833 3.33335 1.75V2.3C1.71669 2.65 0.416687 3.7 0.416687 5.30833C0.416687 7.23333 2.00835 8.19167 4.33335 8.75C6.41669 9.25 6.83335 9.98333 6.83335 10.7583C6.83335 11.3333 6.42502 12.25 4.58335 12.25C3.20835 12.25 2.50002 11.7583 2.22502 11.0583C2.10002 10.7333 1.81669 10.5 1.47502 10.5H1.24169C0.683353 10.5 0.291687 11.0667 0.50002 11.5833C0.97502 12.7417 2.08335 13.425 3.33335 13.6917V14.25C3.33335 14.9417 3.89169 15.5 4.58335 15.5C5.27502 15.5 5.83335 14.9417 5.83335 14.25V13.7083C7.45835 13.4 8.75002 12.4583 8.75002 10.75C8.75002 8.38333 6.72502 7.575 4.83335 7.08333Z"
      fill="#703581"
    />
  </svg>
);

export const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 10.8337H10.8334V15.0003C10.8334 15.4587 10.4584 15.8337 10.0001 15.8337C9.54175 15.8337 9.16675 15.4587 9.16675 15.0003V10.8337H5.00008C4.54175 10.8337 4.16675 10.4587 4.16675 10.0003C4.16675 9.54199 4.54175 9.16699 5.00008 9.16699H9.16675V5.00033C9.16675 4.54199 9.54175 4.16699 10.0001 4.16699C10.4584 4.16699 10.8334 4.54199 10.8334 5.00033V9.16699H15.0001C15.4584 9.16699 15.8334 9.54199 15.8334 10.0003C15.8334 10.4587 15.4584 10.8337 15.0001 10.8337Z"
      fill="#B5C847"
    />
  </svg>
);

export const saveIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6583 2.99167C14.3417 2.675 13.9167 2.5 13.475 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V6.525C17.5 6.08333 17.325 5.65833 17.0083 5.35L14.6583 2.99167ZM10 15.8333C8.61667 15.8333 7.5 14.7167 7.5 13.3333C7.5 11.95 8.61667 10.8333 10 10.8333C11.3833 10.8333 12.5 11.95 12.5 13.3333C12.5 14.7167 11.3833 15.8333 10 15.8333ZM10.8333 7.5H5.83333C4.91667 7.5 4.16667 6.75 4.16667 5.83333C4.16667 4.91667 4.91667 4.16667 5.83333 4.16667H10.8333C11.75 4.16667 12.5 4.91667 12.5 5.83333C12.5 6.75 11.75 7.5 10.8333 7.5Z"
      fill="#703581"
    />
  </svg>
);

export const cancelIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 1.6665C5.39163 1.6665 1.66663 5.3915 1.66663 9.99984C1.66663 14.6082 5.39163 18.3332 9.99996 18.3332C14.6083 18.3332 18.3333 14.6082 18.3333 9.99984C18.3333 5.3915 14.6083 1.6665 9.99996 1.6665ZM13.5833 13.5832C13.2583 13.9082 12.7333 13.9082 12.4083 13.5832L9.99996 11.1748L7.59163 13.5832C7.26663 13.9082 6.74163 13.9082 6.41663 13.5832C6.09163 13.2582 6.09163 12.7332 6.41663 12.4082L8.82496 9.99984L6.41663 7.5915C6.09163 7.2665 6.09163 6.7415 6.41663 6.4165C6.74163 6.0915 7.26663 6.0915 7.59163 6.4165L9.99996 8.82484L12.4083 6.4165C12.7333 6.0915 13.2583 6.0915 13.5833 6.4165C13.9083 6.7415 13.9083 7.2665 13.5833 7.5915L11.175 9.99984L13.5833 12.4082C13.9 12.7248 13.9 13.2582 13.5833 13.5832Z"
      fill="#F76659"
    />
  </svg>
);

export const emptyState = (
  <svg
    width="184"
    height="152"
    viewBox="0 0 184 152"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(24 31.67)">
        <ellipse
          fillOpacity=".8"
          fill="#F5F5F7"
          cx="67.797"
          cy="106.89"
          rx="67.797"
          ry="12.668"
        ></ellipse>
        <path
          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          fill="#AEB8C2"
        ></path>
        <path
          d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
          fill="url(#linearGradient-1)"
          transform="translate(13.56)"
        ></path>
        <path
          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          fill="#F5F5F7"
        ></path>
        <path
          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          fill="#DCE0E6"
        ></path>
      </g>
      <path
        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        fill="#DCE0E6"
      ></path>
      <g transform="translate(149.65 15.383)" fill="#FFF">
        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse>
        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
      </g>
    </g>
  </svg>
);
