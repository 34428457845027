import { useState, useEffect, useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import ConvertToExcel from "../../../components/ConvertToExcel"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import downloadFile from "../../../functions/downloadFile"
import parseQuery from "../../../functions/parseQuery"
import Tag from "../../../components/Tag"
import TableHeadCell from "../../../components/TableCells/TableHeadCell"
import TableHeadSearchCell from "../../../components/TableCells/TableHeadSearchCell"
import TableHeadClientSearchCell from "../../../components/TableCells/TableHeadClientSearchCell"
import TableHeadFilterCell from "../../../components/TableCells/TableHeadFilterCell"
import TableHeadFilterCellCustomList from "../../../components/TableCells/TableHeadFilterCellCustomList"
import TableHeadCalendarCell from "../../../components/TableCells/TableHeadCalendarCell"
import TableBodyCell from "../../../components/TableCells/TableBodyCell"

const useStyles = makeStyles({
  root: {
    transition: "all .1s cubic-bezier(0.94, 0.05, 0.99, 0.02);",
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
})

function provideCycler() {
  function* generator(val = "") {
    var mapper = new Map([
      [0, null],
      [1, "asc"],
      [2, "desc"],
    ])
    var count = 1
    while (true) {
      yield mapper.get(count)
      if (count < 2) count++
      else count = 0
    }
  }
  var it = generator()
  return function (val) {
    return it.next(val).value
  }
}

var cycle

const initialState = {
  deposit_price: {
    order: null,
    arrangement: null,
  },
}

const reducerFn = (state, { column, arrangement }) => {
  switch (arrangement) {
    case "asc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case "desc":
      return { ...initialState, [column]: { order: column, arrangement } }
    case null:
      return initialState
    default:
      throw new Error("Error in reducer")
  }
}

const depositStatusList = [
  { id: "0", name: "Принято" },
  { id: "1", name: "Отдано" },
]

const DepositsReport = () => {
  const { t } = useTranslation()
  let { limit, offset } = parseQuery()
  const pageCount = 10
  const classes = useStyles()

  const [query, setQuery] = useState({})
  const [items, setItems] = useState([])
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [count, setCount] = useState()
  const [balance, setBalance] = useState()
  const [lastClickedArrow, setLastClickedArrow] = useState()
  const [state, dispatch] = useReducer(reducerFn, initialState)
  const [page, setPage] = useState(1)

  const getItems = useCallback(async (params = {}) => {
    let response = await axios.get(`/report/deposit`, { params })
    setItems(await response.deposits)
    setCount(await response.count)
    setBalance(await response.total_amount)
    setIsLoading(false)
  }, [])

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/report/deposit-excel`)
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const handleChangePage = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0, ...query })
      setPage(value)
    },
    [getItems, limit, query]
  )

  const filterHandler = (e, id, paramKey) => {
    const param = {}
    param[paramKey] = String(id)
    e.stopPropagation()
    getItems(param)
    setQuery(param)
    setPage(1)
  }

  useEffect(() => {
    getItems()
  }, [getItems])

  const SorterArrow = useCallback(
    ({ column }) => {
      return state[column].arrangement === null ? (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer"
          classes={{ root: classes.root }}
          color="disabled"
          fontSize="medium"
          onClick={(e) => {
            if (lastClickedArrow !== e.target.closest(".arrow").id) {
              setLastClickedArrow(e.target.closest(".arrow").id)
              cycle = provideCycler()
            }
            dispatch({ column, arrangement: cycle() })
          }}
        />
      ) : state[column].arrangement === "desc" ? (
        <ArrowDownwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      ) : (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() => dispatch({ column, arrangement: cycle() })}
        />
      )
    },
    [classes.root, state, lastClickedArrow]
  )

  useEffect(() => {
    var i, obj
    for (i in state) {
      if (state.hasOwnProperty(i) && state[i].order && state[i].arrangement) {
        obj = state[i]
      }
    }
    getItems({
      limit: pageCount,
      offset: offset ?? 0,
      [obj?.order]: obj?.arrangement,
    })
    setPage(1)
  }, [state])

  return (
    <div>
      <Header title={t("depositsReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        <div className="m-auto">
          {t("balance")}:{" "}
          <span className="font-bold">
            {convertToInternationalCurrencySystem(balance)}
          </span>
        </div>
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell row={2} title="&#8470;" />
                <TableHeadSearchCell
                  title="order.number"
                  url="order"
                  dataKey="orders"
                  paramKey="order_number"
                  filterItemKey="order_number"
                  itemKey="order_number"
                  filter={filterHandler}
                />
                <TableHeadClientSearchCell
                  title="client.name"
                  url="client"
                  paramKey="client_name"
                  filter={filterHandler}
                />
                <TableCell
                  align="center"
                  style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                  className="arrow whitespace-nowrap"
                  id="arrow002"
                >
                  {t("deposit.amount")}
                  {<SorterArrow column="deposit_price" />}
                </TableCell>
                <TableHeadFilterCellCustomList
                  align="right-0"
                  title="status"
                  data={depositStatusList}
                  paramKey="status"
                  filter={filterHandler}
                />
              </TableRow>
            </TableHead>

            {items?.map((item, index) => (
              <TableRow
                onClick={() =>
                  history.push(
                    `/home/orders/${item.order_id}?from_deposits=true`
                  )
                }
                style={{
                  cursor: "pointer",
                  background: index % 2 === 0 && "#F6F8F9",
                }}
                key={index}
              >
                <TableBodyCell title={index + 1} fontWeight="600" />
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.order_number}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.client_name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.amount)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  <Tag
                    shape="subtle"
                    textColor={
                      item?.status
                        ? item?.status === 1
                          ? "text-green-600"
                          : "text-amber-600"
                        : "text-red-600"
                    }
                    bgColor={
                      item?.status
                        ? item?.status === 1
                          ? "bg-green-100"
                          : "bg-amber-100"
                        : "bg-red-100"
                    }
                    size="large"
                    style={{ width: "110px", margin: "auto" }}
                  >
                    {t(
                      item?.status
                        ? item?.status === 1
                          ? "given"
                          : "partial"
                        : "received"
                    )}
                  </Tag>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <div className="flex justify-end">
          <Pagination
            className="mt-5"
            color="primary"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(count / pageCount)}
            page={page}
            onChange={handleChangePage}
          />
        </div>
        <EmptyTable
          message="Нет депозитов"
          isActive={!isLoading && !items?.length > 0}
          style={{ marginTop: "0" }}
        />
        {isLoading && <ReportSkeleton />}
      </div>
    </div>
  )
}

export default DepositsReport
