import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "../../components/Card/index";

import Form from "../../components/Form/Index";
import axios from "../../utils/axios";
import Header from "../../components/Header/Header";
import CustomButton from "../../components/Buttons/index";
import Input from "../../components/Input/index";
import Textarea from "../../components/Textarea/index";
import Skeleton from "../../components/Skeleton";
import { saveIcon } from "../../assets/icons/icons";

function Confidentiality() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isGettingData, setIsGettingData] = useState(false);
  const permissions = useSelector((state) => state.auth.permissions);

  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    getProperty();
  }, []);

  // **** FUNCTIONS ****
  const getProperty = () => {
    setIsGettingData(true);

    axios
      .get(`/confidentiality`)
      .then((res) => {
        let data = {
          ...res,
        };
        setInitialValues(data);
      })
      .finally(() => setIsGettingData(false));
  };

  // **** EVENTS ****
  const onSubmit = (values) => {
    axios
      .put(`/confidentiality`, {
        ...values,
      })
      .then((res) => {
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    title: Yup.mixed().required(t("required.field.error")),
    description: Yup.mixed().required(t("required.field.error")),
  });

  return (
    <div>
      {!isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header
                title={t("confidentiality")}
                endAdornment={
                  permissions.includes("settings_about_us_edit")
                    ? [
                        <CustomButton
                          type="submit"
                          shape="text"
                          color="text-primary-600"
                          textStyle={{ fontWeight: "500", padding: "0.7rem 0" }}
                          icon={
                            loading ? (
                              <CircularProgress color="inherit" size={14} />
                            ) : (
                              saveIcon
                            )
                          }
                        >
                          {t("save")}
                        </CustomButton>,
                      ]
                    : ""
                }
              />

              <div className="p-4 w-full grid grid-cols-3 gap-4 box-border font-body text-sm">
                <Card title={[t("general.information")]} className="col-span-2">
                  {/* Title */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("title")}</div>
                    <div className="w-2/3">
                      <Form.Item name="title" formik={formik}>
                        <Input
                          id="title"
                          type="text"
                          {...formik.getFieldProps("title")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* Description */}
                  <div className="w-full py-2 flex items-baseline">
                    <div className="w-1/3">{t("description")}</div>
                    <div className="w-2/3">
                      <Form.Item name="description" formik={formik}>
                        <Textarea
                          id="description"
                          type="text"
                          {...formik.getFieldProps("description")}
                          style={{ minHeight: "450px" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Skeleton />
      )}
    </div>
  );
}

export default Confidentiality;
