import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import axios from "../../utils/axios"
import dateTimeConvert from "../../functions/dateTimeConvert"
import { StyledTableCell } from "../../components/StyledTableCell"
import convertToInternationalCurrencySystem from "../../functions/convertToInternationalCurrency"
import EmptyTable from "../../components/EmptyTable"
import cashIcon from "../../assets/icons/cash.png"
import clickIcon from "../../assets/icons/click.png"
import humoIcon from "../../assets/icons/humo.png"
import uzcardIcon from "../../assets/icons/uzcard.png"
import visaIcon from "../../assets/icons/visa.png"
import paymeIcon from "../../assets/icons/payme.png"
import depositIcon from "../../assets/icons/deposit.png"
import Pagination from "../../components/Pagination/Pagination"
import parseQuery from "../../functions/parseQuery"

const icons = {
  cash: cashIcon,
  click: clickIcon,
  humo: humoIcon,
  uzcard: uzcardIcon,
  visa: visaIcon,
  payme: paymeIcon,
  deposit: depositIcon,
}

export default function Transactions({ expenseType, fromToDate, paymentType }) {
  const { t } = useTranslation()
  const params = useParams()
  const { offset } = parseQuery()

  const pageCount = 10
  const [count, setCount] = useState()
  const [deposit, setDeposit] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState({})
  const [balance, setBalance] = useState({})

  // **** FUNCTIONS ****
  const getTransactions = useCallback(
    async (queryParams = {}) => {
      let response = await axios.get(`/billing/transaction/cashbox`, {
        params: {
          limit: pageCount,
          offset: pageCount - pageCount,
          cashbox_id: params.id,
          start_date: fromToDate.start,
          end_date: fromToDate.end,
          subcategory_id: expenseType?.value,
          payment_type: paymentType,
          ...queryParams,
        },
      })
      setItems({
        data: response.data,
      })
      setCount(response?.data?.count)
      setIsLoading(false)
    },
    [expenseType?.value, fromToDate, paymentType, params.id]
  )

  const getBalance = async (queryParams = {}) => {
    let response = await axios.get(
      `/billing-report/by?type=cashbox&id=${params.id}`,
      { params: queryParams }
    )
    setBalance(response)
    setIsLoading(false)
  }

  const getDeposit = async () => {
    let response = await axios.get(`/report/deposit`)
    setDeposit(response)
    setIsLoading(false)
  }

  const changeOffset = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      getTransactions({
        limit: pageCount,
        offset: offset ?? 0,
      })
    },
    [getTransactions]
  )

  useEffect(() => {
    getTransactions()
    getBalance()
    getDeposit()
  }, [getTransactions, pageCount, fromToDate, expenseType, paymentType])

  return (
    <div className="px-2">
      <TableContainer elevation={0} component={Paper} className="bg-white p-4">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell
                colSpan={7}
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                {t("balance")}
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                style={{ borderRight: "1px solid #ddd" }}
              >
                {t("total.expense")}
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                style={{ borderRight: "1px solid #ddd" }}
              >
                {t("total.income")}
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>{t("deposit")}</StyledTableCell>
            </TableRow>
            <TableRow className="bg-gray-50">
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {t("payme")}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {t("click")}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {t("uzcard")}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {t("humo")}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {t("visa")}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {t("cash")}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {t("total.balance")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="hover:bg-gray-100 cursor-pointer">
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(
                  balance?.Report?.payme?.difference
                )}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(
                  balance?.Report?.click?.difference
                )}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(
                  balance?.Report?.uzcard?.difference
                )}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(
                  balance?.Report?.humo?.difference
                )}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(
                  balance?.Report?.visa?.difference
                )}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(
                  balance?.Report?.cash?.difference
                )}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(balance?.balance)}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(balance?.total_credit)}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #ddd" }}>
                {convertToInternationalCurrencySystem(balance?.total_debit)}
              </StyledTableCell>
              <StyledTableCell>
                {convertToInternationalCurrencySystem(deposit?.total_amount)}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && (
        <div className="flex justify-center py-2">
          <CircularProgress />
        </div>
      )}
      <TableContainer
        className="mt-2 bg-white p-4"
        elevation={0}
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("expense")}</StyledTableCell>
              <StyledTableCell>{t("income")}</StyledTableCell>
              <StyledTableCell>{t("payment.type")}</StyledTableCell>
              <StyledTableCell>{t("expense.type")}</StyledTableCell>
              <StyledTableCell>{t("created.date")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.data && items.data.billing_transactions?.length ? (
              items.data.billing_transactions?.map(
                (
                  {
                    id,
                    credit,
                    debit,
                    subaccount_category,
                    date,
                    payment_gateway,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    className="hover:bg-gray-100 cursor-pointer"
                    style={{ background: index % 2 === 0 && "#F6F8F9" }}
                  >
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(credit)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(debit)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <span
                        className="flex justify-center 
                                    items-center border 
                                    rounded-md
                                    py-2 px-6  "
                        style={{ width: "85px", height: "40px" }}
                      >
                        <img src={icons[payment_gateway]} alt="" />
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      {t(subaccount_category.name)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="text-blue-600">
                        {dateTimeConvert(date)}
                      </div>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable
        isActive={!isLoading && !items.data?.billing_transactions?.length > 0}
        message="Нет инфо"
      />
      {isLoading && (
        <div className="flex justify-center py-2">
          <CircularProgress />
        </div>
      )}
      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(count / pageCount)}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changeOffset}
        />
      </div>
      <div className="flex justify-end"></div>
    </div>
  )
}
