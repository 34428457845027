import { useState, useEffect, useCallback } from "react"
import { useHistory, useParams } from "react-router"
import { useTranslation } from "react-i18next"
import axios from "../../../utils/axios"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import moment from "moment"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EditIcon from "@material-ui/icons/Edit"
import ClearIcon from "@material-ui/icons/Clear"
import { StyledTableCell } from "../../../components/StyledTableCell"
import Paper from "@material-ui/core/Paper"
import { useSelector } from "react-redux"
import Tag from "../../../components/Tag"
import Pagination from "../../../components/Pagination/Pagination"
import EmptyTable from "../../../components/EmptyTable"
import parseQuery from "../../../functions/parseQuery"

function OrderHistory() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  let { limit, offset } = parseQuery()
  const pageCount = 5
  const permissions = useSelector((state) => state.auth.permissions)

  const [items, setItems] = useState({})
  const [count, setCount] = useState()
  const [dropList, setDropList] = useState({ target: null, id: "" })

  // **** FUNCTIONS ****
  const getItems = useCallback(
    async (queryParams = {}) => {
      let response = await axios.get(`/order?car_id=${params.id}`, {
        params: queryParams,
      })
      setItems(response.data)
      setCount(response.data.count)
    },
    [params.id]
  )

  const changeOffset = useCallback(
    (event, value) => {
      let offset = (value - 1) * pageCount
      console.log(value, pageCount)
      getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
    },
    [getItems, limit]
  )

  // **** EVENTS *****
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      // if edit, then go to edit page
      if (permissions.includes("cars_order_history_edit"))
        history.push(`/home/cars/${params.id}/order-history/${id}`)
    } else if (type === "delete") {
      // if delete, then delete that specific item
      if (permissions.includes("cars_order_history_delete"))
        axios.delete(`/order/${id}`).then((res) => {
          // get the new list of items
          getItems()
          // close the droplist
          setDropList((old) => {
            return { ...old, target: null }
          })
        })
    }
  }

  // Constants

  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-error-100 rounded text-error-600 p-0.5" />,
      type: "delete",
    },
  ]

  useEffect(() => {
    getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  }, [getItems, limit, offset])

  return (
    <div>
      <TableContainer elevation={0} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-gray-50">
              <StyledTableCell>{t("order.number")}</StyledTableCell>
              <StyledTableCell>{t("client")}</StyledTableCell>
              <StyledTableCell>{t("date.from.to")}</StyledTableCell>

              <StyledTableCell>{t("tariff")}</StyledTableCell>
              <StyledTableCell>{t("tariff.price")}</StyledTableCell>
              <StyledTableCell>{t("status")}</StyledTableCell>

              {/* {permissions.includes("cars_order_history_delete") && (
                <StyledTableCell></StyledTableCell>
              )} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.orders && items.orders?.length ? (
              items.orders.map(
                (
                  {
                    id,
                    client,
                    tariff,
                    from_time,
                    to_time,
                    order_number,
                    status,
                  },
                  index
                ) => (
                  <TableRow
                    key={index}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      if (permissions.includes("cars_order_history_edit"))
                        return history.push(
                          `/home/cars/${params.id}/order-history/${id}`
                        )
                    }}
                  >
                    <StyledTableCell>{order_number}</StyledTableCell>
                    <StyledTableCell>
                      <div>
                        {client.first_name} {client.last_name}
                      </div>
                      <div className="text-blue-600">{client.phone_number}</div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div>{`${moment(from_time).format("DD/MM")} `}</div>
                      <div>{moment(to_time).format("DD/MM")}</div>
                    </StyledTableCell>
                    <StyledTableCell>{tariff.name}</StyledTableCell>
                    <StyledTableCell>
                      {convertToInternationalCurrencySystem(tariff.price)}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        color="blue"
                        size="large"
                        style={{ width: "150px" }}
                      >
                        {t(status.id)}
                      </Tag>
                    </StyledTableCell>
                    {/* {permissions.includes("cars_order_history_delete") && (
                      <StyledTableCell
                        style={{ width: "5%", borderLeft: "1px solid #ddd" }}
                      >
                        <MoreHorizIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setDropList({ target: e.currentTarget, id: id });
                          }}
                          className="cursor-pointer"
                        />
                        <StyledPropMenu
                          anchorEl={dropList.target}
                          open={Boolean(dropList.target)}
                          onClose={(e) => {
                            e.stopPropagation();
                            setDropList((old) => {
                              return { ...old, target: null };
                            });
                          }}
                        >
                          <div className="flex flex-col divide-y font-body text-sm">
                            {listRender.map((el) => (
                              <div
                                className="flex w-full cursor-pointer py-2 mx-2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMoreClick(dropList.id, el.type);
                                }}
                              >
                                <div className="rounded">{el.icon}</div>
                                <div className="my-auto mx-2">{el.title}</div>
                              </div>
                            ))}
                          </div>
                        </StyledPropMenu>
                      </StyledTableCell>
                    )} */}
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyTable message="Нет историй" isActive={!items.orders?.length > 0} />
      <div className="flex justify-end">
        <Pagination
          className="mt-5"
          defaultPage={offset ? offset / 10 + 1 : 1}
          count={Math.ceil(count / pageCount)}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={changeOffset}
        />
      </div>
    </div>
  )
}

export default OrderHistory
