import { useState, useEffect, useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import RangePicker from "../../../components/DatePicker/RangePicker"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import Pagination from "../../../components/Pagination/Pagination"
import parseQuery from "../../../functions/parseQuery"
import TableHeadCell from "../../../components/TableCells/TableHeadCell"
import TableHeadSearchCell from "../../../components/TableCells/TableHeadSearchCell"

const useStyles = makeStyles({
  root: {
    transition: "all .1s cubic-bezier(0.94, 0.05, 0.99, 0.02);",
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
})

function provideCycler() {
  function* generator(val = "") {
    var mapper = new Map([
      [0, null],
      [1, "asc"],
      [2, "desc"],
    ])
    var count = 1
    while (true) {
      yield mapper.get(count)
      if (count < 2) count++
      else count = 0
    }
  }
  var it = generator()
  return function (val) {
    return it.next(val).value
  }
}

var cycle

const initialState = {
  count_car: null,
  count_order: null,
  total_income: null,
  total_outcome: null,
  investor_income: null,
}

const reducerFn = (state, { column, arrangment, sort_path }) => {
  switch (arrangment) {
    case "asc":
      return {
        ...initialState,
        [column]: { order: column, sort_path, arrangment },
      }
    case "desc":
      return {
        ...initialState,
        [column]: { order: column, sort_path, arrangment },
      }
    case null:
      return initialState
    default:
      throw new Error("Error in reducer")
  }
}

const tableHeadCells = [
  {
    id: "icr01",
    title: "car.count",
    column: "count_car",
    sort_path: "count_car",
  },
  {
    id: "icr02",
    title: "order.count",
    column: "count_order",
    sort_path: "count_order",
  },
  {
    id: "icr03",
    title: "total.price",
    column: "total_income",
    sort_path: "total_income",
  },
  {
    id: "icr04",
    title: "Сумма расхода",
    column: "total_outcome",
    sort_path: "total_outcome",
  },
  {
    id: "icr05",
    title: "investor.income",
    column: "investor_income",
    sort_path: "investor_income",
  },
]

const InvestorCarsReport = () => {
  const { t } = useTranslation()
  const { id: investorID } = useParams()
  const userType = localStorage.getItem("userType")
  const classes = useStyles()
  // const { limit, offset } = parseQuery()
  // const pageCount = 10

  // const [count, setCount] = useState()
  const [initialItems, setInitialItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  // const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })
  const [lastClickedArrow, setLastClickedArrow] = useState()
  const [state, dispatch] = useReducer(reducerFn, initialState)

  useEffect(() => {
    const getItems = async () => {
      let response = await axios.get(`/report/investor-cars`, {
        params: {
          start_date: fromToDate.start,
          end_date: fromToDate.end,
          investor_id: investorID
        },
      })

      setInitialItems([...response])
      setFilteredItems(response)
      setIsLoading(false)
    }
    getItems()
  }, [fromToDate, investorID])

  // const getItems = useCallback(
  //   async (searchParams = {}) => {
  //     let response = await axios.get(`/report/investor-cars`, {
  //       params: {
  //         start_date: fromToDate.start,
  //         end_date: fromToDate.end,
  //         ...searchParams,
  //       },
  //     })

  //     setItems(response)
  //     // setCount(response.count)
  //     setIsLoading(false)
  //   },
  //   [fromToDate]
  // )

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/report/investor-car-excel`, {
        params: {
          start_date: fromToDate.start,
          end_date: fromToDate.end,
          investor_id: investorID
        },
      })
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const filterHandler = (e, id, paramKey) => {
    const param = {}
    param[paramKey] = String(id)
    e.stopPropagation()
    const filteredData = id
      ? filteredItems.filter((item) => item.id === id)
      : [...initialItems]
    setFilteredItems(filteredData)
  }

  const updateDate = (val) =>
    setFromToDate({
      start: moment(val[0]).toISOString() ?? "",
      end: moment(val[1]).toISOString() ?? "",
    })

  // const changeOffset = useCallback(
  //   (event, value) => {
  //     let offset = (value - 1) * pageCount
  //     getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  //   },
  //   [getItems, limit]
  // )

  // useEffect(() => {
  //   getItems({ limit: limit ?? pageCount, offset: offset ?? 0 })
  // }, [getItems, fromToDate, limit, offset])

  const SorterArrow = useCallback(
    ({ column, sortPath }) => {
      return state[column] === null ? (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer"
          classes={{ root: classes.root }}
          color="disabled"
          fontSize="medium"
          onClick={(e) => {
            if (lastClickedArrow !== e.target.closest(".arrow").id) {
              setLastClickedArrow(e.target.closest(".arrow").id)
              cycle = provideCycler()
            }
            dispatch({ column, sort_path: sortPath, arrangment: cycle() })
          }}
        />
      ) : state[column]?.arrangment === "desc" ? (
        <ArrowDownwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() =>
            dispatch({ column, sort_path: sortPath, arrangment: cycle() })
          }
        />
      ) : (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() =>
            dispatch({ column, sort_path: sortPath, arrangment: cycle() })
          }
        />
      )
    },
    [classes.root, state, lastClickedArrow]
  )

  useEffect(() => {
    var i, obj
    for (i in state) {
      if (state.hasOwnProperty(i) && state[i]) {
        obj = state[i]
      }
    }

    if (obj) {
      if (obj?.arrangment === "asc") {
        let sorted = filteredItems.sort(
          (a, b) => a[obj.sort_path] - b[obj.sort_path]
        )
        setFilteredItems([...sorted])
      } else {
        let sorted = filteredItems.sort(
          (a, b) => b[obj.sort_path] - a[obj.sort_path]
        )
        setFilteredItems([...sorted])
      }
    } else {
      setFilteredItems(initialItems)
    }
  }, [state])
  return (
    <div>
      <Header title={t("investorCarsReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        <RangePicker
          showToday={false}
          showWeekNumber
          dateInputPlaceholder={[t("from"), t("till")]}
          showOk={false}
          showClear
          placeholder={t("select.a.date")}
          hideTimePicker
          onChange={(val) => updateDate(val)}
        />
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell title="&#8470;" />
                <TableHeadSearchCell
                  title="investors"
                  url="investor"
                  dataKey="investors"
                  paramKey="investor_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                  disableSearch={investorID}
                />
                {tableHeadCells?.map((item) => (
                  <TableCell
                    key={item.id}
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                    className="arrow whitespace-nowrap"
                    id={item.id}
                  >
                    {t(item.title)}
                    {filteredItems?.length > 1 && (
                      <SorterArrow
                        column={item.column}
                        sortPath={item.sort_path}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {filteredItems?.map((item, index) => (
              <TableRow
                // onClick={() =>
                //   history.push(
                //     `/home/reports/investor_cars/${item.investor_id}`
                //   )
                // }
                style={{
                  // cursor: "pointer",
                  background: index % 2 === 0 && "#F6F8F9",
                }}
              >
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {item?.investor_name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.count_car}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.count_order}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.total_income)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.investor_expenses)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.investor_income)}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет отчетов"
          isActive={!isLoading && !filteredItems?.length > 0}
        />
        {isLoading && <ReportSkeleton />}
        {/* <div className="flex justify-end">
          <Pagination
            className="mt-5"
            defaultPage={offset ? offset / 10 + 1 : 1}
            count={Math.ceil(count / pageCount)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={changeOffset}
          />
        </div> */}
      </div>
    </div>
  )
}

export default InvestorCarsReport
