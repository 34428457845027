import "./style.scss"
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"

export default function Upload({
  style,
  className,
  children,
  accept,
  disabled,
  getFilesFromEvent,
  maxFiles,
  maxSize,
  minSize,
  multiple,
  noClick,
  noDrag,
  noDragEventsBubbling,
  noKeyboard,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDropAccepted,
  onDropRejected,
  onFileDialogCancel,
  preventDropOnDocument,
  validator,
  shape,
  onChange = function () {},
  ...props
}) {
  // **** USE-HOOKS ****
  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles)
    onChange(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className={`upload ${className} `} style={style}>
      {/* DROPZONE */}
      <div
        {...getRootProps()}
        className={`dropzone ${
          shape === "rounded" ? "rounded-full" : "rounded-md"
        } transition ease-linear hover:bg-gray-100`}
        style={{ height: 164 }}
      >
        <input
          {...getInputProps()}
          {...props}
          accept={accept}
          disabled={disabled}
          getFilesFromEvent={getFilesFromEvent}
          maxFiles={maxFiles}
          maxSize={maxSize}
          minSize={minSize}
          multiple={multiple}
          noClick={noClick}
          noDrag={noDrag}
          noDragEventsBubbling={noDragEventsBubbling}
          noKeyboard={noKeyboard}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          onFileDialogCancel={onFileDialogCancel}
          preventDropOnDocument={preventDropOnDocument}
          validator={validator}
        />
        {children}
      </div>
    </div>
  )
}

// export default function Upload ({
//   className = "",
//   style = {},
//   comment = "",
//   action = "",
//   filename = "file",
//   title,
//   beforeUpload  = function () {},
//   onUploading = function () {},
//   onUploaded = function () {},
//   customRequest = function () {},
//   ...rest
// }) {
//   return (
//     <div {...rest} style={style} className={`
//       p-1
//       px-2
//       flex
//       w-full
//       border
//       text-body
//       space-x-2
//       bg-white
//       transition
//       rounded-lg
//       font-smaller
//       items-center
//       border-gray-200
//       focus-within:ring
//       focus-within:outline-none
//       focus-within:border-blue-300
//       ${className}
//     `}>
//       <div></div>
//       <input type="upload"  />
//     </div>
//   )
// }
