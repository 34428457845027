import { useState, useCallback } from "react"
import TableCell from "@material-ui/core/TableCell"
import SearchIcon from "@material-ui/icons/Search"
import { useTranslation } from "react-i18next"
import axios from "../../../utils/axios"
import searchIcon from "../../../assets/icons/search.svg"
import BackdropPortal from "../../../components/BackdropPortal/BackdropPortal"
import Input from "../../../components/Input"

const TableHeadPermissionSearchCell = (props) => {
  const {
    row = 1,
    col = 1,
    title,
    userType,
    url,
    dataKey,
    paramKey,
    filterItemKey,
    itemKey,
    filter,
  } = props
  const { t } = useTranslation()
  let debounce = setTimeout(() => {}, 0)

  const [data, setData] = useState()

  const getData = useCallback((params = {}) => {
    axios.get(`/${url}`, { params }).then((res) => {
      setData(res.data[dataKey])
    })
  }, [])

  const onDataSearch = (val) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      getData({ search: val })
    }, 300)
  }

  return (
    <TableCell
      colSpan={col}
      rowSpan={row}
      align="center"
      style={{
        fontWeight: "600",
        borderRight: "1px solid #ddd",
        whiteSpace: "pre",
      }}
    >
      <div className="flex justify-between items-center whitespace-pre">
        <div className="mr-2">{t(title)}</div>
        {userType === "SUPER-ADMIN" ? (
          <div
            className="border-solid border-2 border-gray-100 rounded-lg inline-block p-2 cursor-pointer w-8 relative"
            onClick={() => getData()}
          >
            <img src={searchIcon} alt="icon" />
            {data && (
              <>
                <BackdropPortal
                  onConfirm={(e) => {
                    e.stopPropagation()
                    setData(undefined)
                  }}
                />
                <div className="border-solid border-1 bg-white w-80 shadow-xl absolute top-8 left-0 z-50 min-h-32 max-h-40 overflow-auto p-2">
                  <div className="flex space-x-2">
                    <Input
                      icon={<SearchIcon className="text-gray-400" />}
                      placeholder={t("search...")}
                      style={{ width: "350px" }}
                      onChange={(e) => {
                        onDataSearch(e.target.value)
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                  <p
                    className="hover:bg-gray-100 p-2 cursor-pointer"
                    onClick={(e) => {
                      filter(e, "", paramKey)
                      setData(undefined)
                    }}
                  >
                    {t("filter.clear")}
                  </p>
                  {data.map((item, index) => (
                    <p
                      key={index}
                      className="hover:bg-gray-100 p-2 cursor-pointer text-left"
                      onClick={(e) => {
                        filter(e, item[filterItemKey], paramKey)
                        setData(undefined)
                      }}
                    >
                      {item[itemKey]}
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </TableCell>
  )
}

export default TableHeadPermissionSearchCell
