import { useState, useEffect, useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import axios from "../../../utils/axios"
import Header from "../../../components/Header/Header"
import Filters from "../../../components/Filters"
import convertToInternationalCurrencySystem from "../../../functions/convertToInternationalCurrency"
import EmptyTable from "../../../components/EmptyTable"
import RangePicker from "../../../components/DatePicker/RangePicker"
import ConvertToExcel from "../../../components/ConvertToExcel"
import downloadFile from "../../../functions/downloadFile"
import ReportSkeleton from "../../../components/Skeleton/ReportSkeleton"
import TableHeadCell from "../../../components/TableCells/TableHeadCell"
import TableHeadPermissionSearchCell from "../../../components/TableCells/PermissionCells/TableHeadPermissionSearchCell"

const useStyles = makeStyles({
  root: {
    transition: "all .1s cubic-bezier(0.94, 0.05, 0.99, 0.02);",
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
})

function provideCycler() {
  function* generator(val = "") {
    var mapper = new Map([
      [0, null],
      [1, "asc"],
      [2, "desc"],
    ])
    var count = 1
    while (true) {
      yield mapper.get(count)
      if (count < 2) count++
      else count = 0
    }
  }
  var it = generator()
  return function (val) {
    return it.next(val).value
  }
}

var cycle

const initialState = {
  app_count: null,
  app_sum: null,
  website_count: null,
  website_sum: null,
  operator_count: null,
  operator_sum: null,
  cash_count: null,
  cash_sum: null,
  total_count: null,
  investor_sum: null,
  company_sum: null,
  insurance_sum: null,
}

const reducerFn = (state, { column, arrangment, sort_path }) => {
  switch (arrangment) {
    case "asc":
      return {
        ...initialState,
        [column]: { order: column, sort_path, arrangment },
      }
    case "desc":
      return {
        ...initialState,
        [column]: { order: column, sort_path, arrangment },
      }
    case null:
      return initialState
    default:
      throw new Error("Error in reducer")
  }
}

function getVal(obj, path) {
  const keys = path.split(".")
  keys.forEach((key) => (obj = obj[key]))
  return obj
}

const tableHeadCells = [
  { id: "br01", title: "q-ty", column: "app_count", sort_path: "app.count" },
  { id: "br02", title: "sum", column: "app_sum", sort_path: "app.total_price" },
  {
    id: "br03",
    title: "q-ty",
    column: "website_count",
    sort_path: "website.count",
  },
  {
    id: "br04",
    title: "sum",
    column: "website_sum",
    sort_path: "website.total_price",
  },
  {
    id: "br05",
    title: "q-ty",
    column: "operator_count",
    sort_path: "operator.count",
  },
  {
    id: "br06",
    title: "sum",
    column: "operator_sum",
    sort_path: "operator.total_price",
  },
  { id: "br07", title: "q-ty", column: "cash_count", sort_path: "cash.count" },
  {
    id: "br08",
    title: "sum",
    column: "cash_sum",
    sort_path: "cash.total_price",
  },
  { id: "br09", title: "q-ty", column: "total_count", sort_path: "total.count" },
  {
    id: "br10",
    title: "investor.sum",
    column: "investor_sum",
    sort_path: "total.total_price",
  },
]

const BranchReports = () => {
  const { t } = useTranslation()
  const userType = localStorage.getItem("userType")
  const classes = useStyles()

  const [initialItems, setInitialItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isGettingExcel, setIsGettingExcel] = useState(false)
  const [lastClickedArrow, setLastClickedArrow] = useState()
  const [state, dispatch] = useReducer(reducerFn, initialState)

  const [fromToDate, setFromToDate] = useState({ start: "", end: "" })

  const getItems = async () => {
    let response = await axios.get(`/report/branch`, {
      params: {
        start_date: fromToDate.start,
        end_date: fromToDate.end,
      },
    })

    setInitialItems([...response])
    setFilteredItems(response)
    setIsLoading(false)
  }

  const convertToExcel = () => {
    setIsGettingExcel(true)
    axios
      .get(`/report/branch-excel`, {
        params: {
          start_date: fromToDate.start,
          end_date: fromToDate.end,
        },
      })
      .then((res) => {
        downloadFile(res.data, `${Date.now()}.xlsx`)
      })
      .finally(() => setIsGettingExcel(false))
  }

  const filterHandler = (e, id, paramKey) => {
    const param = {}
    param[paramKey] = String(id)
    e.stopPropagation()
    const filteredData = id
      ? filteredItems.filter((item) => item.id === id)
      : [...initialItems]
    setFilteredItems(filteredData)
  }

  const updateDate = (val) => {
    setFromToDate({
      start: val[0] ? moment(val[0]).format('YYYY-MM-DD') ?? "" : "",
      end: val[0] ? moment(val[1]).format('YYYY-MM-DD') ?? "" : "",
    })}

  useEffect(() => {
    getItems()
  }, [fromToDate])

  const SorterArrow = useCallback(
    ({ column, sortPath }) => {
      return state[column] === null ? (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer"
          classes={{ root: classes.root }}
          color="disabled"
          fontSize="medium"
          onClick={(e) => {
            if (lastClickedArrow !== e.target.closest(".arrow").id) {
              setLastClickedArrow(e.target.closest(".arrow").id)
              cycle = provideCycler()
            }
            dispatch({ column, sort_path: sortPath, arrangment: cycle() })
          }}
        />
      ) : state[column]?.arrangment === "desc" ? (
        <ArrowDownwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() =>
            dispatch({ column, sort_path: sortPath, arrangment: cycle() })
          }
        />
      ) : (
        <ArrowUpwardRoundedIcon
          className="cursor-pointer text-blue-600"
          classes={{ root: classes.root }}
          fontSize="medium"
          onClick={() =>
            dispatch({ column, sort_path: sortPath, arrangment: cycle() })
          }
        />
      )
    },
    [classes.root, state, lastClickedArrow]
  )

  useEffect(() => {
    var i, obj
    for (i in state) {
      if (state.hasOwnProperty(i) && state[i]) {
        obj = state[i]
      }
    }

    if (obj) {
      if (obj?.arrangment === "asc") {
        let sorted = filteredItems.sort(
          (a, b) => getVal(a, obj?.sort_path) - getVal(b, obj?.sort_path)
        )
        setFilteredItems([...sorted])
      } else {
        let sorted = filteredItems.sort(
          (a, b) => getVal(b, obj?.sort_path) - getVal(a, obj?.sort_path)
        )
        setFilteredItems([...sorted])
      }
    } else {
      setFilteredItems(initialItems)
    }
  }, [state])

  return (
    <div>
      <Header title={t("branchReportTitle")} />
      <Filters
        extra={
          <div className="flex space-x-2 items-center">
            <ConvertToExcel
              isActive={isGettingExcel}
              onClick={convertToExcel}
            />
          </div>
        }
      >
        <RangePicker
          showToday={false}
          showWeekNumber
          dateInputPlaceholder={[t("from"), t("till")]}
          showOk={false}
          showClear
          placeholder={t("select.a.date")}
          hideTimePicker
          onChange={(val) => updateDate(val)}
        />
      </Filters>

      <div className="px-4 py-6 bg-white m-2">
        <TableContainer className="mt-2" elevation={0} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell row={3} title="&#8470;" />
                <TableHeadPermissionSearchCell
                  row={3}
                  title="branches"
                  userType={userType}
                  url="branch"
                  dataKey="branches"
                  paramKey="branch_id"
                  filterItemKey="id"
                  itemKey="name"
                  filter={filterHandler}
                />
                <TableHeadCell col={8} title="source" />
                <TableHeadCell
                  row={2}
                  col={userType === "INVESTOR" ? 2 : 4}
                  title="overall"
                />
              </TableRow>

              <TableRow>
                <TableHeadCell col={2} title="app" />
                <TableHeadCell col={2} title="website" />
                <TableHeadCell col={2} title="call.center" />
                <TableHeadCell col={2} title="offline" />
              </TableRow>

              <TableRow>
                {tableHeadCells?.map((item) => (
                  <TableCell
                    key={item.id}
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                    className="arrow whitespace-nowrap"
                    id={item.id}
                  >
                    {t(item.title)}
                    {userType === "SUPER-ADMIN" &&
                      filteredItems?.length > 1 && (
                        <SorterArrow
                          column={item.column}
                          sortPath={item.sort_path}
                        />
                      )}
                  </TableCell>
                ))}

                {userType != "INVESTOR" && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                    className="arrow whitespace-nowrap"
                  >
                    {t("company.sum")}
                    {userType === "SUPER-ADMIN" &&
                      filteredItems?.length > 1 && (
                        <SorterArrow
                          column="company_sum"
                          sortPath="total.investor_sum"
                        />
                      )}
                  </TableCell>
                )}
                {userType != "INVESTOR" && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: "600", borderRight: "1px solid #ddd" }}
                    className="arrow whitespace-nowrap"
                  >
                    {t("insurance")}
                    {userType === "SUPER-ADMIN" &&
                      filteredItems?.length > 1 && (
                        <SorterArrow
                          column="insurance_sum"
                          sortPath="total.insurance"
                        />
                      )}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            {filteredItems?.map((item, index) => (
              <TableRow style={{ background: index % 2 === 0 && "#F6F8F9" }}>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {index + 1}
                </TableCell>

                <TableCell style={{ borderRight: "1px solid #ddd" }}>
                  {item?.name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.app.count}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.app.total_price)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.website.count}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(
                    item?.website.total_price
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.operator.count}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(
                    item?.operator.total_price
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.cash.count}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(item?.cash.total_price)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {item?.total.count}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  {convertToInternationalCurrencySystem(
                    item?.total.investor_sum || 0
                  )}
                </TableCell>
                {userType != "INVESTOR" && (
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ddd" }}
                  >
                    {convertToInternationalCurrencySystem(
                      item?.total.company_sum || 0
                    )}
                  </TableCell>
                )}
                {userType != "INVESTOR" && (
                  <TableCell align="center">
                    {convertToInternationalCurrencySystem(
                      item?.total.insurance || 0
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <EmptyTable
          message="Нет отчетов"
          isActive={!isLoading && !filteredItems?.length > 0}
        />
        {isLoading && <ReportSkeleton />}
      </div>
    </div>
  )
}

export default BranchReports
