import { useTranslation } from "react-i18next"
import DeleteIcon from "@material-ui/icons/Delete"
import ClearIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import Card from "../../components/Card/index"
import ImageUpload from "../../components/Upload/ImgUpload"
import Form from "../../components/Form/Index"
import Input from "../../components/Input/index"
import PhoneInput from "../../components/Form/input/PhoneInput"
import Textarea from "../../components/Textarea/index"
import Button from "../../components/Buttons/index"

const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 10.8332H10.8334V14.9998C10.8334 15.4582 10.4584 15.8332 10.0001 15.8332C9.54175 15.8332 9.16675 15.4582 9.16675 14.9998V10.8332H5.00008C4.54175 10.8332 4.16675 10.4582 4.16675 9.99984C4.16675 9.5415 4.54175 9.1665 5.00008 9.1665H9.16675V4.99984C9.16675 4.5415 9.54175 4.1665 10.0001 4.1665C10.4584 4.1665 10.8334 4.5415 10.8334 4.99984V9.1665H15.0001C15.4584 9.1665 15.8334 9.5415 15.8334 9.99984C15.8334 10.4582 15.4584 10.8332 15.0001 10.8332Z"
      fill="#703581"
    />
  </svg>
)

function Context(props) {
  const {
    image,
    contacts,
    formik,
    setImage,
    addContact,
    addContactValue,
    removeContact,
    removeContactValue,
    updateContact,
    updateContactValue,
  } = props

  const { t } = useTranslation()

  return (
    <div className="p-4 w-full grid grid-cols-3 gap-4 box-border font-body text-sm">
      <Card title={t("general.information")} className="col-span-2">
        <div className="py-2 w-full flex justify-center ">
          <ImageUpload
            onChange={(val) => setImage(val.filename)}
            onStart={(val) => console.log(val)}
            onFinish={(val) => console.log(val)}
            onSuccess={(val) => console.log(val)}
            onError={(val) => console.log(val)}
            defaultImage={image}
          >
            <div className="flex flex-col mx-8 items-center justify-center space-y-4">
              <AddIcon style={{ fontSize: "200%" }} />
              <h5>{t("select.image")}</h5>
            </div>
          </ImageUpload>
        </div>
        {/* Title */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3">{t("title")}</div>
          <div className="w-2/3">
            <Form.Item name="title" formik={formik}>
              <Input
                id="title"
                type="text"
                {...formik.getFieldProps("title")}
              />
            </Form.Item>
          </div>
        </div>
        {/* Description */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3">{t("description")}</div>
          <div className="w-2/3">
            <Form.Item name="description" formik={formik}>
              <Textarea
                id="description"
                type="text"
                {...formik.getFieldProps("description")}
                style={{ minHeight: "350px" }}
              />
            </Form.Item>
          </div>
        </div>
      </Card>
      <div className="col-span-2 bg-white rounded py-3">
        <h5 className="flex justify-center font-semibold">{t("contacts")}</h5>
      </div>
      {contacts.map((el, index) => (
        <Card className="col-span-2">
          {/* Delete Button */}
          <div className="relative pb-6">
            <ClearIcon
              onClick={() => removeContact(index)}
              className="absolute -top-4 -right-4 cursor-pointer"
            />
          </div>
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3">
              {t("contact")} {index + 1}
            </div>
            <div className="w-2/3 space-y-4">
              <div className="flex items-center space-x-2">
                <div className="w-full">
                  <Form.Item name="contacts" formik={formik}>
                    <Input
                      value={el.name}
                      onChange={(el) => updateContact(el.target.value, index)}
                    />
                  </Form.Item>
                </div>
              </div>

              {el.contact_values && el.contact_values.length
                ? el.contact_values.map((item, i) => (
                    <div className="flex items-center space-x-2" key={i}>
                      <div className="w-full">
                        <Form.Item
                          name="attribute_id"
                          formik={formik}
                          style={{ width: "100%" }}
                        >
                          <PhoneInput
                            value={item.value}
                            onChange={(el) =>
                              updateContactValue(el.target.value, i, index)
                            }
                          />
                        </Form.Item>
                      </div>

                      {/* Delete Button */}
                      <div
                        className="p-0.5 cursor-pointer rounded border border-red-300 text-red-300"
                        onClick={() => removeContactValue(i, index)}
                        style={{ scale: "0.78" }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ))
                : ""}
              <div
                className="w-full border-2 border-dashed rounded flex justify-center space-x-2 cursor-pointer"
                onClick={() => addContactValue(index)}
              >
                <Button icon={addIcon} shape="text" color="text-primary-600">
                  {t("add")}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      ))}

      {/* Add Contact Field */}
      <div
        className="flex bg-white justify-center col-span-2 
                border-2 border-dashed rounded py-2 cursor-pointer"
        onClick={addContact}
      >
        <Button icon={addIcon} shape="text" color="text-primary-600">
          {t("add.contact")}
        </Button>
      </div>
    </div>
  )
}

export default Context
