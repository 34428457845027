import "./index.scss";
export default function Textarea({
  children,
  className,
  style,
  placeholder,
  ...rest
}) {
  return (
    <div
      className={`border
      bg-white
      flex  py-1
      space-x-2
      items-center
      rounded
      text-body
      relative
      w-full
      text-gray-600
      font-smaller
      focus-within:outline-none
      focus-within:ring-1
      focus-within:ring-blue-600
      transition ease-linear
      hover:border-gray-400 ${className}`}
    >
      <textarea
        className="w-full outline-none px-4"
        type="text"
        style={style}
        {...rest}
        placeholder={placeholder}
      >
        {children}
      </textarea>
    </div>
  );
}
