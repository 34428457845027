import { useState, useEffect, useCallback } from "react"
import axios from "../../../../utils/axios"
import Select from "../../../../components/Select"
import Form from "../../../../components/Form/Index"

function Attributes({
  disabled,
  modelAttributes,
  modelIndex,
  attribute,
  attributeIndex,
  setModelAttributes,
  formik,
}) {
  const [attributeValues, setAttributeValues] = useState([])

  const getAttributeValues = useCallback(async () => {
    let response = await axios.get(`/attribute/${attribute.id}`)

    setAttributeValues(
      response.values.map(({ value, id }) => ({ label: value, value: id }))
    )
  }, [attribute.id])

  // Update Attribute Values
  const updateAttributeValues = (val, categoryIndex, index) => {
    let filteredList = modelAttributes.map((model, modelIndex) =>
      categoryIndex === modelIndex
        ? {
            ...model,
            attributes: model.attributes.map((attribute, attributeIndex) =>
              attributeIndex === index
                ? {
                    ...attribute,
                    value: { value: val.value, label: val.label },
                  }
                : attribute
            ),
          }
        : model
    )

    setModelAttributes(filteredList)
    // react state updates are not synchronous, meaning that it may run this line later
    console.log(modelAttributes, filteredList, "Filtered List")
  }

  useEffect(() => {
    getAttributeValues()
  }, [modelAttributes, getAttributeValues])

  return (
    <div className="w-full py-2 flex items-baseline" key={attributeIndex}>
      <div className="w-1/3">&#42; {attribute.name}</div>
      <div className="w-2/3">
        <Form.Item name={attribute.name} formik={formik}>
          <Select
            isDisabled={disabled}
            options={attributeValues}
            {...formik.getFieldProps(attribute.name)}
            onChange={(val) => {
              formik.setFieldValue(attribute.name, val)
              updateAttributeValues(val, modelIndex, attributeIndex)
            }}
          />
        </Form.Item>
      </div>
    </div>
  )
}

export default Attributes
