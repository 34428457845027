import Dashboard from "../views/dashboard/index.jsx"
import Accordions from "../views/accordions/Accordions.jsx"
import Cards from "../views/cards/index"
import Avatars from "../views/avatars/Avatars"
import TableComp from "../views/tablecomp/index"
import Modal from "../views/modal/index"
import Popup from "../views/popup/index"
import Steps from "../views/steps"
import DatePickers from "../views/datePickers"
import Checkbox from "../views/checkbox/index"
import Radio from "../views/radio/index"
import Cashflow from "../views/dashboard/Cashflow"

import Orders from "../views/orders/Index"
import OrdersCreate from "../views/orders/Create"
import FinePayment from "../views/fine-payment/Index"

import Clients from "../views/clients/Index"
import ClientsCreate from "../views/clients/Create"

import Attributes from "../views/attributes/Index"
import AttributesCreate from "../views/attributes/Create.jsx"

import AttributesCategory from "../views/attribute-category/Index"
import AttributesCategoryCreate from "../views/attribute-category/Create"

import PaymentAccount from "../views/payment-account"
import PaymentAccountCreate from "../views/payment-account/create"

import SalesFunnel from "../views/sales-funnel/index.jsx"
import SalesFunnelCreate from "../views/sales-funnel/create.jsx"

import Cars from "../views/cars/Index"
import CarsCreate from "../views/cars/Create"

import Tariffs from "../views/tariffs/Index"
import TariffsCreate from "../views/tariffs/Create"


import LoadingChart from "../views/loading-chart/Index"

import TariffsCategory from "../views/tariff-category"
import TariffsCategoryCreate from "../views/tariff-category/create"

import AdditionalTariffs from "../views/additional-tariffs/Index"
import AdditionalTariffsCreate from "../views/additional-tariffs/Create"

import About from "../views/about-us/Index"

import Documents from "../views/documents/Index"
import DocumentsCreate from "../views/documents/Create.jsx"

import Organizations from "../views/organizations"
import Roles from "../views/roles"

import Mechanics from "../views/mechanics/Index"
import MechanicsCreate from "../views/mechanics/Create.jsx"

import Cashiers from "../views/cashiers/Index"
import CashiersCreate from "../views/cashiers/Create"

import Operators from "../views/operators/Index"
import OperatorsCreate from "../views/operators/Create"

import Admins from "../views/admins/Index"
import AdminsCreate from "../views/admins/Create"

// import Companies from "../views/companies/Index"
// import CompaniesCreate from "../views/companies/Create"

import Firms from "../views/firms/Index"
import FirmsCreate from "../views/firms/Create"

import Branches from "../views/branches/Index"
import BranchesCreate from "../views/branches/Create.jsx"

import Investors from "../views/investors/Index"
import InvestorsCreate from "../views/investors/Create.jsx"

import CarOwners from "../views/car-owners/index"
import CarOwnersCreate from "../views/car-owners/create"

import BodyType from "../views/body-type/Index"
import BodyTypeCreate from "../views/body-type/Create"

import Brands from "../views/brands/Index"
import BrandsCreate from "../views/brands/Create"

import Categories from "../views/categories/Index"
import CategoriesCreate from "../views/categories/Create"

import Models from "../views/models/index"
import ModelsCreate from "../views/models/create"

import TotalReports from "../views/reports/totalReports/index"
import BranchReport from "../views/reports/branchReport/Index"
import ModelReport from "../views/reports/modelReport/Index"
import CategoryReport from "../views/reports/categoryReport"
import TariffReport from "../views/reports/tariffReport/Index"
import TariffCategoryReport from "../views/reports/tariffCategoryReport"

import OilChange from "../views/oil-change/Index"
import OilChangeCreate from "../views/oil-change/Create.jsx"

import Insurance from "../views/insurance/Index.jsx"
import InsuranceCreate from "../views/insurance/Create.jsx"

import TechInspection from "../views/tech-inspection/Index.jsx"
import TechInspectionCreate from "../views/tech-inspection/Create.jsx"

// import Maintenance from "../views/maintenance/Index.jsx"
// import MaintenanceCreate from "../views/maintenance/Create.jsx"
import OrderHistoryCreate from "../views/cars/tabs/OrderHistoryCreate.jsx"
import Permissions from "../views/permissions/Index.jsx"
import PermissionsCreate from "../views/permissions/Create.jsx"

import Reports from "../views/reports/Index"

import Settings from "../views/settings/Index"

import Confidentiality from "../views/confidentiality/Index.jsx"

import Costs from "../views/costs/Index"
import CostsCreate from "../views/costs/Create.jsx"

import Billing from "../views/billing/Index.jsx"

import Income from "../views/income/Index"
import IncomeCreate from "../views/income/Create"

import Expenses from "../views/expense/Index"
import ExpensesCreate from "../views/expense/Create.jsx"

import ExpenseTypes from "../views/expense-type/Index"
import ExpenseTypesCreate from "../views/expense-type/Create"

import Balance from "../views/balance/Index"
import BalanceCreate from "../views/balance/Create"

import Transactions from "../views/transactions/Index"
import TransactionsCreate from "../views/transactions/Create"

import Cashbox from "../views/cashbox/Index"
import CashboxCreate from "../views/cashbox/Create.jsx"
import CashboxTransactions from "../views/cashbox/Transactions"
// import CashboxTransactionsCreate from "../views/cashbox/TransactionsCreate"
import CashboxTopUpCreate from "../views/cashbox/TopUp"

import Salesmen from "../views/salesmen/Index"
import SalesmenCreate from "../views/salesmen/Create.jsx"
import OrderPayment from "../views/orders/OrderPayment.jsx"

import Fines from "../views/orders/tabs/Fines/[id]"
import OrderHistoryId from "../views/orders/tabs/OrderHistoryId"

import MyProfile from "../views/my-profile/MyProfile"
import InvestorReport from "../views/reports/investorReport/index.jsx"
import InvestorCarsReport from "../views/reports/InvestorCarsReport/Index.jsx"
import InvestorCarList from "../views/reports/InvestorCarsReport/InvestorCarList/index.jsx"
import DebtorsReport from "../views/reports/DebtorsReport/Index.jsx"
import CashflowReport from "../views/reports/CashflowReport/index.jsx"
import PLReport from "../views/reports/PLReport/index"
import OrdersReport from "../views/reports/OrdersReport/index"
import InvestorShareReport from "../views/reports/InvestorShareReport/index.jsx"
// import InvestorShare from "../views/reports/InvestorShareReport/InvestorShare/index.jsx"
import CarExpenses from "../views/car-expenses/Index"
import CarExpensesCreate from "../views/car-expenses/Create"
import DepositsReport from "../views/reports/DepositsReport"
import SalesFunnelReport from "../views/reports/salesFunnelReport/index.jsx"
import RFMAnalysisReport from "../views/reports/RFMAnalysis"

export default [
  // Dashboard
  {
    component: Dashboard,
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    permission: "dashboard",
    children: [],
  },
  {
    component: Accordions,
    path: "/dashboard/accordion",
    exact: true,
    title: "Accordiond",
    showSidepanel: false,
    permission: "",
    children: [],
  },

  {
    component: Cards,
    path: "/dashboard/cards",
    exact: true,
    title: "Cards",
    showSidepanel: false,
    permission: "",
    children: [],
  },

  {
    component: Avatars,
    path: "/dashboard/avatars",
    exact: true,
    title: "Avatar",
    showSidepanel: false,
    permission: "",
    children: [],
  },

  {
    component: TableComp,
    path: "/dashboard/tablecomp",
    exact: true,
    title: "Table Component",
    permission: "",
    children: [],
  },
  {
    component: Modal,
    path: "/dashboard/modal",
    exact: true,
    title: "Modal",
    permission: "",
    children: [],
  },
  {
    component: Popup,
    path: "/dashboard/popup",
    exact: true,
    title: "Popup",
    permission: "",
    children: [],
  },
  {
    component: Steps,
    path: "/dashboard/steps",
    exact: true,
    title: "Steps",
    permission: "",
    children: [],
  },
  {
    component: DatePickers,
    path: "/dashboard/datePickers",
    exact: true,
    title: "Date Pickers",
    permission: "",
    children: [],
  },
  {
    component: Checkbox,
    path: "/dashboard/checkbox",
    exact: true,
    title: "Checkbox",
    permission: "",
    children: [],
  },
  {
    component: Radio,
    path: "/dashboard/radio",
    exact: true,
    title: "Radio",
    permission: "",
    children: [],
  },
  {
    component: Cashflow,
    path: "/dashboard/cashflow",
    exact: true,
    title: "Cashflow",
    permission: "dashboard",
    children: [],
  },

  // Orders
  {
    component: Orders,
    path: "/orders",
    exact: true,
    title: "Orders",
    permission: "orders",
    children: [],
  },
  {
    component: OrdersCreate,
    path: "/orders/create",
    exact: true,
    title: "OrdersCreate",
    permission: "orders_create",
    children: [],
  },
  {
    component: OrdersCreate,
    path: "/orders/:id",
    exact: true,
    title: "OrdersEdit",
    permission: "orders_edit",
    children: [],
  },
  {
    component: FinePayment,
    path: "/orders/:id/fine-payment",
    exact: true,
    title: "FinePayment",
    permission: "orders_fine_payment",
    children: [],
  },
  {
    component: OrderPayment,
    path: "/orders/:id/order-payment",
    exact: true,
    title: "OrderPayment",
    permission: "orders_payment",
    children: [],
  },
  {
    component: Fines,
    path: "/orders/:id/fines/create",
    exact: true,
    title: "Fines",
    permission: "orders_create",
    children: [],
  },
  {
    component: Fines,
    path: "/orders/:id/fines/:fine_id",
    exact: true,
    title: "Fines",
    permission: "orders_create",
    children: [],
  },
  {
    component: OrderHistoryId,
    path: "/orders/:id/history/:history_id",
    exact: true,
    title: "Fines",
    permission: "orders_create",
    children: [],
  },

  // Clients
  {
    component: Clients,
    path: "/clients",
    exact: true,
    title: "Clients",
    permission: "clients",
    children: [],
  },
  {
    component: ClientsCreate,
    path: "/clients/create",
    exact: true,
    title: "Clients Create",
    permission: "clients_create",
    children: [],
  },
  {
    component: ClientsCreate,
    path: "/clients/:id",
    exact: true,
    title: "Clients Edit",
    permission: "clients_edit",
    children: [],
  },

  // Billing
  {
    component: Billing,
    path: "/billing",
    exact: true,
    title: "Billing",
    permission: "billing",
    children: [],
  },

  // Income
  {
    component: Income,
    path: "/billing/income",
    exact: true,
    title: "Income",
    permission: "billing_income",
    children: [],
  },
  {
    component: IncomeCreate,
    path: "/billing/income/create",
    exact: true,
    title: "IncomeCreate",
    permission: "billing_income_create",
    children: [],
  },
  {
    component: IncomeCreate,
    path: "/billing/income/:id",
    exact: true,
    title: "IncomeEdit",
    permission: "billing_income_edit",
    children: [],
  },

  // Expenses
  {
    component: Expenses,
    path: "/billing/expenses",
    exact: true,
    title: "Expenses",
    permission: "billing_expenses",
    children: [],
  },
  {
    component: ExpensesCreate,
    path: "/billing/expenses/create",
    exact: true,
    title: "ExpensesCreate",
    permission: "billing_expenses_create",
    children: [],
  },
  {
    component: ExpensesCreate,
    path: "/billing/expenses/:id",
    exact: true,
    title: "ExpensesEdit",
    permission: "billing_expenses_edit",
    children: [],
  },

  // Expense types
  {
    component: ExpenseTypes,
    path: "/billing/expense-types",
    exact: true,
    title: "ExpenseTypes",
    permission: "billing_expense_types",
    children: [],
  },
  {
    component: ExpenseTypesCreate,
    path: "/billing/expense-types/create",
    exact: true,
    title: "ExpenseTypesCreate",
    permission: "billing_expense_types_create",
    children: [],
  },
  {
    component: ExpenseTypesCreate,
    path: "/billing/expense-types/:id",
    exact: true,
    title: "ExpenseTypesEdit",
    permission: "billing_expense_types_edit",
    children: [],
  },

  // Balance
  {
    component: Balance,
    path: "/billing/balance",
    exact: true,
    title: "Balance",
    permission: "billing_balance",
    children: [],
  },
  {
    component: BalanceCreate,
    path: "/billing/balance/create",
    exact: true,
    title: "BalanceCreate",
    permission: "billing_balance_create",
    children: [],
  },
  {
    component: BalanceCreate,
    path: "/billing/balance/:id",
    exact: true,
    title: "BalanceEdit",
    permission: "billing_balance_edit",
    children: [],
  },

  // Transactions
  {
    component: Transactions,
    path: "/billing/transactions",
    exact: true,
    title: "Transactions",
    permission: "billing_transactions",
    children: [],
  },
  {
    component: TransactionsCreate,
    path: "/billing/transactions/create",
    exact: true,
    title: "TransactionsCreate",
    permission: "billing_transactions_create",
    children: [],
  },
  {
    component: TransactionsCreate,
    path: "/billing/transactions/:id",
    exact: true,
    title: "TransactionsEdit",
    permission: "billing_transactions_edit",
    children: [],
  },

  // Cashbox
  {
    component: Cashbox,
    path: "/billing/cashbox",
    exact: true,
    title: "Cashbox",
    permission: "billing_cashbox",
    children: [],
  },
  {
    component: CashboxCreate,
    path: "/billing/cashbox/create",
    exact: true,
    title: "CashboxCreate",
    permission: "billing_cashbox_create",
    children: [],
  },
  {
    component: CashboxCreate,
    path: "/billing/cashbox/:id",
    exact: true,
    title: "CashboxEdit",
    permission: "billing_cashbox_edit",
    children: [],
  },
  {
    component: CashboxTransactions,
    path: "/billing/cashbox/:id/transactions",
    exact: true,
    title: "CashboxTransactions",
    permission: "billing_cashbox_edit",
    children: [],
  },
  // {
  //   component: CashboxTransactionsCreate,
  //   path: "/billing/cashbox/:id/transactions/create",
  //   exact: true,
  //   title: "CashboxTransactionsCreate",
  //   permission: "billing_cashbox_edit",
  //   children: [],
  // },
  {
    component: CashboxTopUpCreate,
    path: "/billing/cashbox/:id/top-up/create",
    exact: true,
    title: "CashboxTopUpCreate",
    permission: "billing_cashbox_edit",
    children: [],
  },
  // Car Expenses
  {
    component: CarExpenses,
    path: "/billing/car-expenses",
    exact: true,
    title: "CarExpenses",
    permission: "billing_transactions",
    children: [],
  },
  {
    component: CarExpensesCreate,
    path: "/billing/car-expenses/create",
    exact: true,
    title: "CarExpensesCreate",
    permission: "billing_transactions_create",
    children: [],
  },
  {
    component: CarExpensesCreate,
    path: "/billing/car-expenses/:id",
    exact: true,
    title: "CarExpensesEdit",
    permission: "billing_transactions_edit",
    children: [],
  },
  // Reports
  {
    component: Reports,
    path: "/reports",
    exact: true,
    title: "Reports",
    permission: "reports",
    children: [],
  },
  {
    component: TotalReports,
    path: "/reports/total-reports",
    exact: true,
    title: "TotalReports",
    permission: "reports",
    children: [],
  },

  {
    component: BranchReport,
    path: "/reports/branches",
    exact: true,
    title: "BranchReport",
    permission: "reports_by_branches",
    children: [],
  },
  {
    component: CategoryReport,
    path: "/reports/categories",
    exact: true,
    title: "CategoriesReport",
    permission: "reports_by_cars",
    children: [],
  },
  {
    component: ModelReport,
    path: "/reports/models",
    exact: true,
    title: "ModelReport",
    permission: "reports_by_cars",
    children: [],
  },
  {
    component: TariffReport,
    path: "/reports/tariffs",
    exact: true,
    title: "TariffReport",
    permission: "reports_by_tariffs",
    children: [],
  },
  {
    component: TariffCategoryReport,
    path: "/reports/tariff-category",
    exact: true,
    title: "TariffCategoryReport",
    permission: "reports_by_tariffs",
    children: [],
  },
  {
    component: LoadingChart,
    path: "/reports/loading-chart",
    exact: true,
    title: "LoadingCharts",
    permission: "settings_tariffs",
    children: [],
  },
  {
    component: InvestorReport,
    path: "/reports/investors",
    exact: true,
    title: "InvestorReport",
    permission: "reports_by_investors",
    children: [],
  },
  {
    component: InvestorCarsReport,
    path: "/reports/investor_cars", // orders
    exact: true,
    title: "InvestorCarsReport",
    permission: "reports_by_orders", // investor_cars
    children: [],
  },
  {
    component: InvestorCarList,
    path: "/reports/investor_cars/:id",
    exact: true,
    title: "InvestorCarsReportList",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: InvestorShareReport,
    path: "/reports/investor_share",
    exact: true,
    title: "InvestorShareReport",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: InvestorCarsReport,
    path: "/reports/investor_share/:id",
    exact: true,
    title: "InvestorShare",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: DebtorsReport,
    path: "/reports/debtors",
    exact: true,
    title: "Debtors",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: CashflowReport,
    path: "/reports/report/cashflow",
    exact: true,
    title: "Cashflow",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: PLReport,
    path: "/reports/report/pl",
    exact: true,
    title: "P&L",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: OrdersReport,
    path: "/reports/orders",
    exact: true,
    title: "OrdersReport",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: DepositsReport,
    path: "/reports/deposits",
    exact: true,
    title: "DepositsReport",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: SalesFunnelReport,
    path: "/reports/sales-funnel",
    exact: true,
    title: "SalesFunnelReport",
    permission: "reports_by_orders",
    children: [],
  },
  {
    component: RFMAnalysisReport,
    path: "/reports/rfm-analysis",
    exact: true,
    title: "RFMAnalysisReport",
    permission: "reports_by_orders",
    children: [],
  },

  // Settings
  {
    component: Settings,
    path: "/settings",
    exact: true,
    title: "Settings",
    permission: "settings",
    children: [],
  },

  // My Profile
  {
    component: MyProfile,
    path: "/my-profile",
    exact: true,
    title: "My Profile",
    permission: "dashboard",
    children: [],
  },

  // Attributes
  {
    component: Attributes,
    path: "/settings/attributes",
    exact: true,
    title: "Attributes",
    permission: "settings_attributes",
    children: [],
  },
  {
    component: AttributesCreate,
    path: "/settings/attributes/create",
    exact: true,
    title: "AttributesCreate",
    permission: "settings_attributes_create",
    children: [],
  },
  {
    component: AttributesCreate,
    path: "/settings/attributes/:id",
    exact: true,
    title: "AttributesEdit",
    permission: "settings_attributes_edit",
    children: [],
  },

  // Attribute Categories
  {
    component: AttributesCategory,
    path: "/settings/attribute-category",
    exact: true,
    title: "AttributesCategory",
    permission: "settings_attribute_categories",
    children: [],
  },
  {
    component: AttributesCategoryCreate,
    path: "/settings/attribute-category/create",
    exact: true,
    title: "AttributesCategoryCreate",
    permission: "settings_attribute_categories_create",
    children: [],
  },
  {
    component: AttributesCategoryCreate,
    path: "/settings/attribute-category/:id",
    exact: true,
    title: "AttributesCategoryEdit",
    permission: "settings_attribute_categories_edit",
    children: [],
  },

  // Tariff Categories
  {
    component: TariffsCategory,
    path: "/settings/tariff-category",
    exact: true,
    title: "TariffsCategory",
    permission: "settings_attribute_categories",
    children: [],
  },
  {
    component: TariffsCategoryCreate,
    path: "/settings/tariff-category/create",
    exact: true,
    title: "TariffsCategoryCreate",
    permission: "settings_attribute_categories_create",
    children: [],
  },
  {
    component: TariffsCategoryCreate,
    path: "/settings/tariff-category/:id",
    exact: true,
    title: "TariffsCategoryEdit",
    permission: "settings_attribute_categories_edit",
    children: [],
  },
  // Payment Account
  // {
  //   component: PaymentAccount,
  //   path: "/settings/payment-account",
  //   exact: true,
  //   title: "PaymentAccount",
  //   permission: "settings_attributes",
  //   children: [],
  // },
  // {
  //   component: PaymentAccountCreate,
  //   path: "/settings/payment-account/create",
  //   exact: true,
  //   title: "PaymentAccountCreate",
  //   permission: "settings_attributes_create",
  //   children: [],
  // },
  {
    component: PaymentAccountCreate,
    path: "/settings/payment-account/:id",
    exact: true,
    title: "PaymentAccountEdit",
    permission: "settings_attributes_edit",
    children: [],
  },

  // Cars
  {
    component: Cars,
    path: "/cars",
    exact: true,
    title: "Cars",
    permission: "cars",
    children: [],
  },
  {
    component: CarsCreate,
    path: "/cars/create",
    exact: true,
    title: "CarsCreate",
    permission: "cars_create",
    children: [],
  },
  {
    component: CarsCreate,
    path: "/cars/:id",
    exact: true,
    title: "CarsEdit",
    permission: "cars",
    children: [],
  },
  // Oil change
  {
    component: OilChange,
    path: "/cars/:id/oil-change",
    exact: true,
    title: "OilChange",
    permission: "cars_oil_change",
    children: [],
  },
  {
    component: OilChangeCreate,
    path: "/cars/:id/oil-change/create",
    exact: true,
    title: "OilChangeCreate",
    permission: "cars_oil_change_create",
    children: [],
  },
  {
    component: OilChangeCreate,
    path: "/cars/:id/oil-change/:oil_id",
    exact: true,
    title: "OilChangeEdit",
    permission: "cars_oil_change_edit",
    children: [],
  },

  // Insurance
  {
    component: Insurance,
    path: "/cars/:id/insurance",
    exact: true,
    title: "Insurance",
    permission: "cars_insurance",
    children: [],
  },
  {
    component: InsuranceCreate,
    path: "/cars/:id/insurance/create",
    exact: true,
    title: "OilChangeCreate",
    permission: "cars_insurance_create",
    children: [],
  },
  {
    component: InsuranceCreate,
    path: "/cars/:id/insurance/:insurance_id",
    exact: true,
    title: "OilChangeEdit",
    permission: "cars_insurance_edit",
    children: [],
  },
  // Tech Inspection
  {
    component: TechInspection,
    path: "/cars/:id/tech-inspection",
    exact: true,
    title: "TechInspection",
    permission: "cars_tech_inspection",
    children: [],
  },
  {
    component: TechInspectionCreate,
    path: "/cars/:id/tech-inspection/create",
    exact: true,
    title: "TechInspectionCreate",
    permission: "cars_tech_inspection_create",
    children: [],
  },
  {
    component: TechInspectionCreate,
    path: "/cars/:id/tech-inspection/:tech_id",
    exact: true,
    title: "TechInspectionEdit",
    permission: "cars_tech_inspection_edit",
    children: [],
  },
  // Maintenance
  // {
  //   component: Maintenance,
  //   path: "/cars/:id/maintenance",
  //   exact: true,
  //   title: "Maintenance",
  //   permission: "cars_maintenance",
  //   children: [],
  // },
  // {
  //   component: MaintenanceCreate,
  //   path: "/cars/:id/maintenance/create",
  //   exact: true,
  //   title: "MaintenanceCreate",
  //   permission: "cars_maintenance_create",
  //   children: [],
  // },
  // {
  //   component: MaintenanceCreate,
  //   path: "/cars/:id/maintenance/:maintenance_id",
  //   exact: true,
  //   title: "MaintenanceEdit",
  //   permission: "cars_maintenance_edit",
  //   children: [],
  // },
  // Order History
  {
    component: OrderHistoryCreate,
    path: "/cars/:id/order-history/create",
    exact: true,
    title: "OrderHistoryCreate",
    permission: "cars_order_history",
    children: [],
  },
  {
    component: OrderHistoryCreate,
    path: "/cars/:id/order-history/:order_id",
    exact: true,
    title: "OrderHistoryEdit",
    permission: "cars_order_history_edit",
    children: [],
  },

  // Tariffs
  {
    component: Tariffs,
    path: "/settings/tariffs",
    exact: true,
    title: "Tariffs",
    permission: "settings_tariffs",
    children: [],
  },
  {
    component: TariffsCreate,
    path: "/settings/tariffs/create",
    exact: true,
    title: "TariffsCreate",
    permission: "settings_tariffs_create",
    children: [],
  },
  {
    component: TariffsCreate,
    path: "/settings/tariffs/:id",
    exact: true,
    title: "TariffsEdit",
    permission: "settings_tariffs_edit",
    children: [],
  },

  // Additional Tariffs
  {
    component: AdditionalTariffs,
    path: "/settings/additional-tariffs",
    exact: true,
    title: "Additional Tariffs",
    permission: "settings_additional_tariffs",
    children: [],
  },
  {
    component: AdditionalTariffsCreate,
    path: "/settings/additional-tariffs/create",
    exact: true,
    title: "Additional Tariffs Create",
    permission: "settings_additional_tariffs_create",
    children: [],
  },
  {
    component: AdditionalTariffsCreate,
    path: "/settings/additional-tariffs/:id",
    exact: true,
    title: "Additional Tariffs Edit",
    permission: "settings_additional_tariffs_edit",
    children: [],
  },

  // Sales Funnel
  {
    component: SalesFunnel,
    path: "/settings/sales-funnel",
    exact: true,
    title: "SalesFunnel",
    permission: "settings_attribute_categories",
    children: [],
  },
  {
    component: SalesFunnelCreate,
    path: "/settings/sales-funnel/create",
    exact: true,
    title: "SalesFunnelCreate",
    permission: "settings_attribute_categories_create",
    children: [],
  },
  {
    component: SalesFunnelCreate,
    path: "/settings/sales-funnel/:id",
    exact: true,
    title: "SalesFunnelEdit",
    permission: "settings_attribute_categories_edit",
    children: [],
  },

  // About
  {
    component: About,
    path: "/settings/about-us",
    exact: true,
    title: "About us",
    permission: "settings_about_us",
    children: [],
  },

  // Confidentiality
  {
    component: Confidentiality,
    path: "/settings/confidentiality",
    exact: true,
    title: "Confidentiality",
    permission: "settings_confidentiality",
    children: [],
  },
  // Documents
  {
    component: Documents,
    path: "/settings/documents",
    exact: true,
    title: "Documents",
    permission: "settings_documents",
    children: [],
  },
  {
    component: DocumentsCreate,
    path: "/settings/documents/create",
    exact: true,
    title: "DocumentsCreate",
    permission: "settings_documents_edit",
    children: [],
  },
  {
    component: DocumentsCreate,
    path: "/settings/documents/:id",
    exact: true,
    title: "DocumentsEdit",
    permission: "settings_documents_edit",
    children: [],
  },

  // Costs
  {
    component: Costs,
    path: "/settings/costs",
    exact: true,
    title: "Costs",
    permission: "settings_costs",
    children: [],
  },
  {
    component: CostsCreate,
    path: "/settings/costs/create",
    exact: true,
    title: "CostsCreate",
    permission: "settings_costs_create",
    children: [],
  },
  {
    component: CostsCreate,
    path: "/settings/costs/:id",
    exact: true,
    title: "CostsEdit",
    permission: "settings_costs_edit",
    children: [],
  },

  // Organizations
  {
    component: Organizations,
    path: "/settings/organizations",
    exact: true,
    title: "Organizations",
    permission: "settings_organizations",
    children: [],
  },

  // Companies
  // {
  //   component: Companies,
  //   path: "/settings/organizations/companies",
  //   exact: true,
  //   title: "Companies",
  //   permission: "settings_organizations_companies",
  //   children: [],
  // },
  // {
  //   component: CompaniesCreate,
  //   path: "/settings/organizations/companies/create",
  //   exact: true,
  //   title: "CompaniesCreate",
  //   permission: "settings_organizations_companies_create",
  //   children: [],
  // },
  // {
  //   component: CompaniesCreate,
  //   path: "/settings/organizations/companies/:id",
  //   exact: true,
  //   title: "CompaniesEdit",
  //   permission: "settings_organizations_companies_edit",
  //   children: [],
  // },

  // Firms
  {
    component: Firms,
    path: "/settings/organizations/firms",
    exact: true,
    title: "Firms",
    permission: "settings_organizations_companies",
    children: [],
  },
  {
    component: FirmsCreate,
    path: "/settings/organizations/firms/create",
    exact: true,
    title: "FirmsCreate",
    permission: "settings_organizations_companies_create",
    children: [],
  },
  {
    component: FirmsCreate,
    path: "/settings/organizations/firms/:id",
    exact: true,
    title: "FirmsEdit",
    permission: "settings_organizations_companies_edit",
    children: [],
  },

  // Branches
  {
    component: Branches,
    path: "/settings/organizations/branches",
    exact: true,
    title: "Branches",
    permission: "settings_organizations_branches",
    children: [],
  },
  {
    component: BranchesCreate,
    path: "/settings/organizations/branches/create",
    exact: true,
    title: "BranchesCreate",
    permission: "settings_organizations_branches_create",
    children: [],
  },
  {
    component: BranchesCreate,
    path: "/settings/organizations/branches/:id",
    exact: true,
    title: "BranchesEdit",
    permission: "settings_organizations_branches_edit",
    children: [],
  },

  // Investors
  {
    component: Investors,
    path: "/settings/organizations/investors",
    exact: true,
    title: "Investors",
    permission: "settings_organizations_investors",
    children: [],
  },
  {
    component: InvestorsCreate,
    path: "/settings/organizations/investors/create",
    exact: true,
    title: "InvestorsCreate",
    permission: "settings_organizations_investors_create",
    children: [],
  },
  {
    component: InvestorsCreate,
    path: "/settings/organizations/investors/:id",
    exact: true,
    title: "InvestorsEdit",
    permission: "settings_organizations_investors_edit",
    children: [],
  },

  // Car Owners
  {
    component: CarOwners,
    path: "/settings/organizations/car-owners",
    exact: true,
    title: "CarOwners",
    permission: "settings_organizations_investors",
    children: [],
  },
  {
    component: CarOwnersCreate,
    path: "/settings/organizations/car-owners/create",
    exact: true,
    title: "CarOwnersCreate",
    permission: "settings_organizations_investors_create",
    children: [],
  },
  {
    component: CarOwnersCreate,
    path: "/settings/organizations/car-owners/:id",
    exact: true,
    title: "CarOwnersEdit",
    permission: "settings_organizations_investors_edit",
    children: [],
  },

  // Roles
  {
    component: Roles,
    path: "/settings/roles",
    exact: true,
    title: "Roles",
    permission: "settings_roles",
    children: [],
  },

  // Mechanics
  {
    component: Mechanics,
    path: "/settings/roles/mechanics",
    exact: true,
    title: "Mechanics",
    permission: "settings_roles_mechanics",
    children: [],
  },
  {
    component: MechanicsCreate,
    path: "/settings/roles/mechanics/create",
    exact: true,
    title: "MechanicsCreate",
    permission: "settings_roles_mechanics_create",
    children: [],
  },
  {
    component: MechanicsCreate,
    path: "/settings/roles/mechanics/:id",
    exact: true,
    title: "MechanicsEdit",
    permission: "settings_roles_mechanics_create",
    children: [],
  },

  // Salesmen
  {
    component: Salesmen,
    path: "/settings/roles/salesmen",
    exact: true,
    title: "Salesmen",
    permission: "settings_roles_salesmen",
    children: [],
  },
  {
    component: SalesmenCreate,
    path: "/settings/roles/salesmen/create",
    exact: true,
    title: "SalesmenCreate",
    permission: "settings_roles_salesmen_create",
    children: [],
  },
  {
    component: SalesmenCreate,
    path: "/settings/roles/salesmen/:id",
    exact: true,
    title: "SalesmenEdit",
    permission: "settings_roles_salesmen_edit",
    children: [],
  },

  // Cashiers
  {
    component: Cashiers,
    path: "/settings/roles/cashiers",
    exact: true,
    title: "Cashiers",
    permission: "settings_roles_cashiers",
    children: [],
  },
  {
    component: CashiersCreate,
    path: "/settings/roles/cashiers/create",
    exact: true,
    title: "CashiersCreate",
    permission: "settings_roles_cashiers_create",
    children: [],
  },
  {
    component: CashiersCreate,
    path: "/settings/roles/cashiers/:id",
    exact: true,
    title: "CashiersEdit",
    permission: "settings_roles_cashiers_edit",
    children: [],
  },

  // Operators
  {
    component: Operators,
    path: "/settings/roles/operators",
    exact: true,
    title: "Operators",
    permission: "settings_roles_operators",
    children: [],
  },
  {
    component: OperatorsCreate,
    path: "/settings/roles/operators/create",
    exact: true,
    title: "OperatorsCreate",
    permission: "settings_roles_operators_create",
    children: [],
  },
  {
    component: OperatorsCreate,
    path: "/settings/roles/operators/:id",
    exact: true,
    title: "OperatorsEdit",
    permission: "settings_roles_operators_edit",
    children: [],
  },

  // Admins
  {
    component: Admins,
    path: "/settings/roles/admins",
    exact: true,
    title: "Admins",
    permission: "settings_roles_admins",
    children: [],
  },
  {
    component: AdminsCreate,
    path: "/settings/roles/admins/create",
    exact: true,
    title: "AdminsCreate",
    permission: "settings_roles_admins_create",
    children: [],
  },
  {
    component: AdminsCreate,
    path: "/settings/roles/admins/:id",
    exact: true,
    title: "AdminsEdit",
    permission: "settings_roles_admins_edit",
    children: [],
  },

  // Body Type
  {
    component: BodyType,
    path: "/settings/catalog/body-type",
    exact: true,
    title: "BodyType",
    permission: "settings_catalog_categories",
    children: [],
  },
  {
    component: BodyTypeCreate,
    path: "/settings/catalog/body-type/create",
    exact: true,
    title: "BodyTypeCreate",
    permission: "settings_catalog_categories_create",
    children: [],
  },
  {
    component: BodyTypeCreate,
    path: "/settings/catalog/body-type/:id",
    exact: true,
    title: "BodyTypeEdit",
    permission: "settings_catalog_categories_edit",
    children: [],
  },

  // Brands
  {
    component: Brands,
    path: "/settings/catalog/brands",
    exact: true,
    title: "Brands",
    permission: "settings_catalog_brands",
    children: [],
  },
  {
    component: BrandsCreate,
    path: "/settings/catalog/brands/create",
    exact: true,
    title: "BrandsCreate",
    permission: "settings_catalog_brands_create",
    children: [],
  },
  {
    component: BrandsCreate,
    path: "/settings/catalog/brands/:id",
    exact: true,
    title: "BrandsEdit",
    permission: "settings_catalog_brands_edit",
    children: [],
  },

  // Categories
  {
    component: Categories,
    path: "/settings/catalog/categories",
    exact: true,
    title: "Models",
    permission: "settings_catalog_models",
    children: [],
  },
  {
    component: CategoriesCreate,
    path: "/settings/catalog/categories/create",
    exact: true,
    title: "BrandsCreate",
    permission: "settings_catalog_models_create",
    children: [],
  },
  {
    component: CategoriesCreate,
    path: "/settings/catalog/categories/:id",
    exact: true,
    title: "ModelsEdit",
    permission: "settings_catalog_models_edit",
    children: [],
  },
  // Models
  {
    component: Models,
    path: "/settings/catalog/models",
    exact: true,
    title: "Models",
    permission: "settings_catalog_categories",
    children: [],
  },
  {
    component: ModelsCreate,
    path: "/settings/catalog/models/create",
    exact: true,
    title: "ModelsCreate",
    permission: "settings_catalog_categories_create",
    children: [],
  },
  {
    component: ModelsCreate,
    path: "/settings/catalog/models/:id",
    exact: true,
    title: "ModelsEdit",
    permission: "settings_catalog_categories_edit",
    children: [],
  },

  // Permissions
  {
    component: Permissions,
    path: "/settings/permissions",
    exact: true,
    title: "Permissions",
    permission: "settings_permissions",
    children: [],
  },
  {
    component: PermissionsCreate,
    path: "/settings/permissions/:id",
    exact: true,
    title: "PermissionsCreate",
    permission: "settings_permissions_edit",
    children: [],
  },
].map((route) => ({
  ...route,
  path: `/home${route.path}`,
  id: Math.random() + new Date().getTime(),
}))
